import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';


function ChildMenuItem({ item,handleCloseSideBar }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get('tab');
  const formatedTitle = item.title.split(' ').join('-').toLowerCase()

    return (
      <div className="sub__menu__item">
        <NavLink to={item.path} 
        onClick={handleCloseSideBar}
        className={`sub__menu__link ${formatedTitle==tab?'active_sub_menu':''}`}>{item.title}</NavLink>
      </div>
    );
  }
  export default ChildMenuItem