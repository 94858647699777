import React from "react";

function index() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
        fill="#18191F"
        fillOpacity="0.55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5807 11.3798L13.6101 11.8642L13.1204 11.8049C11.3382 11.5775 9.78113 10.8064 8.45911 9.51124L7.81279 8.86863L7.64632 9.34318C7.29378 10.401 7.51901 11.5182 8.25347 12.2695C8.64518 12.6848 8.55704 12.7441 7.88134 12.4969C7.64632 12.4178 7.44067 12.3585 7.42109 12.3882C7.35254 12.4574 7.58756 13.357 7.77362 13.7129C8.02823 14.2073 8.54725 14.6917 9.11523 14.9784L9.59507 15.2058L9.02709 15.2157C8.4787 15.2157 8.45911 15.2256 8.51787 15.4332C8.71372 16.0758 9.48735 16.7579 10.3491 17.0545L10.9563 17.2621L10.4274 17.5785C9.64403 18.0333 8.72352 18.2903 7.803 18.3101C7.36233 18.32 7 18.3595 7 18.3892C7 18.4881 8.19471 19.0417 8.88999 19.2592C10.9758 19.9018 13.4534 19.625 15.314 18.5276C16.636 17.7466 17.958 16.1944 18.575 14.6917C18.9079 13.8909 19.2409 12.4277 19.2409 11.7258C19.2409 11.271 19.2703 11.2117 19.8187 10.6679C20.1418 10.3516 20.4454 10.0056 20.5041 9.9067C20.6021 9.71886 20.5923 9.71886 20.0929 9.88693C19.2605 10.1835 19.143 10.144 19.5543 9.69909C19.8578 9.38272 20.2202 8.80931 20.2202 8.64124C20.2202 8.61159 20.0733 8.66102 19.9068 8.74999C19.7305 8.84886 19.3388 8.99715 19.045 9.08613L18.5162 9.2542L18.0364 8.92795C17.772 8.74999 17.3999 8.55227 17.204 8.49295C16.7046 8.35454 15.9407 8.37431 15.4903 8.53249C14.2662 8.97738 13.4926 10.1242 13.5807 11.3798Z"
        fill="white"
      />
    </svg>
  );
}

export default index;
