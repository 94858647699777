import InputField from 'components/blocks/InputField'
import React from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from 'store/actions';

const UpdatePassword = () => {
  const {token,userInfo} = useSelector(state=>state.auth);
  const {updatingPassword} = useSelector(state=>state.userAccount);
  const dispatch = useDispatch();


  const passwordSchema = yup.object().shape({
    oldPassword: yup.string().required("Old password is required"),
    password: yup.string().required("Password is required")
  .test('password', 'Password must contain minimum of 8 character, uppercase, lowercase,number, special character and no space', function (value) {
    // Perform multiple checks here
    return (
      /[A-Z]/.test(value) &&
      /[a-z]/.test(value) &&
      /[0-9]/.test(value) &&
      /[!@#$%^&*()-+]+/.test(value) &&
      !/\s+/.test(value) &&
      /^.{8,}$/.test(value)
    );
  }),
  password_confirmation: yup.string().required('Confirm Password is required')
  .oneOf([yup.ref('password')], "Confirm Password doesn't match")
  
  });
  const { register, handleSubmit,reset, errors } = useForm({
    resolver: yupResolver(passwordSchema),
    mode:'all'
  });

  const submit = (data)=>{
    dispatch(updatePassword(data,token,userInfo))
  }
  return (
    <div className='profile__info'>
        <p className='title mb-3'>Update Password</p>
        <form onSubmit={handleSubmit(submit)}>
        <div className='form__container'>
        <div className='form__wrapper'>
                <InputField 
                id="oldPassword"
                label="Enter Old Password" 
                name="oldPassword"
                placeHolder="Enter Old Password"
                register={register}
                error={errors.oldPassword?.message}
                type="password" />
            </div>
            
            <div className='form__wrapper'>
                <InputField 
                id="password"
                label="Enter New Password" 
                name="password"
                placeHolder="Enter New Password"
                register={register}
                error={errors.password?.message}
                type="password" />
            </div>
            <div className='form__wrapper'>
                <InputField 
                id="password_confirmation"
                label="Confirm New Password" 
                name="password_confirmation"
                placeHolder="Confirm New Password"
                register={register}
                error={errors.password_confirmation?.message}
                type="password" />
            </div>
            
            <div className='button__wrapper'>
            <button className='button button--purple button__content' 
            disabled={updatingPassword}>Update changes</button>
            </div>
        </div>
        </form>
    </div>
  )
}

export default UpdatePassword