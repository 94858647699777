import React from 'react';

const BackArrow = ({color="#FFF"}) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
  >
    <path
      fill={color}
      d="M18.4 7.2H2.44l6.111-5.82c.32-.305.333-.812.028-1.132-.304-.32-.81-.332-1.131-.027L.468 6.869C.168 7.17 0 7.573 0 8c0 .427.167.83.483 1.145l6.965 6.634c.155.148.353.221.552.221.211 0 .422-.083.58-.248.304-.32.292-.826-.028-1.131L2.415 8.8H18.4c.441 0 .8-.36.8-.8 0-.442-.359-.8-.8-.8z"
    />
  </svg>
);

export default BackArrow;
