import React from "react";
import agentImage from "../../buyer-assets/img/gig.png";
import styles from "./deliveryoption.module.css";

const DeliverOption = ({ courier }) => {
  return (
    <div className={styles.deliveryOption}>
      <div className={styles.agentContainer}>
        <img src={courier?.courier_image} className={styles.agentImage} />
        <div>
          <h3 className={styles.name}>{courier?.courier_name}</h3>
          <p className={styles.duration}>
            {courier?.delivery_eta_time} ({courier?.delivery_eta})
          </p>
        </div>
      </div>
      <p className={styles.price}>₦{courier?.total}</p>
    </div>
  );
};

export default DeliverOption;
