import React from "react";
import { useState } from "react";

function DropdownAction({ title, tabIndex, children }) {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div
      className="dropdown__container"
      tabIndex={tabIndex}
      onBlur={() => setShowDropdown(false)}
    >
      <div
        className="dropdown__action"
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        {title}
      </div>

      {showDropdown ? (
        <div className="dropdown__content">{children}</div>
      ) : null}
    </div>
  );
}

export default DropdownAction;
