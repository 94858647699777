import React from "react";

function index() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99984 5.33268C10.2265 3.66602 12.9998 2.66602 15.9998 2.66602C23.3598 2.66602 29.3332 8.63935 29.3332 15.9993C29.3332 23.3593 23.3598 29.3327 15.9998 29.3327C8.63984 29.3327 2.6665 23.3593 2.6665 15.9993C2.6665 13.586 3.30649 11.3193 4.43982 9.35933L15.9998 15.9993"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.10665 11.9467C8.39998 13.1333 8 14.52 8 16C8 20.4133 11.5867 24 16 24C20.4133 24 24 20.4133 24 16C24 11.5867 20.4133 8 16 8C14.7867 8 13.6267 8.26668 12.6 8.76001"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default index;
