import { LoaderProcessing } from "components/shared";
import { PepperestWebServices } from "libs/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Settings from "../Settings";
const ConnectToFacebookStore = "EscrowBackend/auth/facebook";

const Home = () => {
  const history = useHistory();
  const { userProfile, loading: loadingProfile } = useSelector(
    (state) => state.userAccount
  );

  const {
    error,
  } = useSelector((state) => state.dashboard); 

  
  const loginWithFacebook = () => {
    const windowOptions = "width=600,height=400,left=100,top=100";
    window.open(
      `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`,
      "Share",
      windowOptions
    );
    // window.location.href = `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`;
  };

  


  return (
    <main className="overvieW">
      { error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
       
            <Settings /> 
         
      )}
     
    </main>
  );
};

export default Home;