import React from "react";

function index() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0004 9.54797C23.9204 9.53464 23.8271 9.53464 23.7471 9.54797C21.9071 9.48131 20.4404 7.97464 20.4404 6.10797C20.4404 4.2013 21.9738 2.66797 23.8804 2.66797C25.7871 2.66797 27.3204 4.21464 27.3204 6.10797C27.3071 7.97464 25.8404 9.48131 24.0004 9.54797Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6269 19.2549C24.4536 19.5615 26.4669 19.2415 27.8803 18.2948C29.7603 17.0415 29.7603 14.9882 27.8803 13.7349C26.4536 12.7882 24.4136 12.4682 22.5869 12.7882"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96014 9.54797C8.04014 9.53464 8.13347 9.53464 8.21347 9.54797C10.0535 9.48131 11.5201 7.97464 11.5201 6.10797C11.5201 4.2013 9.98681 2.66797 8.08014 2.66797C6.17347 2.66797 4.64014 4.21464 4.64014 6.10797C4.65347 7.97464 6.12014 9.48131 7.96014 9.54797Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 19.2549C7.50659 19.5615 5.49325 19.2415 4.07992 18.2948C2.19992 17.0415 2.19992 14.9882 4.07992 13.7349C5.50659 12.7882 7.54658 12.4682 9.37325 12.7882"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 19.5089C15.9199 19.4956 15.8266 19.4956 15.7466 19.5089C13.9066 19.4422 12.4399 17.9356 12.4399 16.0689C12.4399 14.1622 13.9733 12.6289 15.8799 12.6289C17.7866 12.6289 19.3199 14.1756 19.3199 16.0689C19.3066 17.9356 17.8399 19.4556 15.9999 19.5089Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.12 23.7104C10.24 24.9637 10.24 27.0171 12.12 28.2704C14.2533 29.6971 17.7466 29.6971 19.88 28.2704C21.76 27.0171 21.76 24.9637 19.88 23.7104C17.76 22.2971 14.2533 22.2971 12.12 23.7104Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default index;
