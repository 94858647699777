import React from "react";

const CopyIcon = ({
  onClick,
  color = "#18191F",
  width = "14",
  height = "15",
}) => (
  <svg
    onClick={onClick}
    className="cursor__pointer"
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33366 8.02496V10.475C9.33366 12.5166 8.51699 13.3333 6.47533 13.3333H4.02533C1.98366 13.3333 1.16699 12.5166 1.16699 10.475V8.02496C1.16699 5.98329 1.98366 5.16663 4.02533 5.16663H6.47533C8.51699 5.16663 9.33366 5.98329 9.33366 8.02496Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8337 4.52496V6.97496C12.8337 9.01663 12.017 9.83329 9.97533 9.83329H9.33366V8.02496C9.33366 5.98329 8.51699 5.16663 6.47533 5.16663H4.66699V4.52496C4.66699 2.48329 5.48366 1.66663 7.52533 1.66663H9.97533C12.017 1.66663 12.8337 2.48329 12.8337 4.52496Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyIcon;
