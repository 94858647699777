import React from "react";

const LinkIcon = ({ color = "#2F80ED", onClick }) => (
  <svg
    onClick={onClick}
    style={{ cursor: "pointer" }}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.33 9.50002C11.33 12.0175 9.29538 14.0521 6.77788 14.0521C4.26038 14.0521 2.2258 12.0175 2.2258 9.50002C2.2258 6.98252 4.26038 4.94794 6.77788 4.94794"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.55911 9.5C8.55911 6.87958 10.6887 4.75 13.3091 4.75C15.9295 4.75 18.0591 6.87958 18.0591 9.5C18.0591 12.1204 15.9295 14.25 13.3091 14.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkIcon;
