export default {
  PROFILEINFO: "/EscrowBackend/api/userAccount/getProfileInfo",
  UPDATE_PROFILE: "/EscrowBackend/api/userAccount/modifyProfileInfo",
  UPDATE_BANK_ACCOUNT: "/EscrowBackend/api/userAccount/addBankAccount",
  UPDATE_USER_TYPE: "/EscrowBackend/api/userAccount/changeAccountType",
  UPDATE_API_SETTING: "/EscrowBackend/api/userAccount/changeAPIsetting",
  UPDATE_NOTIFICATION_SETTING:
    "/EscrowBackend/api/userAccount/changeNotificationSetting",
  UPDATE_PASSWORD: "/EscrowBackend/api/resetPassword",
  ADD_SOCIAL_MEDIA_LINK: "/EscrowBackend/api/userAccount/addSocialMediaLink",
  REMOVE_SOCIAL_MEDIA_LINK:
    "/EscrowBackend/api/userAccount/removeSocialMediaLink",
  GET_BANKS: "/EscrowBackend/api/banks/getBanks",
  ACCOUNT_NAME_ENQUIRY: "/EscrowBackend/api/banks/nameEnquiryVFD",
  UPGRADE_PREMIUM: "/EscrowBackend/api/subscribe/subPayment",
  CONFIRM_PREMIUM_PAYMENT_STATUS: "/EscrowBackend/api/plan/upgrade",
  SETUP_STORE: "/EscrowBackend/api/createStore",
  RESEND_VERIFICATION_EMAIL: "/EscrowBackend/api/email/resend",
  SUBSCRIBE_PREMIUM: "/EscrowBackend/api/tokenizeCard",
  UNSUBSCRIBE_PREMIUM: "/EscrowBackend/api/plan/unsubscribe",
  BILLING_HISTORY: "/EscrowBackend/api/billing/history",
  CUSTOMIZE_STORE: "/EscrowBackend/api/customizeStore",
  CUSTOMIZE_STORE_URL: "/EscrowBackend/api/customizeStoreUrl ",
  PRICING_PLANS: "/EscrowBackend/api/subscriptions/all",
};

export const ErrorMessages = {
  getProfileInfoFailed:
    "We are currently unable to get your pfofile information",
  updateProfileFailed: "We are currently unable to update your profile",
  updateBankAccountFailed:
    "We are currently unable to update your Bank details",
  updateUserTypeFailed: "We are currently unable to update your Account Type,",
  updateApiSettingFailed: "We are currently unable to update your Api Setting",
  updateNotificationSettingFailed:
    "We are currently unable to update your Notification setting",
  updatePasswordFailed:
    "We are currently unable to effect your password change",
  upgradePremium: "We are currently unable to upgrade your plan to Premium",
  setupStore: "We are currently unable to setup your store, try again",
};
