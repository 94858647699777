import * as actionTypes from '../actions/actionTypes';
import {
    updateObject
} from '../utility';

const initialState = {

    loading: false,
    loaded: false,
    error: null,
}

const loading = (state, action) => {
    return updateObject(state, {
        loading: true
    });
}

const loadedInvoice = (state, action) => {
    return updateObject(state, action.invoice);
}



const failedToLoadSingleInvoice = (state, action) => {
    return updateObject(state, {
        loading: false,
        loaded: false,
        error: action.error
    });
}


const selectPage = (state, action) => {
    return updateObject(state, { page: action.page })
}


export const changeInvoiceStatus = () => {
    return {
        type: actionTypes.CHANGE_INVOICE_STATUS,
    };
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_SINGLE_INVOICE:
            return loading(state, action);
        case actionTypes.FINISHED_LOADING_SINGLE_INVOICE:
            return loadedInvoice(state, action);
        case actionTypes.CHANGE_INVOICE_STATUS:
            return changeInvoiceStatus(state, action);
        case actionTypes.LOADING_SINGLE_INVOICE_FAILED:
            return failedToLoadSingleInvoice(state, action)

        default:
            return state;
    }
};

export default reducer;
