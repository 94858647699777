import React,{createContext,useContext,useState} from'react';
    const StateContext=createContext();
    
    export const ActiveStateContextProvider = ({ children }) => {
      const [screenSize, setScreenSize] = useState(undefined);
      const [activeMenu, setActiveMenu] = useState(false);
   
      return (
        
        <StateContext.Provider value={{ activeMenu, screenSize, setScreenSize, setActiveMenu }}>
          {children}
        </StateContext.Provider>
      );
    };
export const useStateContext=()=>useContext(StateContext);