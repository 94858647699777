import InputField from "components/blocks/InputField";
import React, { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { MdOutlineFileUpload } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { formatDateString } from "libs/utils";
import { useDispatch, useSelector } from "react-redux";
import { uploadInvoiceProof } from "store/actions/invoices";

const UploadInvoiceProof = ({ setProofModal, selectedInvoice }) => {
  const [files, setFiles] = useState(null);
  const { processing, actionCompleted, actionError } = useSelector(
    (state) => state.invoices
  );
  const [imageError, setImageError] = useState(false);
  const fileTypes = ["png", "jpg", "jpeg", "pdf", "doc", "docx"];
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (files) => {
      if (files) {
        setFiles(files);
        console.log({ files });
        setImageError("");
      }
    },
    [files]
  );
  const removeFile = (index) => {
    const updatedFile = [...files];
    updatedFile.splice(index, 1);
    setFiles(updatedFile);
  };

  const profSchema = yup.object().shape({
    customer_name: yup.string().required("Customer name is required"),
    cost: yup.string().required("Cost is required"),
    end_date: yup.string().required("Due date is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profSchema),
  });

  const handleUpload = (data) => {
    if (!files) {
      setImageError("Choose a file to continue");
      return;
    }
    let formData = new FormData();
    formData.append("orderID", selectedInvoice.orderID);
    Array.from(files).map((file, index) => {
      formData.append(`order_docs[${index}]`, file);
    });
    dispatch(uploadInvoiceProof(formData));
  };

  useEffect(() => {
    if (actionCompleted) {
      setProofModal(false);
    }
  }, [actionCompleted]);
  return (
    <main className="upload__prof">
      <div className="modal__component">
        <div className="modal__title">Report Dispute</div>
        <div className="modal__content ">
          <form onSubmit={handleSubmit(handleUpload)} className="w-100">
            <section>
              <p className="error__message">{actionError}</p>
              <div className="form__wrapper">
                <InputField
                  id="customer_name"
                  label="Customer Name"
                  name="customer_name"
                  defaultValue={selectedInvoice?.buyerName}
                  register={register}
                  error={errors.customer_name?.message}
                  type="text"
                />
              </div>
              <div className="row row__wrapper">
                <div className="col">
                  <div className="form__wrapper">
                    <InputField
                      require={true}
                      id="cost"
                      label="Cost"
                      name="cost"
                      defaultValue={selectedInvoice?.cost}
                      placeHolder="Cost"
                      register={register}
                      error={errors.cost?.message}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form__wrapper">
                    <InputField
                      require={true}
                      id="end_date"
                      label="Due Date"
                      name="end_date"
                      placeHolder="Due Date"
                      defaultValue={formatDateString(selectedInvoice?.date)}
                      register={register}
                      error={errors.end_date?.message}
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <label className="form__label">Upload Checkin Proof</label>
              <section className="upload__wrapper">
                <div
                  className={`form__wrapper border__wrapper ${
                    imageError ? "border-danger" : ""
                  }`}
                >
                  <FileUploader
                    handleChange={handleChange}
                    multiple={true}
                    types={fileTypes}
                  >
                    <div className="file__uploader__wrapper ">
                      <MdOutlineFileUpload color="#5E60CE" size={30} />
                      <div>
                        <p className="title">
                          Drag and drop an image, or{" "}
                          <span className="upload__button">Browse</span>
                        </p>
                        <p className="resolution__text">
                          High resolution images (png,jpg,gif)
                        </p>
                      </div>
                    </div>
                  </FileUploader>
                </div>
              </section>
              <div className="selected__files">
                {files ? (
                  <>
                    {Array.from(files).map((file, index) => (
                      <div className="file" key={index}>
                        <p>{file?.name}</p>
                        <span
                          className="remove__file"
                          onClick={() => removeFile(index)}
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              <div className="error__message">{imageError && imageError}</div>

              <div className="modal__control">
                <button
                  disabled={processing}
                  type="button"
                  className="button button--purple-outline px-4"
                  onClick={() => setProofModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="button button--purple px-4"
                  type="submit"
                  disabled={processing}
                >
                  Submit
                </button>
              </div>
            </section>
          </form>
        </div>
      </div>
    </main>
  );
};

export default UploadInvoiceProof;
