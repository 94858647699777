import React from "react";
import styles from "./carticon.module.css";
import { CartSVG } from "../../buyer-assets";
import { useCartContext } from "../../buyer-context/cartContext";
import { Link, useRouteMatch } from "react-router-dom";

const CartIcon = () => {
	const {
		state: { cart },
	} = useCartContext();

	let homeMatch = useRouteMatch("/store/:merchantID");
	let productMatch = useRouteMatch("/product/:id");

	if (!cart.length) return null;

	if (!productMatch && !homeMatch) return null;

	return (
		<Link to="/cart" className={styles.wrapper}>
			<div style={{ position: "relative" }}>
				<CartSVG />
				<div className={styles.number}>{cart?.length}</div>
			</div>
		</Link>
	);
};

export default CartIcon;
