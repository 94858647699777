import PlusIcon from "components/vectors/PlusIcon";
import { PepperestWebServices } from "libs/constants";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const WelcomeHeader = ({ handleClick }) => {
  const source = localStorage.getItem("source");
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.auth);

  const ConnectToFacebookStore = "EscrowBackend/auth/facebook";

  const finishSetup = () => {
    localStorage.removeItem("source");
    history.push("/dashboard/complete-setup");
  };

  const loginWithFacebook = () => {
    window.location.href = `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`;
  };

  return (
    <section className="header__section__wrapper">
      <div className="user__info">
        <p className="title">
          Hello <span>{userInfo?.firstName},</span>
        </p>
        <p className="description">Welcome to peppa.io</p>
      </div>

      {source === "onboarding" ? (
        <button
          className="button button--purple text__md button__content"
          onClick={finishSetup}
        >
          Finish Setup
        </button>
      ) : (
        <button
          className="button button--purple-outline button__content"
          onClick={loginWithFacebook}
        >
          <span className="bold"> Connect social store</span>
        </button>
      )}
    </section>
  );
};

export default WelcomeHeader;
