import InputField from "components/blocks/InputField";
import { EditIcon } from "components/vectors";
import User from "components/vectors/User";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import storeBanner from "../../../assets/images/store-banner.png";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { fileTypes } from "libs/constants";
import { addTransactionPin } from "store/actions/wallet";
import { customizeStore, customizeStoreUrl } from "store/actions/userAccount";
import { handleCopy, notify } from "libs/utils";
import { Link, useHistory } from "react-router-dom";
import CrownIcon from "components/vectors/CrownIcon";
import { LoaderProcessing } from "components/shared";
import Switch from "components/dashboardComponent/Switch";

const StoreSetting = () => {
  let url = window.location.origin;
  const {
    userProfile,
    customizingStore,
    loading,
    error: profileError,
  } = useSelector((state) => state.userAccount);

  const { addTransactionPinError, addingTransactionPin, addedTransactionPin } =
    useSelector((state) => state.wallet);

  useEffect(() => {
    if (addedTransactionPin) {
      setPinValue("");
    }
  }, [addedTransactionPin]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [logoPreview, setLogoPreview] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [pickup, setPickup] = useState(false);
  const [logistics, setLogistics] = useState(false);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [merchantCode, setMerchantCode] = useState("loading...");
  const [error, setError] = useState("");
  const [pinValue, setPinValue] = useState("");
  const hasFreeTrial = userProfile?.hasUsedFreeTrial === false;

  const storeSchema = yup.object().shape({
    store_name: yup.string().required("Store name is required"),
  });
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(storeSchema),
  });

  const updateStore = (data) => {
    data.self_delivery = logistics == true ? 1 : 0;
    let formData = new FormData();
    if (logo) {
      formData.append("store_logo", logo);
    }
    if (banner) {
      formData.append("store_banner", banner);
    }
    if (userProfile?.businessname !== data?.store_name) {
      formData.append("store_name", data?.store_name);
    }
    dispatch(customizeStore(formData));
  };

  const updateStoreUrl = () => {
    if (merchantCode) {
      dispatch(customizeStoreUrl({ merchantid: merchantCode }));
    } else {
      notify("Merchant code is required", "error");
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Only allow up to 4 digits
    if (/^\d{0,4}$/.test(inputValue)) {
      setPinValue(inputValue);
    }
  };

  const setTransactionPin = () => {
    if (pinValue) {
      dispatch(addTransactionPin({ pin: pinValue }));
    } else {
      notify("Enter complete pin", "error");
    }
  };

  const handleFileChange = (file) => {
    const imageFile = file;
    const reader = new FileReader();
    reader.onload = (event) => {
      setLogoPreview(event.target.result);
    };
    reader.readAsDataURL(imageFile);
    setLogo(file);
  };

  const handleBannerChange = (file) => {
    const imageFile = file;
    const reader = new FileReader();
    reader.onload = (event) => {
      setBannerPreview(event.target.result);
    };
    reader.readAsDataURL(imageFile);
    setBanner(file);
  };

  const copyLink = () => {
    handleCopy(`${url}/store/${userProfile?.merchantCode}`);
  };

  const upgradePlan = () => {
    history.push("/dashboard/upgrade-plan");
  };

  const handleMerchantCode = (e) => {
    const { value } = e.target;
    if (!value || value == "") {
      setError("Merchant code is required");
      setMerchantCode(value);
    } else {
      setMerchantCode(value);
      setError("");
    }
  };

  const handlePickup = () => {
    setPickup((prev) => !prev);
  };

  const handleLogistics = () => {
    setLogistics((prev) => {
      const newValue = !prev;
      const self_delivery = newValue == true ? 1 : 0;
      dispatch(customizeStore({ self_delivery, merchantid: merchantCode }));
      return newValue;
    });
  };

  useEffect(() => {
    if (userProfile?.businessname) {
      reset({
        store_name: userProfile?.businessname,
      });
      setMerchantCode(userProfile?.merchantCode);
      setLogistics(userProfile?.self_delivery);
    }
  }, [userProfile, logoPreview]);

  return (
    <div className="store__setting">
      {loading ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : profileError ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <p className="page__title">Store Settings</p>
          <main className="main__section">
            <section className="store__info__box">
              <p className="title">Set Up Transaction pin</p>
              <div className="info__container">
                <div className="store__name__container col-12 col-md-8 p-0 ">
                  <div className="form__wrapper col-12 col-md-8 p-0">
                    <InputField
                      value={pinValue}
                      onChange={handleChange}
                      id="transaction_pin"
                      label="Enter 4 digits transaction pin"
                      name="transaction_pin"
                      placeHolder="Enter Transaction Pin"
                      type="number"
                      max="9999"
                    />
                  </div>
                </div>
                <div className="border">
                  <button
                    type="button"
                    className="button button__content button--purple"
                    disabled={addingTransactionPin}
                    onClick={setTransactionPin}
                  >
                    {userProfile?.hasTransactionPin
                      ? "Reset Transaction Pin "
                      : "Set Transaction Pin"}
                  </button>
                </div>
              </div>
            </section>

            <div className="store__info__box">
              <p className="title">Store Link</p>
              <div className="store__info">
                <div className="store__link">
                  <div
                    className={`url__box ${
                      userProfile?.isPremium || hasFreeTrial ? "" : "disabled"
                    }`}
                  >
                    <p className="link">{`${url}/store`}</p>
                    <p className="merchant__link">
                      <input
                        className={`store__code ${
                          userProfile?.isPremium || hasFreeTrial
                            ? ""
                            : "disabled"
                        }`}
                        type="text"
                        value={merchantCode}
                        onChange={handleMerchantCode}
                      />
                    </p>
                  </div>
                  <span className="copy__button" onClick={copyLink}>
                    Copy
                  </span>
                </div>

                <div className="store__link__action">
                  {userProfile?.isPremium || hasFreeTrial ? (
                    <button
                      className="button button--purple button__content"
                      onClick={updateStoreUrl}
                      disabled={customizingStore}
                    >
                      Save Changes
                    </button>
                  ) : (
                    <button
                      className="button upgrade__plan__button button__content "
                      onClick={upgradePlan}
                    >
                      <p className="d-flex gap-1 align-items-center">
                        <CrownIcon /> <span>Upgrade Plan</span>
                      </p>
                    </button>
                  )}

                  <Link
                    to={`/store/${userProfile?.merchantCode}`}
                    target="_blank"
                  >
                    <button className="button button--purple-outline button__content">
                      View store
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <section className="store__info__box">
              <p className="title">Banner Image</p>
              <div className="store__banner__container">
                <div className="banner__detail">
                  <p className="banner__desc">
                    Customize and add desired imagery to replace our default
                    banner to your store, giving it the desired personal touch.
                  </p>

                  {userProfile?.isPremium || hasFreeTrial ? (
                    <FileUploader
                      handleChange={handleBannerChange}
                      types={fileTypes}
                    >
                      <button className="button button--purple button__content">
                        Change banner image
                      </button>
                    </FileUploader>
                  ) : (
                    <button
                      className="button upgrade__plan__button button__content"
                      onClick={upgradePlan}
                    >
                      <p className="d-flex gap-1 align-items-center">
                        <CrownIcon /> <span>Upgrade Plan</span>
                      </p>
                    </button>
                  )}
                </div>
                <div className="banner">
                  {bannerPreview ? (
                    <>
                      <img src={bannerPreview} alt="store Banner" />
                    </>
                  ) : (
                    <>
                      {userProfile?.store_banner ? (
                        <img
                          src={userProfile?.store_banner}
                          alt="store_banner"
                        />
                      ) : (
                        <>
                          {" "}
                          <img src={storeBanner} alt="Store Banner" />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
            <section className="store__info__box">
              <p className="title">Store Name & Logo</p>
              <form onSubmit={handleSubmit(updateStore)}>
                <div className="info__container">
                  <div className="store__name__container col-12 col-md-8 p-0 ">
                    <div className="upload__wrapper">
                      <FileUploader
                        handleChange={handleFileChange}
                        types={fileTypes}
                      >
                        <section className="logo__wrapper">
                          <p className="store__logo">Store Logo</p>
                          <div className="image__container">
                            {logoPreview ? (
                              <>
                                <img
                                  src={logoPreview}
                                  className="logo__preview"
                                />
                              </>
                            ) : (
                              <>
                                {userProfile?.store_logo ? (
                                  <img
                                    src={userProfile?.store_logo}
                                    className="logo__preview"
                                  />
                                ) : (
                                  <>AK</>
                                )}
                              </>
                            )}
                          </div>
                        </section>
                        <div className="edit__image__icon">
                          <EditIcon color="white" height="13" width="13" />
                        </div>
                      </FileUploader>
                    </div>
                    <div className="form__wrapper col-12 col-md-8 p-0">
                      <InputField
                        id="store_name"
                        label="Store Name"
                        name="store_name"
                        placeHolder="Akon & Sons store"
                        register={register}
                        error={errors.store_name?.message}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="border">
                    <button
                      className="button button__content button--purple"
                      disabled={customizingStore}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </section>

            <section className="store__info__box">
              <p className="title">Shipping Preferences</p>
              <main className="store__preferences">
                <div className="preference">
                  <section className="preference__content">
                    <h3 className="title">Self Pickup?</h3>
                    <p className="description">
                      Enable this if you want to give buyers the option to pick
                      up packages from your store?
                    </p>
                  </section>
                  <section className="action__switch">
                    <Switch
                      name="Pickup"
                      handleSwitch={handlePickup}
                      checked={pickup}
                    />
                  </section>
                </div>
                <div className="preference">
                  <section className="preference__content">
                    <h3 className="title">Logistics Partner</h3>
                    <p className="description">
                      Enable this if you already have and use your own logistics
                      arrangement for delivering packages to buyers
                    </p>
                  </section>
                  <section className="action__switch">
                    <Switch
                      name="Logistics"
                      handleSwitch={handleLogistics}
                      checked={logistics || userProfile?.self_delivery == true}

                    />
                  </section>
                </div>
              </main>
            </section>
          </main>
        </>
      )}
    </div>
  );
};

export default StoreSetting;
