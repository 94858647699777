import React from "react";

const PlusIcon = ({
  handleClick,
  color = "#FE8F01",
  height = "10",
  width = "10",
}) => (
  <svg
    width={width}
    style={{ cursor: "pointer" }}
    height={height}
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M7.00126 2.91797V11.0846M2.91797 7.00126H11.0846"
      stroke={color}
      strokeWidth="1.16666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
