import React from "react";

const SenderMessage = ({ chat,attachments }) => {
  return (
    <div className="sender__box__container">
      <div className="sender__box">
        <p className="message">{chat?.message}</p>
      </div>
      {attachments.length > 0 ? 

          attachments?.map((attachment,index) =>(
            <div key={index} >
          <img src={attachment?.attachment} className="attachment__file" alt="image"/> 
          </div>
          ))
          :
          ""
          }
      <p className="message__time">{chat?.time}</p>
    </div>
  );
};

export default SenderMessage;
