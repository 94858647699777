import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Messaging,
  MessagingErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAlert } from "./alert";


export const sendMessage = (data) => {
    return (dispatch, getState) => {
      let { token } = getState().auth;
      dispatch({ type: actionTypes.SEND_MESSAGE });
      const headers = {
        Authorization: token,
      };
      
      PepperestAxios.post(Messaging.SEND_MESSAGE, data, { headers })
        .then((response) => {
          console.log(response?.data);
          dispatch({ type: actionTypes.SEND_MESSAGE_SUCCESS });
          dispatch(setAlert("Message sent", "success"));
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch({ type: actionTypes.SEND_MESSAGE_FAIL });
            return dispatch(
              setAlert(error?.response?.data?.ResponseMessage, "error")
            );
          }
        });
    };
  };


export const getMyChats = () => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_MY_CHATS });
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Messaging.MY_CHATS, { headers })
      .then((response) => {
        const { chats } = response.data;
        dispatch({
          type: actionTypes.GET_MY_CHATS_SUCCESS,
          chats
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : MessagingErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_MY_CHATS_FAIL,
          error: message,
        });
      });
  };
};

export const getChatMessages = (chat_id) => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_CHAT_MESSAGES });
    const headers = {
      Authorization: token,
    };
    
    const params = {
        chat_id
      };
    PepperestAxios.get(Messaging.GET_CHAT_MESSAGES, { headers, params })
      .then((response) => {
       // console.log("chat mesages respponse : ",response)
        const { chatMessages } = response.data;
        dispatch({
          type: actionTypes.GET_CHAT_MESSAGES_SUCCESS,
          chatMessages,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : MessagingErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_CHAT_MESSAGES_FAIL,
          error: message,
        });
      });
  };
};


