import React, { useState } from "react";
import styles from "./Card.module.css";
import { CiCalendar } from "react-icons/ci";
import { Link } from "react-router-dom";

const Card = ({ product, setModalOpen, setCancelModalOpen, onOrderSelect }) => {
  const [status] = useState("in-transit");

  const returnStatusColor = (status) => {
    var string;
    switch (status.toLowerCase()) {
      case "shipped":
        string = "#6941C6";
        break;
      case "delivered":
        string = "#00D222";
        break;
      default:
        string = "#ffa500";
        break;
    }
    return string;
  };

  const handleDispute = () => {
    onOrderSelect(product);
    setModalOpen(true);
  };
  const handleCancelOrder = () => {
    onOrderSelect(product);
    setCancelModalOpen(true);
  };

  return (
    <>
      <div className={styles["productCard-wrapper"]}>
        <div className={styles["productCard-content"]}>
          <div className={styles["productCard-img"]}>
            <img src={product?.orderItems[0]?.image} alt="product" />
          </div>
          <div className="w-100">
            <p
              className={styles["status"]}
              style={{ color: returnStatusColor(product?.status) }}
            >
              {product?.status}
            </p>
            <div className={styles["cardContent-wrapper"]}>
              <p className={styles.orderTitle}>
                {product?.orderItems[0]?.productname}
              </p>
              <p className={styles.orderPrice}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "NGN",
                }).format(product.cost)}
                <span className="ml-2">total</span>
              </p>
            </div>
            <p className={styles.orderNum}>
              Order Number: <span>{product?.orderId}</span>
            </p>
            <div className={styles["cardContent-wrapper"]}>
              <div className={styles["cardContent-wrapper2"]}>
                <div className={styles.orderDate}>
                  <span>
                    <CiCalendar />
                  </span>
                  Order Date:{" "}
                  {product.orderDate.slice(
                    0,
                    product.orderDate.lastIndexOf("/")
                  )}
                </div>
                <div className={styles.orderDate}>
                  <span>
                    <CiCalendar />
                  </span>
                  Delivery Date: {product.date}
                </div>
              </div>

              <div className="d-flex gap-3 align-items-center">
                <Link
                  to={`/tracker/${product?.orderId}`}
                  className={styles["productCard-btn"]}
                >
                  See Details
                </Link>
                {product?.status === "Delivered" ? (
                  <button
                    className={styles["dispute-btn"]}
                    onClick={handleDispute}
                  >
                    Report Dispute
                  </button>
                ) : null}

                {[
                  "Completed",
                  "Canceled",
                  "Pending",
                  "Confirmed",
                  "Shipped",
                  "Disputed",
                ].includes(product?.status) ? null : (
                  <button
                    className={styles["dispute-btn"]}
                    onClick={handleCancelOrder}
                  >
                    Cancel order
                  </button>
                )}
              </div>

              {/* {product?.status === "delivered" ? (
								<button
									className={styles["productCard-btn"]}
									onClick={() => {
										setModalOpen(true);
									}}
								>
									Report Dispute
								</button>
							) : (
								<Link to={`/tracker/${product?.orderId}`} className={styles["productCard-btn"]}>
									See Details
								</Link>
							)} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
