import { CloseIcon } from "components/vectors";
import React, { useEffect, useState } from "react";


const PayOptionModal = ({close,tokenizeCard,upgradingPremium}) => {

 
  return (
    <div className="modal__component">
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
           Select a payment option
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
       
          <button
               onClick={()=>tokenizeCard("wallet")}
               disabled={upgradingPremium}
                className="button button--green mt-4"
                
              >
                Pay with wallet
              </button>

              <button
                onClick={()=>tokenizeCard()}
                disabled={upgradingPremium}
                className="button button--purple mt-4"  
              >
                Paystack
              </button>
              <br/>
          </div>    

    </div>
  );
};

export default PayOptionModal;


