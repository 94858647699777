import React from "react";

const AtmCard = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size + 3}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.27783 12.7461H22.0558"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0558 9.8978V18.6368C22.0192 22.1202 21.0658 23.0002 17.4357 23.0002H5.89792C2.20677 23.0002 1.27783 22.0835 1.27783 18.4412V9.8978C1.27783 6.59777 2.04784 5.53443 4.94454 5.36331C5.23787 5.35109 5.55569 5.33887 5.89792 5.33887H17.4357C21.1269 5.33887 22.0558 6.25554 22.0558 9.8978Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7223 5.55894V14.1024C25.7223 17.4024 24.9523 18.4657 22.0556 18.6369V9.89787C22.0556 6.25561 21.1267 5.33893 17.4355 5.33893H5.89772C5.55549 5.33893 5.23767 5.35116 4.94434 5.36338C4.981 1.88001 5.93438 1 9.56442 1H21.1023C24.7934 1 25.7223 1.91668 25.7223 5.55894Z"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 19.1006H7.35221"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96777 19.1006H14.1723"
        stroke="#FE8F01"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AtmCard;
