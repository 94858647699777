import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Products,
  ProductsErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAddress } from "./orders";
import { Orders } from "../../libs/constants/PepperestWebServices";
import axios from "axios";
import { setAlert } from "./alert";
import { getMerchantStoreProducts } from ".";
import { mixPanelUserProperty } from "libs/mixPanel";
import { notify } from "libs/utils";

export const publishSingleProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    if (!user) {
      return dispatch(holdProductValuesInStore(extraParams));
    }
    dispatch(publishingSingleProduct());
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
      "Content-Type": "multipart/form-data",
    };
    const body = {
      merchantID: user?.customerID,
      ...extraParams,
    };

    PepperestAxios.post(Products.PUBLISH_SINGLE_PRODUCT, extraParams, {
      headers,
    })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch(publishSingleProductSuccess());
        dispatch(setAlert(`Product Added succesfully`, "success"));
        dispatch(loadedProduct(products, meta, links));
        mixPanelUserProperty("product_uploaded", {});
      })
      .catch((error) => {
        const message = error.response?.data
          ? error.response?.data?.message
          : "Couldn't connect to server";
        dispatch(failedToPublishSingleProduct(message));
        dispatch(setAlert(message, "error"));
      });
  };
};

export const publishProductForNonUser = (token, user) => {
  return (dispatch, getState) => {
    let productDetails = getState().products.holdProductValuesInStore;
    if (!productDetails.length) return;
    dispatch(publishSingleProduct(token, user, productDetails));
  };
};

export const holdProductValuesInStore = (extraParams) => {
  return {
    type: actionTypes.HOLD_PRODUCT_VALUES_IN_STORE,
    payload: extraParams,
  };
};

export const publishSingleSocialProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const body = {
      merchantID: user?.customerID,
      ...extraParams,
    };

    let formData = new FormData();

    for (const key in body) {
      formData.append(key, body[key]);
    }
    PepperestAxios.post(Products.PUBLISH_SINGLE_SOCIAL_PRODUCT, formData, {
      headers,
    })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch(loadedProduct(products, meta, links));
        dispatch(setConnectSocialStoreStatus(true));

        dispatch(setAlert("Product Added successfully!", "success"));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const publishSocialProducts = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.PUBLISH_SOCIAL_PRODUCT });
    const { token, userInfo } = getState().auth;
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
      "Content-Type": "application/json",
    };
    const body = {
      merchantID: userInfo?.customerID,
      ...params,
    };

    PepperestAxios.post(Products.PUBLISH_SOCIAL_PRODUCTS, body, {
      headers,
    })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch({ type: actionTypes.PUBLISH_SOCIAL_PRODUCT_SUCCESS });
        dispatch(loadedProduct(products, meta, links));
        dispatch(setConnectSocialStoreStatus(true));
        dispatch(setAlert("Product Added successfully!", "success"));
      })
      .catch((error) => {
        const message = error.response?.data
          ? error.response.data.ResponseMessage
          : "We couldn't make this happen, try again";
        console.log(error.response);
        dispatch({ type: actionTypes.PUBLISH_SOCIAL_PRODUCT_FAIL });
        dispatch(setAlert(message, "error"));
      });
  };
};

export const getSingleProduct = (token, user, productID) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_PRODUCT,
    });

    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      productID: productID,
    };

    PepperestAxios.get(Products.GET_SINGLE_PRODUCT, { headers, params })
      .then((response) => {
        const product = response.data.product;
        dispatch(loadedSingleProduct(product));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch({ type: actionTypes.LOADING_PRODUCT_FAIL });
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const getAllMerchantProducts = (token, user) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user.customerID,
      // ...extraParams,
    };

    PepperestAxios.get(Products.PRODUCTS, { headers, params })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch(loadedProduct(products, meta, links));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const getProductCategories = (token, user) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRODUCT_CATEGORIES,
    });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };

    PepperestAxios.get(Products.PRODUCT_CATEGORIES, { headers })
      .then((response) => {
        const { categories } = response.data;
        dispatch({
          type: actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
          categories,
        });
      })
      .catch((error) => {
        console.error(error.response);
        //dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const getProductWeightUnits = (token, user) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRODUCT_WEIGHT_UNIT,
    });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };

    PepperestAxios.get(Products.PRODUCT_WEIGHT_UNIT, { headers })
      .then((response) => {
        const { units } = response.data;
        dispatch({
          type: actionTypes.GET_PRODUCT_WEIGHT_UNIT_SUCCESS,
          units,
        });
      })
      .catch((error) => {
        console.error(error.response);
        //dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const updateProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_PRODUCT });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };

    PepperestAxios.post(Products.UPDATE_PRODUCT, extraParams, { headers })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch(loadedProduct(products, meta, links));
        dispatch(getMerchantStoreProducts(user.merchantCode));
        dispatch({ type: actionTypes.UPDATE_PRODUCT_COMPLETE });
        dispatch(setAlert("You Product was updated successfully", "success"));
        mixPanelUserProperty("productdetails_updated", {});
      })
      .catch((error) => {
        dispatch({ type: actionTypes.UPDATE_PRODUCT_FAIL });
        console.error(error.response);
        if (error.response && error?.response?.status === 401) {
          return dispatch(setAlert(error.response.data.message, "error"));
        }
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const removeProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PRODUCT,
    });
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user?.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Products.REMOVE_PRODUCT, {
      params,
      headers,
    })
      .then((response) => {
        const products = response.data.products.data;
        // const meta = response.data.products.meta
        // const links = response.data.products.links
        dispatch(updateStoreProducts(products));
        dispatch(getMerchantStoreProducts(user.merchantCode));
        dispatch({ type: actionTypes.DELETE_PRODUCT_COMPLETE });
        dispatch(setAlert("Product Deleted Succesfully", "success"));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch({
          type: actionTypes.DELETE_PRODUCT_FAIL,
        });
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const removeMultipleProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PRODUCT,
    });
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      merchantID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Products.REMOVE_MULTIPLE_PRODUCT, params, { headers })
      .then((response) => {
        const products = response?.data?.products?.data;
        dispatch(updateStoreProducts(products));
        dispatch(getMerchantStoreProducts(user.merchantCode));
        dispatch({ type: actionTypes.DELETE_PRODUCT_COMPLETE });
        dispatch(setAlert("Products Deleted Succesfully", "success"));
      })
      .catch((error) => {
        console.error({ error: error.response?.data });
        dispatch({
          type: actionTypes.DELETE_PRODUCT_FAIL,
        });
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const toggleProductStock = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_PRODUCT_STOCK,
    });
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = {
      merchantID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.post(Products.TOGGLE_PRODUCT_STOCK, params, { headers })
      .then((response) => {
        const products = response?.data?.products?.data;
        dispatch(updateStoreProducts(products));
        dispatch(getMerchantStoreProducts(user?.merchantCode));
        dispatch({ type: actionTypes.TOGGLE_PRODUCT_STOCK_SUCCESS });
        dispatch(setAlert("Products stock updated", "success"));
      })
      .catch((error) => {
        console.error({ error: error.response?.data });
        dispatch({
          type: actionTypes.TOGGLE_PRODUCT_STOCK_FAIL,
        });
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const getFacebookPages = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingFacebookPages());
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user?.customerID,
      provider: "facebook",
      ...extraParams,
    };
    PepperestAxios.get(Products.GET_INFO, {
      params,
      headers,
    })
      .then((response) => {
        const pages = response.data.pages;
        dispatch(loadedFacebookPages(pages));
      })
      .catch((error) => {
        console.error(error.response);
        const errorMessage = error?.response?.data
          ? error?.response?.data?.ResponseMessage
          : "We couldn't make this happen at the moment, please try again";
        dispatch(failedToLoadFacebookPages(errorMessage));
        notify(errorMessage, "error");
      });
  };
};

export const getPageData = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingFacebookProduct());
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user?.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Products.GET_PAGE_PRODUCTS, {
      params,
      headers,
    })
      .then((response) => {
        const items = [];
        response.data.items.forEach((item, index) => {
          items.push({
            id: index + 1,
            url: item,
            published: false,
          });
        });

        dispatch(loadedFacebookProduct(items));
      })
      .catch((error) => {
        console.error(error.response);
        const errorMessage = error?.response?.data
          ? error?.response?.data?.ResponseMessage
          : "We couldn't make this happen at the moment, please try again";
        dispatch(failedToLoadFacebookProduct(errorMessage));
        notify(errorMessage, "error");
      });
  };
};

export const loadProduct = (token, user, extraParams = {}) => {
  return (dispatch) => {
    dispatch(loadingProduct());
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user?.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Products.PRODUCTS, {
      params,
      headers,
    })
      .then((response) => {
        const products = response.data.products.data;
        const meta = response.data.products.meta;
        const links = response.data.products.links;
        dispatch(loadedProduct(products, meta, links));
      })
      .catch((error) => {
        dispatch(failedToLoadProduct(ProductsErrorMessages.getHistoryFailed));
      });
  };
};

export const loadAllProducts = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user.customerID,
      ...extraParams,
    };
    PepperestAxios.get(Products.ALL_PRODUCTS, {
      params,
      headers,
    })
      .then((response) => {
        const products = response.data.products;
        dispatch(loadedAllProducts(products));
      })
      .catch((error) => {
        console.log({ error });
        // dispatch(failedToLoadProduct(ProductsErrorMessages.getHistoryFailed));
      });
  };
};

export const loadProductsAndAddress = (token, user, extraParams = {}) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user?.customerID,
    };
    const params = {
      merchantID: user.customerID,
      customerID: user.customerID,
      ...extraParams,
    };
    axios
      .all([
        PepperestAxios.get(Products.PRODUCTS, { params, headers }),
        PepperestAxios.get(Orders.ADDRESS, { params, headers }),
      ])
      .then(
        axios.spread((...responses) => {
          const products = responses[0].data.products.data;
          const meta = responses[0].data.products.meta;
          const links = responses[0].data.products.links;
          dispatch(loadedProduct(products, meta, links));
          dispatch(setAddress(responses[1].data.addresses));
        })
      )
      .catch((error) => {
        console.error(error.response);
        dispatch(setAlert("An error occurred", "error"));
      });
  };
};

export const loadingProduct = () => {
  return {
    type: actionTypes.LOADING_PRODUCTS,
  };
};

export const setConnectSocialStoreStatus = (status) => {
  return {
    type: actionTypes.CONNECT_SOCIAL_STORE_STATUS,
    payload: status,
  };
};

export const loadedProduct = (products, meta, links) => {
  const update = {
    products,
    meta,
    links,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_PRODUCTS,
    update: update,
  };
};

export const loadedAllProducts = (products) => {
  const update = {
    allProducts: products,
  };
  return {
    type: actionTypes.LOADED_ALL_PRODUCTS,
    update: update,
  };
};
export const loadedSingleProduct = (product) => {
  const update = {
    singleProduct: product,
    products: [],
    meta: null,
    links: null,
    loading: false,
    loaded: true,
    error: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_SINGLE_PRODUCTS,
    update: update,
  };
};

const updateStoreProducts = (products) => {
  return {
    type: actionTypes.UPDATE_STORE_PRODUCTS,
    products,
  };
};

export const publishSocialProductComplete = () => {
  return {
    type: actionTypes.PUBLISH_SOCIAL_PRODUCT_COMPLETE,
  };
};

export const failedToLoadProduct = (error) => {
  return {
    type: actionTypes.LOADING_PRODUCTS_FAILED,
    error: error,
  };
};

export const failedToPublishSingleProduct = (error) => {
  return {
    type: actionTypes.PUBLISH_SINGLE_PRODUCT_FAIL,
    error: error,
    success: false,
  };
};

export const publishingSingleProduct = () => {
  return {
    type: actionTypes.PUBLISHING_SINGLE_PRODUCT,
  };
};

export const publishSingleProductComplete = () => {
  return {
    type: actionTypes.PUBLISH_SINGLE_PRODUCT_COMPLETE,
  };
};

export const publishSingleProductSuccess = () => {
  return {
    type: actionTypes.PUBLISH_SINGLE_PRODUCT_SUCCESS,
    success: true,
  };
};

export const loadingFacebookPages = () => {
  return {
    type: actionTypes.LOADING_FACEBOOK_PAGES,
  };
};

export const loadedFacebookPages = (pages) => {
  const update = {
    pages,
    loadingFacebookPages: false,
    loadedFacebookPages: true,
    errorFacebookPages: null,
    loading: false,
  };
  return {
    type: actionTypes.FINISHED_LOADING_FACEBOOK_PAGES,
    update: update,
  };
};
export const failedToLoadFacebookPages = (error) => {
  return {
    type: actionTypes.LOADING_FACEBOOK_PAGES_FAILED,
    error: error,
  };
};

export const loadingFacebookProduct = () => {
  return {
    type: actionTypes.LOADING_FACEBOOK_PRODUCTS,
  };
};

export const loadedFacebookProduct = (items) => {
  const update = {
    items,
    loadingFacebookProducts: false,
    loadedFacebookProducts: true,
    errorFacebookProducts: null,
  };
  return {
    type: actionTypes.FINISHED_LOADING_FACEBOOK_PRODUCTS,
    update: update,
  };
};
export const failedToLoadFacebookProduct = (error) => {
  return {
    type: actionTypes.LOADING_FACEBOOK_PRODUCTS_FAILED,
    error: error,
  };
};
export const selectPage = (page) => {
  return {
    type: actionTypes.SELECT_PAGE,
    page: page,
  };
};

export const createProductDiscount = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT });
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };

    PepperestAxios.post(Products.ADD_DISCOUNT, data, { headers })
      .then((response) => {
        console.log({ response });
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT_SUCCESS });
        dispatch(setAlert("Discount created successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.ADD_PRODUCT_DISCOUNT_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const addDiscountComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT_COMPLETE });
  };
};

export const getProductDiscounts = (extraParams) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.GET_PRODUCT_DISCOUNTS });
    const params = {
      ...extraParams,
    };
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };

    PepperestAxios.get(Products.GET_DISCOUNTS, { params, headers })
      .then((response) => {
        console.log({ response });
        const { discounts } = response.data;
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({
          type: actionTypes.GET_PRODUCT_DISCOUNTS_SUCCESS,
          discounts: {
            data: discounts?.data,
            meta: discounts?.meta,
          },
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not make this happen at the moment, try again";
        dispatch({
          type: actionTypes.GET_PRODUCT_DISCOUNTS_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const updateProductDiscount = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Products.UPDATE_DISCOUNT, data, { headers })
      .then((response) => {
        console.log({ response });
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT_SUCCESS });
        dispatch(setAlert("Discount updated successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.ADD_PRODUCT_DISCOUNT_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const deleteProductDiscount = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.DELETE_DISCOUNT });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Products.REMOVE_DISCOUNT, data, { headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.DELETE_DISCOUNT_SUCCESS });
        dispatch(setAlert("Discount deleted successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.DELETE_DISCOUNT_FAIL,
          error: message,
        });
      });
  };
};


export const createCoupon = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.ADD_COUPON });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Products.ADD_COUPON, data, { headers })
      .then((response) => {
        console.log({ response });
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({ type: actionTypes.ADD_COUPON_SUCCESS });
        dispatch(setAlert("Coupon created successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.ADD_COUPON_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const addcouponComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_PRODUCT_DISCOUNT_COMPLETE });
  };
};

export const getMyCoupons = (extraParams) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.GET_COUPONS });
    const params = {
      ...extraParams,
    };
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.get(Products.MY_COUPONS, { params, headers })
      .then((response) => {
        console.log({ response });
        const { coupons } = response.data;
        //dispatch(getMerchantStoreProducts(userInfo.merchantCode));
        dispatch({
          type: actionTypes.GET_COUPONS_SUCCESS,
          coupons: {
            data: coupons?.data,
            meta: coupons?.meta,
          },
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not make this happen at the moment, try again";
        dispatch({
          type: actionTypes.GET_COUPONS_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const updateCoupon = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.ADD_COUPON });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Products.UPDATE_COUPON, data, { headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.ADD_COUPON_SUCCESS });
        dispatch(setAlert("Coupon updated successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.ADD_COUPON_FAIL,
          error: message,
        });
        //return dispatch(setAlert(error.response?.data?.ResponseMessage, "error"));
      });
  };
};

export const deleteCouponCode = (data) => {
  return (dispatch, getState) => {
    const { token, userInfo } = getState().auth;
    dispatch({ type: actionTypes.DELETE_COUPON });
    const headers = {
      Authorization: token,
      customerID: userInfo.customerID,
    };

    PepperestAxios.post(Products.REMOVE_COUPON, data, { headers })
      .then((response) => {
        console.log({ response });
        dispatch({ type: actionTypes.DELETE_COUPON_SUCCESS });
        dispatch(setAlert("Coupon deleted successfully", "success"));
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.ResponseMessage
          : "Could not connect to server";
        dispatch({
          type: actionTypes.DELETE_COUPON_FAIL,
          error: message,
        });
      });
  };
};
