import React from "react";

import { Oval } from "react-loader-spinner";

const Preloader = () => {
	return (
		<>
			<div className="d-flex align-items-center justify-content-center mt-auto" style={{height:"100dvh"}}>
				<Oval
					height={40}
					width={40}
					color="#5e60ce"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel="oval-loading"
					secondaryColor="#5e60ce"
					strokeWidth={2}
					strokeWidthSecondary={2}
				/>
			</div>
		</>
	);
};

export default Preloader;
