import { LoadingListItem } from "components/blocks";
import InputField from "components/blocks/InputField";
import DropdownAction from "components/dashboardComponent/DropdownAction";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import Pagination from "components/dashboardComponent/Pagination";
import { SearchBar } from "components/dashboardComponent/SearchBar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ThreeDot } from "components/vectors";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { changeOrderStatus, loadOrders } from "store/actions";
import { currencyFormatter } from "store/utility";
import {
  cancelMyPurchasesStatus,
  myPurchasesStatus,
  orderStatus,
  pickupOrderStatus,
} from "libs/constants";
import { loadMyPurchases } from "store/actions/orders";
import { checkOrderStatus } from "libs/utils";

const MyPurchases = ({ history }) => {
  const { token, userInfo } = useSelector((state) => state.auth);
  const { myPurchases, loading, error, updatingOrderStatus, success } =
    useSelector((state) => state.orders);
  const [pageType, setPageType] = useState("All");
  const [pageSize, setPageSize] = useState(10);
  const [issueModal, setIssueModal] = useState(false);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const issueSchema = yup.object().shape({
    order_status: yup.string().required("Order Status is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(issueSchema),
  });

  const getMyPurchases = (page, pageType) => {
    const params = {
      page: page,
      query: query,
      per_page: pageSize,
    };
    dispatch(loadMyPurchases(token, userInfo, pageType, params));
  };
  let timeout = useRef();
  const handleSearch = (event) => {
    event.persist();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuery(event.target.value);
    }, 2000);
  };

  useEffect(() => {
    getMyPurchases(1, pageType);
  }, [pageType, query, success, pageSize]);

  const handleOrderStatusModal = (order) => {
    if (order.status == "Pending" || order.status == "Completed") return;
    setSelectedOrder(order);
    setIssueModal((prev) => !prev);
  };

  const handleViewOrder = (order) => {
    history.push(`/dashboard/mypurchases/${order?.orderRef}`);
  };

  const handleChangeOrderStatus = (data) => {
    dispatch(
      changeOrderStatus(
        token,
        userInfo,
        selectedOrder.orderId,
        data.order_status
      )
    );
  };

  const handlePageSize = (size) => {
    setPageSize(size);
  };

  useEffect(() => {
    if (success) {
      setIssueModal(false);
      reset({
        order_status: "",
      });
    }
  }, [success]);

  return (
    <main className="orders">
      <div className="header__section__wrapper">
        <p className="title__text">
          {`My Purchases (${
            myPurchases?.meta?.total ? myPurchases?.meta?.total : 0
          })`}
        </p>
      </div>

      <div className="search__product__action__wrapper">
        <div className="search__wrapper">
          <SearchBar placeholder="Search for orders" onChange={handleSearch} />
        </div>
      </div>
      <div className="order__types__wrapper">
        {orderTypes.map((order, index) => (
          <p
            key={index}
            className={`order__type ${
              pageType === order ? "active__order__type" : ""
            }`}
            onClick={() => setPageType(order)}
          >
            {order} Purchases
          </p>
        ))}
      </div>

      {loading ? (
        <div className="list-body">
          <ul>
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
            <LoadingListItem />
          </ul>
        </div>
      ) : (
        <>
          <section className="table__wrapper">
            <table className="product__table table__large">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Address</th>
                  <th>Date</th>
                  <th>Total price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {error ? (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {error}
                    </td>
                  </tr>
                ) : null}

                {myPurchases.orders.length == 0 && !error ? (
                  <tr>
                    <td colSpan={7} className="text-center">
                      You dont have any orders yet
                    </td>
                  </tr>
                ) : (
                  <>
                    {myPurchases?.orders.map((order, index) => (
                      <tr key={index}>
                        <td>#{order.orderId}</td>
                        <td>{order.customerName}</td>
                        <td>{order.address}</td>
                        <td>{order.date}</td>
                        <td>{currencyFormatter.format(order.cost)}</td>
                        <td width={150}>
                          <span
                            className={`order__status ${
                              order.status.toLowerCase() == "in progress"
                                ? "status__in__progress d-block"
                                : `status__${order.status.toLowerCase()} d-block`
                            }`}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td>
                          <DropdownAction title={<ThreeDot />} tabIndex={index}>
                            <li
                              disabled={
                                order?.status == "Pending" ||
                                order?.status == "Completed" ||
                                order?.status == "Canceled"
                                  ? true
                                  : false
                              }
                              className={`dropdown__item text__black ${
                                order.status == "Pending" ||
                                order?.status == "Completed" ||
                                order?.status == "Canceled"
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => handleOrderStatusModal(order)}
                            >
                              Change order status
                            </li>
                            <li
                              className={`dropdown__item text__black`}
                              onClick={() => handleViewOrder(order)}
                            >
                              View order
                            </li>
                          </DropdownAction>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={myPurchases?.meta?.current_page}
              totalCount={myPurchases?.meta?.total || 0}
              pageSize={myPurchases?.meta?.per_page}
              onChangePageSize={handlePageSize}
              onPageChange={(page) => getMyPurchases(page, pageType)}
            />
          </section>
        </>
      )}

      <ModalComponent show={issueModal} centered size="md">
        <div className="modal__component">
          <div className="modal__title">Update order status</div>
          <div className="modal__content ">
            <form
              onSubmit={handleSubmit(handleChangeOrderStatus)}
              className="col-md-12"
            >
              <section>
                <div className="form__wrapper">
                  <InputField
                    id="order_status"
                    label="Order Status"
                    name="order_status"
                    placeHolder="Order Status"
                    register={register}
                    options={
                      selectedOrder &&
                      checkOrderStatus(myPurchasesStatus, selectedOrder?.status)
                    }
                    error={errors.order_status?.message}
                    type="select"
                  />
                </div>

                <div className="modal__control">
                  <button
                    type="button"
                    className="button button--purple-outline px-4"
                    onClick={() => setIssueModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button button--purple px-4"
                    type="submit"
                    disabled={updatingOrderStatus}
                  >
                    Save Change
                  </button>
                </div>
              </section>
            </form>
          </div>
        </div>
      </ModalComponent>
    </main>
  );
};

export default MyPurchases;

const orderTypes = [
  "All",
  "Pending",
  "Shipped",
  "Delivered",
  "Paid",
  "Disputed",
];
