import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "store/utility";

const CustomerInfo = ({
  customerName,
  customerEmail,
  phone,
  totalTransaction,
  totalSpent,
  status,
}) => (
  <>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Full Name</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{customerName}</p>
      </div>
    </div>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Email</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{customerEmail}</p>
      </div>
    </div>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Phone Number</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{phone || "No. not provided"}</p>
      </div>
    </div>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Total Transactions</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{totalTransaction}</p>
      </div>
    </div>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Total Spent</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>
          {currencyFormatter.format(totalSpent)}
        </p>
      </div>
    </div>
    <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Status</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{status}</p>
      </div>
    </div>
  </>
);

CustomerInfo.propTypes = {
  status: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  customerEmail: PropTypes.string.isRequired,
  totalTransaction: PropTypes.number.isRequired,
  totalSpent: PropTypes.number.isRequired,
};

export default CustomerInfo;
