/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { useHistory } from "react-router-dom";
import { mixPanelUserProperty } from "libs/mixPanel";

const BlackBackArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
      <path
        fill="black"
        d="M18.4 7.2H2.44l6.111-5.82c.32-.305.333-.812.028-1.132-.304-.32-.81-.332-1.131-.027L.468 6.869C.168 7.17 0 7.573 0 8c0 .427.167.83.483 1.145l6.965 6.634c.155.148.353.221.552.221.211 0 .422-.083.58-.248.304-.32.292-.826-.028-1.131L2.415 8.8H18.4c.441 0 .8-.36.8-.8 0-.442-.359-.8-.8-.8z"
      />
    </svg>
  );
};

const CommonHeader = ({
  history,
  showCart,
  commonHeaderTitle,
  cart,
  user,
  noUserCartCount,
  products,
}) => {
  const isMerchant = user?.usertype === "Merchant" || user?.usertype === "Both";
  const page = useHistory();

  console.log(page, "paage");

  const isBuyer = user?.usertype === "Buyer" || user?.usertype === "Both";
  const onBoardingPage = page.location.pathname == "/onboarding";

  const cartCount = user ? (cart ? cart.items_count : 0) : noUserCartCount;

  return (
    <div className="nsHeader-alternate">
      <div className="nsHeader-main">
        <h4 className="nsHeader-main-title d-flex align-items-center">
          {onBoardingPage !== "/onboarding" && (
            <div
              onClick={() => {
                page.goBack();
              }}
              className="pr-2"
            >
              <BlackBackArrow />
            </div>
          )}
          {commonHeaderTitle}
        </h4>

        {/* <div>This is where we place the searchbar</div> */}

        {showCart ? (
          <>
            {isBuyer && (
              <div
                role="button"
                tabIndex="0"
                className="flex-row button button-md button--orange d-flex"
                onClick={() => {
                  history.push("/cart");
                }}
              >
                My Cart
                <div className="mBadge-alt">{cartCount}</div>
              </div>
            )}
            {!user ? (
              <div
                role="button"
                tabIndex="0"
                className="flex-row button button-md button--orange d-none d-md-flex"
                onClick={() => {
                  history.push("/cart");
                }}
              >
                My Cart
                <div className="mBadge-alt">{cartCount}</div>
              </div>
            ) : onBoardingPage ? (
              <div className="d-none d-md-flex" style={{ width: "max-content", gap: "1rem" }}>
                <a
                  href="http://bit.ly/joinpepperestcomm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-list-item "
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    mixPanelUserProperty("community_clicked", {});
                  }}
                >
                  Join Community
                </a>
                <a
                  href="https://prosperofpepperest.substack.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-list-item"
                  style={{ textDecoration: "none" }}
                >
                  Visit Our Blog
                </a>
                <></>
              </div>
            ) : null}
          </>
        ) : onBoardingPage ? (
          <div className="d-none d-md-flex" style={{ width: "max-content", gap: "1rem" }}>
            <a
              href="http://bit.ly/joinpepperestcommnity"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-list-item "
              style={{ textDecoration: "none" }}
            >
              Join Community
            </a>
            <a
              href="https://prosperofpepperest.substack.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-list-item"
              style={{ textDecoration: "none" }}
            >
              Visit Our Blog
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

CommonHeader.defaultProps = {
  showCart: false,
};

CommonHeader.propTypes = {
  showCart: PropTypes.bool,
  commonHeaderTitle: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    user: state.auth.userInfo,
    noUserCartCount: state.cart.noUserCartLength,
    products: state.merchant.products,
  };
};

export default connect(mapStateToProps)(withRouter(CommonHeader));
