import { PAGE_TYPES as InvoicesPageTypes } from "libs/constants/PepperestWebServices/Invoices";

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  addresses: [],
  addressError: null,
  redirectUrl: null,
  loading: true,
  confirmInvoiceDetails: null,
  creatingInvoice: false,
  success: false,
  updating: false,
  processing: false,
  actionCompleted: false,
};
// Object.values(InvoicesPageTypes).forEach((pageTypes) => {
//   initialState[pageTypes] =
//     pageTypes !== InvoicesPageTypes.DASHBOARD
//       ? {
//           invoices: [],
//           meta: null,
//           links: null,
//           loading: false,
//           loaded: false,
//           error: null,
//         }
//       : {
//           total: "--",
//           canceled: "--",
//           pending: "--",
//           confirmed: "--",
//           today_invoices: [],
//           yesterday_invoices: [],
//           loading: false,
//           loaded: false,
//           error: null,
//         };
// });

const loading = (state, action) => {
  const updatedPaymentLoadingState = updateObject(state[action.pageType], {
    loading: true,
  });
  const update = {};
  update[action.pageType] = updatedPaymentLoadingState;
  return updateObject(state, update);
};

const loadedInvoices = (state, action) => {
  const update = {};
  update[action.pageType] = action.pageTypeUpdate;
  return updateObject(state, update);
};

const failedToLoadInvoices = (state, action) => {
  const errorChanges = {
    loading: false,
    loaded: false,
    error: action.error,
  };
  const updatedPaymentLoadingState = updateObject(
    state[action.pageType],
    errorChanges
  );
  const update = {};
  update[action.pageType] = updatedPaymentLoadingState;
  return updateObject(state, update);
};

const redirect = (state, action) => {
  const update = { redirectUrl: action.redirectUrl };
  return updateObject(state, update);
};

const confirmLoading = (state, action) => {
  return updateObject(state, { loading: action.loading });
};

const invoiceConfirmed = (state, action) => {
  return updateObject(state, action.payload);
};

const createInvoice = (state, action) => {
  const update = { creatingInvoice: true };
  return updateObject(state, update);
};

const createInvoiceSuccess = (state, action) => {
  const update = { success: true, creatingInvoice: false };
  return updateObject(state, update);
};

const createInvoiceFail = (state, action) => {
  const update = {
    error: action.error,
    success: false,
    creatingInvoice: false,
  };
  return updateObject(state, update);
};

const createInvoiceComplete = (state, action) => {
  const update = { success: false, creatingInvoice: false };
  return updateObject(state, update);
};

const getInvoices = (state, action) => {
  const update = { loading: true, error: "" };
  return updateObject(state, update);
};

const getInvoicesSuccess = (state, action) => {
  const update = { allInvoices: action.invoices, loading: false, error: "" };
  return updateObject(state, update);
};

const getInvoicesFail = (state, action) => {
  const update = { allInvoices: [], error: action.error, loading: false };
  return updateObject(state, update);
};

const getInvoice = (state, action) => {
  const update = { loading: true, error: "" };
  return updateObject(state, update);
};

const getInvoiceSuccess = (state, action) => {
  const update = {
    invoice: action.order,
    loading: false,
    error: "",
  };
  return updateObject(state, update);
};

const getInvoiceFail = (state, action) => {
  const update = { invoice: null, error: action.error, loading: false };
  return updateObject(state, update);
};

const changeInvoiceStatus = (state, action) => {
  const update = { updating: true, error: "", success: false };
  return updateObject(state, update);
};

const changeInvoiceStatusSuccess = (state, action) => {
  const update = { success: true, loading: false, error: "" };
  return updateObject(state, update);
};

const changeInvoiceStatusFail = (state, action) => {
  const update = { error: action.error, updating: false, success: false };
  return updateObject(state, update);
};

const updateInvoiceComplete = (state, action) => {
  const update = { success: false, updating: false };
  return updateObject(state, update);
};

const updateInvoiceStart = (state, action) => {
  const update = { updating: true, error: "" };
  return updateObject(state, update);
};
const updateInvoiceSuccess = (state, action) => {
  const update = { success: true, error: "", updating: false };
  return updateObject(state, update);
};

const updateInvoiceFail = (state, action) => {
  const update = { success: false, error: action.error, updating: false };
  return updateObject(state, update);
};

const verifyInvoicePayment = (state, action) => {
  const update = { loading: true, error: "", success: false };
  return updateObject(state, update);
};
const verifyInvoicePaymentSuccess = (state, action) => {
  const update = {
    paymentInfo: action.paymentInfo,
    success: true,
    error: "",
    loading: false,
  };
  return updateObject(state, update);
};

const verifyInvoicePaymentFail = (state, action) => {
  const update = { success: false, error: action.error, loading: false };
  return updateObject(state, update);
};

const uploadProf = (state, action) => {
  const update = { processing: true, actionError: "" };
  return updateObject(state, update);
};
const uploadProfSuccess = (state, action) => {
  const update = { actionCompleted: true, processing: false, actionError: "" };
  return updateObject(state, update);
};

const uploadProfFail = (state, action) => {
  const update = {
    actionError: action.error,
    actionCompleted: false,
    processing: false,
  };
  return updateObject(state, update);
};
const uploadProfComplete = (state, action) => {
  const update = { actionCompleted: false, processing: false };
  return updateObject(state, update);
};

const requestInvoicePayment = (state, action) => {
  const update = { processing: true, actionError: "" };
  return updateObject(state, update);
};
const requestInvoicePaymentSuccess = (state, action) => {
  const update = { actionCompleted: true, processing: false, actionError: "" };
  return updateObject(state, update);
};

const requestInvoicePaymentfFail = (state, action) => {
  const update = {
    actionError: action.error,
    actionCompleted: false,
    processing: false,
  };
  return updateObject(state, update);
};

const deleteInvoice = (state, action) => {
  const update = { processing: true, actionError: "" };
  return updateObject(state, update);
};
const deleteInvoiceSuccess = (state, action) => {
  const update = { actionCompleted: true, processing: false, actionError: "" };
  return updateObject(state, update);
};

const deleteInvoiceFail = (state, action) => {
  const update = {
    actionError: action.error,
    actionCompleted: false,
    processing: false,
  };
  return updateObject(state, update);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INVOICES:
      return loading(state, action);
    case actionTypes.FINISHED_LOADING_INVOICES:
      return loadedInvoices(state, action);
    case actionTypes.LOADING_INVOICES_FAILED:
      return failedToLoadInvoices(state, action);
    case actionTypes.PLACE_INVOICE_REDIRECT:
      return redirect(state, action);
    case actionTypes.CONFIRM_LOADING:
      return confirmLoading(state, action);
    case actionTypes.CONFIRM_INVOICE_DETAILS:
      return invoiceConfirmed(state, action);
    case actionTypes.LOADING_SINGLE_INVOICE:
      return loadedInvoices(state, action);
    case actionTypes.CREATE_INVOICE:
      return createInvoice(state, action);
    case actionTypes.CREATE_INVOICE_SUCCESS:
      return createInvoiceSuccess(state, action);
    case actionTypes.CREATE_INVOICE_FAIL:
      return createInvoiceFail(state, action);
    case actionTypes.CREATE_INVOICE_COMPLETE:
      return createInvoiceComplete(state, action);
    case actionTypes.GET_INVOICES:
      return getInvoices(state, action);
    case actionTypes.GET_INVOICES_SUCCESS:
      return getInvoicesSuccess(state, action);
    case actionTypes.GET_INVOICES_FAIL:
      return getInvoicesFail(state, action);
    case actionTypes.GET_INVOICE:
      return getInvoice(state, action);
    case actionTypes.GET_INVOICE_SUCCESS:
      return getInvoiceSuccess(state, action);
    case actionTypes.GET_INVOICE_FAIL:
      return getInvoiceFail(state, action);
    case actionTypes.CHANGE_INVOICE_STATUS_START:
      return changeInvoiceStatus(state, action);
    case actionTypes.CHANGE_INVOICE_STATUS_SUCCESS:
      return changeInvoiceStatusSuccess(state, action);
    case actionTypes.CHANGE_INVOICE_STATUS_FAIL:
      return changeInvoiceStatusFail(state, action);
    case actionTypes.UPDATING_INVOICE:
      return updateInvoiceStart(state, action);
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      return updateInvoiceSuccess(state, action);
    case actionTypes.UPDATE_INVOICE_FAIL:
      return updateInvoiceFail(state, action);
    case actionTypes.UPDATE_INVOICE_COMPLETE:
      return updateInvoiceComplete(state, action);
    case actionTypes.VERIFY_INVOICE_PAYMENT:
      return verifyInvoicePayment(state, action);
    case actionTypes.VERIFY_INVOICE_PAYMENT_SUCCESS:
      return verifyInvoicePaymentSuccess(state, action);
    case actionTypes.VERIFY_INVOICE_PAYMENT_FAIL:
      return verifyInvoicePaymentFail(state, action);
    case actionTypes.UPLOAD_INVOICE_PROOF:
      return uploadProf(state, action);
    case actionTypes.UPLOAD_INVOICE_PROOF_SUCCESS:
      return uploadProfSuccess(state, action);
    case actionTypes.UPLOAD_INVOICE_PROOF_FAIL:
      return uploadProfFail(state, action);
    case actionTypes.UPLOAD_INVOICE_PROOF_COMPLETE:
      return uploadProfComplete(state, action);
    case actionTypes.REQUEST_INVOICE_PAYMENT:
      return requestInvoicePayment(state, action);
    case actionTypes.REQUEST_INVOICE_PAYMENT_SUCCESS:
      return requestInvoicePaymentSuccess(state, action);
    case actionTypes.REQUEST_INVOICE_PAYMENT_FAIL:
      return requestInvoicePaymentfFail(state, action);
    case actionTypes.DELETE_INVOICE:
      return deleteInvoice(state, action);
    case actionTypes.DELETE_INVOICE_SUCCESS:
      return deleteInvoiceSuccess(state, action);
    case actionTypes.DELETE_INVOICE_FAIL:
      return deleteInvoiceFail(state, action);

    default:
      return state;
  }
};

//decided to add this

export default reducer;
