import React from "react";
import ChatUser from "../../../buyer-assets/img/avatar.png";

const ChatContactItem = ({ message, active, handleClick }) => {

  const timeFormat = (dateTimeString)=>{
    const dateTime = new Date(dateTimeString);
    const formattedTime = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return formattedTime;
  }

  return (
    <main
      className={`chat__contact__item ${active ? "active__item" : ""}`}
      onClick={handleClick}
    >
      <section className="chat__item__wrapper">
        {message?.senderProfilePic ? 
        <div className="image__container">
        <img src={message?.senderProfilePic} alt="chat-user" className="image__user" />
      </div> : 
      <div className="image__container">
      <img src={ChatUser} alt="chat-user" className="image__user" />
      </div>
        }
        
        <div className="chat__info">
          <section className="user__detail">
            <h2 className="username">{message?.sender}</h2>
            {message.new || message.unread ? (
              <>
                {message?.new ? (
                  <span className="message__badge">New</span>
                ) : null}
                {message?.unread ? (
                  <span className="message__unread">
                    {message?.message_count}
                  </span>
                ) : null}
              </>
            ) : null}
          </section>
          <section className="message__wrapper">
            <div className="message__container">
              <p className="message">{message?.last_message?.message}</p>
            </div>
            <p className="message__timer">{timeFormat(message?.last_message?.created_at)}</p>
          </section>
        </div>
      </section>
    </main>
  );
};

export default ChatContactItem;
