import React from "react";

const PlayButton = ({ onClick, width = "70", height = "70" }) => (
  <svg
    style={{ maxHeight: "100%", maxWidth: "100%", cursor: "pointer" }}
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="30" fill="white" />
    <circle cx="35" cy="35" r="35" fill="white" fill-opacity="0.25" />
    <path
      d="M30.6687 45.5467C30.0854 45.9258 29.4951 45.948 28.8977 45.6132C28.3004 45.2783 28.0012 44.7603 28 44.0592V25.9467C28 25.2467 28.2992 24.7287 28.8977 24.3927C29.4962 24.0567 30.0866 24.0788 30.6687 24.4592L44.9312 33.5154C45.4562 33.8654 45.7188 34.3612 45.7188 35.0029C45.7188 35.6446 45.4562 36.1404 44.9312 36.4904L30.6687 45.5467Z"
      fill="#FF0000"
    />
  </svg>
);

export default PlayButton;
