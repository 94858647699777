import React from "react";

const Switch = ({ name, handleSwitch, checked }) => {
  return (
    <div className="switch__container">
      <input
        type="checkbox"
        name={name}
        id={name}
        defaultChecked={checked}
        onChange={handleSwitch}
      />
      <label htmlFor={name} data-on="✓" data-off="x"></label>
    </div>
  );
};

export default Switch;
