import React from 'react'

const WhatsappIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 24L1.69627 17.8403C0.647564 16.0323 0.0974211 13.9848 0.103152 11.8916C0.103152 5.3327 5.46704 0 12.0516 0C15.2493 0 18.2521 1.23764 20.5043 3.48479C22.7622 5.73194 24.0057 8.72053 24 11.8973C24 18.4563 18.6361 23.789 12.0458 23.789H12.0401C10.0401 23.789 8.07449 23.2871 6.32664 22.3403L0 24ZM6.63037 20.1901L6.9914 20.4068C8.51575 21.308 10.2636 21.7814 12.0458 21.7871H12.0516C17.5243 21.7871 21.9828 17.3555 21.9828 11.903C21.9828 9.26236 20.9513 6.78137 19.0773 4.91065C17.2034 3.03992 14.7049 2.01331 12.0516 2.01331C6.57879 2.0076 2.12034 6.43916 2.12034 11.8916C2.12034 13.7567 2.64183 15.576 3.63897 17.1502L3.87392 17.5266L2.87106 21.1711L6.63037 20.1901Z" fill="white"/>
    <path d="M0.417969 23.5838L2.05694 17.6351C1.04261 15.8956 0.509659 13.9165 0.509659 11.8975C0.51539 5.5667 5.69017 0.416504 12.0512 0.416504C15.14 0.416504 18.034 1.61422 20.2117 3.78152C22.3893 5.94882 23.587 8.83476 23.587 11.9032C23.587 18.234 18.4065 23.3842 12.0512 23.3842H12.0455C10.1142 23.3842 8.21739 22.8994 6.53258 21.9869L0.417969 23.5838Z" fill="#5D5FCC"/>
    <path d="M0 24L1.69627 17.8403C0.647564 16.0323 0.0974211 13.9848 0.103152 11.8916C0.103152 5.3327 5.46704 0 12.0516 0C15.2493 0 18.2521 1.23764 20.5043 3.48479C22.7622 5.73194 24.0057 8.72053 24 11.8973C24 18.4563 18.6361 23.789 12.0458 23.789H12.0401C10.0401 23.789 8.07449 23.2871 6.32664 22.3403L0 24ZM6.63037 20.1901L6.9914 20.4068C8.51575 21.308 10.2636 21.7814 12.0458 21.7871H12.0516C17.5243 21.7871 21.9828 17.3555 21.9828 11.903C21.9828 9.26236 20.9513 6.78137 19.0773 4.91065C17.2034 3.03992 14.7049 2.01331 12.0516 2.01331C6.57879 2.0076 2.12034 6.43916 2.12034 11.8916C2.12034 13.7567 2.64183 15.576 3.63897 17.1502L3.87392 17.5266L2.87106 21.1711L6.63037 20.1901Z" fill="url(#paint0_linear_6888_17535)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.06551 6.91843C8.84202 6.42223 8.60706 6.41082 8.39503 6.40512C8.22311 6.39941 8.02253 6.39941 7.82196 6.39941C7.62139 6.39941 7.30047 6.47356 7.0254 6.77014C6.75033 7.06671 5.98242 7.78535 5.98242 9.25113C5.98242 10.7112 7.05405 12.1257 7.20305 12.3253C7.35205 12.5249 9.27182 15.6218 12.3033 16.8139C14.8248 17.8063 15.3406 17.6066 15.885 17.5553C16.4294 17.504 17.65 16.8367 17.9022 16.1409C18.1486 15.445 18.1486 14.8519 18.0741 14.7264C17.9996 14.6009 17.799 14.5268 17.501 14.3785C17.203 14.2302 15.736 13.5116 15.4609 13.4089C15.1859 13.312 14.9853 13.2606 14.7904 13.5572C14.5899 13.8538 14.0168 14.5211 13.8449 14.7207C13.673 14.9203 13.4953 14.9431 13.1973 14.7949C12.8993 14.6466 11.9366 14.3329 10.7962 13.3177C9.90792 12.5306 9.3062 11.5553 9.13428 11.2587C8.96236 10.9622 9.11709 10.8025 9.26609 10.6542C9.39789 10.523 9.56408 10.3063 9.71308 10.1352C9.86208 9.96405 9.91365 9.83858 10.0111 9.63896C10.1085 9.43934 10.0626 9.26824 9.98815 9.11995C9.91365 8.97736 9.32912 7.50588 9.06551 6.91843Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_6888_17535" x1="12.0023" y1="23.9977" x2="12.0023" y2="0" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F9F9F9"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default WhatsappIcon