import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AlertCloseIcon } from "components/vectors";

const Alert = ({ isError, message }) => {
  const [showAlert, setShowAlert] = useState(true);
  const changeAlertState = () => {
    setShowAlert(!showAlert);
    setTimeout(() => {
      setShowAlert(!showAlert);
    }, 500);
  };

  return (
    message &&
    (showAlert ? (
      <div className={`alert-wrapper ${isError ? "alert-error" : "alert-success"}`}>
        <div className="max-container">
          <div className="alert">
            <AlertCloseIcon onClick={changeAlertState} className="alert-icon" />
            <span className="alert-message">{message}</span>
          </div>
        </div>
      </div>
    ) : null)
  );
};

Alert.defaultProps = {
  isError: false,
};

Alert.propTypes = {
  isError: PropTypes.bool,
  message: PropTypes.string.isRequired,
};
export default Alert;
