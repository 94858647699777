import React from 'react';

const WhiteTick = (props) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M3.621 5.692a.93.93 0 0 1-1.313 0L.272 3.657a.929.929 0 1 1 1.314-1.314l1.213 1.213c.091.091.24.091.331 0L6.414.272a.93.93 0 0 1 1.314 1.314L3.621 5.692z"
    />
  </svg>
);

export default WhiteTick;
