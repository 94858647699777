import { Loader } from "components/blocks";
import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as actions from "store/actions/index";

const SocialPages = () => {
  const { token, userInfo } = useSelector((state) => state.auth);
  const {
    pages,
    loadingFacebookPages,
    loadedFacebookProducts,
    loadingFacebookProducts,
  } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState(null);

  const code = useMemo(() => {
    return new URLSearchParams(location.search).get("code");
  }, [location.search]);

  const handleSelectPage = (page) => {
    setSelectedPage(page);
  };

  useEffect(() => {
    const getInfo = (token, user, extraParams) => {
      dispatch(actions.getFacebookPages(token, user, extraParams));
    };
    if (code) {
      getInfo(token, userInfo, { code });
    }
  }, [code]);

  const getSocialProducts = () => {
    const params = {
      page_id: selectedPage?.id,
      page_access_token: selectedPage?.access_token,
    };
    dispatch(actions.getPageData(token, userInfo, params));
  };

  useEffect(() => {
    if (loadedFacebookProducts) {
      history.push("/dashboard/social-products", {
        replace: true,
        state: { selectedPage },
      });
      history.replace("/dashboard/social-products");
    }
  }, [loadedFacebookProducts]);

  return (
    <main className="social__pages">
      <p className="page__header">Link your Facebook/Instagram page</p>
      <section className="pages__wrapper">
        <aside className="sidebar__text">
          <p className="title">Select one page: </p>
          <p className="description">
            Products on the page would be uploaded to your Peppa store
          </p>
        </aside>
        <section className="pages__container">
          <p className="title">Social media pages</p>

          {loadingFacebookPages ? (
            <p className="empty__page__text py-3">Loading pages...</p>
          ) : (
            <>
              {pages && pages.length == 0 ? (
                <p className="empty__page__text mt-2 mb-5 pb-5">
                  Social pages will appear here
                </p>
              ) : (
                <>
                  <div className="page__list">
                    {pages.map((page) => (
                      <div className="page__item" key={page.id}>
                        <input
                          type="radio"
                          onChange={() => handleSelectPage(page)}
                          name="page"
                          value={page.id}
                          className="radio-button"
                        />
                        <label>
                          <span>{page?.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="action__button__wrapper">
                    <button
                      className="button button--purple button__content"
                      disabled={loadingFacebookProducts}
                      onClick={getSocialProducts}
                    >
                      Fetch products
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </section>
      </section>
    </main>
  );
};

export default SocialPages;
