import InputField from "components/blocks/InputField";
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect } from "react";
import UploadIcon from "components/vectors/UploadIcon";
import InputGroup from "components/blocks/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  getProductCategories,
  getProductWeightUnits,
  getSingleProduct,
  updateProduct,
} from "store/actions/products";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components/blocks";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BreadCrumb from "components/shared/BreadCrumb";
import { LoaderProcessing } from "components/shared";
import ProductVariant from "components/dashboardComponent/ProductVariant";
import { formatVariant, hasEmptyProperties, notify } from "libs/utils";
import ImageUploadPreview from "components/dashboardComponent/ImageUploadPreview";

function Product() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.auth);
  const {
    loaded,
    loading,
    productCategories,
    weightUnit,
    updatingProduct,
    updateProductComplete,
    singleProduct,
  } = useSelector((state) => state.products);
  const [files, setFiles] = useState(null);
  const [imageError, setImageError] = useState(false);
  const fileTypes = ["png", "jpg", "jpeg"];
  const [showVariant, setShowVariant] = useState(false);
  const { productId } = params;
  const [productImages, setProductImages] = useState(null);
  const [images, setImages] = useState([]);
  const [variants, setVariants] = useState([]);

  const crumbs = [
    {
      name: "Back to products",
      path: "/dashboard/products",
    },
    {
      name: "Edit product",
    },
  ];

  const handleVariant = (variants) => {
    setVariants(variants);
  };

  const storeSchema = yup.object().shape({
    productname: yup.string().required("Product name is required"),
    description: yup.string().required("Description is required"),
    price: yup
      .number()
      .typeError("Provide a valid price")
      .required("price is required"),
    category_id: yup.string().required("Product Category is required"),
    weight: yup
      .number()
      .typeError("Only numbers allowed")
      .required("Weight is required"),
    //discount_percent: yup.number().typeError("Only numbers allowed"),
    weight_unit: yup.string().required("Weight unit is required"),
    // productType: yup.string().required("Product type is required"),
    quantity: yup
      .number()
      .typeError("Only numbers allowed")
      .required("Quantity is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(storeSchema),
  });

  const onlyNumbers = (value) => {
    return value?.replace(/[^0-9]/, "");
  };

  const handleChange = (files) => {
    if (files) {
      setFiles(files);
    }
  };

  const handleFileChange = (files) => {
    setProductImages(files);
  };

  useEffect(() => {
    dispatch(getProductWeightUnits(token, userInfo));
    dispatch(getProductCategories(token, userInfo));
  }, []);

  useEffect(() => {
    const getProduct = () => {
      dispatch(getSingleProduct(token, userInfo, productId));
    };
    getProduct();
  }, [productId, dispatch]);

  useEffect(() => {
    if (loaded && productCategories && weightUnit) {
      reset({
        productname: singleProduct?.productName,
        description: singleProduct?.productDescription,
        price: singleProduct?.amount,
        quantity: singleProduct?.quantity,
        discount_percent: singleProduct?.discount_percent,
        weight_unit: "kg",
        weight: singleProduct?.weight,
        category_id: singleProduct?.category?.id,
        productType: "Tangible",
      });
      if (singleProduct?.image_url) {
        setImages([
          { image_link: singleProduct.image_url },
          ...singleProduct?.other_images,
        ]);
      }
      let productVariants = singleProduct?.productVariants;
      let normalizeVariants = productVariants?.map((item) => {
        let { name, variants } = item;
        return {
          name,
          variant: formatVariant(variants),
          errors: {
            name: null,
            variant: null,
          },
        };
      });
      setVariants(normalizeVariants);
    }
  }, [loaded, productId]);

  const submit = (data) => {
    console.log({ data });
    if (files) {
      data.product_image = productImages[0].file;
    }
    data.currency = "NGN";
    data.deliveryperiod = "2";
    data.productID = productId;
    data.merchantID = userInfo.customerID;

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    if (variants.length > 0) {
      let extraVariants = [];
      if (hasEmptyProperties(variants)) {
        notify("Variants can not be empty", "error");
        return;
      } else {
        variants.map((item) => {
          const { variant, name } = item;
          extraVariants.push({ name: name, variant: variant });
          return { name, variant };
        });

        extraVariants.forEach((item, index) => {
          formData.append(`product_variants[${index}][name]`, item.name);
          item.variant.forEach((variant, variantIndex) => {
            formData.append(
              `product_variants[${index}][variant][${variantIndex}]`,
              variant
            );
          });
        });
      }
    }
    const optionalImages = productImages?.slice(1, productImages.length);
    if (optionalImages?.length > 0) {
      optionalImages.map((item, index) => {
        formData.append(`optional_images[${index}]`, item.file);
      });
    }

    dispatch(updateProduct(token, userInfo, formData));
  };

  useEffect(() => {
    if (updateProductComplete) {
      history.push("/dashboard/products", { replace: true });
    }
  }, [updateProductComplete]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return loading ? (
    <div className="loader__container">
      <LoaderProcessing />
    </div>
  ) : (
    <div className="products">
      <BreadCrumb crumbs={crumbs} />
      <p className="page__title">Edit products</p>
      <form onSubmit={handleSubmit(submit)} onKeyDown={(e) => checkKeyDown(e)}>
        <div className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="productname"
                label="Product Name"
                name="productname"
                placeHolder="Product Name"
                register={register}
                error={errors.productname?.message}
                type="text"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputGroup
                require={true}
                id="price"
                label="Product Price (Numbers only)"
                name="price"
                placeholder="1000"
                register={register}
                error={errors.price?.message}
                type="number"
              />
            </div>
          </div>
        </div>
        <section className="row">
          <div className="form__wrapper">
            <InputField
              require={true}
              id="description"
              label="Product Description"
              name="description"
              placeHolder="Product Description"
              register={register}
              error={errors.description?.message}
              type="textarea"
            />
          </div>
        </section>

        <div className="row">
          <div>
            <label className="form__label">
              Product Images <span className="require__field">*</span>
            </label>
          </div>
          <section className="file__upload__wrapper">
            <div
              className={`form__wrapper border__wrapper ${
                imageError ? "border-danger" : ""
              }`}
            >
              <FileUploader
                handleChange={handleChange}
                multiple={true}
                types={fileTypes}
              >
                <div className="file__uploader__wrapper ">
                  <UploadIcon />
                  <div>
                    <p className="title">
                      Drag and drop images here or{" "}
                      <span className="upload__button">
                        browse on your computer
                      </span>
                    </p>

                    <p className="image__sizing">
                      Recommended image size is 1280x780px
                    </p>
                  </div>
                </div>
              </FileUploader>
            </div>
          </section>
        </div>
        <ImageUploadPreview
          defaultImages={images}
          handleFileChange={handleFileChange}
          files={files}
        />

        <section className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper ">
              <InputField
                require={true}
                id="quantity"
                label="Product Quantity"
                name="quantity"
                placeHolder="0"
                register={register}
                error={errors.quantity?.message}
                type="number"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="category_id"
                label="Product Category"
                name="category_id"
                placeHolder="Product Category"
                register={register}
                defaultValue={singleProduct?.category?.id}
                options={productCategories?.map((item) => ({
                  label: item.category,
                  value: item.id,
                }))}
                error={errors.category_id?.message}
                type="select"
              />
            </div>
          </div>
        </section>

        <div className="row row__wrapper">
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="weight"
                label="Product Weight"
                name="weight"
                placeHolder="0"
                register={register}
                error={errors.weight?.message}
                type="text"
              />
            </div>
          </div>
          <div className="col">
            <div className="form__wrapper">
              <InputField
                require={true}
                id="weight_unit"
                label="Product weight Unit"
                name="weight_unit"
                placeHolder="Product Weight Unit"
                register={register}
                options={weightUnit?.map((unit) => ({
                  label: unit,
                  value: unit,
                }))}
                error={errors.weight_unit?.message}
                type="select"
              />
            </div>
          </div>
        </div>

        {/* <div className="row gap-5">
          <div className="col">
          <div className="form__wrapper">
            <InputField
              id="productType"
              label="Product Type"
              name="productType"
              placeHolder="Product Type "
              register={register}
              options={[
                { value: "Tangible", label: "Tangible" },
                // { value: "Intangible", label: "Intangible" },
              ]}
              error={errors.productType?.message}
              type="select"
            />
          </div>
          </div>
          <div className="col">
          <div className="form__wrapper">
            <InputField
              id="category_id"
              label="Product Category"
              name="category_id"
              placeHolder="Product Category"
              register={register}
              options={productCategories?.map((item) => ({
                label: item.category,
                value: item.id,
              }))}
              error={errors.category_id?.message}
              type="select"
            />
          </div>
          </div>
        </div> */}

        <div className="row">
          <section className="more__product__detail">
            <p className="product__variant">
              <span>Product Variants</span> <i>(Optional)</i>
            </p>
            <p className="detail">
              Do you need to add more details like colours, variations,size for
              your product? Click{" "}
              <span className="add__more" onClick={() => setShowVariant(true)}>
                Add More Details
              </span>{" "}
              to add product variants
            </p>
          </section>
          {showVariant ? (
            <ProductVariant
              handleVariant={handleVariant}
              defaultVariants={variants}
            />
          ) : null}
          <div className="col-md-6 p-0">
            {/* <div className="error__message">{imageError && imageError}</div> */}
            <button
              className="button button--purple mt-4"
              disabled={updatingProduct}
              type="submit"
            >
              Update product
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Product;
