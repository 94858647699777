import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const ImageUploadPreview = ({files,handleFileChange,defaultImages}) => {
    const [imagePreviews, setImagePreviews] = useState([]);
    const [startIndex,setStartIndex] = useState(null)
    
  useEffect(()=>{
    if(files){
        const fileArray = Array.from(files);
        fileArray.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const imageData = event.target.result;
            setImagePreviews((prevPreviews) => {
                const newPreviews = [...prevPreviews, {
                    id: Date.now(),
                    file,
                    preview: imageData,
                  }]
                  
                  return newPreviews
            });
          };
          reader.readAsDataURL(file);
        });
       }
  },[files])

  useEffect(()=>{
    if(imagePreviews){
        handleFileChange(imagePreviews);
      }
  },[imagePreviews])
   
  const handleRemove = (index) => {
    const updatedImages = imagePreviews.filter((image, i) => i !== index);
    setImagePreviews(updatedImages);
    handleFileChange(updatedImages)
  };

  

  const handleSwap =  (dragIndex, dropIndex) => {
    const updatedImages = [...imagePreviews];
    const draggedImage = updatedImages[dragIndex];
    updatedImages.splice(dragIndex, 1);
    updatedImages.splice(dropIndex, 0, draggedImage);
    setImagePreviews(updatedImages);
    handleFileChange(updatedImages)
  };

  const handleDragStart = (i)=>{
      setStartIndex(i)
  }
  const handleDropIndex = (index)=>{
    if(startIndex){
      handleSwap(startIndex,index);
    }
  }


  return (
    <section className="image__preview__container">
        {
            defaultImages && imagePreviews.length==0? (
                <>
                {
                    defaultImages.map((image,index)=>(
                        <div key={index} className="image__preview">
                                 <img src={image?.image_link} alt="Preview" />
                        </div>
                    ))
                }
                </>
            ):(null)
        }
    {imagePreviews.map((image, index) => (
      <div key={index} className="image__preview"
       draggable
      onDragStart={() => handleDragStart(index)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => handleDropIndex(index)}
      >
        <img src={image.preview} alt="Preview" />

        <span
          className="image__action"
          onClick={() => handleRemove(index)}
        >
          &times;
        </span>
      </div>
    ))}
  </section>
  )
}

export default ImageUploadPreview