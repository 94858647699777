export default {
  HOST: "https://v2.pepperest.com",
};

// export const bugsnagClient = bugsnag({
//   apiKey: '08be603252d6fff7c145233e764263d5',
// })

export { default as Auth, ErrorMessages as AuthErrorMessages } from "./Auth";
export {
  default as Payments,
  ErrorMessages as PaymentsErrorMessages,
} from "./Payments";
export {
  default as Products,
  ErrorMessages as ProductsErrorMessages,
} from "./Products";
export {
  default as Customers,
  ErrorMessages as CustomersErrorMessages,
} from "./Customers";
export {
  default as Orders,
  ErrorMessages as OrdersErrorMessages,
} from "./Orders";
export {
  default as Invoices,
  ErrorMessages as InvoiceErrorMessages,
} from "./Invoices";
export {
  default as UserAccount,
  ErrorMessages as UserAccountErrorMessages,
} from "./UserAccount";
export { default as Cart, ErrorMessages as CartErrorMessages } from "./Cart";
export {
  default as Marketplace,
  ErrorMessages as MarketplaceErrorMessages,
} from "./Marketplace";
export {
  default as Dashboard,
  ErrorMessages as DashboardErrorMessages,
} from "./Dashboard";
export {

  default as Messaging,
  ErrorMessages as MessagingErrorMessages,
} from "./Messaging";

export{
  default as Wallet,
  ErrorMessages as WalletErrorMessages,
} from "./Wallet";
