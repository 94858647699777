/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useEffect, useState } from "react";
import { PepperestContext } from "components/helpers/constant";
import useResizeObserver from "components/customHook/useResizeObserver";
import { connect } from "react-redux";
import * as actions from "store/actions/index";

const OrderListItemDetailMobileModal = ({ detailsOfOrder, user, changeOrderStatus, token }) => {
  const pepperestContext = useContext(PepperestContext);
  const [ref, { contentRect }] = useResizeObserver();
  const [state, setState] = useState({ isRecentTransactionActive: false, style: {} });
  const { status, date, address, orderId, cost, customerEmail, customerName, orderItems } =
    pepperestContext.state.orderDetails;

  const isBuyer = user.usertype === "Buyer" || user.usertype === "Both";
  const isMerchant = user.usertype === "Merchant" || user.usertype === "Both";

  useEffect(() => {
    if (contentRect) {
      setState({
        ...state,
        style: {
          top: contentRect.height >= window.innerHeight - 350 ? "350px" : "unset",
          bottom: contentRect.height >= window.innerHeight - 350 ? "unset" : "0",
          minHeight: contentRect.height >= window.innerHeight - 350 ? "unset" : "500px",
        },
      });
    }
  }, [contentRect]);

  const [orderStatus, setOrderStatus] = useState("");
  const handleOrderStatus = (e, token, user, OrderId) => {
    if (e.target.value == orderStatus) return;
    setOrderStatus(e.target.value);
    changeOrderStatus(token, user, OrderId, e.target.value);
  };

  const handleToggle = () => {
    setState({
      ...state,
      isRecentTransactionActive: !state.isRecentTransactionActive,
    });
  };

  return (
    <>
      <div className="list-modal-overlay" />
      <div
        className="list-modal"
        onClick={() => {
          pepperestContext.updateShowOrdersListModal(false);
        }}
      >
        <div
          className="list-modal__body"
          onClick={(event) => {
            event.stopPropagation();
          }}
          ref={ref}
          style={state.style}
          id="elem"
        >
          <div className="list-modal__header">
            <div
              className="list-item-detail__container-close"
              onClick={() => {
                pepperestContext.updateShowOrdersListModal(false);
              }}
            >
              Close
            </div>
          </div>
          <ul className="list-modal__list">
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Order ID</p>
              <p className="list-item-detail__main-item__details">{orderId}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Customer</p>
              <p className="list-item-detail__main-item__details">{customerName}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Order Date</p>
              <p className="list-item-detail__main-item__details">{date}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Total Amount</p>
              <p className="list-item-detail__main-item__details">NGN {cost}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Customer Email</p>
              <p className="list-item-detail__main-item__details">{customerEmail}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Status</p>
              <p className="list-item-detail__main-item__details list-item__status-text text--active">
                {status}
              </p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Delivery Address</p>
              <p className="list-item-detail__main-item__details">{address}</p>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Items in Order</p>
              <div className="list-item__details-product__image-container">
                {orderItems &&
                  orderItems.reverse().map((item, index) => {
                    return (
                      <figure key={index} className="mr-2 figure">
                        <img
                          src={item.image || "/assets/images/product.jpeg"}
                          className="mx-auto list-item__details-product__image"
                          alt="Product image"
                        />
                        <figcaption className="text-center figure-caption">
                          {item.productname}
                        </figcaption>
                      </figure>
                    );
                  })}
              </div>
            </li>
            <li className="list-modal__list-item">
              <p className="list-item-detail__main-item__title">Payment Method</p>
              <p className="list-item-detail__main-item__details">Flutterwave</p>
            </li>

            <div className="list-modal__list-item list-modal__list-item__alternate">
              {/* <div className="button button-md button--grey">Print Receipt</div> */}
              <PepperestContext.Consumer>
                {(context) => (
                  <div
                    role="presentation"
                    className="button button-md button--grey"
                    onClick={() => {
                      context.updateShowReportIssueModal(true);
                    }}
                  >
                    Report an issue
                  </div>
                )}
              </PepperestContext.Consumer>

              <select
                name="orderStatus"
                className="button button-md button--orange-border"
                id="orderStatus"
                value={orderStatus}
                onChange={(e) => handleOrderStatus(e, token, user, orderId, orderStatus)}
              >
                <option value="" default>
                  Change Order Status
                </option>
                {isMerchant && (
                  <>
                    <option value="Shipped">Shipped</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Confirmed">Confirmed</option>
                  </>
                )}
                {isBuyer && (
                  <>{status === "Shipped" && <option value="Delivered">Received</option>}</>
                )}
              </select>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOrderStatus: (token, user, orderID, status) =>
      dispatch(actions.changeOrderStatus(token, user, orderID, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderListItemDetailMobileModal);
