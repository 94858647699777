import NewTrashIcon from "components/vectors/NewTrashIcon";
import PlusIcon from "components/vectors/PlusIcon";
import React, { useCallback, useEffect, useState } from "react";
import TagsInput from "../TagsInput";
import { generateKey, getStringHash } from "libs/utils";

const ProductVariant = ({ handleVariant, defaultVariants }) => {
  const [variants, setVariants] = useState(
    defaultVariants
      ? defaultVariants
      : [
          {
            name: "",
            variant: [],
            errors: {
              name: null,
              variant: null,
            },
          },
        ]
  );
  let variantIndex = null;

  const setIndex = (index) => {
    variantIndex = index;
  };

  const selectedTags = (tags) => {
    const updatedVariants = [...variants];
    updatedVariants[variantIndex].variant = tags;
    updatedVariants[variantIndex].errors.variant = null;
    setVariants(updatedVariants);
    handleVariant(updatedVariants);
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    updatedVariants[index].errors[field] = null;
    setVariants(updatedVariants);
    handleVariant(updatedVariants);
  };

  const prevIsValid = useCallback(() => {
    if (variants.length === 0) {
      return true;
    }

    const someEmpty = variants.some(
      (item) => item.name == "" || item.variant.length == 0
    );

    if (someEmpty) {
      variants.map((item, index) => {
        const allPrev = [...variants];

        if (variants[index].name == "") {
          allPrev[index].errors.name = "Variant Name is required";
        }

        if (variants[index].variant == "") {
          allPrev[index].errors.variant = "Variant is required";
        }
        setVariants(allPrev);
      });
    }

    return !someEmpty;
  }, [variants]);

  const handleAddVariant = () => {
    if (prevIsValid()) {
      setVariants([
        ...variants,
        {
          name: "",
          variant: [],
          errors: {
            name: null,
            variant: null,
          },
        },
      ]);
    }
  };

  const handleRemoveVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
    handleVariant(updatedVariants);
  };

  useEffect(() => {
    setVariants(variants);
  }, [variants.length]);

  return (
    <div>
      {variants.map((variant, index) => (
        <section key={index} className="variant__wrapper">
          <div className="form__wrapper col-md-4 p-0">
            <label className="form__label">Variant <span>(e.g size)</span></label>
            <input
              type="text"
              placeholder="Enter Variant (e.g color)"
              className="form__input w__full"
              value={variant?.name}
              onChange={(e) =>
                handleVariantChange(index, "name", e.target.value)
              }
            />
            <div className="error__message">{variant?.errors?.name}</div>
          </div>
          <div className="form__wrapper col-md-6 p-0">
            <label className="form__label">Variant values <span>(press Enter to add tag)</span></label>

            <TagsInput
              selectedTags={selectedTags}
              setIndex={() => setIndex(index)}
              tags={variants[index]?.variant}
            />
            <div className="error__message">{variant?.errors?.variant}</div>
          </div>

          <span
            className="cursor__pointer mt-4"
            onClick={() => handleRemoveVariant(index)}
          >
            <NewTrashIcon fill="#EB5757" width={42} height={42} />{" "}
          </span>
        </section>
      ))}
      <div className="add__variant" onClick={handleAddVariant}>
        <PlusIcon color="white" width="20" height="20" />
      </div>
      <p className="text__primary text-center">Add another variant</p>
    </div>
  );
};

export default ProductVariant;
