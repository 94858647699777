import React from "react";
import styles from "./cardsummary.module.css";
const CartSummary = ({
  wishlistPrice,
  totalItems,
  pepperestfee,
  couponValue,
}) => {
  return (
    <section className={styles.productSummary}>
      <div className={styles.summaryItem}>
        <label className={styles.label}>Subtotal</label>
        <p className={styles.value}>₦{wishlistPrice}</p>
      </div>
      <div className={styles.summaryItem}>
        <label className={styles.label}>Platform Fee</label>
        <p className={styles.value}>₦{pepperestfee}</p>
      </div>
      {couponValue > 0 ? (
        <div className={styles.summaryItem}>
          <label className={styles.label}>Discount</label>
          <p className={styles.value}>-₦{couponValue}</p>
        </div>
      ) : null}

      <div className={styles.summaryItem}>
        <label className={styles.label}>Total Items</label>
        <p className={styles.value}>{totalItems}</p>
      </div>
    </section>
  );
};

export default CartSummary;
