import React from "react";
import {
  BallIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "components/vectors";
import { Link } from "react-router-dom";
import PlayStore from "components/vectors/PlayStore";
import AppleStoreButton from "components/vectors/AppleStoreButton";

const footerContent = {
  Company: [
    { text: "About Us", link: "/aboutUs" },
    { text: "Blog", link: "https://blog.peppa.io" },
    { text: "Pricing", link: "/pricing" },
    { text: "Support", link: "/support" },
    { text: "FAQs", link: "/support" },
    // { text: "Careers", link: "#" },
  ],
  Products: [
    {
      text: "peppa app",
      link: "https://play.google.com/store/apps/details?id=com.paysure.peppa",
    },
    { text: "Online Storefront", link: "/products#storefront" },
    { text: "Payment Protection service", link: "/escrow" },
    { text: "Marketplace", link: "/marketplace" },
    { text: "Products policy", link: "/products-policy" },
  ],
  "Install App": [
    {
      text: <PlayStore />,
      link: "https://t.peppa.io/DaST/website",
    },
    {
      text: <AppleStoreButton />,
      link: "https://t.peppa.io/DaST/website",
    },
  ],
  "Get In Touch": [
    // { text: "prosper@pepperest.com", link: "https://prosperofpepperest.substack.com/" },
    // { text: "160 City Road London EC1V 2NX", link: "#" },
    // { text: "Company name: Pepper Rest Ltd", link: "#" },
    // { text: "Intl address: City Road London UK.", link: "#" },
    // { text: "NG address: 2 Samuel Onafuwa Close Rivervalley Estate Lagos", link: "#" },
  ],
};

const footerIcons = [
  {
    icon: <InstagramIcon color="white" />,
    link: "https://instagram.com/usepeppa?igshid=YmMyMTA2M2Y=",
  },
  { icon: <FacebookIcon />, link: "https://m.facebook.com/pepperestdotcom" },
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/usepeppa/status/1615312617333231617?s=46&t=UdiPyMahOXFMGw0R9tV42g",
  },
];

function Footer() {
  return (
    <footer className="footer pt-5 pb-3">
      <div className="container row d-flex items-center flex-wrap justify-content-between ">
        {Object.keys(footerContent).map((content, index) => {
          return (
            <FooterList
              title={content}
              key={index}
              list={footerContent[content]}
            />
          );
        })}
      </div>
      <hr style={{ borderBottom: "1px solid white", opacity: "0.1" }} />
      {/* Logo section of the border */}
      <div>
        <div className="row container px-0 d-flex  align-items-center justify-content-between h-100 ">
          <div className="col-12 col-md order-md-1 mb-3 mb-md-0 text-center mx-auto">
            <a href="/">
              <img src="/assets/images/logo/peppa_white.svg" alt="logo" />
            </a>
          </div>
          <div className="col-12 col-md order-md-3  mb-4 mb-md-0 align-self-md-end">
            <p className="text-sm-right text-center footer__item pt-md-4" style={{fontSize:"12px"}}>
              <a href="/terms-condition" className="text-decoration-none">
                Terms of Service{" "}
              </a>{" "}
              |{" "}
              <a href="/privacy" className="text-decoration-none">
                Privacy Policy
              </a>
              {" "}|{" "}
              <a href="/aml-cft-policy" className="text-decoration-none">
                AML-CFT Policy
              </a>
            </p>
          </div>
          <div className="col-12 col-md align-self-end ">
            <p className="footer__item text-md-left text-center">
              © {new Date().getFullYear()} peppa.io. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const FooterList = ({ title, list }) => {
  return (
    <div className="col-12 col-md-3 ">
      <ul className="footer__list">
        <li className="footer__header">{title}</li>
        {title === "Get In Touch" && (
          <>
            <li className="footer__item__soclal">
              {footerIcons.map(({ icon, link }, index) => (
                <span className="rounded-circle mb-2" key={index}>
                  <a target="_blank" rel="noreferrer noopener" href={link}>
                    {icon}
                  </a>
                </span>
              ))}
            </li>
            <li className="footer__item">
              <b>Email Address: </b>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://prosperofpepperest.substack.com/"
              >
                prosper@peppa.io
              </a>
            </li>
            <li className="footer__item">
              <b>UK Office: </b>
              <a target="_blank" rel="noreferrer noopener">
                Grosvenor House, 11 St Paul’s Square, Birmingham, B3 1RB
              </a>
            </li>
            <li className="footer__item">
              <b>NG Office: </b>
              <a target="_blank" rel="noreferrer noopener">
                25, Kayode Otitoju Street, Lekki Phase 1, Lagos
              </a>
            </li>
          </>
        )}
        {list.map((item, index) => (
          <li className="footer__item" key={index}>
            <a rel="noreferrer noopener" href={item.link}>
              {item.text}
            </a>
            {item.label ? (
              <span className="item__label ml-1">{item?.label}</span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};
