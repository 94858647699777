import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const BankDetail = () => {
  const history = useHistory();
  const { userProfile } = useSelector((state) => state.userAccount);
  const addBank = () => {
    history.push("/dashboard/settings?tab=bank-detail&action=add");
  };
  const next = () => {
    history.push("?tab=premium");
  };

  return (
    <main>
      {userProfile?.bankAccountNo ? (
        <>
          <p className="title">Add your bank details</p>
          <p className="description">
            Your bank details has been updated, enjoy seamless transactions
          </p>
          <div className="button__wrapper justify-content-center">
            <button
              className="button button--purple button__content px-5"
              onClick={next}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="title">Add your bank details</p>
          <p className="description">
            Add your bank details for seamless transactions
          </p>
          <div className="button__wrapper justify-content-center">
            <button
              className="button button--purple button__content"
              onClick={addBank}
            >
              Add bank details
            </button>
          </div>
        </>
      )}
    </main>
  );
};

export default BankDetail;
