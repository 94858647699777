import React from "react";

const SendIcon = ({ color = "#5D5FCC", width = "29", height = "29" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.756685 10.1961C0.0171854 9.9496 0.0101021 9.55152 0.770852 9.29794L27.8108 0.285104C28.5602 0.0357704 28.9894 0.455104 28.7798 1.18894L21.0533 28.2274C20.8408 28.9769 20.4087 29.0024 20.0914 28.2912L14.9999 16.8332L23.4999 5.49985L12.1665 13.9999L0.756685 10.1961Z"
        fill={color}
      />
    </svg>
  );
};

export default SendIcon;
