import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import "bootstrap/dist/css/bootstrap.min.css";

import { PageNotFound } from "pages";
import { getCookie, getStringHash } from "libs/utils";
import PepperestProvider from "components/helpers/PepperestProvider";
import * as actions from "./store/actions/index";
import Alert from "components/shared/Alert";
import "./assets/scss/styles.scss";
import routes from "config/routes";
import axios from "axios";
import { CartIcon } from "buyer-components";
import { LoaderSuspense } from "components/shared";
import CookieConsent from "components/blocks/CookieConsent";

// MixPanel settup
mixpanel.init("c09dd75d85d64840cc200e65f64c0d8a", { debug: true });
// faceBook Pixel setup
// const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("695286824824193", options);

function App(props) {
  // const [noRedirect, setNoRedirect] = useState(false);
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!props.isAuthenticated) {
      props.onTryAutoSignup();
    }
  }, []);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  // useEffect(() => {
  //   const getLocation = async () => {
  //     try {
  //       const res = await axios.get("https://geolocation-db.com/json/");
  //       console.log(res);
  //       console.log(res.data);
  //       const userLocation = res?.data?.country_code;
  //       if (userLocation == "NG") {
  //         //SET NOREDIRECT TRUE
  //         setNoRedirect(true);
  //         setLoading(false);
  //       } else if (userLocation == "Not found") {
  //         setNoRedirect(true);
  //         setLoading(false);
  //       } else {
  //         // Redirect to the desired URL for users from the UK
  //         //window.location.replace("https://fx.peppa.io");

  //         const redirectLink = document.createElement("a");
  //         redirectLink.href = "https://mypeppa.co.uk";
  //         redirectLink.click();
  //       }
  //     } catch (error) {
  //       console.error("Error fetching location:", error);
  //       setNoRedirect(true);
  //       setLoading(false);
  //     }
  //   };

  //   getLocation();
  // }, []);

  // if (loading) {
  //   return <p className="location">Loading...</p>;
  // }
  // if (noRedirect) {
    return (
      <PepperestProvider>
        <Router>
          <Suspense
            fallback={
              <div className="loader__container">
                <LoaderSuspense />
              </div>
            }
          >
            <>
              <Alert />
              <Switch>
                {routes.map(({ path, component, exact, isProtected }) => (
                  <Route
                    key={getStringHash(path)}
                    path={path}
                    exact={exact}
                    component={component}
                  />
                ))}

                <Route path="/404" component={PageNotFound} />
                <Redirect to={{ pathname: "/404" }} />
              </Switch>
              <CartIcon />
            </>
          </Suspense>
        </Router>
        <CookieConsent />
      </PepperestProvider>
    );
  // }
  // return null;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    user: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
