import React, { useState, useEffect } from "react";
import { getStringHash } from "libs/utils";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import ListFooter from "../ListFooter";
import MerchantProductCard from "../MerchantProductCard";
import { toast } from "react-toastify";
import { mixPanelUserProperty } from "libs/mixPanel";
import { PepperestWebServices } from "libs/constants";

const InstagramProductList = (props) => {
  const [page, setPage] = useState(null);

  const [query, setQuery] = useState("");

  const refreshData = (params) => {
    props.onLoadProducts(props.token, props.user, params);
  };

  const gotoPage = (page) => {
    setPage(page);
    const params = {
      page: page,
      query: query,
    };
    refreshData(params);
  };

  // const search = (query) => {
  //   setQuery(query);
  //   const params = {
  //     page: page,
  //     query: query,
  //   };
  //   refreshData(params);
  // };

  let isOwnerOfStore = props.user?.customerID == props?.merchantID?.merchantID;

  useEffect(() => {
    props.getAllMerchantProducts(props.token, props.user);
    mixPanelUserProperty("connecttoyourstore_completed", {});
  }, []);

  const copyMerchantLink = async function (link) {
    let textArea = document.createElement("textarea");
    document.body.append(textArea);
    textArea.innerText = link;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toast.success(`Copied: ${textArea.value} `);
  };

  return (
    <div className="list">
      <div className="list-item__details-product text-center text-md-left flex-wrap justify-content-center justify-content-md-start ml-0 mb-2 d-flex align-items-center">
        Click on this button to copy your store link{" "}
        <div
          class="button button-md button--orange mx-auto ml-md-2"
          style={{ width: "110px" }}
          onClick={(e) =>
            copyMerchantLink(`${PepperestWebServices.HOST}/merchant/${props.user?.merchantCode}`)
          }
        >
          Copy Store Link
        </div>
      </div>

      <div className="max-content">
        <div className="">
          <ul className="row">
            {props.products &&
              props.products.map((item) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3" key={getStringHash()}>
                    <MerchantProductCard item={item} isOwnerOfStore={isOwnerOfStore} />
                  </div>
                );
              })}
          </ul>
        </div>
      </div>

      <ListFooter meta={props.meta} links={props.links} gotoPage={(page) => gotoPage(page)} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    products: state.merchant.products,
    meta: state.merchant.meta,
    links: state.merchant.links,
    loaded: state.merchant.loaded,
    loading: state.merchant.loading,
    error: state.merchant.error,
    businessname: state.merchant.merchantID?.businessname,
    noUserCartCount: state.cart.noUserCartLength,
    cart: state.cart.cart,
    merchantID: state.merchant.merchantID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadProducts: (token, user, extraParams) =>
      dispatch(actions.loadProduct(token, user, extraParams)),
    getAllMerchantProducts: (token, user, extraParams) =>
      dispatch(actions.getAllMerchantProducts(token, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramProductList);
