import React, { useState } from "react";
import PropTypes from "prop-types";
import { getStatusTextClass, getIndicatorClass, getStringHash } from "libs/utils";
import { PepperestContext } from "components/helpers/constant";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { OrderListItemDetailMobileModal } from "..";
import { currencyFormatter } from "store/utility";
import { RefundCustomerModal } from "components/blocks";

const OrderListItemDetails = ({
  status,
  onClick,
  date,
  address,
  orderId,
  cost,
  customerEmail,
  customerName,
  orderItems,
  user,
  token,
  changeOrderStatus,
  orderDetails,
}) => {
  const isBuyer = user.usertype === "Buyer" || user.usertype === "Both";
  const isMerchant = user.usertype === "Merchant" || user.usertype === "Both";

  const [orderStatus, setOrderStatus] = useState("");
  const handleOrderStatus = (e, token, user, OrderId) => {
    if (e.target.value == orderStatus) return;
    setOrderStatus(e.target.value);
    changeOrderStatus(token, user, OrderId, e.target.value);
  };

  const { requestRefund, paymentRef } = orderDetails;

  return (
    <>
      <div className="list-item-detail__container d-none d-md-block">
        <div className={`list-item__indicator ${getIndicatorClass(status)}`} />
        <div className="list-item-detail__header">
          <div
            role="button"
            tabIndex={0}
            className="list-item-detail__container-close"
            onClick={() => {
              onClick(false);
            }}
          >
            Close
          </div>
        </div>
        <div className="list-item-detail__main">
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Order ID</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">{orderId}</p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">
                {!isBuyer ? "Customer Name" : "Seller's Name"}
              </p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">{customerName}</p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Order Date</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">{date}</p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Total Amount</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">
                {currencyFormatter.format(cost)}
              </p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">
                {!isBuyer ? "Customer Email" : "Sellers Email"}
              </p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">{customerEmail}</p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Phone Number</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">
                {orderDetails?.buyerPhone ? orderDetails?.buyerPhone : "No number was provided"}
              </p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Status</p>
            </div>
            <div className="px-0 col-md-9">
              <p
                className={`list-item-detail__main-item__details status-text ${getStatusTextClass(
                  status
                )}`}
              >
                {status}
              </p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Delivery Addresss</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">{address}.</p>
            </div>
          </div>
          <div className="mx-0 list-item-detail__main-item list-item-detail__main-item-alternate row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Items in Order</p>
            </div>
            <div className="px-0 col-md-9">
              <div className="list-item__details-product__image-container">
                {orderItems &&
                  orderItems.reverse().map((item, index) => {
                    return (
                      <figure key={index} className="mr-2 figure">
                        <img
                          src={item.image || "/assets/images/product.jpeg"}
                          className="mx-auto list-item__details-product__image"
                          alt="Product image"
                        />
                        <figcaption className="text-center figure-caption">
                          {item.productname}
                        </figcaption>
                      </figure>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* <div className="mx-0 list-item-detail__main-item row">
            <div className="px-0 col-md-3">
              <p className="list-item-detail__main-item__title">Payment Method</p>
            </div>
            <div className="px-0 col-md-9">
              <p className="list-item-detail__main-item__details">Flutterwave</p>
            </div>
          </div> */}
          <div className="list-item-detail__main-item">
            {/* <div className='button button-md button--orange-border'>
            Print Receipt
          </div> */}
            {/* Refund button that only appears for buyer and only when its set to true from the endpoint */}
            {isBuyer && requestRefund && (
              <PepperestContext.Consumer>
                {(context) => {
                  return (
                    <div
                      role="presentation"
                      className="button button-md button--orange-border"
                      onClick={() => {
                        context.updateShowRefundCustomerModal(true, paymentRef);
                      }}
                    >
                      Request Refund
                    </div>
                  );
                }}
              </PepperestContext.Consumer>
            )}

            <PepperestContext.Consumer>
              {(context) => (
                <div
                  role="presentation"
                  className="button button-md button--orange-border"
                  onClick={() => {
                    context.updateShowReportIssueModal(true);
                  }}
                >
                  Report an issue
                </div>
              )}
            </PepperestContext.Consumer>

            <select
              name="orderStatus"
              className="button button-md button--orange-border"
              id="orderStatus"
              value={orderStatus}
              onChange={(e) => handleOrderStatus(e, token, user, orderId, orderStatus)}
            >
              <option value="" default>
                Change Order Status
              </option>
              {isMerchant && (
                <>
                  <option value="Shipped">Shipped</option>
                  <option value="Canceled">Canceled</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Confirmed">Confirmed</option>
                </>
              )}
              {isBuyer && (
                <>
                  {status === "Shipped" && <option value="Delivered">Received</option>}
                  <option value="Cancel">Cancel</option>
                </>
              )}
            </select>
          </div>
        </div>
      </div>
      <PepperestContext.Consumer>
        {(context) =>
          context.state.showOrdersListModal && (
            <OrderListItemDetailMobileModal
              key={getStringHash(status)}
              status={status}
              date={date}
              address={address}
              orderId={orderId}
              customerEmail={customerEmail}
              customerName={customerName}
              cost={cost}
              orderItems={orderItems}
              detailsOfOrder={orderDetails}
            />
          )
        }
      </PepperestContext.Consumer>
      <PepperestContext.Consumer>
        {(context) =>
          context.state.showRefundCustomerModal ? <RefundCustomerModal context={context} /> : null
        }
      </PepperestContext.Consumer>
    </>
  );
};

OrderListItemDetails.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOrderStatus: (token, user, orderID, status) =>
      dispatch(actions.changeOrderStatus(token, user, orderID, status)),
  };
};

// const mapDispatchToProps = (dispatch) => ({
//   addToCart: (token, user, productID) =>
//     dispatch(actions.addToCart(token, user, productID)),
// });
 export default connect(mapStateToProps, mapDispatchToProps)(OrderListItemDetails);
