import React,{ createContext,useContext,useState } from "react"
import { useLocalStorage } from "components/customHook/useResizeObserver/useLocalStorage";
import { toast } from "react-toastify";
import { notify } from "libs/utils";


const ShoppingCartContext = createContext();

export function useShoppingCart(){
    return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({children}){
    const [cartItems, setCartItems] = useLocalStorage("cart",[]);
    const [product,setProduct]=useState({});
    
    const [cartPopup,setCartPopup] = useState(false);
    
  
    
      const total = cartItems.reduce((total,item)=>{return total+item.total_cost},0);
    

    function getItemQuantity(id){
        return cartItems.find(item=>item.id===id)?.quantity||0;
    }
  
    function addItemToCart(product){
        const {id,productName,image_url,amount} = product;
        
        
        setCartItems(currentItem=>{
            if(currentItem.find(item=>item.id===id)==null){
                setProduct(product);
                setCartPopup(prev=>!prev);
                return [...currentItem,{id,productName,amount:Number(amount),total_cost:Number(amount),image_url,quantity:1}]
            
            }else{
                return currentItem.map(item=>{
                    if(item.id===id){
                        notify(`Item quantity has been updated`,'success');
                        return {...item, quantity:item.quantity+1,total_cost:item.total_cost+item.amount}
                    }else{
    
                        return item;
                    }
                })
                
            }
        })
       
        
    }

    function decreaseCartQuantity(id){
        setCartItems(currentItem=>{
            if(currentItem.find(item=>item.id===id)?.quantity===1){
                return currentItem.filter(item=>item.id!==id);
            }else{
                return currentItem.map(item=>{
                    if(item.id===id){
                        return {...item, quantity:item.quantity-1,total_cost:item.total_cost-item.amount}
                    }else{
                        return item;
                    }
                })
            }
        })
        notify(`Item quantity has been updated`,'success');
    }
    function removeFromCart(id){
        setCartItems(currentItem=>{
            return currentItem.filter(item=>item.id!==id);
        });
        notify(`Product was removed from cart`,'success');
        
    }
    
    

    return <ShoppingCartContext.Provider 
    value={{addItemToCart,decreaseCartQuantity,
        removeFromCart,cartItems,getItemQuantity,total,setCartItems,cartPopup,setCartPopup,
        product,setProduct
    }}>
        {children}

    </ShoppingCartContext.Provider>

}