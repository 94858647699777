import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AbstractInput } from "components/forms";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { SpinnerIcon } from "../../vectors";
import { Alert } from "components/blocks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const pickupAddressSchema = yup.object().shape({
  street: yup.string().required("please enter your street name"),
  city: yup.string().required("city field cant be empty"),
  state: yup.string().required("state field cant be empty"),
  country: yup.string().required("country field cant be empty"),
  zip_code: yup.number(),
});

const PickupAddress = (props) => {
  const { user } = props;

  useEffect(() => {
    // if (state.updatedFormStateValuesWithProps) {
    updateFormStateValuesWithProps();
    // }
  }, [props.pickupAddressFromStore]);
  const updateFormStateValuesWithProps = () => {
    if (props.pickupAddressFromStore) {
      Object.values(state.profileForm).forEach((formElement) => {
        state.profileForm[formElement.elementConfig.name].value =
          props.pickupAddressFromStore[formElement.elementConfig.name];
      });
      setState({ ...state, updatedFormStateValuesWithProps: true });
    }
  };

  const [state, setState] = useState({
    updatedFormStateValuesWithProps: false,
    loading: false,
    profileForm: {
      street: {
        elementConfig: {
          name: "street",
          type: "inputWithoutLabel",
          placeholder: "",
          label: "Street",
          id: "street",
        },
        inputWithoutLabelType: "text",
        value: "",
        validation: {
          rules: {
            required: "Street field is required",
          },
          errorMessage: null,
        },
      },
      city: {
        elementConfig: {
          name: "city",
          type: "inputWithoutLabel",
          placeholder: "",
          label: "City",
          id: "city",
        },
        inputWithoutLabelType: "text",
        value: "",
        validation: {
          rules: {
            required: "State field is required",
          },
          errorMessage: null,
        },
      },
      state: {
        elementConfig: {
          name: "state",
          type: "inputWithoutLabel",
          placeholder: "",
          label: "State",
          id: "state",
        },
        inputWithoutLabelType: "text",
        value: "",
        validation: {
          rules: {
            required: "State field is required",
          },
          errorMessage: null,
        },
      },
      country: {
        elementConfig: {
          name: "country",
          type: "inputWithoutLabel",
          placeholder: "",
          label: "Country",
          id: "country",
        },
        inputWithoutLabelType: "text",
        value: "",
        validation: {
          rules: {
            required: "Country field is required",
          },
          errorMessage: null,
        },
      },
      zip: {
        elementConfig: {
          name: "zip",
          type: "inputWithoutLabel",
          placeholder: "",
          label: "Zip code",
          id: "zip",
        },
        inputWithoutLabelType: "number",
        value: "",
        validation: {
          rules: {
            required: "Zip code field is required",
          },
          errorMessage: null,
        },
      },
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(pickupAddressSchema),
  });

  const inputChangedHandler = (event, inputIdentifier) => {
    updateFormStateValue(inputIdentifier, event.target.value);
  };

  const updateFormStateValue = (inputIdentifier, newValue) => {
    const updatedProfileForm = {
      ...state.profileForm,
    };
    const updatedFormElement = {
      ...updatedProfileForm[inputIdentifier],
    };
    updatedFormElement.value = newValue;
    updatedProfileForm[inputIdentifier] = updatedFormElement;
    setState({ ...state, profileForm: updatedProfileForm });
  };
  const pickUpAddressHandler = () => {
    setState({ ...state, loading: true });
    const payLoad = {
      street: state.profileForm.street.value,
      city: state.profileForm.city.value,
      state: state.profileForm.state.value,
      country: state.profileForm.country.value,
      zip_code: parseInt(state.profileForm.zip.value),
    };
    // for (let value in payLoad) {
    //   if (!payLoad[value]) {
    //     return toast.error(`All fields are required`);
    //   }
    // }
    if (!Object.keys(props.pickupAddressFromStore).length) {
      props.addPickUpAddress(props.token, props.user, payLoad);
    } else {
      props.updatePickUpAddress(props.token, props.user, payLoad);
    }
  };

  return (
    <>
      <div className="pcard">
        <div className="pcard-header">
          <h3 className="pcard-header__title">Pickup Address</h3>
        </div>
        <form className="nsForm auth-panel-form" onSubmit={handleSubmit(pickUpAddressHandler)}>
          <div className="pcard-body">
            {Object.values(state.profileForm).map((pickupForm) => {
              return (
                <div className="pcard-form_control-wrapper">
                  <div className="pcard-form_control">
                    <div className="pModal-form__label-control">
                      <label htmlFor="cost_item" className="pModal-form__label">
                        {pickupForm.elementConfig.label}
                      </label>
                    </div>
                  </div>
                  <div className="pcard-form_control">
                    <AbstractInput
                      key={pickupForm.elementConfig.name}
                      value={pickupForm.value}
                      elementConfig={pickupForm.elementConfig}
                      onChange={(event) =>
                        inputChangedHandler(event, pickupForm.elementConfig.name)
                      }
                      validation={register(pickupForm.validation.rules)}
                      // hasError={errors[pickupForm.elementConfig.name]}
                      // errorMessage={
                      //   (errors[pickupForm.elementConfig.name] &&
                      //     errors[pickupForm.elementConfig.name].message) ||
                      //   props.errors[pickupForm.elementConfig.name]
                      // }

                      hasForgotPasswordLabel={pickupForm.hasForgotPasswordLabel}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pcard-footer">
            {props.loading ? (
              <SpinnerIcon />
            ) : (
              <button className="button button-md button--orange">Save Changes</button>
            )}
          </div>
        </form>
      </div>
      {<Alert message={props.error} isError={true} />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadOrders: (token, user, pageType, extraParams) =>
      dispatch(actions.loadOrders(token, user, pageType, extraParams)),
    addPickUpAddress: (token, user, extraParams) =>
      dispatch(actions.addPickUpAddresses(token, user, extraParams)),
    updatePickUpAddress: (token, user, extraParams) =>
      dispatch(actions.updatePickUpAddress(token, user, extraParams)),
    getReferralStats: (token, user) => dispatch(actions.getReferralStats(token, user)),
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.userInfo,
    userProfile: state.userAccount.userProfile,
    loading: state.userAccount.updatingProfile,
    error: state.userAccount.updateProfileError,
    pickupAddressFromStore: state.orders.pickUpAddress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupAddress);
