import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "components/blocks";
import { getStringHash, getCamelCase } from "libs/utils";
import { DownChevron, UpChevron } from "components/vectors";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { PepperestContext } from "components/helpers/constant";

const HeaderAlternate = ({ page, links, location, user, cart, history }) => {
  const getPathName = () => {
    const { pathname } = location;
    const indexOfLastSlash = pathname.lastIndexOf("/");
    return pathname.substring(indexOfLastSlash + 1, pathname.length);
  };
  const presentRouteString = getCamelCase(getPathName());
  const isBuyer = user?.usertype === "Buyer";
  const isBuyerOrBoth = user?.usertype === "Buyer" || user?.usertype === "Both";

  let filteredLinkBaseOnUserType;
  if (links) {
    if (isBuyer) {
      filteredLinkBaseOnUserType = links.filter((word) => word.title !== "Referal Stats");
    } else {
      filteredLinkBaseOnUserType = links;
    }
  }

  const determineButton = (pageTitle) => {
    switch (pageTitle.toLowerCase()) {
      case "payments":
        return (
          <PepperestContext.Consumer>
            {(context) => {
              return (
                <>
                  {!isBuyer
                    ? // <a
                      //   className='button button-md button--orange'
                      //   href='/invoice'
                      // >
                      //   Create Invoice
                      // </a>
                      null
                    : null}
                </>
              );
            }}
          </PepperestContext.Consumer>
        );
      case "products":
        return (
          <PepperestContext.Consumer>
            {(context) => (
              <div
                className="button button-md button--orange"
                onClick={() => {
                  context.updateShowProductModal(true);
                }}
              >
                Add Product
              </div>
            )}
          </PepperestContext.Consumer>
        );

      default:
        return null;
    }
  };

  const [state, setState] = useState({ isOpen: false });
  return (
    <div className="nsHeader-alternate">
      <div className="nsHeader-main">
        <nav className="nsHeader-nav nsHeader-alternate-nav">
          {filteredLinkBaseOnUserType.map(({ url, title, link }, index) => {
            if (!link) {
              let isActive;
              if (getPathName() !== "single") {
                isActive = title.toLowerCase().includes(getPathName()) && "active";
              } else if (title == "Products") {
                isActive = getPathName() && "active";
              }
              return (
                <NavLink
                  key={getStringHash(title)}
                  url={url}
                  title={title}
                  classNames={`nsHeader-nav__item nsHeader-alternate-nav__item ${isActive}`}
                  exact
                />
              );
            }
            let isActive = link.toLowerCase().includes(getPathName()) && "active";
            return (
              <NavLink
                key={getStringHash(title)}
                url={url}
                title={title}
                classNames={`nsHeader-nav__item nsHeader-alternate-nav__item ${isActive}`}
                exact
              />
            );
          })}
        </nav>
        <div></div>
        {isBuyerOrBoth && (
          <div
            role="button"
            tabIndex="0"
            className="flex-row button mr-auto mr-md-0 button-md button--orange d-flex"
            onClick={() => {
              history.push("/cart");
            }}
          >
            My Cart
            {cart != null && <div className="mBadge-alt">{cart?.items_count || 0}</div>}
          </div>
        )}
        <h3 className="nsHeader-nav__current-link d-block d-md-none">
          {presentRouteString === "Transactions" ? "All Transactions" : presentRouteString}
        </h3>
        {determineButton(page)}
        {state.isOpen ? (
          <div
            className="d-block d-lg-none"
            onClick={() => {
              setState({ ...state, isOpen: !state.isOpen });
            }}
          >
            <UpChevron />
          </div>
        ) : (
          <div
            className="d-block d-lg-none"
            onClick={() => {
              setState({ ...state, isOpen: !state.isOpen });
            }}
          >
            <DownChevron />
          </div>
        )}
        {state.isOpen ? (
          <>
            <div className="nsHeader-alternate-nav__mobile-overlay" />
            <div
              className="nsHeader-alternate-nav__mobile"
              onClick={() => {
                setState({ ...state, isOpen: !state.isOpen });
              }}
            >
              <ul className="nsHeader-alternate-nav__mobile-menu">
                {filteredLinkBaseOnUserType
                  .filter(({ title }) => !title.includes(presentRouteString))
                  .map(({ url, title }) => (
                    <NavLink
                      key={getStringHash(title)}
                      url={url}
                      title={title}
                      classNames="nsHeader-alternate-nav__item"
                      exact
                    />
                  ))}
              </ul>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
HeaderAlternate.defaultProps = {
  // isPaymentButton:
};

HeaderAlternate.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  page: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.userInfo,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps, null)(withRouter(HeaderAlternate));
