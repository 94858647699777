import React from "react";

const CustomerDetailCard = ({ customer }) => {
  return (
    <div className="customer__info">
      <h1 className="title">Customers Details</h1>
      <div className="customer__card">
        <div className="card__item">
          <p>Name</p>
          <p>{customer?.customer_name}</p>
        </div>
        <h2 className="item__title">Contact Information</h2>
        <div className="card__item">
          <p>Email Address</p>
          <p>{customer?.customerEmail}</p>
        </div>
        <div className="card__item">
          <p>Phone</p>
          <p>{customer?.buyerPhone}</p>
        </div>
        {customer?.address ? (
          <>
            <h2 className="item__title">Shipping Address</h2>
            {customer?.address?.split(",")?.map((item, index) => (
              <p className="label" key={index}>
                {item}
              </p>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerDetailCard;
