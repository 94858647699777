import React from "react";
import { Link, useHistory } from "react-router-dom";

function SectionBeforeFooter({ buyer = false }) {
  const { push } = useHistory();
  return (
    <div
      style={{
        borderBottom: "0.8px solid grey",
      }}
    >
      <section
        style={{
          height: `${buyer ? "290px" : "470px"}`,
          //backgroundImage: "url('/assets/images/SectionBeforeNavPop.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          objectFit: "contain",
          backgroundColor: "rgba(0, 159, 253, 0.1)",
          position: "relative",
        }}
        className=" py-5 d-flex justify-content-center align-items-center"
      >
        <div className="blueOverlay"></div>
        <div className="col text-center d-flex flex-column justify-content-center align-items-center">
          <h1 className="community-section  col-md-7 display-6 text-center px-0">
            {buyer ? "Need more information?" : "Get quality advisory from our business community"}
          </h1>
          {buyer && (
            <p className="w-md-50 px-md-5 mb-2 mx-auto text-center">
              Our support is available 24/7 for customers and stores
            </p>
          )}
          <div
            className={` ${
              buyer ? "mt-2" : "mt-4"
            } col-md-3 d-flex align-items-center justify-content-center mx-auto mb-5`}
          >
            <button
              
              className="button--lighOrange fw-light px-4 button font-weight-normal register-button"
            >
              {buyer ? (
                <a
                  href="http://bit.ly/pepperestsupporttwo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-white"
                >
                  Talk to us
                </a>
              ) : (
                <a
                  href="http://bit.ly/joinpepperestcommunity"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-white"
                >
                  Join our business community
                </a>
              )}
            </button>
          </div>
        </div>
      </section>
      {/* White section before the footer */}
      
    </div>
  );
}

export default SectionBeforeFooter;
