import React from "react";

const ChatDate = ({date}) => {
  return (
    <div className="chat__date">
      <p>{date}</p>
    </div>
  );
};

export default ChatDate;
