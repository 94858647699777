import React from "react";
import styles from "./wishlistcard.module.css";
import productImage from "../../buyer-assets/img/Product pic.png";
import { CustomCheckbox } from "buyer-components/CustomInput";

const WishListCard = ({ isChecked, item, id, handleCheck }) => {
  return (
    <div
      className={` ${styles.wishlistCard} ${isChecked ? styles.active : ""}`}
    >
      <div className={styles.cardWrapper}>
        <div className={styles.productContainer}>
          <img src={item?.image_url} className={styles.productImage} />
          <div className={styles.nameContainer}>
            <div>
              <h3 className={styles.productTitle}>{item?.product_name}</h3>
              <p className={styles.variant}>Color:</p>
            </div>
            <p className={styles.price}>₦{item?.price}</p>
          </div>
        </div>
        <div>
          <CustomCheckbox
            isChecked={isChecked}
            id={id}
            toggleCheckbox={handleCheck}
          />
        </div>
      </div>
    </div>
  );
};

export default WishListCard;
