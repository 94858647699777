import * as actionTypes from '../actions/actionTypes';

const initialState = [];

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (action.type) {
    case actionTypes.SET_ALERT:
      return [...state, payload];
    case actionTypes.REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}
