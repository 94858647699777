import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    error: null,
    emailError:null,
    verifying:true,
    loading: false,
    userInfo: null,
    sessionExpired:false,
    success:false,
    errors: {
        accountType : null,
        email : null,
        name : null,
        businessName : null,
        password : null,
    }
};

const loginStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true,success:false } );
};

const loginSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.token,
        userInfo: action.userInfo,
        error: null,
        success:true,
        loading: false
     } );
};

const vendorAuthSuccess = (state, action) => {
    return updateObject( state, { 
            token: action.token,
            userInfo: action.userInfo,
            success:true,
            error:"",
            loading:false,
            
     } );
};
const vendorAuthComplete = (state, action) => {
    return updateObject( state, { 
            success:false,
     } );
};

const loginFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const verifyEmail = ( state, action ) => {
    return updateObject( state, 
        { emailError: null, verifying: true,success:false } );
};
const verifyEmailSuccess = ( state, action ) => {
    return updateObject( state, 
        { emailError: null, verifying: false,success:true } );
};
const verifyEmailFail = ( state, action ) => {
    return updateObject( state, 
        { emailError: action.error, verifying: false,success:false } );
};

const logout = (state, action) => {
    return updateObject(state, { token: null, userInfo: null });
};

const sessionExpired = (state, action) => {
    return updateObject(state, { sessionExpired:true });
};

const registerFail = (state, action) => {
    return updateObject(state, { errors: action.errors, loading: false});
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return loginStart(state, action);
        case actionTypes.AUTH_SUCCESS: return loginSuccess(state, action);
        case actionTypes.AUTH_FAIL: return loginFail(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.SESSION_EXPIRED: return sessionExpired(state, action);
        case actionTypes.REGISTER_FAIL: return registerFail(state, action);
        case actionTypes.VERIFY_EMAIL: return verifyEmail(state, action);
        case actionTypes.VERIFY_EMAIL_SUCCESS: return verifyEmailSuccess(state, action);
        case actionTypes.VERIFY_EMAIL_FAIL: return verifyEmailFail(state, action);
        case actionTypes.INITIATE_VENDOR_AUTH_SUCCESS: return vendorAuthSuccess(state, action);
        case actionTypes.INITIATE_VENDOR_AUTH_COMPLETE: return vendorAuthComplete(state, action);
        default:
            return state;
    }
};

export default reducer;