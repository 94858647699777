import React from 'react'

const LogoutIcon = () => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.80859 4.91001C8.62776 2.81001 7.54859 1.95251 5.18609 1.95251H5.11026C2.50276 1.95251 1.45859 2.99668 1.45859 5.60418V9.40752C1.45859 12.015 2.50276 13.0592 5.11026 13.0592H5.18609C7.53109 13.0592 8.61026 12.2133 8.80276 10.1483" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.25034 7.5H11.8887" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5878 5.5459L12.542 7.50007L10.5878 9.45423" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)

export default LogoutIcon