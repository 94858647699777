import React,{ useState,useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);



const SaleGraph = ({data,salesGraphMode}) => {
    let lineGraphRef;
    const [salesGraphLabels, setSalesGraphLabels] = useState([]);
    const [salesDataSet1, setSalesDataSet1] = useState({});
    const [salesDataSet2, setSalesDataSet2] = useState({});

    useEffect(() => {

        const dataSet1 = {
          id: 1,
          label: 'Revenue',
          data: [],
          tension: 0.5,
          borderColor: '#6941c6',
          backgroundColor: '#6941c6',
        }
        const dataSet2 = {
          id: 2,
          label: 'Sales',
          data: [],
          tension: 0.5,
          borderColor: '#f96e46',
          backgroundColor: '#f96e46',
        }
        let days = []; //months name used for label
        data.this_week_report.map((item,index)=>{
            days.push(item.day);
            dataSet1.data.push(item.total_revenue);
            dataSet2.data.push(item.total_orders);
            if (index + 1 === data.this_week_report.length) {
                setSalesGraphLabels(days);
                setSalesDataSet1(dataSet1);
                setSalesDataSet2(dataSet2);
            }
        })

      }, [data])
    

      const salesGraphOptions = {
        layout: {
          padding: 5
      },
        plugins: {
          legend: {
            display: true,
            usePointStyle: true,
            pointStyle: 'circle',
            position:'bottom'
          },
         
        },
        
          scales: {
            x: {
              grid: {
                display: false,
                
              }
            },
            y: {
                beginAtZero: true,
                ticks: {
                 
                
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function(val, index) {
                   return val>1?val:Math.floor(index*1000);
                  },
                
                },
                suggestedMin: 1000,
                
              },
            
          }
      }

      useEffect(() => {
        const dataSet1 = {
          id: 1,
          label: 'Revenue',
          data: [],
          tension: 0.1,
          borderColor: '#6941c6',
          backgroundColor: '#6941c6',
        }
        const dataSet2 = {
          id: 2,
          label: 'Sales',
          data: [],
          tension: 0.1,
          borderColor: '#f96e46',
          backgroundColor: '#f96e46',
        }
        if (salesGraphMode === "month") {
            let months = []; //months name used for label
            data.this_month_report.map((item,index)=>{
                months.push(item.date);
                dataSet1.data.push(item.total_revenue);
                dataSet2.data.push(item.total_orders);
                if (index + 1 === data.this_month_report.length) {
                    setSalesGraphLabels(months);
                    setSalesDataSet1(dataSet1);
                    setSalesDataSet2(dataSet2);
                }
            })
    
        }else  if (salesGraphMode === "week") {
            let days = []; //months name used for label
            data.this_week_report.map((item,index)=>{
                days.push(item.day);
                dataSet1.data.push(item.total_revenue);
                dataSet2.data.push(item.total_orders);
                if (index + 1 === data.this_week_report.length) {
                    setSalesGraphLabels(days);
                    setSalesDataSet1(dataSet1);
                    setSalesDataSet2(dataSet2);
                }
            })
    
        }
        
      }, [salesGraphMode])

  return (
            <Line ref = {(ref)=> lineGraphRef = ref}
                  options={salesGraphOptions}
                  datasetIdKey='id'
                  data={{
                    labels: salesGraphLabels,
                    datasets: [salesDataSet1, salesDataSet2 ],
                  }}
                />
           
  )
}

export default SaleGraph