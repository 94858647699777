import React from "react";

function index() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.7549 13.5419H43.7016C43.2899 12.9569 42.8565 12.4152 42.4015 11.8735L40.7549 13.5419Z"
        fill="#FE8F01"
      />
      <path
        d="M40.1268 9.57686C39.5852 9.12186 39.0435 8.68852 38.4585 8.27686V11.2235L40.1268 9.57686Z"
        fill="#FE8F01"
      />
      <path
        d="M42.4231 11.8735L48.8148 5.48178C49.4431 4.85345 49.4431 3.81345 48.8148 3.18512C48.1865 2.55678 47.1465 2.55678 46.5181 3.18512L40.1265 9.57678C40.9498 10.2918 41.7081 11.0718 42.4231 11.8735Z"
        fill="#FE8F01"
      />
      <path
        d="M38.4583 6.5C38.4583 5.61167 37.7216 4.875 36.8333 4.875C35.9666 4.875 35.2733 5.56833 35.23 6.41333C36.3566 6.955 37.44 7.56167 38.4583 8.27667V6.5Z"
        fill="#FE8F01"
      />
      <path
        d="M47.125 15.1665C47.125 14.2782 46.3883 13.5415 45.5 13.5415H43.7017C44.4167 14.5598 45.045 15.6432 45.565 16.7698C46.4317 16.7265 47.125 16.0332 47.125 15.1665Z"
        fill="#FE8F01"
      />
      <path
        d="M27.625 31.9582H28.275C29.12 31.9582 29.7917 31.1999 29.7917 30.2682C29.7917 29.0982 29.4667 28.9249 28.73 28.6649L27.625 28.2749V31.9582Z"
        fill="#FE8F01"
      />
      <path
        d="M45.5868 16.7702C45.5652 16.7702 45.5435 16.7918 45.5002 16.7918H36.8335C36.6168 16.7918 36.4218 16.7485 36.2052 16.6618C35.8152 16.4885 35.4902 16.1852 35.3168 15.7735C35.2518 15.5785 35.2085 15.3835 35.2085 15.1668V6.50016C35.2085 6.4785 35.2302 6.45683 35.2302 6.4135C32.4135 5.09183 29.2935 4.3335 26.0002 4.3335C14.0402 4.3335 4.3335 14.0402 4.3335 26.0002C4.3335 37.9602 14.0402 47.6668 26.0002 47.6668C37.9602 47.6668 47.6668 37.9602 47.6668 26.0002C47.6668 22.7068 46.9085 19.5868 45.5868 16.7702ZM29.7918 25.6102C31.1785 26.0868 33.0418 27.1052 33.0418 30.2902C33.0418 32.9985 30.8968 35.2302 28.2752 35.2302H27.6252V35.7718C27.6252 36.6602 26.8885 37.3968 26.0002 37.3968C25.1118 37.3968 24.3752 36.6602 24.3752 35.7718V35.2302H24.2018C21.3202 35.2302 18.9585 32.8035 18.9585 29.8135C18.9585 28.9035 19.6952 28.1668 20.5835 28.1668C21.4718 28.1668 22.2085 28.9035 22.2085 29.7918C22.2085 30.9835 23.0968 31.9585 24.2018 31.9585H24.3752V27.1485L22.2085 26.3902C20.8218 25.9135 18.9585 24.8952 18.9585 21.7102C18.9585 19.0018 21.1035 16.7702 23.7252 16.7702H24.3752V16.2502C24.3752 15.3618 25.1118 14.6252 26.0002 14.6252C26.8885 14.6252 27.6252 15.3618 27.6252 16.2502V16.7918H27.7985C30.6802 16.7918 33.0418 19.2185 33.0418 22.2085C33.0418 23.0968 32.3052 23.8335 31.4168 23.8335C30.5285 23.8335 29.7918 23.0968 29.7918 22.2085C29.7918 21.0168 28.9035 20.0418 27.7985 20.0418H27.6252V24.8518L29.7918 25.6102Z"
        fill="#FE8F01"
      />
      <path
        d="M22.2085 21.7315C22.2085 22.9015 22.5335 23.0748 23.2702 23.3348L24.3752 23.7248V20.0415H23.7252C22.9018 20.0415 22.2085 20.7998 22.2085 21.7315Z"
        fill="#FE8F01"
      />
    </svg>
  );
}

export default index;
