import React from "react";

function index({fill='white'}) {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5208 4.00461L7.55438 4.55825L6.99479 4.49045C4.95791 4.23058 3.17843 3.34929 1.66756 1.86916L0.928908 1.13474L0.73865 1.67708C0.33575 2.88604 0.593158 4.16279 1.43253 5.02149C1.8802 5.49604 1.77948 5.56383 1.00725 5.28136C0.73865 5.19097 0.503625 5.12318 0.481242 5.15708C0.4029 5.23617 0.6715 6.26435 0.884142 6.6711C1.17513 7.23604 1.76828 7.78967 2.4174 8.11734L2.96579 8.37721L2.31668 8.3885C1.68994 8.3885 1.66756 8.3998 1.73471 8.63708C1.95854 9.37149 2.84268 10.1511 3.82755 10.4901L4.52143 10.7273L3.91708 11.0889C3.02175 11.6086 1.96973 11.9024 0.917717 11.925C0.414092 11.9363 0 11.9815 0 12.0154C0 12.1284 1.36538 12.7611 2.15999 13.0097C4.54382 13.7441 7.37531 13.4277 9.50173 12.1736C11.0126 11.281 12.5235 9.50708 13.2286 7.78967C13.6091 6.87448 13.9896 5.20227 13.9896 4.40006C13.9896 3.88032 14.0232 3.81253 14.6499 3.1911C15.0192 2.82955 15.3662 2.43409 15.4333 2.3211C15.5452 2.10643 15.534 2.10643 14.9633 2.29851C14.012 2.63747 13.8777 2.59227 14.3477 2.08383C14.6947 1.72227 15.1088 1.06695 15.1088 0.874871C15.1088 0.840975 14.9409 0.897468 14.7506 0.999157C14.5492 1.11214 14.1015 1.28162 13.7658 1.38331L13.1614 1.57539L12.613 1.20253C12.3108 0.999157 11.8856 0.773183 11.6617 0.705391C11.0909 0.547209 10.218 0.569806 9.70318 0.750585C8.30422 1.25903 7.42008 2.56968 7.5208 4.00461Z"
        fill={fill}
      />
    </svg>
  );
}

export default index;
