import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
 
  loading: true,
  loaded: false,
  error: null,

  loadingChatMessages: false,
  loadedChatMessages: false,
  errorChatMessages: null,
  chatMessages : null,
  
  loadingMyChats: false,
  loadedMyChats: false,
  errorMyChats: null,
  chats :null,

  sendMessageError: null,
  sendingMessage: false,
  messageSent: false,
  
};

const loadingChatMessages = (state, action) => {
  return updateObject(state, {
    loadingChatMessages: true,
    loadedChatMessages: false
  });
};

const loadingMyChats = (state, action) => {
    return updateObject(state, {
      loadingMyChats: true,
      loadedMyChats: false
    });
  };

const sendMessageStart = (state, action) => {
  return updateObject(state, {
    sendingMessage: true,
    messageSent: false,
    sendMessageError:""

  });
};

const sendMessageComplete = (state, action) => {
  return updateObject(state, {
    sendingMessage: false,
    messageSent: false,
    sendMessageError:""
  });
};


const sendMessageFail = (state, action) => {
  return updateObject(state, {
    sendingMessage: false,
    messageSent: false,
    sendMessageError: action.error,
  });
};

const sendMessageSuccess = (state, action) => {
    return updateObject(state, {
      sendMessageError: "",
      sendingMessage: false,
      messageSent: true,
    });
  };



const getMyChats = (state, action) => {
  return updateObject(state, {
    loadingMyChats: true,
    errorMyChats: "",
    loadedMyChats: false,
  });
};
const getMyChatsSuccess = (state, action) => {
  return updateObject(state, {
    chats: action.chats,
    errorMyChats: "",
    loadingMyChats: false,
  });
};

const getMyChatsFail = (state, action) => {
  return updateObject(state, {
    myChats: null,
    errorMyChats: action.error,
    loadingMyChats: false,
  });
};

const getChatMessages = (state, action) => {
    return updateObject(state, {
      loadingChatMessages: true,
      errorChatMessages: "",
      loadedChatMessages: false,
    });
  };
  const getChatMessagesSuccess = (state, action) => {
    return updateObject(state, {
      chatMessages: action.chatMessages,
      errorChatMessages: "",
      loadingChatMessages: false,
    });
  };
  
  const getChatMessagesFail = (state, action) => {
    return updateObject(state, {
      chatMessages: null,
      errorChatMessages: action.error,
      loadingChatMessages: false,
    });
  };



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_CHAT_MESSAGES:
      return loadingChatMessages(state, action);
    case actionTypes.LOADING_MY_CHATS:
      return loadingMyChats(state, action);
    case actionTypes.SEND_MESSAGE:
      return sendMessageStart(state, action);
    case actionTypes.SEND_MESSAGE_SUCCESS:
      return sendMessageSuccess(state, action);
    case actionTypes.SEND_MESSAGE_FAIL:
      return sendMessageFail(state, action);
    case actionTypes.SEND_MESSAGE_COMPLETE:
      return sendMessageComplete(state, action);
    case actionTypes.GET_MY_CHATS:
      return getMyChats(state, action);
    case actionTypes.GET_MY_CHATS_SUCCESS:
      return getMyChatsSuccess(state, action);
    case actionTypes.GET_MY_CHATS_FAIL:
      return getMyChatsFail(state, action);
    case actionTypes.GET_CHAT_MESSAGES:
        return getChatMessages(state, action);
    case actionTypes.GET_CHAT_MESSAGES_SUCCESS:
        return getChatMessagesSuccess(state, action);
    case actionTypes.GET_CHAT_MESSAGES_FAIL:
        return getChatMessagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
