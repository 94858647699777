import React from 'react';
import PropTypes from 'prop-types';


const EyeIcon = ({ classNames, hasError, onClick }) => (
 
  <svg 
  className={`${classNames} ${hasError ? 'error' : ''}`}
  onClick={() => { onClick(); }}
  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_1_2379)">
  <path d="M24.2811 12.6605C21.8472 8.16101 17.5789 5.43823 12.8555 5.43823C8.13219 5.43823 3.85663 8.16101 1.44441 12.6605L1.24219 12.9999L1.42997 13.3466C3.86385 17.846 8.13219 20.5688 12.8555 20.5688C17.5789 20.5688 21.8544 17.8821 24.2811 13.3466L24.4689 12.9999L24.2811 12.6605ZM12.8555 19.0882C8.78941 19.0882 5.05552 16.8205 2.88885 12.9999C5.05552 9.17934 8.78941 6.91157 12.8555 6.91157C16.9216 6.91157 20.6122 9.18657 22.815 12.9999C20.6122 16.8205 16.9144 19.0882 12.8555 19.0882Z" 
  fill={hasError?'#b00020':"#18191F"}/>
  <path d="M13.0648 8.06727C12.0855 8.07442 11.1304 8.3716 10.3199 8.92128C9.50946 9.47097 8.88009 10.2485 8.51128 11.1557C8.14247 12.0628 8.05077 13.059 8.24776 14.0182C8.44475 14.9775 8.9216 15.8568 9.61807 16.5452C10.3145 17.2337 11.1994 17.7002 12.1609 17.886C13.1224 18.0718 14.1173 17.9685 15.0202 17.5892C15.923 17.2098 16.6931 16.5715 17.2333 15.7547C17.7735 14.9379 18.0596 13.9793 18.0553 13C18.0525 12.3482 17.9211 11.7033 17.6685 11.1024C17.416 10.5014 17.0474 9.95617 16.5838 9.49792C16.1202 9.03968 15.5707 8.67743 14.9668 8.43193C14.363 8.18643 13.7166 8.06251 13.0648 8.06727ZM13.0648 16.5317C12.3731 16.5246 11.6989 16.3132 11.1269 15.9242C10.5549 15.5352 10.1107 14.9858 9.8499 14.3451C9.58914 13.7044 9.5235 13.0009 9.66124 12.323C9.79898 11.6451 10.1339 11.023 10.6241 10.5349C11.1142 10.0468 11.7376 9.71433 12.4161 9.57936C13.0945 9.44439 13.7977 9.5129 14.4374 9.77628C15.077 10.0397 15.6246 10.4862 16.0113 11.0597C16.398 11.6333 16.6066 12.3083 16.6109 13C16.6128 13.4656 16.5223 13.9269 16.3446 14.3572C16.1669 14.7875 15.9055 15.1782 15.5757 15.5067C15.2458 15.8353 14.854 16.095 14.423 16.271C13.992 16.4469 13.5303 16.5355 13.0648 16.5317Z" 
  fill={hasError?'#b00020':"#18191F"}/>
  </g>
  <defs>
  <clipPath id="clip0_1_2379">
  <rect width="26" height="26" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
);

EyeIcon.defaultProps = {
  hasError: false,
};

EyeIcon.propTypes = {
  classNames: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

export default EyeIcon;
