import * as Yup from "yup";
import { CouponInput } from "buyer-components/CustomInput";
import { Formik } from "formik";
import React from "react";
import { applyWishlistCoupon } from "buyer-api";
import { useMutation } from "@tanstack/react-query";
import { getFromStorage } from "buyer-constants";
import { notify } from "libs/utils";
import { useCartContext } from "buyer-context/cartContext";
import styles from "./coupon.module.css";

const Coupon = ({ disabled }) => {
  const { dispatch } = useCartContext();
  let selectedCart = getFromStorage("state");
  const extraParams = getFromStorage("extraParams");

  const {
    isLoading: applyingCoupon,
    mutateAsync: applyCouponFN,
    error: couponError,
  } = useMutation(applyWishlistCoupon);
  const couponSchema = Yup.object().shape({
    coupon_code: Yup.string().required("Coupon code is required"),
  });

  const applyCoupon = async (values) => {
    const params = {
      ...values,
      wishlist_id: extraParams?.wishlist_id,
      wishlist_item_ids: selectedCart.wishlistCart?.map(
        (item) => item?.product?.wishlist_item_id
      ),
    };
    try {
      const result = await applyCouponFN(params);
      if (result.ResponseStatus == "Successful") {
        dispatch({
          type: "APPLY-WISHLIST-COUPON",
          payload: result.coupon_value,
        });
        notify(result?.ResponseMessage, "success");
      }
    } catch (error) {
      notify(error?.message, "error");
      console.log({ error });
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          coupon_code: "",
        }}
        validationSchema={couponSchema}
        onSubmit={(values, actions) => {
          applyCoupon(values);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className={""}>
              <h4 className={styles.couponLabel}>Discount Coupon</h4>
              <p className={styles.couponDescription}>
                Have a discount coupon? Enter code below
              </p>
            </div>
            <CouponInput
              loading={applyingCoupon}
              onClick={handleSubmit}
              name="coupon_code"
              onChange={handleChange}
              value={values.coupon_code}
              disabled={disabled}
              placeholder="Enter coupon code"
              title={disabled ? "Applied" : "Apply"}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Coupon;
