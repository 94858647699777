import React, { useEffect, useState } from "react";
import Dropdown from "components/dashboardComponent/Dropdown";
import { SearchBar } from "components/dashboardComponent/SearchBar";
import DashboardLayout from "components/layouts/dashboardLayout";
import { ThreeDot, TrashIcon } from "components/vectors";
import StockIcon from "components/vectors/StockIcon";
import Pagination from "components/dashboardComponent/Pagination";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import { loadProduct, removeProduct } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter, handleCopy } from "libs/utils";
import { Loader, LoadingListItem } from "components/blocks";
import { useRef } from "react";
import DropdownAction from "components/dashboardComponent/DropdownAction";
import { Link, useHistory, useLocation } from "react-router-dom";
import PlusIcon from "components/vectors/PlusIcon";
import {
  removeMultipleProduct,
  toggleProductStock,
} from "store/actions/products";
import CustomSelect from "components/dashboardComponent/CustomSelect";
import useDocumentMeta from "components/customHook/useDocumentMeta";

const Products = () => {
  useDocumentMeta("Products | Peppa", "");
  const source = localStorage.getItem("source");
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.auth);
  const { products, loading, meta, updatingProduct, updateProductComplete } =
    useSelector((state) => state.products);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isStockAll, setIsStockAll] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(products.map((product) => product.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item != id));
    }
  };

  let timeout = useRef();
  const handleSearch = (event) => {
    event.persist();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuery(event.target.value);
    }, 2000);
  };

  const getProducts = (page) => {
    const params = {
      page: page,
      query: query,
      per_page: pageSize,
    };
    dispatch(loadProduct(token, userInfo, params));
  };

  useEffect(() => {
    getProducts(1);
  }, [query, pageSize]);

  const toggleDeleteProduct = (product) => {
    setDeleteProductId(product?.id);
    setconfirmDelete(true);
  };

  const handleSelect = (product) => {
    dispatch(
      toggleProductStock(token, userInfo, { productIDs: [product?.id] })
    );
    // setIsStockAll(product);
  };

  const deleteProduct = () => {
    dispatch(removeProduct(token, userInfo, { productID: deleteProductId }));
  };
  const deleteProductMultiple = () => {
    dispatch(removeMultipleProduct(token, userInfo, { productIDs: isCheck }));
  };

  const onToggleProductStock = () => {
    dispatch(toggleProductStock(token, userInfo, { productIDs: isCheck }));
  };

  const editProduct = (product) => {
    history.push(`/dashboard/products/${product?.id}`);
  };

  const copyProductLink = (product) => {
    let link = `${window.location.origin}/product/${product?.id}`;
    handleCopy(link, "product link copied");
  };

  const handlePageSize = (size) => {
    setPageSize(size);
  };

  useEffect(() => {
    setconfirmDelete(false);
    setShowDelete(false);
    setIsCheckAll(false);
    setIsCheck([]);
  }, [updateProductComplete]);

  const finishSetup = () => {
    localStorage.removeItem("source");
    history.push("/dashboard/complete-setup");
  };
  return (
    <main className="products">
      <div className="product__button__wrapper">
        {source === "onboarding" ? (
          <>
            <p className="product__count">{meta?.total} products found</p>
            <button
              className="button button--purple text__md button__content"
              onClick={finishSetup}
            >
              Finish Setup
            </button>
          </>
        ) : (
          <>
            <p className="product__count"> Products</p>
            <Link to="/dashboard/products/new">
              <button className="button button--purple button__content">
                <span>
                  <PlusIcon color="white" /> Add Product
                </span>
              </button>
            </Link>
          </>
        )}
      </div>
      <div className="search__product__action__wrapper">
        <div className="search__wrapper">
          <SearchBar placeholder="Search in products" onChange={handleSearch} />
        </div>
        <div className="actions__wrapper">
          <div
            className={`cursor__pointer ${
              isCheckAll || isCheck.length > 0 ? "" : "disabled"
            }`}
            onClick={() => setShowDelete(true)}
          >
            {" "}
            <TrashIcon width={16} height={17} /> Delete
          </div>
          <div
            className={`cursor__pointer  ${
              isCheckAll || isCheck.length > 0 ? "" : "disabled"
            }`}
            onClick={onToggleProductStock}
          >
            {" "}
            <StockIcon width={16} height={17} /> Toggle Stock
          </div>
        </div>
      </div>

      {loading ? (
        <ul>
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
        </ul>
      ) : (
        <section className="table__wrapper">
          <table className="product__table">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      onClick={handleSelectAll}
                      className="form_input"
                    />
                    <span></span>
                  </label>
                </th>
                <th>Product</th>
                <th>Price</th>
                <th>Status</th>
                <th align="right" width="120px">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {products.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    Products will appear here
                  </td>
                </tr>
              ) : (
                <>
                  {products.map((product, index) => (
                    <tr key={product?.id}>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            key={product.id}
                            id={product.id}
                            checked={isCheck.find((item) => item == product.id)}
                            onChange={handleClick}
                            className="form_input"
                          />
                          <span></span>
                        </label>
                      </td>
                      <td data-cell="Product">
                        <img
                          src={product?.image_url}
                          className="product__image"
                        />
                        <span>{product?.productName}</span>
                      </td>
                      <td data-cell="Amount">
                        {currencyFormatter.format(product.amount)}
                      </td>
                      <td data-cell="Status">
                        <CustomSelect
                          disabled={updatingProduct}
                          defaultValue={
                            product?.inStock == 0 ? "Out of stock" : "In Stock"
                          }
                          options={stockOption}
                          onSelect={() => handleSelect(product)}
                        />
                        {/* <Dropdown bordered={false} options={stockOption} /> */}
                      </td>
                      <td data-cell="Action">
                        <DropdownAction title={<ThreeDot />} tabIndex={index}>
                          <li
                            className="dropdown__item"
                            onClick={() => editProduct(product)}
                          >
                            Edit product
                          </li>
                          <li
                            className="dropdown__item"
                            onClick={() => copyProductLink(product)}
                          >
                            Product link
                          </li>
                          <li
                            className="dropdown__item "
                            onClick={() => toggleDeleteProduct(product)}
                          >
                            Delete product
                          </li>
                        </DropdownAction>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={meta?.current_page}
            totalCount={meta?.total}
            pageSize={meta?.per_page}
            onChangePageSize={handlePageSize}
            onPageChange={(page) => getProducts(page)}
          />
        </section>
      )}

      <ModalComponent show={showDelete} centered size="md">
        <div className="modal__component">
          <div className="modal__title">Delete Products?</div>
          <div className="modal__content">
            <p>Are you sure you want to delete these products? </p>
            <div className="modal__control">
              <button
                className="button button--purple-outline px-4"
                onClick={() => setShowDelete(false)}
              >
                Cancel
              </button>
              <button
                className="button button--purple px-4"
                onClick={deleteProductMultiple}
                disabled={updatingProduct}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent show={confirmDelete} centered size="md">
        <div className="modal__component">
          <div className="modal__title">Delete Product?</div>
          <div className="modal__content">
            <p>
              Are you sure you want to delete these product{" "}
              <span className="fw-bold">
                {
                  products?.find((product) => product?.id == deleteProductId)
                    ?.productName
                }
              </span>
              ?{" "}
            </p>
            <div className="modal__control">
              <button
                className="button button--purple-outline px-4"
                onClick={() => setconfirmDelete(false)}
              >
                Cancel
              </button>
              <button
                className="button button--purple px-4"
                onClick={deleteProduct}
              >
                {updatingProduct ? <Loader /> : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </main>
  );
};

export default Products;

const productAction = [
  "Edit product",
  "Product link",
  "Duplication product",
  "Delete product",
];
const stockOption = ["in stock", "out of stock"];
