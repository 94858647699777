import React from "react";

function AppleStoreButton({ color = "black" }) {
  return (
    <svg
      style={{ maxWidth: "100%", maxHeight: "100%" }}
      width="162"
      height="48"
      viewBox="0 0 162 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7735_19565)">
        <path
          d="M149.099 0.000156H12.908C12.4115 0.000156 11.921 0.000156 11.4259 0.002556C11.0115 0.004956 10.6003 0.011928 10.1819 0.017796C9.2728 0.0272782 8.36589 0.0981675 7.46899 0.229848C6.57336 0.364395 5.70578 0.618055 4.8956 0.982248C4.08641 1.34952 3.34704 1.82676 2.70429 2.39664C2.05817 2.96492 1.51958 3.62178 1.10923 4.34195C0.697744 5.06069 0.412419 5.83088 0.263109 6.62593C0.112379 7.41998 0.0312704 8.22318 0.0205099 9.02833C0.00794675 9.39625 0.00662003 9.7654 0 10.1334V37.8705C0.00662003 38.2431 0.00794675 38.604 0.0205099 38.9768C0.0312737 39.7819 0.112383 40.585 0.263109 41.379C0.412007 42.1745 0.697348 42.9452 1.10923 43.6642C1.51939 44.382 2.05806 45.0362 2.70429 45.6014C3.3446 46.1738 4.08445 46.6513 4.8956 47.0158C5.70577 47.381 6.57326 47.6361 7.46899 47.7728C8.36604 47.9034 9.27286 47.9743 10.1819 47.9849C10.6003 47.9931 11.0115 47.9978 11.4259 47.9978C11.921 48.0002 12.4115 48.0002 12.908 48.0002H149.099C149.586 48.0002 150.08 48.0002 150.567 47.9978C150.979 47.9978 151.402 47.9931 151.815 47.9849C152.722 47.9749 153.627 47.9039 154.522 47.7728C155.421 47.6352 156.292 47.3801 157.106 47.0158C157.916 46.6511 158.655 46.1736 159.295 45.6014C159.94 45.034 160.48 44.3803 160.895 43.6642C161.304 42.9447 161.586 42.1741 161.733 41.379C161.884 40.5849 161.968 39.7819 161.984 38.9768C161.989 38.604 161.989 38.2431 161.989 37.8705C162 37.4345 162 37.001 162 36.5579V11.4435C162 11.0041 162 10.5681 161.989 10.1334C161.989 9.7654 161.989 9.39625 161.984 9.02828C161.968 8.22306 161.884 7.42004 161.733 6.62588C161.586 5.83129 161.303 5.06116 160.895 4.3419C160.059 2.89839 158.734 1.72339 157.106 0.98214C156.292 0.618837 155.421 0.365245 154.522 0.22974C153.627 0.0974794 152.722 0.0265653 151.815 0.017628C151.402 0.011772 150.979 0.00474 150.567 0.0024C150.08 0 149.586 0 149.099 0V0.000156Z"
          fill="#A6A6A6"
        />
        <path
          d="M11.316 46.9535C10.9028 46.9535 10.4996 46.9489 10.0896 46.9407C9.2404 46.9309 8.39315 46.8655 7.55484 46.7449C6.77315 46.6258 6.01591 46.4041 5.30809 46.0873C4.60675 45.7731 3.96708 45.3611 3.41358 44.8671C2.85206 44.379 2.38457 43.8128 2.02963 43.191C1.67079 42.5652 1.42245 41.8944 1.29326 41.2019C1.15374 40.4579 1.07826 39.7056 1.06746 38.9515C1.05886 38.6983 1.04761 37.8555 1.04761 37.8555V10.1291C1.04761 10.1291 1.0596 9.29919 1.06753 9.05538C1.07787 8.30244 1.15291 7.55129 1.29201 6.80843C1.42144 6.11411 1.66997 5.44136 2.02901 4.81349C2.38264 4.19214 2.84753 3.62552 3.40567 3.1356C3.96318 2.64102 4.6049 2.22692 5.30742 1.90838C6.01362 1.59257 6.76943 1.37249 7.54954 1.25551C8.3906 1.13377 9.24079 1.06795 10.093 1.0586L11.3166 1.04395H150.684L151.922 1.05919C152.767 1.06807 153.609 1.1333 154.442 1.25434C155.23 1.37278 155.994 1.5944 156.708 1.91189C158.116 2.55384 159.261 3.56947 159.983 4.81642C160.337 5.43996 160.581 6.10721 160.709 6.79554C160.85 7.54449 160.929 8.30155 160.945 9.06066C160.949 9.40057 160.949 9.76569 160.949 10.1291C160.96 10.5791 160.96 11.0076 160.96 11.4395V36.5591C160.96 36.9952 160.96 37.4207 160.949 37.8496C160.949 38.24 160.949 38.5975 160.944 38.9655C160.928 39.7111 160.85 40.4546 160.712 41.1902C160.585 41.8876 160.338 42.5636 159.98 43.1946C159.622 43.8096 159.157 44.3709 158.602 44.8578C158.048 45.3544 157.407 45.7688 156.704 46.085C155.992 46.4043 155.23 46.6268 154.442 46.7449C153.604 46.8661 152.757 46.9315 151.908 46.9407C151.51 46.9489 151.094 46.9535 150.691 46.9535L149.221 46.9559L11.316 46.9535Z"
          fill={color}
        />
        <path
          d="M39.3569 24.3608C39.3698 23.3592 39.6358 22.3771 40.1303 21.506C40.6247 20.6348 41.3314 19.9029 42.1847 19.3783C41.6427 18.6041 40.9275 17.967 40.0961 17.5175C39.2647 17.0681 38.34 16.8187 37.3954 16.7891C35.3803 16.5776 33.4267 17.9949 32.3999 17.9949C31.3531 17.9949 29.7721 16.8101 28.0697 16.8451C26.9685 16.8807 25.8952 17.2009 24.9546 17.7746C24.0139 18.3483 23.2379 19.1558 22.7022 20.1186C20.3814 24.1367 22.1125 30.0419 24.3356 33.2899C25.4479 34.8803 26.7478 36.6568 28.4488 36.5938C30.1132 36.5248 30.7349 35.5324 32.744 35.5324C34.7346 35.5324 35.3178 36.5938 37.0532 36.5538C38.8393 36.5248 39.9646 34.9563 41.0379 33.3508C41.837 32.2176 42.452 30.9651 42.86 29.6398C41.8223 29.2009 40.9368 28.4663 40.3139 27.5275C39.6909 26.5888 39.3581 25.4874 39.3569 24.3608Z"
          fill="white"
        />
        <path
          d="M36.079 14.6531C37.0528 13.484 37.5326 11.9814 37.4164 10.4644C35.9286 10.6206 34.5543 11.3317 33.5673 12.4559C33.0847 13.0051 32.7151 13.644 32.4796 14.3361C32.2441 15.0283 32.1474 15.76 32.1948 16.4896C32.939 16.4972 33.6752 16.3359 34.348 16.0178C35.0208 15.6997 35.6127 15.2331 36.079 14.6531Z"
          fill="white"
        />
        <path
          d="M60.397 32.5677H54.7169L53.3529 36.5954H50.947L56.3271 21.6938H58.8267L64.2068 36.5954H61.7599L60.397 32.5677ZM55.3052 30.7091H59.8076L57.5881 24.1724H57.526L55.3052 30.7091Z"
          fill="white"
        />
        <path
          d="M75.8258 31.1637C75.8258 34.5399 74.0188 36.709 71.2918 36.709C70.601 36.7452 69.914 36.5861 69.3095 36.2499C68.7049 35.9137 68.2073 35.4141 67.8735 34.8082H67.8219V40.1895H65.5918V25.7309H67.7504V27.5379H67.7914C68.1406 26.935 68.6467 26.438 69.2558 26.0997C69.865 25.7615 70.5544 25.5948 71.2508 25.6172C74.0082 25.6172 75.8258 27.7969 75.8258 31.1637ZM73.5336 31.1637C73.5336 28.9641 72.3969 27.518 70.6625 27.518C68.9586 27.518 67.8125 28.9946 67.8125 31.1637C67.8125 33.3528 68.9586 34.8188 70.6625 34.8188C72.3969 34.8188 73.5336 33.3833 73.5336 31.1637Z"
          fill="white"
        />
        <path
          d="M87.7838 31.1637C87.7838 34.5399 85.9768 36.709 83.2498 36.709C82.5591 36.7452 81.8721 36.586 81.2675 36.2499C80.663 35.9137 80.1653 35.4141 79.8315 34.8082H79.7799V40.1895H77.5498V25.7309H79.7084V27.5379H79.7494C80.0986 26.935 80.6046 26.438 81.2138 26.0997C81.8229 25.7615 82.5124 25.5948 83.2088 25.6172C85.9662 25.6172 87.7838 27.7969 87.7838 31.1637ZM85.4916 31.1637C85.4916 28.9641 84.3549 27.518 82.6205 27.518C80.9166 27.518 79.7705 28.9946 79.7705 31.1637C79.7705 33.3528 80.9166 34.8188 82.6205 34.8188C84.3549 34.8188 85.4916 33.3833 85.4916 31.1637H85.4916Z"
          fill="white"
        />
        <path
          d="M95.6869 32.4434C95.8521 33.9212 97.2877 34.8914 99.2494 34.8914C101.129 34.8914 102.481 33.9211 102.481 32.5887C102.481 31.432 101.666 30.7395 99.7346 30.2649L97.8033 29.7996C95.067 29.1387 93.7967 27.859 93.7967 25.7824C93.7967 23.2113 96.0373 21.4453 99.2189 21.4453C102.368 21.4453 104.526 23.2113 104.599 25.7824H102.348C102.213 24.2953 100.984 23.3977 99.1873 23.3977C97.3908 23.3977 96.1615 24.3059 96.1615 25.6277C96.1615 26.6813 96.9466 27.3012 98.8673 27.7757L100.509 28.1789C103.567 28.9019 104.837 30.1301 104.837 32.3097C104.837 35.0976 102.616 36.8437 99.0841 36.8437C95.7794 36.8437 93.5482 35.1386 93.4041 32.4433L95.6869 32.4434Z"
          fill="white"
        />
        <path
          d="M109.65 23.1597V25.7308H111.716V27.4968H109.65V33.4862C109.65 34.4167 110.063 34.8503 110.972 34.8503C111.217 34.846 111.462 34.8288 111.705 34.7987V36.5542C111.297 36.6305 110.882 36.665 110.466 36.6573C108.267 36.6573 107.409 35.8311 107.409 33.7241V27.4968H105.829V25.7308H107.409V23.1597H109.65Z"
          fill="white"
        />
        <path
          d="M112.912 31.1636C112.912 27.7452 114.926 25.5972 118.065 25.5972C121.215 25.5972 123.219 27.7452 123.219 31.1636C123.219 34.5913 121.226 36.73 118.065 36.73C114.906 36.73 112.912 34.5913 112.912 31.1636ZM120.947 31.1636C120.947 28.8187 119.872 27.4347 118.065 27.4347C116.258 27.4347 115.185 28.8292 115.185 31.1636C115.185 33.5179 116.258 34.8913 118.065 34.8913C119.872 34.8913 120.947 33.5179 120.947 31.1636H120.947Z"
          fill="white"
        />
        <path
          d="M125.058 25.7308H127.185V27.58H127.236C127.38 27.0025 127.718 26.4921 128.194 26.1346C128.67 25.7771 129.255 25.5944 129.849 25.6171C130.106 25.6162 130.363 25.6441 130.614 25.7003V27.7863C130.289 27.6871 129.951 27.6416 129.612 27.6515C129.288 27.6384 128.965 27.6955 128.665 27.8189C128.365 27.9424 128.095 28.1293 127.875 28.3668C127.654 28.6042 127.487 28.8867 127.386 29.1947C127.284 29.5027 127.251 29.829 127.288 30.1511V36.5953H125.058L125.058 25.7308Z"
          fill="white"
        />
        <path
          d="M140.895 33.4042C140.595 35.3765 138.675 36.73 136.217 36.73C133.057 36.73 131.095 34.6124 131.095 31.2151C131.095 27.8073 133.067 25.5972 136.123 25.5972C139.129 25.5972 141.02 27.662 141.02 30.9562V31.7202H133.346V31.855C133.311 32.2548 133.361 32.6576 133.493 33.0366C133.625 33.4156 133.837 33.7622 134.113 34.0532C134.389 34.3443 134.725 34.5732 135.096 34.7248C135.468 34.8763 135.868 34.9471 136.269 34.9323C136.796 34.9817 137.325 34.8596 137.777 34.5843C138.229 34.309 138.58 33.8951 138.778 33.4042L140.895 33.4042ZM133.357 30.1616H138.788C138.808 29.8021 138.754 29.4423 138.628 29.1049C138.503 28.7674 138.309 28.4596 138.058 28.2007C137.808 27.9417 137.507 27.7373 137.174 27.6003C136.841 27.4632 136.483 27.3965 136.123 27.4042C135.76 27.402 135.4 27.4718 135.064 27.6095C134.728 27.7472 134.423 27.9501 134.166 28.2064C133.908 28.4628 133.704 28.7676 133.566 29.1031C133.427 29.4387 133.356 29.7985 133.357 30.1616V30.1616Z"
          fill="white"
        />
        <path
          d="M55.0255 10.4774C55.4931 10.4438 55.9622 10.5144 56.3992 10.6842C56.8361 10.8539 57.2299 11.1185 57.5522 11.4588C57.8745 11.7992 58.1173 12.2068 58.263 12.6523C58.4086 13.0978 58.4536 13.5702 58.3947 14.0352C58.3947 16.3227 57.1584 17.6376 55.0255 17.6376H52.4392V10.4774H55.0255ZM53.5513 16.625H54.9013C55.2354 16.6449 55.5697 16.5902 55.88 16.4646C56.1902 16.3391 56.4686 16.1459 56.6948 15.8993C56.921 15.6526 57.0893 15.3586 57.1875 15.0386C57.2858 14.7187 57.3114 14.3809 57.2626 14.0498C57.3079 13.72 57.2796 13.3842 57.1799 13.0666C57.0801 12.749 56.9114 12.4574 56.6857 12.2127C56.46 11.968 56.183 11.7762 55.8745 11.6512C55.566 11.5261 55.2337 11.4708 54.9013 11.4892H53.5513V16.625Z"
          fill="white"
        />
        <path
          d="M59.651 14.9335C59.617 14.5784 59.6576 14.2202 59.7702 13.8817C59.8828 13.5432 60.0649 13.232 60.3049 12.968C60.5448 12.704 60.8373 12.4931 61.1635 12.3488C61.4897 12.2044 61.8425 12.1299 62.1992 12.1299C62.5559 12.1299 62.9087 12.2044 63.2349 12.3488C63.5612 12.4931 63.8536 12.704 64.0935 12.968C64.3335 13.232 64.5156 13.5432 64.6282 13.8817C64.7408 14.2202 64.7814 14.5784 64.7475 14.9335C64.7821 15.289 64.7419 15.6478 64.6296 15.9868C64.5173 16.3258 64.3353 16.6376 64.0953 16.9021C63.8553 17.1666 63.5627 17.378 63.2361 17.5226C62.9096 17.6673 62.5564 17.742 62.1992 17.742C61.8421 17.742 61.4889 17.6673 61.1623 17.5226C60.8358 17.378 60.5431 17.1666 60.3031 16.9021C60.0631 16.6376 59.8811 16.3258 59.7688 15.9868C59.6565 15.6478 59.6164 15.289 59.651 14.9335ZM63.6506 14.9335C63.6506 13.7623 63.1244 13.0773 62.201 13.0773C61.274 13.0773 60.7526 13.7623 60.7526 14.9335C60.7526 16.1142 61.2741 16.7939 62.201 16.7939C63.1244 16.7939 63.6506 16.1095 63.6506 14.9335H63.6506Z"
          fill="white"
        />
        <path
          d="M71.522 17.6374H70.4158L69.299 13.6577H69.2146L68.1025 17.6374H67.0068L65.5173 12.2339H66.599L67.5669 16.3571H67.6466L68.7576 12.2339H69.7806L70.8916 16.3571H70.9759L71.9392 12.2339H73.0056L71.522 17.6374Z"
          fill="white"
        />
        <path
          d="M74.2583 12.2341H75.2849V13.0925H75.3646C75.4997 12.7842 75.7277 12.5258 76.0168 12.3532C76.3058 12.1806 76.6415 12.1026 76.9771 12.1298C77.24 12.1101 77.504 12.1497 77.7495 12.2458C77.995 12.3419 78.2158 12.4921 78.3954 12.6851C78.575 12.8781 78.7089 13.109 78.7871 13.3608C78.8654 13.6126 78.886 13.8788 78.8474 14.1396V17.6376H77.781V14.4074C77.781 13.539 77.4036 13.1072 76.6149 13.1072C76.4364 13.0989 76.2582 13.1293 76.0926 13.1962C75.9269 13.2632 75.7777 13.3653 75.6551 13.4953C75.5326 13.6254 75.4396 13.7804 75.3826 13.9498C75.3255 14.1191 75.3058 14.2988 75.3247 14.4765V17.6377H74.2583L74.2583 12.2341Z"
          fill="white"
        />
        <path
          d="M80.5466 10.1245H81.613V17.6374H80.5466V10.1245Z"
          fill="white"
        />
        <path
          d="M83.0955 14.9331C83.0616 14.578 83.1022 14.2197 83.2149 13.8812C83.3275 13.5427 83.5096 13.2315 83.7496 12.9675C83.9895 12.7035 84.282 12.4926 84.6083 12.3483C84.9345 12.204 85.2873 12.1294 85.6441 12.1294C86.0008 12.1294 86.3536 12.204 86.6799 12.3483C87.0061 12.4926 87.2986 12.7035 87.5385 12.9675C87.7785 13.2315 87.9606 13.5427 88.0732 13.8812C88.1859 14.2197 88.2265 14.578 88.1926 14.9331C88.2272 15.2886 88.187 15.6474 88.0746 15.9864C87.9623 16.3255 87.7803 16.6372 87.5402 16.9017C87.3002 17.1662 87.0075 17.3776 86.681 17.5222C86.3544 17.6668 86.0012 17.7416 85.6441 17.7416C85.2869 17.7416 84.9337 17.6668 84.6071 17.5222C84.2806 17.3776 83.9879 17.1662 83.7479 16.9017C83.5078 16.6372 83.3258 16.3255 83.2135 15.9864C83.1011 15.6474 83.0609 15.2886 83.0955 14.9331ZM87.0951 14.9331C87.0951 13.7619 86.5689 13.0769 85.6455 13.0769C84.7186 13.0769 84.1971 13.7619 84.1971 14.9332C84.1971 16.1138 84.7186 16.7935 85.6455 16.7935C86.569 16.7935 87.0951 16.1091 87.0951 14.9331H87.0951Z"
          fill="white"
        />
        <path
          d="M89.3152 16.1091C89.3152 15.1364 90.0394 14.5757 91.3249 14.496L92.7886 14.4116V13.9452C92.7886 13.3745 92.4113 13.0522 91.6824 13.0522C91.0871 13.0522 90.6746 13.2708 90.5562 13.6528H89.5238C89.6328 12.7247 90.5058 12.1294 91.7316 12.1294C93.0863 12.1294 93.8503 12.8038 93.8503 13.9452V17.6372H92.8238V16.8778H92.7394C92.5681 17.1502 92.3277 17.3723 92.0425 17.5213C91.7574 17.6704 91.4378 17.7411 91.1164 17.7262C90.8895 17.7498 90.6602 17.7256 90.4433 17.6552C90.2264 17.5847 90.0267 17.4696 89.857 17.3173C89.6873 17.1649 89.5514 16.9787 89.4581 16.7706C89.3648 16.5625 89.3161 16.3371 89.3152 16.1091ZM92.7886 15.6474V15.1956L91.4691 15.28C90.7249 15.3298 90.3874 15.5829 90.3874 16.0593C90.3874 16.5456 90.8093 16.8286 91.3894 16.8286C91.5594 16.8458 91.7311 16.8286 91.8943 16.7781C92.0575 16.7276 92.2089 16.6448 92.3394 16.5346C92.47 16.4244 92.577 16.289 92.6542 16.1366C92.7314 15.9842 92.7771 15.8178 92.7886 15.6474Z"
          fill="white"
        />
        <path
          d="M95.252 14.9333C95.252 13.2259 96.1297 12.1443 97.4949 12.1443C97.8326 12.1287 98.1678 12.2096 98.4612 12.3775C98.7546 12.5453 98.9942 12.7933 99.1519 13.0923H99.2316V10.1245H100.298V17.6374H99.2762V16.7837H99.1918C99.0219 17.0807 98.7739 17.3255 98.4748 17.4917C98.1757 17.6579 97.8369 17.7391 97.4949 17.7265C96.1203 17.7265 95.252 16.6448 95.252 14.9333ZM96.3536 14.9333C96.3536 16.0794 96.8938 16.7691 97.7973 16.7691C98.6961 16.7691 99.2516 16.0695 99.2516 14.938C99.2516 13.8118 98.6903 13.1023 97.7973 13.1023C96.8996 13.1023 96.3535 13.7966 96.3535 14.9333H96.3536Z"
          fill="white"
        />
        <path
          d="M104.71 14.9335C104.676 14.5784 104.717 14.2202 104.829 13.8817C104.942 13.5432 105.124 13.232 105.364 12.968C105.604 12.704 105.896 12.4931 106.223 12.3488C106.549 12.2044 106.902 12.1299 107.258 12.1299C107.615 12.1299 107.968 12.2044 108.294 12.3488C108.62 12.4931 108.913 12.704 109.153 12.968C109.393 13.232 109.575 13.5432 109.687 13.8817C109.8 14.2202 109.841 14.5784 109.807 14.9335C109.841 15.289 109.801 15.6478 109.689 15.9868C109.576 16.3258 109.394 16.6376 109.154 16.9021C108.914 17.1666 108.622 17.378 108.295 17.5226C107.969 17.6673 107.615 17.742 107.258 17.742C106.901 17.742 106.548 17.6673 106.221 17.5226C105.895 17.378 105.602 17.1666 105.362 16.9021C105.122 16.6376 104.94 16.3258 104.828 15.9868C104.716 15.6478 104.675 15.289 104.71 14.9335ZM108.71 14.9335C108.71 13.7623 108.183 13.0773 107.26 13.0773C106.333 13.0773 105.812 13.7623 105.812 14.9335C105.812 16.1142 106.333 16.7939 107.26 16.7939C108.183 16.7939 108.71 16.1095 108.71 14.9335Z"
          fill="white"
        />
        <path
          d="M111.238 12.2341H112.264V13.0925H112.344C112.479 12.7842 112.707 12.5258 112.996 12.3532C113.285 12.1806 113.621 12.1026 113.956 12.1298C114.219 12.1101 114.483 12.1497 114.729 12.2458C114.974 12.3419 115.195 12.4921 115.375 12.6851C115.554 12.8781 115.688 13.109 115.766 13.3608C115.845 13.6126 115.865 13.8788 115.827 14.1396V17.6376H114.76V14.4074C114.76 13.539 114.383 13.1072 113.594 13.1072C113.416 13.0989 113.237 13.1293 113.072 13.1962C112.906 13.2632 112.757 13.3653 112.634 13.4953C112.512 13.6254 112.419 13.7804 112.362 13.9498C112.305 14.1191 112.285 14.2988 112.304 14.4765V17.6377H111.238V12.2341Z"
          fill="white"
        />
        <path
          d="M121.852 10.8887V12.2586H123.023V13.1568H121.852V15.9354C121.852 16.5014 122.086 16.7492 122.616 16.7492C122.752 16.7488 122.888 16.7406 123.023 16.7246V17.6129C122.832 17.6471 122.638 17.6654 122.443 17.6674C121.257 17.6674 120.785 17.2502 120.785 16.2084V13.1568H119.927V12.2585H120.785V10.8887H121.852Z"
          fill="white"
        />
        <path
          d="M124.48 10.1245H125.537V13.1023H125.621C125.763 12.7911 125.997 12.5312 126.292 12.3579C126.587 12.1847 126.928 12.1066 127.269 12.1343C127.53 12.1201 127.792 12.1637 128.034 12.2622C128.277 12.3606 128.495 12.5114 128.673 12.7038C128.851 12.8962 128.984 13.1255 129.063 13.3753C129.142 13.625 129.165 13.8891 129.13 14.1487V17.6374H128.062V14.4118C128.062 13.5487 127.66 13.1116 126.907 13.1116C126.723 13.0966 126.539 13.1218 126.367 13.1854C126.194 13.2491 126.037 13.3497 125.908 13.4802C125.778 13.6107 125.679 13.7679 125.616 13.9409C125.554 14.1138 125.53 14.2984 125.546 14.4815V17.6374H124.48L124.48 10.1245Z"
          fill="white"
        />
        <path
          d="M135.348 16.1783C135.203 16.6722 134.889 17.0993 134.461 17.3851C134.033 17.6709 133.518 17.7971 133.006 17.7416C132.65 17.751 132.296 17.6828 131.969 17.5418C131.642 17.4007 131.35 17.1902 131.113 16.9249C130.875 16.6595 130.698 16.3456 130.594 16.005C130.49 15.6645 130.461 15.3053 130.51 14.9525C130.463 14.5987 130.492 14.2388 130.596 13.8971C130.699 13.5555 130.875 13.2402 131.112 12.9725C131.348 12.7048 131.639 12.491 131.965 12.3456C132.291 12.2001 132.644 12.1264 133.002 12.1295C134.505 12.1295 135.412 13.1567 135.412 14.8535V15.2256H131.596V15.2854C131.58 15.4836 131.605 15.6832 131.67 15.8713C131.735 16.0594 131.838 16.2319 131.974 16.3776C132.109 16.5234 132.274 16.6392 132.456 16.7177C132.639 16.7962 132.837 16.8356 133.035 16.8334C133.291 16.864 133.549 16.818 133.778 16.7014C134.007 16.5847 134.196 16.4027 134.321 16.1783L135.348 16.1783ZM131.596 14.4369H134.326C134.339 14.2556 134.314 14.0734 134.253 13.9021C134.192 13.7309 134.096 13.5743 133.971 13.4425C133.845 13.3106 133.694 13.2065 133.526 13.1367C133.358 13.0668 133.177 13.0329 132.996 13.0371C132.811 13.0348 132.628 13.0694 132.457 13.139C132.286 13.2085 132.131 13.3115 132.001 13.442C131.87 13.5724 131.767 13.7277 131.698 13.8985C131.629 14.0694 131.594 14.2525 131.596 14.4369H131.596Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7735_19565">
          <rect width="162" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppleStoreButton;
