import React from "react";

function index() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.5835 33.5835C47.1902 33.5835 47.6668 34.0602 47.6668 34.6668V36.8335C47.6668 40.4302 44.7635 43.3335 41.1668 43.3335C41.1668 39.7585 38.2418 36.8335 34.6668 36.8335C31.0918 36.8335 28.1668 39.7585 28.1668 43.3335H23.8335C23.8335 39.7585 20.9085 36.8335 17.3335 36.8335C13.7585 36.8335 10.8335 39.7585 10.8335 43.3335C7.23683 43.3335 4.3335 40.4302 4.3335 36.8335V32.5002C4.3335 31.3085 5.3085 30.3335 6.50016 30.3335H27.0835C30.0735 30.3335 32.5002 27.9068 32.5002 24.9168V13.0002C32.5002 11.8085 33.4752 10.8335 34.6668 10.8335H36.4868C38.0468 10.8335 39.4768 11.6785 40.2568 13.0218L41.6435 15.4485C41.8385 15.7952 41.5785 16.2502 41.1668 16.2502C38.1768 16.2502 35.7502 18.6768 35.7502 21.6668V28.1668C35.7502 31.1568 38.1768 33.5835 41.1668 33.5835H46.5835Z"
        fill="#FE8F01"
      />
      <path
        d="M17.3333 47.6667C19.7266 47.6667 21.6667 45.7266 21.6667 43.3333C21.6667 40.9401 19.7266 39 17.3333 39C14.9401 39 13 40.9401 13 43.3333C13 45.7266 14.9401 47.6667 17.3333 47.6667Z"
        fill="#FE8F01"
      />
      <path
        d="M34.6668 47.6667C37.0601 47.6667 39.0002 45.7266 39.0002 43.3333C39.0002 40.9401 37.0601 39 34.6668 39C32.2736 39 30.3335 40.9401 30.3335 43.3333C30.3335 45.7266 32.2736 47.6667 34.6668 47.6667Z"
        fill="#FE8F01"
      />
      <path
        d="M47.6667 27.1483V30.3333H41.1667C39.975 30.3333 39 29.3583 39 28.1667V21.6667C39 20.475 39.975 19.5 41.1667 19.5H43.9617L47.1033 25.0033C47.4717 25.6533 47.6667 26.39 47.6667 27.1483Z"
        fill="#FE8F01"
      />
      <path
        d="M28.3402 4.3335H12.3285C7.9085 4.3335 4.3335 7.9085 4.3335 12.3285V26.1518C4.3335 27.3435 5.3085 28.3185 6.50016 28.3185H26.3252C28.5352 28.3185 30.3335 26.5202 30.3335 24.3102V6.32683C30.3335 5.22183 29.4452 4.3335 28.3402 4.3335ZM21.8185 15.3185L17.2902 19.6952C16.9652 19.9985 16.5535 20.1502 16.1635 20.1502C15.7518 20.1502 15.3402 19.9985 15.0368 19.6952L12.8485 17.6152C12.1985 16.9868 12.1768 15.9468 12.8052 15.2968C13.4118 14.6468 14.4518 14.6468 15.1018 15.2535L16.1635 16.2718L19.5652 12.9785C20.2152 12.3502 21.2335 12.3718 21.8618 13.0218C22.4902 13.6718 22.4685 14.6902 21.8185 15.3185Z"
        fill="#FE8F01"
      />
    </svg>
  );
}

export default index;
