import React, { useState } from "react";
import UserProfile from "./UserProfile";
import { AddBankDetail } from "components/dashboardComponent/Setting";
import PickAddress from "./PickupAddress";
import UpdatePassword from "./UpdatePassword";
import LinkedMediaAccounts from "./LinkedMediaAccounts";
import { useSelector } from "react-redux";
import { LoaderProcessing } from "components/shared";
import SendBroadcast from "./SendBroadcast";

const Profile = () => {
  const { loading, error } = useSelector((state) => state.userAccount);
  const [activeTab, setActiveTab] = useState("User Profile");

  return (
    <div className="user__profile">
      {loading ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <p className="title">Profile</p>
          <ul className="user__profile__tab">
            {tabs.map((tab, index) => (
              <li
                className={`tab ${activeTab == tab.name ? "active__tab" : ""}`}
                key={index}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </li>
            ))}
          </ul>
          <section className="user__profile__content">
            {tabs.map((tab, index) => (
              <div key={index}>
                {tab.name == activeTab ? tab.component : null}
              </div>
            ))}
          </section>
        </>
      )}
    </div>
  );
};

export default Profile;

const tabs = [
  { name: "User Profile", component: <UserProfile /> },
  {
    name: "Bank Details",
    component: <AddBankDetail title="Bank Details" update={true} />,
  },
  { name: "Pickup Address", component: <PickAddress /> },
  { name: "Update Password", component: <UpdatePassword /> },
  { name: "Linked Media Accounts", component: <LinkedMediaAccounts /> },
  { name: "Send Broadcast", component: <SendBroadcast /> },
];
