import CancelOrderModal from "components/dashboardComponent/CancelOrder";
import ConfirmOrder from "components/dashboardComponent/ConfirmOrder";
import CustomerDetailCard from "components/dashboardComponent/CustomerDetailCard";
import { LoaderProcessing } from "components/shared";
import BreadCrumb from "components/shared/BreadCrumb";
import { currencyFormatter, formatVariant } from "libs/utils";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewOrderDetails } from "store/actions";

const OrderDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const { loading, orderDetails, success } = useSelector(
    (state) => state.orders
  );
  const { orderRef } = params;
  const crumbs = [
    {
      name: "Back to Orders",
      path: "/dashboard/orders",
    },
    {
      name: `Order ${orderRef}`,
    },
  ];
  useEffect(() => {
    dispatch(viewOrderDetails({ orderRef }));
  }, [orderRef, success]);
  return loading ? (
    <div className="loader__container">
      <LoaderProcessing />
    </div>
  ) : (
    <main className="order__detail">
      <BreadCrumb crumbs={crumbs} />
      <div className="order__header">
        <h3 className="title">{`Order ${orderRef}`}</h3>
        <button className="invoice__button">Generate Invoice</button>
      </div>
      <section className="order__container">
        <div className="order__details">
          <h1 className="title">Order Details</h1>
          <div className="order__info">
            <div className="info__item">
              <p className="label">Status</p>
              <p
                className={`order__status ${
                  orderDetails?.status?.toLowerCase() == "in progress"
                    ? "status__in__progress d-block"
                    : `status__${orderDetails?.status?.toLowerCase()} d-block`
                }`}
              >
                {orderDetails?.status}
              </p>
            </div>
            <div className="info__item">
              <p className="label">Date</p>
              <p className="value">{orderDetails?.date}</p>
            </div>
            <div className="info__item">
              <p className="label">Delivery Method</p>
              <p className="value">{orderDetails?.delivery_type}</p>
            </div>
            <div className="table__container">
              <table className="order__item__table">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th>Quantity</th>
                    <th className="item__right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.orderItems?.length > 0 ? (
                    orderDetails?.orderItems?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="order__item__info">
                            <img
                              src={item?.image}
                              className="order__item__image"
                            />
                            <div className="order__item__detail">
                              <p>{item?.productname}</p>
                              {item?.product_variants != null &&
                              item?.product_variants != "null"
                                ? formatVariant(item?.product_variants).map(
                                    (v, i) => (
                                      <p key={i}>
                                        {v?.name}: {v?.variant}
                                      </p>
                                    )
                                  )
                                : null}
                            </div>
                          </div>
                        </td>
                        <td>{`${item?.quantity} Unit`} </td>
                        <td align="right">
                          {currencyFormatter.format(item?.price)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              <div className="total__section">
                <p>Total</p>
                <p>{currencyFormatter.format(orderDetails?.cost)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="customer__details">
          <CustomerDetailCard
            customer={{
              customer_name: orderDetails?.customerName,
              buyerPhone: orderDetails?.buyerPhone,
              customerEmail: orderDetails?.customerEmail,
              address: orderDetails?.address,
            }}
          />
          <div className="d-flex gap-5">
            <button
              className="button button--purple my-3"
              disabled={[
                "Canceled",
                "Completed",
                "Pending",
                "Confirmed",
              ].includes(orderDetails?.status)}
              onClick={() => setConfirmModal(true)}
            >
              Confirm Order
            </button>
            <button
              className="button button--red my-3"
              disabled={
                ["Canceled", "Completed", "Pending", "Confirmed"].includes(
                  orderDetails?.status
                ) || orderDetails?.status === "Disputed"
              }
              onClick={() => setCancelModal(true)}
            >
              Cancel Order
            </button>
          </div>
          {/* <button className="button button--purple-outline">Edit Order</button>
          <div className="text-center">
            <button className="cancel__order__button">Cancel Order</button>
          </div> */}
        </div>
      </section>
      <ConfirmOrder
        order={orderDetails}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
      />
      <CancelOrderModal
        order={orderDetails}
        setCancelModal={setCancelModal}
        cancelModal={cancelModal}
      />
    </main>
  );
};

export default OrderDetail;
