import React, { useState } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const libraries = ["places"];
const PlacesAutoComplete = ({ onPlaceSelect, children }) => {
  const [searchBox, setSearchBox] = useState(null);
  const [address, setAddress] = useState("");

  const onLoad = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    if (searchBox) {
      const newPlaces = searchBox.getPlaces();
      const selectedAddress = newPlaces[0].formatted_address;
      setAddress(selectedAddress);
      onPlaceSelect(selectedAddress);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
      libraries={libraries}
    >
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        {children}
      </StandaloneSearchBox>
    </LoadScript>
  );
};

export default PlacesAutoComplete;
