import {
  AddBankDetail,
  BankDetail,
  PremiumPlan,
  VerifyEmail,
} from "components/dashboardComponent/Setting";
import WelcomeHeader from "components/dashboardComponent/WelcomeHeader";
import DashboardLayout from "components/layouts/dashboardLayout";
import { LoaderProcessing } from "components/shared";
import { CheckedIcon } from "components/vectors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserProfile } from "store/actions";

const Settings = () => {
  const dispatch = useDispatch();
  const { userInfo, token } = useSelector((state) => state.auth);
  const { userProfile, loading, error } = useSelector(
    (state) => state.userAccount
  );
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab") ?? "verify-email";
  const action = params.get("action");

  useEffect(() => {
    if (!userProfile) {
      dispatch(getUserProfile(token, userInfo));
    }
  }, [
    userProfile?.isPremium,
    userProfile?.email_verified,
    userProfile?.bankAccountNo,
  ]);

  const reload = () => {
    window.location.reload(true);
  };
  return (
    <main>
      {loading ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : error ? (
        <div className="empty__state">
          <p>{error}</p>
          <button
            className="button button--purple button__content"
            onClick={reload}
          >
            Refresh page
          </button>
        </div>
      ) : (
        <>
          <WelcomeHeader />
          <main className="settings">
            <div className="settings__wrapper">
              <aside className="settings__sidebar">
                <p className="title">Getting started</p>
                <div className="settings__steps">
                  <div className="step">
                    {userProfile?.email_verified ? (
                      <CheckedIcon />
                    ) : (
                      <div className="round__unchecked"></div>
                    )}

                    <p className="step__title">Verify your email address</p>
                  </div>
                  <div className="step">
                    {userProfile?.bankAccountNo ? (
                      <CheckedIcon />
                    ) : (
                      <div className="round__unchecked"></div>
                    )}
                    <p className="step__title">Add bank details</p>
                  </div>

                  <div className="step">
                    {userProfile?.isPremium ? (
                      <CheckedIcon />
                    ) : (
                      <div className="round__unchecked"></div>
                    )}
                    <p className="step__title">{userProfile?.isPremium ? "Upgrade to premium": "Start 14 Days trial"}</p>
                  </div>
                </div>
              </aside>
              <main className="settings__content">
                <div className="content">
                  {tab == "bank-detail" ? (
                    action == "add" ? (
                      <AddBankDetail />
                    ) : (
                      <BankDetail />
                    )
                  ) : null}
                  {tab == "premium" ? <PremiumPlan /> : null}
                  {tab == "verify-email" ? <VerifyEmail /> : null}
                </div>
              </main>
            </div>
          </main>
        </>
      )}
    </main>
  );
};

export default Settings;
