export {
  register,
  login,
  logout,
  authCheckState,
  googleSocialLogin,
  socialLogin,
  miniRegistration,
} from "./auth";

export { loadPayments } from "./payments";

export {
  loadProduct,
  getFacebookPages,
  getPageData,
  publishSingleProduct,
  getSingleProduct,
  publishSingleSocialProduct,
  selectPage,
  updateProduct,
  removeProduct,
  loadProductsAndAddress,
  getAllMerchantProducts,
  holdProductValuesInStore,
  setConnectSocialStoreStatus,
  publishSingleProductComplete,
} from "./products";

export { loadCustomer, reportIssue, requestRefund, requestPayment } from "./customers";

export {
  loadOrders,
  placeOrder,
  confirmOrder,
  getAddress,
  addAddress,
  editAddress,
  deleteAddress,
  changeOrderStatus,
  getReferralStats,
  getCourierRates,
  addPickUpAddresses,
  updatePickUpAddress,
  getExternalTrxRef,
  viewOrderDetails,
} from "./orders";

export { loadInvoices, placeInvoice, confirmInvoicePayment } from "./invoices";

export { loadSingleInvoice, confirmInvoice, failedToLoadSingleInvoice } from "./singleInvoice";

export {
  updateProfile,
  updateBankAccount,
  updateUserType,
  updateApiSetting,
  updateNotificationSetting,
  updatePassword,
  getUserProfile,
  addSocialMediaLink,
  removeSocialMediaLink,

  getBanks,
  confirmPremiumPaymentStatus

} from "./userAccount";

export {
  loadCustomerCart,
  addToCart,
  removeItemFromCart,
  productCheck,
  noUserAddToCart,
} from "./cart";

export { getMerchantStoreProducts, createInvoice } from "./merchant";

export { setAlert } from "./alert";
