import React from 'react';

const OrderTotalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="48"
    viewBox="0 0 45 48"
  >
    <path
      fill="#878B94"
      d="M19.095.002c4.737 0 8.578 3.84 8.578 8.578v2.78h2.943c1.552-.006 2.875 1.123 3.113 2.656l2.693 15.202c4.185.611 7.453 3.933 7.997 8.127.544 4.195-1.77 8.24-5.662 9.897-3.89 1.658-8.41.524-11.059-2.774H5.56C2.52 44.464.058 42 .054 38.962c0-.047.004-.093.013-.14L4.46 14.017c.238-1.533 1.561-2.662 3.112-2.655h2.944V8.58c0-4.738 3.84-8.579 8.578-8.579zm15.962 30.713c-2.956 0-5.66 1.663-6.995 4.3-1.335 2.636-1.076 5.8.672 8.182 1.481 2.01 3.827 3.198 6.324 3.203l.25-.003c4.013-.127 7.286-3.274 7.571-7.278l.014-.251c.167-4.208-3.018-7.796-7.216-8.13-.205-.015-.414-.023-.62-.023zm-24.54-17.758H7.573c-.767-.003-1.42.556-1.536 1.314l-.003.016L1.65 39.029c.04 2.132 1.777 3.84 3.91 3.843h21.097c-1.479-2.884-1.375-6.324.273-9.114 1.65-2.79 4.614-4.539 7.853-4.633l-2.628-14.838-.003-.016c-.115-.758-.77-1.317-1.537-1.314h-2.943v4.336c0 .441-.358.798-.799.798-.44 0-.798-.357-.798-.798v-4.336H12.113v4.336c0 .441-.357.798-.798.798-.44 0-.798-.357-.798-.798v-4.336zM39.106 35.5c.206-.2.503-.275.779-.195.275.08.487.301.554.58.067.28-.02.573-.23.769l-5.742 5.484c-.314.3-.81.294-1.116-.012l-3.257-3.257c-.312-.312-.312-.818 0-1.13.312-.31.817-.31 1.129 0l2.706 2.706zM19.095 1.598c-3.856 0-6.982 3.126-6.982 6.982v2.78h13.963V8.58c0-3.856-3.125-6.982-6.981-6.982z"
    />
  </svg>
);

export default OrderTotalIcon;
