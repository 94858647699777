import React from "react";

const TrashIcon = ({ width = 20, height = 24, color = "#B0B0B0", onClick }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    style={{ cursor: "pointer" }}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 4.48665C11.78 4.26665 9.54667 4.15332 7.32 4.15332C6 4.15332 4.68 4.21999 3.36 4.35332L2 4.48665"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66699 3.81333L5.81366 2.94C5.92033 2.30666 6.00033 1.83333 7.12699 1.83333H8.87366C10.0003 1.83333 10.087 2.33333 10.187 2.94666L10.3337 3.81333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5669 6.59332L12.1336 13.3066C12.0603 14.3533 12.0003 15.1666 10.1403 15.1666H5.86026C4.00026 15.1666 3.94026 14.3533 3.86693 13.3066L3.43359 6.59332"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.88672 11.5H9.10672"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33301 8.83334H9.66634"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
