import React, { useState } from "react";

const CustomSelect = ({ options, onSelect, defaultValue, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div className={`customSelectContainer ${disabled ? "disabled" : ""}`}>
      <div
        className={`customSelect ${disabled ? "disabled" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption}
        <span className={`arrow ${isOpen ? "open" : ""}`}></span>
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
