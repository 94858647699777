import React from "react";

function index() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
        fill="#18191F"
        fillOpacity="0.55"
      />
      <path
        d="M10.1025 8.64657C10.0819 7.7119 9.4135 7 8.32814 7C7.24278 7 6.5332 7.7119 6.5332 8.64657C6.5332 9.56189 7.2218 10.2943 8.28695 10.2943H8.30723C9.4135 10.2943 10.1025 9.56189 10.1025 8.64657Z"
        fill="white"
      />
      <path d="M9.89352 11.5955H6.72082V21.1281H9.89352V11.5955Z" fill="white" />
      <path
        d="M17.6825 11.3716C19.7703 11.3716 21.3355 12.7343 21.3355 15.6624L21.3354 21.1281H18.1628V16.0281C18.1628 14.7471 17.7037 13.873 16.5552 13.873C15.6786 13.873 15.1566 14.4623 14.9273 15.0314C14.8434 15.2354 14.8228 15.5196 14.8228 15.8045V21.1283H11.6497C11.6497 21.1283 11.6915 12.4902 11.6497 11.5957H14.8228V12.9459C15.2439 12.2968 15.998 11.3716 17.6825 11.3716Z"
        fill="white"
      />
    </svg>
  );
}

export default index;
