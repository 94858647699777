import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  products: [],
  allProducts: [],
  singleProduct: null,
  meta: null,
  links: null,
  loading: true,
  loaded: false,
  error: null,
  publishProductError: null,
  publishProductSuccess: false,
  publishingProduct: false,
  loadingFacebookPages: false,
  loadedFacebookPages: false,
  errorFacebookPages: null,
  pages: [],
  selectedPage: {},
  productCategories: [],
  weightUnit: [],
  connectedSocialStore: false,
  loadingFacebookProducts: false,
  loadedFacebookProducts: false,
  errorFacebookProducts: null,
  items: null,
  holdProductValuesInStore: {},
  updatingProduct: false,
  updateProductComplete: false,
  discountError: null,
  addingDiscount: false,
  addedDiscount: false,
  discounts: null,
  coupons: null,
  couponError: null,
  addingCoupon: false,
  addedCoupon: false,
};

const loading = (state, action) => {
  return updateObject(state, {
    loading: true,
    loaded: false,
    updateProductComplete: false,
  });
};

const updatingProduct = (state, action) => {
  return updateObject(state, {
    updatingProduct: true,
    updateProductComplete: false,
  });
};

const updatingProductComplete = (state, action) => {
  return updateObject(state, {
    updatingProduct: false,
    updateProductComplete: true,
  });
};

const updatingProductFail = (state, action) => {
  return updateObject(state, {
    updatingProduct: false,
    updateProductComplete: false,
  });
};

const publishingSingleProduct = (state, action) => {
  return updateObject(state, {
    publishingProduct: true,
  });
};

const updateSocialStore = (state, action) => {
  return updateObject(state, { connectedSocialStore: action.payload });
};

const loadedProducts = (state, action) => {
  return updateObject(state, action.update);
};

const loadedAllProducts = (state, action) => {
  return updateObject(state, action.update);
};
const loadedSingleProducts = (state, action) => {
  return updateObject(state, action.update);
};

const updateStoreProducts = (state, action) => {
  return updateObject(state, { products: action.products });
};

const updateHoldProductValuesInStore = (state, action) => {
  return updateObject(state, { holdProductValuesInStore: action.payload });
};

const failedToLoadProducts = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    loaded: false,
  });
};

const failedToPublishSingleProduct = (state, action) => {
  return updateObject(state, {
    publishProductError: action.error,
    publishProductSuccess: false,
    publishingProduct: false,
  });
};
const publishSingleProductSuccess = (state, action) => {
  return updateObject(state, {
    publishProductError: null,
    publishProductSuccess: true,
    publishingProduct: false,
  });
};
const publishSingleProductComplete = (state, action) => {
  return updateObject(state, {
    publishProductSuccess: false,
  });
};

const loadingFacebookPages = (state, action) => {
  return updateObject(state, {
    loadingFacebookPages: true,
  });
};

const loadedFacebookPages = (state, action) => {
  return updateObject(state, action.update);
};

const failedToLoadFacebookPages = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadedFacebookPages: false,
    loadingFacebookPages: false,
    error: action.error,
  });
};

const selectPage = (state, action) => {
  return updateObject(state, { page: action.page });
};

const loadingFacebookProducts = (state, action) => {
  return updateObject(state, {
    loadingFacebookProducts: true,
  });
};

const loadedFacebookProducts = (state, action) => {
  return updateObject(state, action.update);
};

const failedToLoadFacebookProducts = (state, action) => {
  return updateObject(state, {
    loadingFacebookProducts: false,
    loadedFacebookProducts: false,
    errorFacebookProducts: action.error,
  });
};

const productCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    productCategories: action.categories,
  });
};
const productWeightUnitSuccess = (state, action) => {
  return updateObject(state, {
    weightUnit: action.units,
  });
};

const publishSocialProduct = (state, action) => {
  return updateObject(state, {
    publishingProduct: true,
  });
};
const publishSocialProductSuccess = (state, action) => {
  return updateObject(state, {
    publishProductSuccess: true,
    publishingProduct: false,
  });
};
const publishSocialProductFail = (state, action) => {
  return updateObject(state, {
    publishProductSuccess: false,
    publishingProduct: false,
  });
};

const publishSocialProductComplete = (state, action) => {
  return updateObject(state, {
    publishProductSuccess: false,
    publishingProduct: false,
  });
};

const addDiscountStart = (state, action) => {
  return updateObject(state, {
    discountError: "",
    addingDiscount: true,
    addedDiscount: false,
  });
};
const addDiscountFail = (state, action) => {
  return updateObject(state, {
    discountError: action.error,
    addingDiscount: false,
    addedDiscount: false,
  });
};
const addDiscountSuccess = (state, action) => {
  return updateObject(state, {
    discountError: "",
    addingDiscount: false,
    addedDiscount: true,
  });
};

const addDiscountComplete = (state, action) => {
  return updateObject(state, {
    discountError: "",
    addingDiscount: false,
    addedDiscount: false,
  });
};

const getProductDiscounts = (state, action) => {
  return updateObject(state, {
    loadingDiscounts: true,
    error: "",
    deletedDiscount: false,
  });
};
const getProductDiscountsSuccess = (state, action) => {
  return updateObject(state, {
    discounts: action.discounts,
    error: "",
    loadingDiscounts: false,
  });
};

const getProductDiscountsFail = (state, action) => {
  return updateObject(state, {
    discounts: null,
    error: action.error,
    loadingDiscounts: false,
  });
};

const deleteProductDiscount = (state, action) => {
  return updateObject(state, { deletingDiscount: true, error: "" });
};
const deleteProductDiscountSuccess = (state, action) => {
  return updateObject(state, {
    deletedDiscount: true,
    deletingDiscount: false,
    error: "",
  });
};

const deleteProductDiscountFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    deletedDiscount: false,
    deletingDiscount: false,
  });
};

const addCouponStart = (state, action) => {
  return updateObject(state, {
    couponError: "",
    addingCoupon: true,
    addedCoupon: false,
  });
};
const addCouponFail = (state, action) => {
  return updateObject(state, {
    couponError: action.error,
    addingCoupon: false,
    addedCoupon: false,
  });
};
const addCouponSuccess = (state, action) => {
  return updateObject(state, {
    couponError: "",
    addingCoupon: false,
    addedCoupon: true,
  });
};

const addCouponComplete = (state, action) => {
  return updateObject(state, {
    couponError: "",
    addingCoupon: false,
    addedCoupon: false,
  });
};

const getCoupon = (state, action) => {
  return updateObject(state, {
    loadingCoupon: true,
    couponError: "",
    deletedCoupon: false,
  });
};
const getCouponSuccess = (state, action) => {
  return updateObject(state, {
    coupons: action.coupons,
    couponError: "",
    loadingCoupon: false,
  });
};

const getCouponFail = (state, action) => {
  return updateObject(state, {
    coupons: null,
    couponError: action.error,
    loadingCoupon: false,
  });
};

const deleteCoupon = (state, action) => {
  return updateObject(state, { deletingCoupon: true, couponError: "" });
};
const deleteCouponSuccess = (state, action) => {
  return updateObject(state, {
    deletedCoupon: true,
    deletingCoupon: false,
    couponError: "",
  });
};

const deleteCouponFail = (state, action) => {
  return updateObject(state, {
    CouponError: action.error,
    deletedCoupon: false,
    deletingCoupon: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_PRODUCTS:
      return loading(state, action);
    case actionTypes.LOADING_PRODUCT:
      return loading(state, action);
    case actionTypes.LOADING_PRODUCT_FAIL:
      return failedToLoadProducts(state, action);
    case actionTypes.FINISHED_LOADING_PRODUCTS:
      return loadedProducts(state, action);
    case actionTypes.LOADED_ALL_PRODUCTS:
      return loadedAllProducts(state, action);
    case actionTypes.FINISHED_LOADING_SINGLE_PRODUCTS:
      return loadedSingleProducts(state, action);
    case actionTypes.UPDATE_STORE_PRODUCTS:
      return updateStoreProducts(state, action);
    case actionTypes.HOLD_PRODUCT_VALUES_IN_STORE:
      return updateHoldProductValuesInStore(state, action);
    case actionTypes.LOADING_PRODUCTS_FAILED:
      return failedToLoadProducts(state, action);
    case actionTypes.LOADING_FACEBOOK_PAGES:
      return loadingFacebookPages(state, action);
    case actionTypes.FINISHED_LOADING_FACEBOOK_PAGES:
      return loadedFacebookPages(state, action);
    case actionTypes.LOADING_FACEBOOK_PAGES_FAILED:
      return failedToLoadFacebookPages(state, action);
    case actionTypes.CONNECT_SOCIAL_STORE_STATUS:
      return updateSocialStore(state, action);
    case actionTypes.SELECT_PAGE:
      return selectPage(state, action);
    case actionTypes.LOADING_FACEBOOK_PRODUCTS:
      return loadingFacebookProducts(state, action);
    case actionTypes.FINISHED_LOADING_FACEBOOK_PRODUCTS:
      return loadedProducts(state, action);
    case actionTypes.LOADING_FACEBOOK_PRODUCTS_FAILED:
      return failedToLoadProducts(state, action);
    case actionTypes.PUBLISHING_SINGLE_PRODUCT:
      return publishingSingleProduct(state, action);
    case actionTypes.PUBLISH_SINGLE_PRODUCT_SUCCESS:
      return publishSingleProductSuccess(state, action);
    case actionTypes.PUBLISH_SINGLE_PRODUCT_COMPLETE:
      return publishSingleProductComplete(state, action);
    case actionTypes.PUBLISH_SINGLE_PRODUCT_FAIL:
      return failedToPublishSingleProduct(state, action);
    case actionTypes.PUBLISH_SOCIAL_PRODUCT:
      return publishSocialProduct(state, action);
    case actionTypes.PUBLISH_SOCIAL_PRODUCT_SUCCESS:
      return publishSocialProductSuccess(state, action);
    case actionTypes.PUBLISH_SOCIAL_PRODUCT_FAIL:
      return publishSocialProductFail(state, action);
    case actionTypes.PUBLISH_SOCIAL_PRODUCT_COMPLETE:
      return publishSocialProductComplete(state, action);
    case actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS:
      return productCategoriesSuccess(state, action);
    case actionTypes.GET_PRODUCT_WEIGHT_UNIT_SUCCESS:
      return productWeightUnitSuccess(state, action);
    case actionTypes.DELETE_PRODUCT:
      return updatingProduct(state, action);
    case actionTypes.DELETE_PRODUCT_COMPLETE:
      return updatingProductComplete(state, action);
    case actionTypes.DELETE_PRODUCT_FAIL:
      return updatingProductFail(state, action);
    case actionTypes.UPDATE_PRODUCT:
      return updatingProduct(state, action);
    case actionTypes.UPDATE_PRODUCT_COMPLETE:
      return updatingProductComplete(state, action);
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return updatingProductFail(state, action);
    case actionTypes.ADD_PRODUCT_DISCOUNT:
      return addDiscountStart(state, action);
    case actionTypes.ADD_PRODUCT_DISCOUNT_SUCCESS:
      return addDiscountSuccess(state, action);
    case actionTypes.ADD_PRODUCT_DISCOUNT_FAIL:
      return addDiscountFail(state, action);
    case actionTypes.ADD_PRODUCT_DISCOUNT_COMPLETE:
      return addDiscountComplete(state, action);
    case actionTypes.GET_PRODUCT_DISCOUNTS:
      return getProductDiscounts(state, action);
    case actionTypes.GET_PRODUCT_DISCOUNTS_SUCCESS:
      return getProductDiscountsSuccess(state, action);
    case actionTypes.GET_PRODUCT_DISCOUNTS_FAIL:
      return getProductDiscountsFail(state, action);
    case actionTypes.DELETE_DISCOUNT:
      return deleteProductDiscount(state, action);
    case actionTypes.DELETE_DISCOUNT_SUCCESS:
      return deleteProductDiscountSuccess(state, action);
    case actionTypes.DELETE_DISCOUNT_FAIL:
      return deleteProductDiscountFail(state, action);
    case actionTypes.ADD_COUPON:
      return addCouponStart(state, action);
    case actionTypes.ADD_COUPON_SUCCESS:
      return addCouponSuccess(state, action);
    case actionTypes.ADD_COUPON_FAIL:
      return addCouponFail(state, action);
    case actionTypes.ADD_COUPON_COMPLETE:
      return addCouponComplete(state, action);
    case actionTypes.GET_COUPONS:
      return getCoupon(state, action);
    case actionTypes.GET_COUPONS_SUCCESS:
      return getCouponSuccess(state, action);
    case actionTypes.GET_COUPONS_FAIL:
      return getCouponFail(state, action);
    case actionTypes.DELETE_COUPON:
      return deleteCoupon(state, action);
    case actionTypes.DELETE_COUPON_SUCCESS:
      return deleteCouponSuccess(state, action);
    case actionTypes.DELETE_COUPON_FAIL:
      return deleteCouponFail(state, action);
    case actionTypes.TOGGLE_PRODUCT_STOCK:
      return updatingProduct(state, action);
    case actionTypes.TOGGLE_PRODUCT_STOCK_SUCCESS:
      return updatingProductComplete(state, action);
    case actionTypes.TOGGLE_PRODUCT_STOCK_FAIL:
      return updatingProductFail(state, action);

    default:
      return state;
  }
};

export default reducer;
