import InputField from "components/blocks/InputField";
import { FacebookIcon, InstagramIcon, TrashIcon } from "components/vectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { addSocialMediaLink } from "store/actions";
import { useEffect } from "react";
import { mixPanelUserProperty } from "libs/mixPanel";
import { PepperestWebServices } from "libs/constants";

const LinkedMediaAccounts = () => {
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.auth);
  const { updatingsocialMediaLink, userProfile } = useSelector(
    (state) => state.userAccount
  );
  const mdeiaSchema = yup.object().shape({
    mediaLink: yup.string().required("Media Link is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mdeiaSchema),
  });

  const ConnectToInstagramStore = "EscrowBackend/auth/instagram";
  const ConnectToFacebookStore = "EscrowBackend/auth/facebook";

  const submit = (data) => {
    dispatch(addSocialMediaLink(data.mediaLink, userInfo, token));
  };

  useEffect(() => {
    if (userProfile) {
      let length = userProfile?.socialMedialLInks?.length;
      reset({
        mediaLink:
          userProfile?.socialMedialLInks[length - 1]?.social_media_link,
      });
    }
  }, [userProfile?.socialMedialLInks]);

  const loginWithInstagram = () => {
    mixPanelUserProperty("connecttoyourstore_initiated", {});
    window.location.href = `${PepperestWebServices.HOST}/${ConnectToInstagramStore}`;
  };

  const loginWithFacebook = () => {
    mixPanelUserProperty("connecttoyourstore_initiated", {});
    window.location.href = `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`;
  };

  return (
    <div className="profile__info">
      <p className="title mb-3 text-center">Linked Media Accounts</p>

      <div className="social__connect">
        <div className="col-md-8 mx-auto">
          <button className="button button--purple" onClick={loginWithFacebook}>
            <p className="d-flex gap-md-3 gap-2">
              <FacebookIcon color="white" />
              <InstagramIcon color="white" />
              <span>Connect to Socials</span>
            </p>
          </button>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit(submit)}>
        <div className="form__container">
          <section className="d-flex gap-2 align-items-center">
            <div className="form__wrapper col-md-11 col-10 p-0">
              <InputField
                id="mediaLink"
                label="Enter social media link"
                name="mediaLink"
                placeHolder="Enter social media link"
                register={register}
                error={errors.mediaLink?.message}
                type="text"
              />
            </div>
            <div className="col-md-1 p-0 mt-2">
              <TrashIcon width={28} height={28} />
            </div>
          </section>

          <div className="button__wrapper">
            <button
              className="button button--purple button__content"
              disabled={updatingsocialMediaLink}
            >
              Update changes
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};

export default LinkedMediaAccounts;
