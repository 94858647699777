import PepperestAxios from "../../libs/utils/PepperestAxios";
import * as actionTypes from "./actionTypes";
import { Cart, CartErrorMessages } from "../../libs/constants/PepperestWebServices";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";
import { FbTrackCustomEvent } from "libs/facebookPanel";
import { mixPanelUserProperty } from "libs/mixPanel";

export const loadCustomerCart = (token, user) => {
  return (dispatch) => {
    dispatch(loadingCart());
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const params = { customerID: user.customerID };
    PepperestAxios.get(Cart.BUYER_CART, { params, headers })
      .then((response) => {
        dispatch(loadedCart(response.data.cart));
      })
      .catch((error) => {
        console.error(error);
        dispatch(failedLoadingCart(error));
        dispatch(setAlert(CartErrorMessages.getCartData, "error"));
      });
  };
};

export const noUserAddToCart = (id) => (dispatch) => {
  let product = [];
  let path = "/cart";
  dispatch(setAlert("Item added to cart", "success"));
  if (!localStorage.getItem("product")) {
    product.push(id);
    window.localStorage.setItem("product", JSON.stringify(product));
    window.localStorage.setItem("path", JSON.stringify(path));
    dispatch(noUserCartLength(product.length));
  } else {
    let product = JSON.parse(localStorage.getItem("product"));
    product.push(id);
    dispatch(noUserCartLength(product.length));
    window.localStorage.setItem("product", JSON.stringify(product));
    window.localStorage.setItem("path", JSON.stringify(path));
  }
};

export const addToCart = (token, user, productID, quantity = 1) => {
  const referer = JSON.parse(localStorage.getItem('referer'));
  console.log(typeof referer);
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      productID,
      quantity,
    };
    console.log({referer});
    if(referer){
      body.referral_code = referer;
    }
    PepperestAxios.post(Cart.ADD, body, { headers })
      .then((response) => {
        FbTrackCustomEvent("ADD_TO_CART");
        mixPanelUserProperty("Add_to_cart", {});
        const cart = { cart: response.data.cart, loaded: true };
        dispatch(loadedCart(response.data.cart));
        dispatch(setAlert("Item added to cart", "success"));
      })
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(setAlert(error.response.data.ResponseMessage, "error"));
        } else {
          console.log(error?.response?.data?.ResponseMessage);
          const message = error?.response?.data?
          error?.response?.data?.ResponseMessage :'An error occurred' 
          dispatch(failedLoadingCart(error));
          dispatch(setAlert(message, "error"));
        }
      });
  };
};

export const productCheck = (token, user) => {
  return (dispatch) => {
    const productIDs = JSON.parse(window.localStorage.getItem("product"));
    if (productIDs) {
      for (let productID of productIDs) {
        dispatch(addToCart(token, user, productID));
      }
      window.localStorage.removeItem("product");
      // we might come back to remove the path here too
      // window.localStorage.removeItem("path");
    }
  };
};

export const removeItemFromCart = (token, user, cart_id, productID, quantity = 1) => {
  return (dispatch) => {
    const headers = {
      Authorization: token,
      customerID: user.customerID,
    };
    const body = {
      customerID: user.customerID,
      cart_id,
      productID,
      quantity,
    };
    PepperestAxios.post(Cart.REMOVE, body, { headers })
      .then((response) => {
        dispatch(loadedCart(response.data.cart));
        dispatch(setAlert("Item removed", "success"));
      })
      .catch((error) => {
        dispatch(failedLoadingCart(error));
        dispatch(setAlert("An error occurred.", "error"));
      });
  };
};

export const loadingCart = () => {
  return {
    type: actionTypes.LOADING_CART,
  };
};

export const loadedCart = (cart) => {
  return {
    type: actionTypes.FINISHED_LOADING_CART,
    cart: {
      loaded: true,
      loading: false,
      cart,
    },
  };
};

const failedLoadingCart = (error) => {
  return {
    type: actionTypes.FAILED_LOADING_CART,
    error: error,
  };
};

export const clearCart = () => {
  return {
    type: actionTypes.CLEAR_CART,
  
  };
};

const noUserCartLength = (productLength) => {
  return {
    type: actionTypes.ADD_NO_USER_CART,
    payload: productLength,
  };
};
