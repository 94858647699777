import React from "react";
const InputGroup = ({
  type,
  register,
  error,
  label,
  icon = "N",
  id,
  options,
  require,
  ...inputProps
}) => (
  <div className="mb-3 d-flex flex-column">
    {label && (
      <label htmlFor={id} className="form__label">
        {label}
        {require ? <span className="require__field">*</span> : null}
      </label>
    )}
    {type == "select" ? (
      <select
        className={`form__input cursor__pointer ${error ? "error" : ""}`}
        ref={register}
        id={id}
        {...inputProps}
      >
        {options?.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
    ) : type == "textarea" ? (
      <textarea
        rows={6}
        type={type}
        ref={register}
        id={id}
        {...inputProps}
        className="form__textarea"
      ></textarea>
    ) : (
      <section className="input__group">
        <div className={`input__group__button ${error ? "error" : ""}`}>
          {icon}
        </div>
        <input
          className={`input__group__input ${error ? "error" : ""}`}
          type={type}
          ref={register}
          id={id}
          {...inputProps}
        />
      </section>
    )}
    {error && <div className="text-danger">{error}</div>}
  </div>
);

export default InputGroup;
