import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
// import { STATUS as PaymentStatus } from 'libs/constants/PepperestWebServices/Payments';

let initialState = {
  cart: null,
  loading: false,
  loaded: false,
  error: null,
  noUserCartLength: JSON.parse(localStorage.getItem("product"))
    ? JSON.parse(localStorage.getItem("product")).length
    : 0,
};

const loading = (state, action) => {
  return updateObject(state, { loading: true });
};

const loadedCart = (state, action) => {
  return updateObject(state, action.cart);
};

const addToNoUserCart = (state, action) => {
  let update = {};
  update["noUserCartLength"] = action.payload;
  return updateObject(state, update);
};

const failedToLoadCart = (state, action) => {
  return updateObject(state, {
    loading: false,
    loaded: false,
    error: action.error,
  });
};

const clearCart = (state, action) => {
  return updateObject(state, { cart: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_CART:
      return loading(state, action);
    case actionTypes.FINISHED_LOADING_CART:
      return loadedCart(state, action);
    case actionTypes.ADD_NO_USER_CART:
      return addToNoUserCart(state, action);
    case actionTypes.FAILED_LOADING_CART:
      return failedToLoadCart(state, action);
    case actionTypes.CLEAR_CART:
      return clearCart(state, action);
    default:
      return state;
  }
};

export default reducer;
