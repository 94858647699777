import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Dashboard,
  DashboardErrorMessages,
} from "../../libs/constants/PepperestWebServices";
import * as actionTypes from "./actionTypes";
import { setAlert } from "./alert";
import { getStringHash } from "libs/utils";

export const getDashboardMetrics = () => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_DASHBOARD_OVERVIEW });
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Dashboard.OVERVIEW, { headers })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionTypes.GET_DASHBOARD_OVERVIEW_SUCCESS,
          overview: data,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : DashboardErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_DASHBOARD_OVERVIEW_FAIL,
          error: message,
        });
      });
  };
};

export const getTopCustomers = () => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_TOP_CUSTOMERS });
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Dashboard.TOP_CUSTOMERS, { headers })
      .then((response) => {
        const { topCustomers } = response.data;
        dispatch({
          type: actionTypes.GET_TOP_CUSTOMERS_SUCCESS,
          topCustomers,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : DashboardErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_TOP_CUSTOMERS_FAIL,
          error: message,
        });
      });
  };
};

export const getRecentOrders = () => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_RECENT_ORDERS });
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Dashboard.RECENT_ORDERS, { headers })
      .then((response) => {
        const { recentOrders } = response.data;
        dispatch({
          type: actionTypes.GET_RECENT_ORDERS_SUCCESS,
          recentOrders,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : DashboardErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_RECENT_ORDERS_FAIL,
          error: message,
        });
      });
  };
};

export const getSalesReport = () => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.GET_SALES_REPORT });
    const headers = {
      Authorization: token,
    };

    PepperestAxios.get(Dashboard.SALES_REPORT, { headers })
      .then((response) => {
        const salesReports = response.data;
        dispatch({
          type: actionTypes.GET_SALES_REPORTS_SUCCESS,
          salesReports,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : DashboardErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.GET_SALES_REPORT_FAIL,
          error: message,
        });
      });
  };
};

export const downloadSalesReport = ({ type = "All", period }) => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    dispatch({ type: actionTypes.DOWNLOAD_SALES_REPORT });
    const headers = {
      Authorization: token,
    };
    const body = {
      type,
      period,
    };

    PepperestAxios.post(Dashboard.DOWNLOAD_SALES_REPORT, body, { headers })
      .then((response) => {
        const { download_link, ResponseStatus } = response.data;
        console.log({ download_link });
        dispatch({
          type: actionTypes.DOWNLOAD_SALES_REPORT_SUCCESS,
          download_link,
        });
      })
      .catch((error) => {
        const message = error?.response?.data
          ? error.response?.data?.message
          : DashboardErrorMessages.getHistoryFailed;
        dispatch({
          type: actionTypes.DOWNLOAD_SALES_REPORT_FAIL,
          error: message,
        });
      });
  };
};
export const downloadSalesReportComplete = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD_SALES_REPORT_COMPLETE });
  };
};
