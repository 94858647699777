import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import { useHistory } from "react-router-dom";
import { PepperestContext } from "components/helpers/constant";
import { toast } from "react-toastify";
import { PepperestWebServices } from "libs/constants";

const MerchantProductCard = (props) => {
  const {
    user,
    token,
    addToCart,
    noUserAddToCart,
    item: { id, productName, image_url, amount, productDescription,quantity,discount_amount,discounted_price,discount_percent  },
    isOwnerOfStore,
  } = props;
  const history = useHistory();
  const isSocialStore = history.location.pathname === "/products/instagram";
  const add = () => {
    // toast.success(`Product Added Succesfully`);
    if (user && token) {
      addToCart(token, user, id);
    } else {
      noUserAddToCart(id);
    }
  };

  const handleCopy = async (text) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(`Copied: ${textField.value} `);
  };
  const openImageModal = (context) => {
    return (e) => {
      context.updateProductModalImage(true, {
        imageSrc: e.target.src,
        productDescription: productDescription,
      });
    };
  };

  return (
    <div className="merchant-product-card" style={{ maxHeight: `${!isOwnerOfStore && "400px"}` }}>
      <div className="merchant-product-card__top">
        <div className="merchant-product-card__image-wrapper">
          <PepperestContext.Consumer>
            {(context) => (
              <img
                src={image_url}
                onClick={openImageModal(context)}
                className="merchant-product-card__image pointer"
                alt={productName}
                style={{ cursor: "pointer" }}
              />
            )}
          </PepperestContext.Consumer>
        </div>
        <div className="merchant-product-card__details">
          <h5 className="merchant-product-card__title">{productName}</h5>
          <p className="merchant-product-card__info">{`${productDescription?.substring(0,60)} ${productDescription?.length>60?'...':''}`}</p>
          <div className="merchant-product-card__details__base">
            <div className="d-flex align-items-baseline  justify-content-between">
              {
                discount_percent!=='0.00'?(
                  <>
                    {!isOwnerOfStore? (
                <p className="merchant-product-card__amount merchant-product-card__slash_price text-secondary">N{amount}</p>
              ):(null)}

                  <p className="merchant-product-card__amount">N {discounted_price}</p>
                  </>
                ):(
                  <p className="merchant-product-card__amount">N {amount}</p>
                )
              }
              

            
              {isOwnerOfStore && (
                <button
                  onClick={() => {
                    handleCopy(
                      `${PepperestWebServices.HOST}/merchant/${props.user?.merchantCode}/getProduct?productId=${id}`
                    );
                  }}
                  style={{ marginTop: "3px" }}
                  className="button button-md button--orange-outline"
                >
                  Copy item Link
                </button>
              )}
            </div>
            {isOwnerOfStore && !isSocialStore ? (
              <div
                style={{ justifyContent: "space-around", alignItems: "center" }}
                className="d-flex"
              >
                <PepperestContext.Consumer>
                  {(context) => (
                    <button
                      onClick={() => {
                        context.updateShowEditProductModal(true, props.item);
                      }}
                      className="button button-md button--orange-outline"
                    >
                      Edit Item
                    </button>
                  )}
                </PepperestContext.Consumer>

                <PepperestContext.Consumer>
                  {(context) => (
                    <button
                      onClick={() => {
                        context.updateShowDeleteProductModal(true, props.item);
                      }}
                      className="button button-md button--orange-outline"
                    >
                      Delete Item
                    </button>
                  )}
                </PepperestContext.Consumer>
              </div>
            ) : (
              <>
              {quantity<=0? (
                <button disabled className="text-dark mt-1 button button-lg btn btn-outline-light">
                OUT OF STOCK
              </button>
              ):(
                <button onClick={add} className="mt-1 button button-lg button--orange-outline">
                ADD TO CART
              </button>
              )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="merchant-product-card__mobile-base">
        <div className="d-flex align-items-start flex-column justify-content-between">
          <p className="merchant-product-card__amount">N {amount}</p>
          <p className="merchant-product-card__delivery-day">
            {/* {deliveryDate} {deliveryDate > 1 ? "Qtys" : "Qty"} available */}
          </p>
        </div>
        {isOwnerOfStore ? (
          <>
            <PepperestContext.Consumer>
              {(context) => (
                <button
                  onClick={() => {
                    context.updateShowEditProductModal(true, props.item);
                  }}
                  className="button button-md button--orange-outline"
                >
                  Edit Item
                </button>
              )}
            </PepperestContext.Consumer>

            <PepperestContext.Consumer>
              {(context) => (
                <button
                  onClick={() => {
                    context.updateShowDeleteProductModal(true, props.item);
                  }}
                  className="button button-md button--orange-outline"
                >
                  Delete Item
                </button>
              )}
            </PepperestContext.Consumer>
          </>
        ) : (
          <>
              {quantity<=0? (
                <button disabled className="text-dark mt-1 button button-lg btn btn-outline-light">
                OUT OF STOCK
              </button>
              ):(
                <button onClick={add} className="mt-1 button button-lg button--orange-outline">
                ADD TO CART
              </button>
              )}
              </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state, { item }) {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
    item: item,
    merchantStoreName: state.merchant.businessname,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (token, user, productID) => dispatch(actions.addToCart(token, user, productID)),
  noUserAddToCart: (productId) => dispatch(actions.noUserAddToCart(productId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MerchantProductCard);
