import React from "react";
import { Link } from "react-router-dom";
import { Product } from "../../buyer-assets";
import styles from "./secondproductinfo.module.css";
import FullGoldIcon from '../../buyer-assets/img/fullgoldicon.png'

const SecondProductInfo = ({product}) => {
	console.log(product);
	return (
		<div className="row mb-5">
			<div className="col-3">
				<img src={product?.image} alt="product" className={styles.productImage} />
			</div>
			<div className="col-9 d-flex flex-column justify-content-between py-2">
				<div className="d-flex justify-content-between">
					<Link to={`/product/${product?.productID}`} className={styles.productName}>
						{product?.productname}
					</Link>
					<div className="d-flex">
					<Link to={`/product-review-summary/${product?.productID}`} className={styles.rateReview}>
						View Rating
					</Link>
					<img src={FullGoldIcon} alt="star" className={styles.star} />
					</div>
					
				</div>
				<div className="d-flex justify-content-start align-items-center ">
					
					<p className={styles.quantity}>Quantity: {product?.quantity}</p>
				</div>
				<div className="d-flex justify-content-between align-items-end">
					<p className={styles.productPrice}>
						{new Intl.NumberFormat("en-GB", {
							style: "currency",
							currency: "NGN",
						}).format(product.totalCost)}
					</p>
					<Link to={`/rateproducts/${product?.productID}`} className={styles.ratebutton}>
						Rate Product
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SecondProductInfo;
