import {
  CloseIcon,
  DiscountIcon,
  InvoiceIcon,
  MessageIcon,
} from "components/vectors";
import CrownIcon from "components/vectors/CrownIcon";
import CustomersIcon from "components/vectors/CustomersIcon";
import OrderIcon from "components/vectors/OrdersIcon";
import OverviewIcon from "components/vectors/OverviewIcon";
import HomeIcon from "components/vectors/HomeIcon";
import PaymentsIcon from "components/vectors/PaymentsIcon";
import ProductsIcon from "components/vectors/ProductsIcon";
import SettingsIcon from "components/vectors/SettingsIcon";
import { useStateContext } from "context/activeStateContext";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ParentMenuItem from "../Menu/ParentMenuItem";
import { useSelector } from "react-redux";

function SideBar() {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const { userProfile, loading } = useSelector((state) => state.userAccount);

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  useEffect(() => {}, [userProfile]);

  return (
    <div
      className={`sidebar__container ${
        activeMenu ? "sidebar-visible" : "sidebar-hidden"
      }`}
    >
      <div className="logo__section">
        <img src="/assets/images/logo/purple_peppa.svg" alt="logo" />
        <span className="">
          <CloseIcon onClick={() => setActiveMenu(false)} />
        </span>
      </div>
      <div className="menu__container">
        <ul className="nav__menu">
          {menuItems.map((item, index) => (
            <ParentMenuItem
              key={index}
              keyIndex={index}
              item={item}
              handleCloseSideBar={handleCloseSideBar}
            />
          ))}
        </ul>

        {loading ? null : (
          <>
            {userProfile && userProfile?.isPremium == false ? (
              <li
                className={`menu__item upgrade__wrapper ${
                  !activeMenu ? "hide__button" : "show__button"
                }`}
              >
                <CrownIcon />
                <Link
                  onClick={handleCloseSideBar}
                  to="/dashboard/upgrade-plan"
                  className="menu__link"
                >
                  Upgrade Premium
                </Link>
              </li>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default SideBar;

const menuItems = [
  {
    title: "Home",
    slug: "home",
    icon: {
      solid: <HomeIcon type="solid" />,
      light: <HomeIcon />,
    },
    path: "/dashboard/home",
    hasSubMenu: false,
  },
  {
    title: "Overview",
    slug: "overview",
    icon: {
      solid: <OverviewIcon type="solid" />,
      light: <OverviewIcon />,
    },
    path: "/dashboard/overview",
    hasSubMenu: false,
  },
  {
    title: "Products",
    slug: "products",
    icon: {
      solid: <ProductsIcon type="solid" />,
      light: <ProductsIcon />,
    },
    path: "/dashboard/products",
    hasSubMenu: true,
    subMenuItems: [
      { id: 1, title: "All Products", path: "/dashboard/products" },
      { id: 2, title: "Add New Products", path: "/dashboard/products/new" },
    ],
  },
  {
    title: "Orders",
    slug: "orders",
    icon: {
      solid: <OrderIcon />,
      light: <OrderIcon />,
    },
    path: "/dashboard/orders",
    hasSubMenu: false,
  },
  {
    title: "Customers",
    slug: "customers",
    icon: {
      solid: <CustomersIcon />,
      light: <CustomersIcon />,
    },
    path: "/dashboard/customers",
    hasSubMenu: false,
  },
  {
    title: "My Purchases",
    slug: "mypurchases",
    icon: {
      solid: <OrderIcon />,
      light: <OrderIcon />,
    },
    path: "/dashboard/mypurchases",
     hasSubMenu: false,
},
{
    title: "Messaging",
    slug: "messaging",
    icon: {
      solid: <MessageIcon />,
      light: <MessageIcon />,
    },
    path: "/dashboard/messaging",
    hasSubMenu: false,
  },
  {
    title: "Invoices",
    slug: "invoices",
    icon: {
      solid: <InvoiceIcon type="solid" />,
      light: <InvoiceIcon />,
    },
    path: "/dashboard/invoices",
    hasSubMenu: false,
  },
  {
    title: "Discounts",
    slug: "discounts",
    icon: {
      solid: <DiscountIcon type="solid" />,
      light: <DiscountIcon />,
    },
    path: "/dashboard/discounts",
    hasSubMenu: true,
    subMenuItems: [
      { id: 1, title: "Discounts", path: "/dashboard/discounts" },
      { id: 2, title: "Coupons", path: "/dashboard/coupons" },
    ],
  },
  // {
  //   title: "Wallet",
  //   slug: "wallet",
  //   icon: {
  //     solid: <PaymentsIcon />,
  //     light: <PaymentsIcon />,
  //   },
  //   path: "/dashboard/wallet",
  //   hasSubMenu: false,
  // },
  {
    title: "Payments",
    slug: "payments",
    icon: {
      solid: <PaymentsIcon />,
      light: <PaymentsIcon />,
    },
    path: "/dashboard/payments",
    hasSubMenu: true,
    subMenuItems: [
      {
        id: 11,
        title: "Incoming Payments",
        path: "/dashboard/payments?tab=incoming-payments",
      },
      {
        id: 12,
        title: "completed Payments",
        path: "/dashboard/payments?tab=completed-payments",
      },
    ],
  },
  {
    title: "Store Setting",
    slug: "store-setting",
    icon: {
      solid: <SettingsIcon type="solid" />,
      light: <SettingsIcon />,
    },
    path: "/dashboard/store-setting",
    hasSubMenu: false,
  },
];
