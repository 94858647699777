import { OnlineStatus } from "components/vectors";
import OrderIcon from "components/vectors/OrdersIcon";
import { FileUploader } from "react-drag-drop-files";
import React,{useRef,useState,useCallback, useEffect} from "react";
import ChatDate from "../ChatDate";
import ReceiverMessage from "../ReceiverMessage";
import SenderMessage from "../SenderMessage";
import PlusIcon from "components/vectors/PlusIcon";
import SendIcon from "components/vectors/SendIcon";
import ChatUser from "../../../buyer-assets/img/avatar.png";
import ImageUploadPreview from "components/dashboardComponent/ImageUploadPreview";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  sendMessage
} from "store/actions/messaging";
import { Loader } from "components/blocks";

const ChatMessageBox = ({userdata}) => {
  const inputRef = useRef(null);
  const [files, setFiles] = useState(null); 
  const [attachments, setAttachments] = useState(null)
  const fileTypes = ["png", "jpg", "jpeg"];
  const { userInfo } = useSelector((state) => state.auth);
  const user_id = userInfo?.userID;
  

  const { messageSent, sendMessageError, sendingMessage } = useSelector(
    (state) => state.messaging
  );

  const dispatch = useDispatch();

  const currentTime = ()=>{
    const currentDate = new Date();
   const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
   return formattedTime;
  }

  const chatSchema = yup.object().shape({
    message : yup.string().required("message field required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(chatSchema),
    mode: "all",
  });

  const onSubmit = (data) => {
  
    const receiver_id = user_id === userdata?.last_message?.sender_id ? userdata?.last_message?.receiver_id : userdata?.last_message?.sender_id;
      
    console.log("reciver_id",receiver_id)
    data.receiver_id = receiver_id
    data.chat_id = userdata?.last_message?.chat_id
    data.product_id = ""
  
    let formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    
    if (attachments?.length > 0) {
      attachments.map((item, index) => {
        formData.append(`attachments[${index}]`, item.file);
      });
    }
      dispatch(sendMessage(formData));
  };


  const handleFileChange=useCallback((files)=>{
    setAttachments(files)  
},[files])

const handleChange = useCallback((files) => {
  if(files){
    setFiles(files);
  }
},[files]);

useEffect(()=>{
   if(messageSent){
    reset({message:''})
   }

 },[messageSent])

  return (
    <div className="chat__message__box">
      <div className="chat__user">
        <div className="user__info">
          {userdata?.senderProfilePic ?
          <div className="">
          <img src={userdata?.senderProfilePic} alt="chat-user" className="image_user"/>
        </div>
        :
        <div className="">
            <img src={ChatUser} alt="chat-user" className="image_user"/>
          </div>
          }
          <section className="username__container">
            <h2 className="username">{userdata?.sender}</h2>
            <div className="status__container">
              <OnlineStatus /> <p className="online__status">Online</p>
              <p className="time">{currentTime()}</p>
            </div>
          </section>
        </div>
        <div className="profile__view__container">
          <section className="profile__view">
            <p className="new__customer__label">New Customer</p>
            <p className="profile">View Profile</p>
          </section>
          <div className="order__count">
            <OrderIcon />
            <p>0 Orders</p>
          </div>
        </div>
      </div>
      <section className="chat__area">
        <ChatDate date={userdata?.chat_created} />
       {userdata?.messages?.map((chat,index) =>(
        <span key={index}>
          {chat?.from === userdata?.sender ? 
          <SenderMessage
          chat={chat}
          attachments={chat?.attachments}
        /> 
        :
        <ReceiverMessage
        chat={chat}
        attachments={chat?.attachments}
        />
         }
        
        
        </span>   
       ))}
       
      </section>
      
      <section className="wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        {files ? <div className="image__preview"><ImageUploadPreview handleFileChange={handleFileChange}  files={files} /></div>:"" }
        <div className="message__input__container">
          <section className="input__box__container">
            <div className="file__picker">
            <FileUploader
                handleChange={handleChange}
                multiple={true}
                types={fileTypes}
              >
            <PlusIcon width="24" height="24" color="#1C1D22" />
            </FileUploader>
            </div>
            <textarea
              className="input__box"
              ref={register}
              name="message"
              id = "message"
              rows={1}
              placeholder="Your message"
            ></textarea>
          </section>
          <section className="send__button__container">
            {/* <div className="emoji">emo</div> */}
            <button className="send__button">
              {sendingMessage ? (<Loader/>):(
               <>
               <span>Send</span>{" "}
              <SendIcon color="#1C1D22" width="20" height="20" /></>
              )}
            </button>
          </section>
          
        </div>
        </form>
      </section>
    </div>
  );
};

export default ChatMessageBox;
