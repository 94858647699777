import React from 'react';

const ErrorIcon = ({width="8",height="8"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 8 8"
  >
    <path
      fill="#D80000"
      d="M3.938 0c2.176 0 3.938 1.762 3.938 3.938 0 2.175-1.762 3.938-3.938 3.938S0 6.113 0 3.938C0 1.762 1.762 0 3.938 0zm0 .641C2.118.641.642 2.117.642 3.938c0 1.82 1.476 3.296 3.296 3.296 1.82 0 3.296-1.476 3.296-3.296 0-1.82-1.476-3.297-3.296-3.297zm.004 4.512c.385 0 .64.27.647.662 0 .379-.255.662-.647.662h-.016c-.377 0-.64-.283-.64-.662 0-.393.27-.662.656-.662zm.575-3.66l-.16 3.297h-.83l-.16-3.296h1.15z"
    />
  </svg>
);

export default ErrorIcon;
