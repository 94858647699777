/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import {
  ProfileForm,
  ChangeAccountTypeForm,
  ChangePasswordForm,
  PickupAddress,
} from "components/forms";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

const ProfilePage = (props) => {
  const isMerchant = props.user.usertype === "Merchant" || props.user.usertype === "Both";
  return (
    <div className='profile-settings'>
      <ProfileForm />
      {isMerchant && <PickupAddress />}
      <ChangeAccountTypeForm />
      <ChangePasswordForm />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.userInfo,
    userProfile: state.userAccount.userProfile,
    loading: state.userAccount.updatingProfile,
    error: state.userAccount.updateProfileError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadOrders: (token, user, pageType, extraParams) =>
      dispatch(actions.loadOrders(token, user, pageType, extraParams)),
    getReferralStats: (token, user) => dispatch(actions.getReferralStats(token, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
