import React, { useEffect } from "react";
import styles from "./wishlistitem.module.css";
import productImage from "../../buyer-assets/img/Product pic.png";
import { RemoveIcon, TrashIcon } from "components/vectors";
import RemoveIconBlack from "components/vectors/RemoveIconBlack";
import MinusIcon from "components/vectors/MinusIcon";
import PlusIcon from "components/vectors/PlusIcon";
import { useCartContext } from "buyer-context/cartContext";

const WishListCartItem = ({ item, handleCheck }) => {
  const { dispatch, getItemQuantity } = useCartContext();

  const setQuantity = (quantity) => {
    console.log({ quantity });
    dispatch({
      type: "CHANGE-QUANTITY-WISHLIST",
      payload: {
        id: item.product.id,
        quantity,
      },
    });
  };
  const removeItem = (item) => {
    dispatch({
      type: "REMOVE-WISHLIST",
      payload: item.product.id,
    });
  };

  return (
    <div className={styles.wishlistCard}>
      <div className={styles.cardWrapper}>
        <div className={styles.productContainer}>
          <img src={item?.product?.image_url} className={styles.productImage} />
          <div className={styles.nameContainer}>
            <div className={styles.innerItem}>
              <div>
                {" "}
                <h3 className={styles.productTitle}>
                  {item.product.productName}
                </h3>
                <p className={styles.variant}>Color:</p>
              </div>
              <span onClick={() => removeItem(item)}>
                <TrashIcon />
              </span>
            </div>

            <div className={styles.innerItem}>
              <p className={styles.price}>₦{item.product.price}</p>
              <div className={styles.cartbutton}>
                <button
                  onClick={() => {
                    if (getItemQuantity(item.product.id) > 1) {
                      setQuantity(getItemQuantity(item.product.id) - 1);
                    }
                  }}
                >
                  <MinusIcon />
                </button>
                <p>{getItemQuantity(item.product.id)}</p>
                <button
                  onClick={() => {
                    setQuantity(getItemQuantity(item.product.id) + 1);
                  }}
                >
                  <PlusIcon color="black" width="14" height="14" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishListCartItem;
