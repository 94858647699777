import React, { Fragment, useState } from "react";
import { withAuthLayout } from "components/layouts";
import { Input, Button, AuthFooter } from "components/blocks";
import axios from "axios";
import { PepperestWebServices } from "libs/constants";

const PasswordReset = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = params.get("token");
    const email = params.get("email");
    if (password != confirmPassword) {
      setError("Confirm password did not match");
      return;
    }

    const option = {
      password,
      token,
      email,
    };

    axios
      .post(`${PepperestWebServices.HOST}/EscrowBackend/api/password/resetConfirm`, option)
      .then((response) => {
        setMessage(response.data.message);
        setLoading(false);
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });

    setTimeout(() => {
      setError("");
      setMessage("");
    }, 5000);
  };

  return (
    <Fragment>
      <div className="auth-panel auth-panel--alternate">
        <div className="auth-panel__head">
          <h4 className="auth-panel__head-header">Reset Password</h4>
          <p className="auth-panel__head-help">Enter your new password.</p>
        </div>
        <div className="auth-panel-main">
          <div className="text-center text-success">{message && message}</div>
          <div className="text-center text-danger">{error && error}</div>
          <form className="nsForm auth-panel-form" method="POST">
            <Input
              name="password"
              type="password"
              placeholder="New Password"
              label="New Password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Input
              name="passwordConfirm"
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
              id="passwordConfirm"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <Button
              type="submit"
              value={
                loading == true ? (
                  <div>
                    <div class="spinner-border text-white" role="status"></div>
                  </div>
                ) : (
                  "RESET PASSWORD"
                )
              }
              name="send_recovery"
              handleClick={(e) => handleSubmit(e)}
            />
          </form>
        </div>
      </div>
      <AuthFooter
        value="SIGN IN"
        url="/"
        label="Already have an account ?"
        isAlternate={false}
      />
    </Fragment>
  );
};

export default withAuthLayout(PasswordReset, {});
