import PepperestAxios from "../../libs/utils/PepperestAxios";
import {
  Auth,
  AuthErrorMessages,
} from "../../libs/constants/PepperestWebServices";
// import userflow from "userflow.js";
import * as actionTypes from "./actionTypes";
import {
  setStateInLocalStorage,
  getStateFromLocalStorage,
  removeStateFromLocalStorage,
} from "../utility";
import { getBanks, getUserProfile } from "store/actions/userAccount";
import { productCheck } from "./cart";
import { publishProductForNonUser } from "./products";
import { getPickupAddress } from "./orders";
import { mixPanelAuth, mixPanelUserProperty } from "libs/mixPanel";
import { FbTrackCustomEvent } from "libs/facebookPanel";
import { toast } from "react-toastify";
import { setAlert } from "./alert";
import bugsnagClient from "libs/utils/bugsnag";
import { identifyUser, trackEvent } from "libs/utils/customer-io";
import { setToStorage } from "buyer-constants";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userInfo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userInfo: userInfo,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const SessionExpired = () => {
  return {
    type: actionTypes.SESSION_EXPIRED,
  };
};

export const vendorAuthSuccess = (token, userInfo) => {
  return {
    type: actionTypes.INITIATE_VENDOR_AUTH_SUCCESS,
    token: token,
    userInfo: userInfo,
  };
};

export const vendorAuthComplete = () => {
  return {
    type: actionTypes.INITIATE_VENDOR_AUTH_COMPLETE,
  };
};

export const registerFail = (errors) => {
  errors = {
    accountType: errors.accountType && errors.accountType.join("<br />"),
    email: errors.email && errors.email.join("<br />"),
    name: errors.name && errors.name.join("<br />"),
    businessName: errors.businessName && errors.businessName.join("<br />"),
    password: errors.password && errors.password.join("<br />"),
  };
  return {
    type: actionTypes.REGISTER_FAIL,
    errors: errors,
  };
};

export const logout = () => {
  const token = getStateFromLocalStorage("token");
  const userInfo = JSON.parse(getStateFromLocalStorage("userInfo"));
  removeStateFromLocalStorage("token");
  removeStateFromLocalStorage("expirationDate");
  removeStateFromLocalStorage("tokenType");
  removeStateFromLocalStorage("userInfo");
  removeStateFromLocalStorage("productDetails");

  if (token && userInfo) {
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };
    const payLoad = {
      token: token.replace("Bearer ", ""),
    };
    PepperestAxios.post(Auth.LOGOUT, payLoad, { headers: headers });
  }
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(refreshToken());
    }, expirationTime * 1000 - 60000);
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    let token = getStateFromLocalStorage("token");
    const userInfo = JSON.parse(getStateFromLocalStorage("userInfo"));
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };
    PepperestAxios.post(Auth.REFRESH_TOKEN, {}, { headers: headers })
      .then((response) => {
        token = "Bearer " + response.data.token.access_token;
        const expirationDate = new Date(
          new Date().getTime() + response.data.token.expires_in * 1000
        );

        setStateInLocalStorage("token", token);
        setStateInLocalStorage("expirationDate", expirationDate);
        dispatch(authSuccess(token, userInfo));
        dispatch(checkAuthTimeout(response.data.token.expires_in));
      })
      .catch((error) => {
        dispatch(logout());
      });
  };
};

export const login = (email, password) => {
  mixPanelUserProperty("Login_initiated", {});
  return (dispatch) => {
    dispatch(autenticate({ email, password }, Auth.LOGIN));
  };
};

export const register = (payLoad) => {
  return (dispatch) => {
    dispatch(autenticate(payLoad, Auth.REGISTER));
  };
};

export const googleSocialLogin = (response) => {
  const { profileObj } = response;
  const payload = {
    provider: "google",
    phoneNo: "",
    name: profileObj?.name,
    userProviderID: profileObj?.googleId,
    accountType: "Both",
    email: profileObj?.email,
  };
  return (dispatch) => {
    dispatch(autenticate(payload, Auth.GOOGLE_SOCIAL));
  };
};
export const socialLogin = (payLoad) => {
  return (dispatch) => {
    dispatch(autenticate(payLoad, Auth.SOCIAL));
  };
};

export const loginIsLoading = (payload) => {
  return (dispatch) => {
    dispatch();
  };
};

export const autenticate = (payLoad, endpoint) => {
  return (dispatch, getState) => {
    dispatch(authStart());
    PepperestAxios.post(endpoint, payLoad)
      .then((response) => {
        const { name, email, usertype } = response.data.userInfo;
        const token = "Bearer " + response.data.token.access_token;
        const userInfo = response.data.userInfo;
        const expirationDate = new Date(
          new Date().getTime() + response.data.token.expires_in * 1000
        );
        if (usertype === "Merchant") {
          FbTrackCustomEvent("COMPLETE_REGISTRATION");

          const isMerchant =
            response.data.userInfo.usertype === "Merchant" ||
            response.data.userInfo.usertype === "Both";

          setStateInLocalStorage("token", token);
          setStateInLocalStorage("expirationDate", expirationDate);
          setStateInLocalStorage(
            "tokenType",
            response?.data?.token?.token_type
          );
          setStateInLocalStorage("userInfo", JSON.stringify(userInfo));
          dispatch(authSuccess(token, userInfo));
          dispatch(getUserProfile(token, userInfo));
          dispatch(getBanks());
          dispatch(checkAuthTimeout(response?.data?.token?.expires_in));
          dispatch(productCheck(token, userInfo));

          const productDetails = getState().products.holdProductValuesInStore;
          if (productDetails.length > 0) {
            dispatch(publishProductForNonUser(token, userInfo));
          }
          const shortUrl = endpoint.split("/")[3];
          if (shortUrl === "register") {
            identifyUser(response?.data?.userInfo);
            trackEvent("create_account_successful");
          }
          toast.success("You've Logged in Successfully");
          trackEvent("Logged_in_successfully");
        } else {
          //buyer
          // dispatch(authSuccess(null, null));
          // setToStorage("userToken", response?.data?.token?.access_token);
          // setToStorage("userTokenExpiredDate", expirationDate);
          // setToStorage("customerID", userInfo?.customerID);
          dispatch(setAlert("Sorry email is not signed up as a merchant", "error"));
          window.location.href ="/";
           
          
        }
      })
      .catch((error) => {
        bugsnagClient.notify(error);
        let errorMessage = error?.response?.data
          ? error.response?.data?.message
          : "Unable to connect to the server";
        dispatch(authFail(errorMessage));
        dispatch(setAlert(errorMessage, "error"));
      });
  };
};

// Mini registration if a user doesnt have an account and wants to checkout
export const miniRegistration = (payLoad) => (dispatch, getState) => {
  dispatch(authStart());
  PepperestAxios.post(Auth.MINIMAL_REGISTRATION, payLoad)
    .then((response) => {
      const token = "Bearer " + response.data.token.access_token;
      const expirationDate = new Date(
        new Date().getTime() + response.data.token.expires_in * 1000
      );
      const isMerchant =
        response.data.userInfo.usertype === "Merchant" ||
        response.data.userInfo.usertype === "Both";
      const productDetails = getState().products?.holdProductValuesInStore;
      setStateInLocalStorage("token", token);
      setStateInLocalStorage("expirationDate", expirationDate);
      setStateInLocalStorage("tokenType", response.data.token.token_type);
      setStateInLocalStorage(
        "userInfo",
        JSON.stringify(response.data.userInfo)
      );
      dispatch(authSuccess(token, response.data.userInfo));
      // if (isMerchant) dispatch(getPickupAddress(token));
      dispatch(getUserProfile(token, response.data.userInfo));

      dispatch(checkAuthTimeout(response.data.token.expires_in));
      dispatch(productCheck(token, response.data.userInfo));
      if (productDetails.length)
        dispatch(publishProductForNonUser(token, response.data.userInfo));
    })
    .catch((error) => {
      let errorMessage = null;
      // if (endpoint === Auth.LOGIN || endpoint === Auth.SOCIAL) {
      //   errorMessage = error.response
      //     ? AuthErrorMessages[error.response.data.error] || AuthErrorMessages.default
      //     : "Unable to connect to the server"; //TODO handle this in global PepperestAxios
      //   dispatch(authFail(errorMessage));
      // } else {
      errorMessage = error.response
        ? error.response.data.message
        : "Unable to connect to the server"; //TODO handle this in global PepperestAxios
      dispatch(authFail(errorMessage));
      dispatch(registerFail(errorMessage));

      dispatch(setAlert(errorMessage, "error"));
      // }
    });
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = getStateFromLocalStorage("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(
        getStateFromLocalStorage("expirationDate")
      );
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );

        const userInfo = JSON.parse(getStateFromLocalStorage("userInfo"));
        const isMerchant =
          userInfo?.usertype === "Merchant" || userInfo?.usertype === "Both";
        dispatch(authSuccess(token, userInfo));
        dispatch(getUserProfile(token, userInfo));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
        // if (isMerchant) dispatch(getPickupAddress(token));
        if (localStorage.getItem("productDetails")) {
          dispatch(publishProductForNonUser(token, userInfo));
        }
      }
    }
  };
};

export const verifyEmail = (verifyToken) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.VERIFY_EMAIL });
    const { token, userInfo } = getState().auth;
    const { userProfile } = getState().userAccount;
    const headers = {
      Authorization: token,
      customerID: userInfo?.customerID,
    };
    PepperestAxios.post(Auth.VERIFY_EMAIL, { token: verifyToken }, { headers })
      .then((response) => {
        dispatch({ type: actionTypes.VERIFY_EMAIL_SUCCESS });
        trackEvent("email_verification");
        dispatch(getUserProfile(token, userInfo));
      })
      .catch((error) => {
        const message = error.response?.data
          ? error.response.data?.message
          : "Unable to connect to the server"; //TODO handle this in global PepperestAxios
        dispatch({ type: actionTypes.VERIFY_EMAIL_FAIL, error: message });
      });
  };
};

export const initiateVendorAuth = (merchantCode) => {
  return (dispatch) => {
    dispatch(authStart());
    PepperestAxios.get(
      `${Auth.INITIATE_VENDOR_AUTH}?merchantCode=${merchantCode}`
    )
      .then((response) => {
        if (response?.data?.token) {
          const token = "Bearer " + response?.data?.token?.access_token;
          const userInfo = response?.data?.userInfo;
          const expirationDate = new Date(
            new Date().getTime() + response?.data?.token?.expires_in * 1000
          );

          setStateInLocalStorage("token", token);
          setStateInLocalStorage("expirationDate", expirationDate);
          setStateInLocalStorage(
            "tokenType",
            response?.data?.token?.token_type
          );
          setStateInLocalStorage("userInfo", JSON.stringify(userInfo));
          dispatch(getUserProfile(token, userInfo));
          dispatch(vendorAuthSuccess(token, userInfo));
          dispatch(getBanks());
          dispatch(checkAuthTimeout(response?.data?.token?.expires_in));
        } else {
          const message = response?.data
            ? response.data?.ResponseMessage
            : "Sorry, we couldn't make this happen at the moment, please try again"; //TODO handle this in global PepperestAxios
          dispatch(authFail(message));
        }
      })
      .catch((error) => {
        const message = error.response?.data
          ? error.response.data?.ResponseMessage
          : "Sorry, we couldn't make this happen at the moment, please try again"; //TODO handle this in global PepperestAxios
        dispatch(authFail(message));
      });
  };
};
