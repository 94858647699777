import React, { useState } from "react";
import { CloseIcon } from "components/vectors";
import { InputWithoutLabel, SelectInputWithoutLabel } from "components/blocks";
import EscapeCloseModalHelper from "components/helpers/EscapeCloseModalHelper";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const AddAddressSchema = yup.object({
  // postal_code: yup.number().positive().required("The Postal code is required"),
  phone: yup.string().min(11).max(11),
});

const EditAddressModal = (props) => {
  const { context, editAddress } = props;
  const { address } = context.state;
  const [street_1, setStreet_1] = useState(address.street_1);
  const [street_2, setStreet_2] = useState(address.street_2);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [postal_code, setPostal_code] = useState(address.postal_code);
  const [country_id, setCountry_id] = useState("247");
  const [phone, setPhone] = useState(address.phone);
  const [name, setName] = useState(address.name);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddAddressSchema),
  });

  const edit = (event) => {
    event.preventDefault();
    const extraParams = {
      street_1,
      city,
      state,
      postal_code,
      country_id,
      phone,
      addressId: address.address_id,
      street_2,
    };
    for (let value in extraParams) {
      // Name Validation
      if (value == "name") {
        let verifyName = extraParams[value].split(" ").length;
        if (verifyName < 2) {
          return toast.error("please enter your firstname and lastname e.g 'Paul Wang'");
        }
      }
      // Phone number Validation
      if (value == "phone") {
        if (extraParams[value].length < 11) {
          return toast.error("phone number must be 11 digits e.g 0706583xxxx");
        }
        if (extraParams[value].length > 11) {
          return toast.error("phone number must be 11 digits e.g 0706583xxxx");
        }
      }
    }
    editAddress(extraParams);
    context.updateShowEditAddressModal(false);
  };
  return (
    <>
      <div className="pModal">
        <div className="pModal-overlay" />
        <div className="pModal-content pModal-midcontent">
          <form onSubmit={edit}>
            <div className="pModal-header pModal-border-bottom">
              <h6 className="text--small">Edit Address</h6>
              <div onClick={() => context.updateShowEditAddressModal(false)}>
                <CloseIcon />
              </div>
            </div>
            <div className="pModal-main">
              <div className="pModal-form">
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Name</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      required
                      name="name"
                      type="text"
                      placeholder="Enter your fullname E.g Paul Wang"
                      id="name"
                      value={name}
                      defaultValue=""
                      validation={register("name", {
                        required: "this field is required",
                      })}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      hasError={errors.name ? errors.name.message : false}
                      errorMessage={errors.name ? errors.name.message : ""}
                      classNames="nsForm-input__alternate"
                    />
                  </div>
                </div>
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Phone Number</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      required
                      name="phone_number"
                      type="tel"
                      placeholder="Phone No Eg. 0706583xxxxx"
                      id="phone_number"
                      value={phone}
                      defaultValue=""
                      validation={register("phone", {
                        required: "this field is required",
                        min: 10,
                        max: 12,
                      })}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                      classNames="nsForm-input__alternate"
                    />
                  </div>
                </div>
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Street</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      required
                      name="street"
                      type="text"
                      placeholder="Street Eg. 12 Kunle Street"
                      id="street"
                      value={street_1}
                      onChange={(event) => {
                        setStreet_1(event.target.value);
                      }}
                      classNames="nsForm-input__alternate"
                    />
                  </div>
                </div>
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">Closest Bus Stop</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      required
                      name="street_2"
                      type="text"
                      placeholder="Street Bus stop or landmark Eg. 12 Kunle Street"
                      id="street_2"
                      validation={register("street", { required: "this field is required" })}
                      value={street_2}
                      onChange={(event) => {
                        setStreet_2(event.target.value);
                      }}
                      classNames="nsForm-input__alternate"
                      // hasError={errors.street ? errors.street.message : false}
                      // errorMessage={errors.street ? errors.street.message : "This field is required"}
                    />
                  </div>
                </div>
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">City</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <InputWithoutLabel
                      name="city"
                      required
                      type="text"
                      placeholder="Eg. Yaba"
                      id="city"
                      value={city}
                      onChange={(event) => {
                        setCity(event.target.value);
                      }}
                      classNames="nsForm-input__alternate"
                    />
                  </div>
                </div>
               
                <div className="pModal-form-control row mx-0">
                  <div className="col-md-5">
                    <div className="pModal-form__label-control">
                      <label className="pModal-form__label">State</label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <SelectInputWithoutLabel
                      options={[
                        { value: "Lagos", label: "Lagos" },
                        { value: "Rivers", label: "Rivers" },
                        { value: "Abuja", label: "Abuja" },
                        { value: "Oyo", label: "Oyo" },
                        { value: "Ogun", label: "Ogun" },
                        { value: "Osun", label: "Osun" },
                      ]}
                      name="state"
                      id="state"
                      value={state}
                      onChange={(event) => {
                        setState(event.target.value);
                      }}
                      defaultValue="Lagos"
                      classNames="nsForm-select__alternate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pModal-footer pModal-border-top">
              <div
                className="button button--auto button-md button--neutral"
                onClick={() => context.updateShowEditAddressModal(false)}
              >
                CANCEL
              </div>
              <button type="submit" className="button button-md button--orange">
                EDIT ADDRESS
                {/* <SpinnerIcon /> */}
              </button>
            </div>
          </form>
        </div>
      </div>
      <EscapeCloseModalHelper />
    </>
  );
};

export default EditAddressModal;
