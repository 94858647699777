import { useEffect } from "react";

const useDocumentMeta = (
  title,
  description = "Peppa - Social E commerce Platform that Provides Online Storefront for Merchants to drive sales, social proof, ROI. Escrow services to protect Merchants."
) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }

    if (description) {
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = description;
    }
  }, [title, description]);
};

export default useDocumentMeta;
