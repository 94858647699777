import React, { useState } from "react";
import PropTypes from "prop-types";
import { CustomerListItem, ListHeader, ListFooter, LoadingListItem } from "components/blocks";
import { getStringHash } from "libs/utils";

const CustomerList = ({ productListActionLabel, loading, customers, meta, links, refreshData }) => {
  const [page, setPage] = useState(null);

  const [query, setQuery] = useState("");

  const gotoPage = (page) => {
    setPage(page);
    const params = {
      page: page,
      query: query,
    };
    refreshData(params);
  };

  const search = (query) => {
    setQuery(query);
    const params = {
      page: page,
      query: query,
    };
    refreshData(params);
  };

  const uniqueCustomers = {};

  customers.forEach((cus) => {
    if (uniqueCustomers[cus.customerEmail]) {
      uniqueCustomers[cus.customerEmail].recentTransactions = [
        ...uniqueCustomers[cus.customerEmail].recentTransactions,
        ...cus.recentTransactions,
      ];
      uniqueCustomers[cus.customerEmail].totalSpent =
        uniqueCustomers[cus.customerEmail].totalSpent + cus.totalSpent;
      uniqueCustomers[cus.customerEmail].totalTransactions =
        uniqueCustomers[cus.customerEmail].totalTransactions + cus.totalTransactions;

      return;
    }
    uniqueCustomers[cus.customerEmail] = cus;
  });

  const filteredCustomers = Object.values(uniqueCustomers);

  if (loading) {
    return (
      <div className='list-body'>
        <ul>
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
          <LoadingListItem />
        </ul>
      </div>
    );
  }

  return customers.length ? (
    <div className='list'>
      <ListHeader search={search} />
      <div className='list-item row  d-none d-md-flex mx-0'>
        <div className={`list-item__indicator `} />
        <div className='col-4 col-md-4 px-0 d-none d-lg-flex'>
          <div className='d-flex flex-row'>
            <div className='list-item__date'>
              {/* <p className='list-item__date-text'>{phone}</p> */}
            </div>
            <div className='list-item__status'>
              <div className='list-item__status-container'>
                <div className={`list-item__status-tag `}>Status</div>
              </div>
            </div>
          </div>
        </div>
        <div className='list-item__details col-12 col-md-4 px-0'>
          <div className='list-item__details-container'>
            <p className='list-item__details-product'>Product Name</p>
            <p
              className='list-item__details-email'
              style={{ color: "#10182a", opacity: "1", fontWeight: "600" }}
            >
              Email
            </p>
          </div>
        </div>
        <div className='col-2 col-md-4 px-0'>
          <div className='d-flex flex-row justify-content-end'>
            <div
              className='list-item__more'

              // onClick={() => {
              //   setListDetailsOpen(true);
              // }}
            >
              More
            </div>
          </div>
        </div>
      </div>
      <div className='list-body'>
        <ul>
          {filteredCustomers.reverse().map((customer, index) => (
            <CustomerListItem
              key={getStringHash(index)}
              status={customer.status}
              phone={customer.phone}
              customerName={customer.customerName}
              paymentName={customer.paymentName}
              customerEmail={customer.customerEmail}
              totalTransactions={customer.totalTransactions}
              totalSpent={customer.totalSpent}
              recentTransactions={customer.recentTransactions.reverse()}
            />
          ))}
        </ul>
      </div>
      <ListFooter meta={meta} links={links} gotoPage={(page) => gotoPage(page)} />
    </div>
  ) : (
    <p className='mx-auto text-center' style={{ width: "80%", height: "auto" }}>
      You dont have any customers yet
    </p>
  );
};

CustomerList.defaultProps = {
  customers: [],
};

CustomerList.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      customerName: PropTypes.string.isRequired,
      paymentName: PropTypes.string.isRequired,
      totalSpent: PropTypes.number.isRequired,
      customerEmail: PropTypes.string.isRequired,
    })
  ),
};

export default CustomerList;
