import React from "react";

const DiscountIcon = ({ type = "light" }) =>
  type == "light" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.5299 10.8709L20.0099 9.35086C19.7499 9.09086 19.5399 8.58086 19.5399 8.22086V6.06086C19.5399 5.18086 18.8199 4.46086 17.9399 4.46086H15.7899C15.4299 4.46086 14.9199 4.25086 14.6599 3.99086L13.1399 2.47086C12.5199 1.85086 11.4999 1.85086 10.8799 2.47086L9.33988 3.99086C9.08988 4.25086 8.57988 4.46086 8.20988 4.46086H6.05988C5.17988 4.46086 4.45988 5.18086 4.45988 6.06086V8.21086C4.45988 8.57086 4.24988 9.08086 3.98988 9.34086L2.46988 10.8609C1.84988 11.4809 1.84988 12.5009 2.46988 13.1209L3.98988 14.6409C4.24988 14.9009 4.45988 15.4109 4.45988 15.7709V17.9209C4.45988 18.8009 5.17988 19.5209 6.05988 19.5209H8.20988C8.56988 19.5209 9.07988 19.7309 9.33988 19.9909L10.8599 21.5109C11.4799 22.1309 12.4999 22.1309 13.1199 21.5109L14.6399 19.9909C14.8999 19.7309 15.4099 19.5209 15.7699 19.5209H17.9199C18.7999 19.5209 19.5199 18.8009 19.5199 17.9209V15.7709C19.5199 15.4109 19.7299 14.9009 19.9899 14.6409L21.5099 13.1209C22.1599 12.5109 22.1599 11.4909 21.5299 10.8709ZM7.99988 9.00086C7.99988 8.45086 8.44988 8.00086 8.99988 8.00086C9.54988 8.00086 9.99988 8.45086 9.99988 9.00086C9.99988 9.55086 9.55988 10.0009 8.99988 10.0009C8.44988 10.0009 7.99988 9.55086 7.99988 9.00086ZM9.52988 15.5309C9.37988 15.6809 9.18988 15.7509 8.99988 15.7509C8.80988 15.7509 8.61988 15.6809 8.46988 15.5309C8.17988 15.2409 8.17988 14.7609 8.46988 14.4709L14.4699 8.47086C14.7599 8.18086 15.2399 8.18086 15.5299 8.47086C15.8199 8.76086 15.8199 9.24086 15.5299 9.53086L9.52988 15.5309ZM14.9999 16.0009C14.4399 16.0009 13.9899 15.5509 13.9899 15.0009C13.9899 14.4509 14.4399 14.0009 14.9899 14.0009C15.5399 14.0009 15.9899 14.4509 15.9899 15.0009C15.9899 15.5509 15.5499 16.0009 14.9999 16.0009Z"
        fill="none"
        stroke="#18191F"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.5299 10.8709L20.0099 9.35086C19.7499 9.09086 19.5399 8.58086 19.5399 8.22086V6.06086C19.5399 5.18086 18.8199 4.46086 17.9399 4.46086H15.7899C15.4299 4.46086 14.9199 4.25086 14.6599 3.99086L13.1399 2.47086C12.5199 1.85086 11.4999 1.85086 10.8799 2.47086L9.33988 3.99086C9.08988 4.25086 8.57988 4.46086 8.20988 4.46086H6.05988C5.17988 4.46086 4.45988 5.18086 4.45988 6.06086V8.21086C4.45988 8.57086 4.24988 9.08086 3.98988 9.34086L2.46988 10.8609C1.84988 11.4809 1.84988 12.5009 2.46988 13.1209L3.98988 14.6409C4.24988 14.9009 4.45988 15.4109 4.45988 15.7709V17.9209C4.45988 18.8009 5.17988 19.5209 6.05988 19.5209H8.20988C8.56988 19.5209 9.07988 19.7309 9.33988 19.9909L10.8599 21.5109C11.4799 22.1309 12.4999 22.1309 13.1199 21.5109L14.6399 19.9909C14.8999 19.7309 15.4099 19.5209 15.7699 19.5209H17.9199C18.7999 19.5209 19.5199 18.8009 19.5199 17.9209V15.7709C19.5199 15.4109 19.7299 14.9009 19.9899 14.6409L21.5099 13.1209C22.1599 12.5109 22.1599 11.4909 21.5299 10.8709ZM7.99988 9.00086C7.99988 8.45086 8.44988 8.00086 8.99988 8.00086C9.54988 8.00086 9.99988 8.45086 9.99988 9.00086C9.99988 9.55086 9.55988 10.0009 8.99988 10.0009C8.44988 10.0009 7.99988 9.55086 7.99988 9.00086ZM9.52988 15.5309C9.37988 15.6809 9.18988 15.7509 8.99988 15.7509C8.80988 15.7509 8.61988 15.6809 8.46988 15.5309C8.17988 15.2409 8.17988 14.7609 8.46988 14.4709L14.4699 8.47086C14.7599 8.18086 15.2399 8.18086 15.5299 8.47086C15.8199 8.76086 15.8199 9.24086 15.5299 9.53086L9.52988 15.5309ZM14.9999 16.0009C14.4399 16.0009 13.9899 15.5509 13.9899 15.0009C13.9899 14.4509 14.4399 14.0009 14.9899 14.0009C15.5399 14.0009 15.9899 14.4509 15.9899 15.0009C15.9899 15.5509 15.5499 16.0009 14.9999 16.0009Z"
        fill="#5E60CE"
      />
    </svg>
  );

export default DiscountIcon;
