import React from "react";
import { usePagination } from "components/customHook/pagination/usePagination";
import ArrowBackward from "components/vectors/ArrowBackward";
import ArrowForward from "components/vectors/ArrowForward";

const Pagination = ({
  onPageChange,
  onChangePageSize,
  totalCount = 20,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 10,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange?.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <div className="pagination__">
      <section className="per__page__wrapper">
        <p className="pagination__title">Rows per page</p>
        <select
          className="pagination__filter__select"
          value={pageSize>6?pageSize:''}
          onChange={(e) => onChangePageSize(e.target.value)}
        >
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </section>
      <div className="page__number__wrapper">
        <p className="page__number">
          {(currentPage - 1) * pageSize + 1} - {" "}
          {currentPage * pageSize > totalCount ? totalCount : currentPage * pageSize} of{" "}
          {totalCount}
        </p>
        <div className="page__control">
          <button
            className="control"
            onClick={onPrevious}
            disabled={currentPage == 1 ? "disabled" : ""}
          >
            <ArrowBackward
              onClick={() => {}}
              height={12}
              width={8}
              fill={"#646A86"}
            />
          </button>
          <button
            className="control"
            onClick={onNext}
            disabled={currentPage == lastPage ? "disabled" : ""}
          >
            <ArrowForward
              onClick={() => {}}
              height={12}
              width={8}
              fill={"#646A86"}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
