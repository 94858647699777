import CustomerDetailCard from "components/dashboardComponent/CustomerDetailCard";
import { LoaderProcessing } from "components/shared";
import BreadCrumb from "components/shared/BreadCrumb";
import { currencyFormatter } from "libs/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleInvoice, requestPayment } from "store/actions/invoices";

const InvoiceDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { invoiceID } = params;
  const { loading, invoice, actionCompleted, processing } = useSelector(
    (state) => state.invoices
  );

  const crumbs = [
    {
      name: "Back to Invoices",
      path: "/dashboard/invoices",
    },
    {
      name: `Invoice No:${invoiceID}`,
    },
  ];

  const handleRequestPayment = (invoice) => {
    let params = {
      orderID: invoice?.orderID,
    };
    dispatch(requestPayment(params));
  };

  useEffect(() => {
    dispatch(getSingleInvoice(invoiceID));
  }, [invoiceID, actionCompleted]);
  return loading ? (
    <div className="loader__container">
      <LoaderProcessing />
    </div>
  ) : (
    <main className="invoice__detail">
      <BreadCrumb crumbs={crumbs} />
      <h1 className="page__title">Payment Invoice</h1>
      <section className="invoice__container">
        <div className="invoice__details">
          <h1 className="title">Order Details</h1>
          <div className="invoice__info">
            <div className="info__item">
              <p className="label">Status</p>
              <p
                className={`order__status ${
                  invoice?.status?.toLowerCase() == "in progress"
                    ? "status__in__progress d-block"
                    : `status__${invoice?.status?.toLowerCase()} d-block`
                }`}
              >
                {invoice?.status}
              </p>
            </div>
            <div className="info__item">
              <p className="label">Date</p>
              <p className="value">{invoice?.date}</p>
            </div>
            <div className="info__item">
              <p className="label">Description</p>
              <p className="value">{invoice?.description}</p>
            </div>
            <div className="info__item">
              <p className="label">End Date</p>
              <p className="value">{invoice?.endDate}</p>
            </div>
            <div className="info__item">
              <p className="label">Cost</p>
              <p className="value">{currencyFormatter.format(invoice?.cost)}</p>
            </div>
            <div className="info__item">
              <p className="label">Caution Fee</p>
              <p className="value">
                {currencyFormatter.format(
                  invoice?.caution ? invoice?.caution : 0
                )}
              </p>
            </div>
            <div className="info__item">
              <p className="label">Platform Fee</p>
              <p className="value">
                {currencyFormatter.format(invoice?.pepperestFees)}
              </p>
            </div>
          </div>
        </div>
        <div className="customer__details">
          <CustomerDetailCard
            customer={{
              customer_name: invoice?.buyer_name,
              buyerPhone: invoice?.buyerPhone,
              customerEmail: invoice?.buyerEmail,
            }}
          />
          <button
            className="button button--purple my-3"
            onClick={() => handleRequestPayment(invoice)}
            disabled={
              ["Canceled", "Completed", "Pending", "Confirmed"].includes(
                invoice?.status
              ) || processing
            }
          >
            Request Payment
          </button>
        </div>
      </section>
    </main>
  );
};

export default InvoiceDetail;
