import React from 'react'

const LoaderProcessing = () => {
  return (
    <div className="loader__processing">
    <div></div>
    <div></div>
    <div></div>
  </div>
  )
}

export default LoaderProcessing