import React, { useEffect } from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { deleteInvoice } from "store/actions/invoices";

const DeleteInvoice = ({ selectedInvoice, deleteModal, setDeleteModal }) => {
  const { processing, actionCompleted } = useSelector(
    (state) => state.invoices
  );
  const dispatch = useDispatch();

  const handleDeleteInvoice = () => {
    dispatch(deleteInvoice({ orderID: selectedInvoice.orderID }));
  };

  useEffect(() => {
    if (actionCompleted) {
      setDeleteModal(false);
    }
  }, [actionCompleted]);

  return (
    <ModalComponent show={deleteModal} centered size="md">
      <div className="modal__component">
        <div className="modal__title">Delete Invoice?</div>
        <div className="modal__content">
          <p>
            Are you sure you want to delete these invoice:{" "}
            <span className="fw-bold">{selectedInvoice?.orderRef}</span>?{" "}
          </p>
          <div className="modal__control">
            <button
              className="button button--purple-outline px-4"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="button button--purple px-4"
              onClick={handleDeleteInvoice}
              disabled={processing}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteInvoice;
