import React, { useEffect } from 'react'
import ChildMenuItem from "./ChildMenuItem";


function SubMenu({ subMenuItems,isActive,handleCloseSideBar }) {

    return (
      <div className={`sub__menu`}>
        {subMenuItems.map((item,index) => (
          <ChildMenuItem key={index} item={item} handleCloseSideBar={handleCloseSideBar} />
        ))}
      </div>
    );
  }

export default SubMenu