export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const REGISTER = "REGISTER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RESET_ERROR = "RESET_ERROR";

export const LOADING_PAYMENTS = "LOADING_PAYMENTS";
export const FINISHED_LOADING_PAYMENTS = "FINISHED_LOADING_PAYMENTS";
export const LOADING_PAYMENTS_FAILED = "LOADING_PAYMENTS_FAILED";

export const GET_PENDING_PAYMENTS = "GET_PENDING_PAYMENTS";
export const GET_PENDING_PAYMENTS_SUCCESS = "GET_PENDING_PAYMENTS_SUCCESS";
export const GET_PENDING_PAYMENTS_FAILED = "GET_PENDING_PAYMENTS_FAILED";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAILED = "GET_PAYMENTS_FAILED";

export const GET_STORE_PAYMENTS = "GET_STORE_PAYMENTS";
export const GET_STORE_PAYMENTS_SUCCESS = "GET_STORE_PAYMENTS_SUCCESS";
export const GET_STORE_PAYMENTS_FAILED = "GET_STORE_PAYMENTS_FAILED";

export const LOADING_PRODUCTS = "LOADING_PRODUCTS";
export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const FINISHED_LOADING_SINGLE_PRODUCTS =
  "FINISHED_LOADING_SINGLE_PRODUCTS";
export const FINISHED_LOADING_PRODUCTS = "FINISHED_LOADING_PRODUCTS";
export const LOADING_PRODUCTS_FAILED = "LOADING_PRODUCTS_FAILED";
export const UPDATE_STORE_PRODUCTS = "UPDATE_STORE_PRODUCTS";
export const HOLD_PRODUCT_VALUES_IN_STORE = "HOLD_PRODUCT_VALUES_IN_STORE";
export const LOADING_PRODUCT_FAIL = "LOADING_PRODUCT_FAIL";

export const LOADED_ALL_PRODUCTS = "LOADED_ALL_PRODUCTS";

export const PUBLISHING_SINGLE_PRODUCT = "PUBLISHING_SINGLE_PRODUCT";
export const PUBLISH_SINGLE_PRODUCT_FAIL = "PUBLISH_SINGLE_PRODUCT_FAIL";
export const PUBLISH_SINGLE_PRODUCT_SUCCESS = "PUBLISH_SINGLE_PRODUCT_SUCCESS";
export const PUBLISH_SINGLE_PRODUCT_COMPLETE =
  "PUBLISH_SINGLE_PRODUCT_COMPLETE";

export const DELETE_PRODUCT_COMPLETE = "DELETE_PRODUCT_COMPLETE";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_COMPLETE = "UPDATE_PRODUCT_COMPLETE";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const REPORT_ISSUE = "REPORT_ISSUE";
export const REPORT_ISSUE_SUCCESS = "REPORT_ISSUE_SUCCESS";
export const REPORT_ISSUE_FAIL = "REPORT_ISSUE_FAIL";

export const GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW";
export const GET_DASHBOARD_OVERVIEW_SUCCESS = "GET_DASHBOARD_OVERVIEW_SUCCESS";
export const GET_DASHBOARD_OVERVIEW_FAIL = "GET_DASHBOARD_OVERVIEW_FAIL";

export const GET_TOP_CUSTOMERS = "GET_TOP_CUSTOMERS";
export const GET_TOP_CUSTOMERS_SUCCESS = "GET_TOP_CUSTOMERS_SUCCESS";
export const GET_TOP_CUSTOMERS_FAIL = "GET_DASHBOARD_OVERVIEW_FAIL";

export const GET_RECENT_ORDERS = "GET_RECENT_ORDERS";
export const GET_RECENT_ORDERS_SUCCESS = "GET_RECENT_ORDERS_SUCCESS";
export const GET_RECENT_ORDERS_FAIL = "GET_RECENT_ORDERS_FAIL";

export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const GET_SALES_REPORTS_SUCCESS = "GET_SALES_REPORTS_SUCCESS";
export const GET_SALES_REPORT_FAIL = "GET_SALES_REPORT_FAIL";

export const LOADING_FACEBOOK_PAGES = "LOADING_FACEBOOK_PAGES";
export const FINISHED_LOADING_FACEBOOK_PAGES =
  "FINISHED_LOADING_FACEBOOK_PAGES";
export const LOADING_FACEBOOK_PAGES_FAILED = "LOADING_FACEBOOK_PAGES_FAILED";
export const SELECT_PAGE = "SELECT_PAGE";

export const LOADING_FACEBOOK_PRODUCTS = "LOADING_FACEBOOK_PRODUCTS";
export const FINISHED_LOADING_FACEBOOK_PRODUCTS =
  "FINISHED_LOADING_FACEBOOK_PRODUCTS";
export const LOADING_FACEBOOK_PRODUCTS_FAILED =
  "LOADING_FACEBOOK_PRODUCTS_FAILED";

export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const FINISHED_LOADING_CUSTOMERS = "FINISHED_LOADING_CUSTOMERS";
export const LOADING_CUSTOMERS_FAILED = "LOADING_CUSTOMERS_FAILED";

export const LOADING_MY_PURCHASES = "LOADING_MY_PURCHASES";
export const FINISHED_LOADING__MY_PURCHASES = "FINISHED_LOADING__MY_PURCHASES";
export const LOADING_MY_PURCHASES_FAILED = "LOADING_MY_PURCHASES_FAILED";

export const LOADING_ORDERS = "LOADING_ORDERS";
export const FINISHED_LOADING_ORDERS = "FINISHED_LOADING_ORDERS";
export const LOADING_ORDERS_FAILED = "LOADING_ORDERS_FAILED";
export const ORDER_REDIRECT = "ORDER_REDIRECT";
export const ORDER_PAYMENT_PROVIDER = "ORDER_PAYMENT_PROVIDER";
export const CART_ORDER = "CART_ORDER";
export const CLEAR_CART = "CLEAR_CART";
export const CONFIRM_LOADING = "CONFIRM_LOADING";
export const CONFIRM_THIRDPARTY_LOADING = "CONFIRM_THIRDPARTY_LOADING";
export const CONFIRM_ORDER_DETAILS = "CONFIRM_ORDER_DETAILS";

export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_COMPLETED = "CHANGE_ORDER_STATUS_COMPLETED";
export const CHANGE_ORDER_STATUS_FAILED = "CHANGE_ORDER_STATUS_FAILED";

export const CONFIRM_THIRDPARTY_ORDER_DETAILS =
  "CONFIRM_THIRDPARTY_ORDER_DETAILS";
export const SET_COURIER_SERVICE = "SET_COURIER_SERVICES_RATES";
export const VIEW_ORDER_DETAILS = "VIEW_ORDER_DETAILS";
export const VIEW_ORDER_DETAILS_SUCCESS = "VIEW_ORDER_DETAILS_SUCCESS";
export const VIEW_ORDER_DETAILS_FAIL = "VIEW_ORDER_DETAILS_FAIL";

export const LOADING_INVOICES = "LOADING_INVOICES";
export const FINISHED_LOADING_INVOICES = "FINISHED_LOADING_INVOICES";
export const LOADING_INVOICES_FAILED = "LOADING_INVOICES_FAILED";
export const PLACE_INVOICE_REDIRECT = "PLACE_INVOICE_REDIRECT";
export const CONFIRM_INVOICE_DETAILS = "CONFIRM_INVOICE_DETAILS";

export const LOAD_SINGLE_INVOICE = "LOAD_SINGLE_INVOICE";
export const LOADING_SINGLE_INVOICE = "LOADING_SINGLE_INVOICE";
export const FINISHED_LOADING_SINGLE_INVOICE =
  "FINISHED_LOADING_SINGLE_INVOICE";
export const CHANGE_INVOICE_STATUS = "CHANGE_INVOICE_STATUS";
export const LOADING_SINGLE_INVOICE_FAILED = "LOADING_SINGLE_INVOICE_FAILED";

export const LOAD_ADDRESS = "LOAD_ADDRESS";
export const SET_REFERRAL_STATS = "SET_REFERRAL_STATS";
export const LOAD_ADDRESS_ERROR = "LOAD_ADDRESS_ERROR";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const ADD_PICKUP_ADDRESS_START = "ADD_PICKUP_ADDRESS_START";
export const ADD_PICK_UP_ADDRESS = "ADD_PICK_UP_ADDRESS";
export const ADD_PICKUP_ADDRESS_FAIL = "ADD_PICKUP_ADDRESS_FAIL";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const UPDATING_PROFILE = "UPDATING_PROFILE";
export const UPDATED_PROFILE = "UPDATED_PROFILE";
export const FAILED_TO_UPDATE_PROFILE = "FAILED_TO_UPDATE_PROFILE";

export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  "RESEND_VERIFICATION_EMAIL_SUCCESS";
export const RESEND_VERIFICATION_EMAIL_FAIL = "RESEND_VERIFICATION_EMAIL_FAIL";

export const UPDATING_BANK_ACCOUNT = "UPDATING_BANK_ACCOUNT";
export const UPDATED_BANK_ACCOUNT = "UPDATED_BANK_ACCOUNT";
export const FAILED_TO_UPDATE_BANK_ACCOUNT = "FAILED_TO_UPDATE_BANK_ACCOUNT";

export const UPDATING_USER_TYPE = "UPDATING_USER_TYPE";
export const UPDATED_USER_TYPE = "UPDATED_USER_TYPE";
export const FAILED_TO_UPDATE_USER_TYPE = "FAILED_TO_UPDATE_USER_TYPE";

export const UPDATING_API_SETTING = "UPDATING_API_SETTING";
export const UPDATED_API_SETTING = "UPDATED_API_SETTING";
export const FAILED_TO_UPDATE_API_SETTING = "FAILED_TO_UPDATE_API_SETTING";

export const UPDATING_NOTIFICATION_SETTING = "UPDATING_NOTIFICATION_SETTING";
export const UPDATED_NOTIFICATION_SETTING = "UPDATED_NOTIFICATION_SETTING";
export const FAILED_TO_UPDATE_NOTIFICATION_SETTING =
  "FAILED_TO_UPDATE_NOTIFICATION_SETTING";

export const UPDATING_PASSWORD = "UPDATING_PASSWORD";
export const UPDATED_PASSWORD = "UPDATED_PASSWORD";
export const FAILED_TO_UPDATE_PASSWORD = "FAILED_TO_UPDATE_PASSWORD";

export const FINISHED_LOADING_CART = "FINISHED_LOADING_CART";
export const LOADING_CART = "LOADING_CART";
export const FAILED_LOADING_CART = "FAILED_LOADING_CART";
export const ADD_NO_USER_CART = "ADD_NO_USER_CART";

export const LOADING_MERCHANT_PAGE = "LOADING_MERCHANT_PAGE";
export const LOADED_MERCHANT_PAGE = "LOADED_MERCHANT_PAGE";
export const FAILED_LOADING_MERCHANT_PAGE = "FAILED_LOADING_MERCHANT_PAGE";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const CONNECT_SOCIAL_STORE_STATUS = "CONNECT_SOCIAL_STORE_STATUS";

export const UPDATE_SOCIAL_LINK = "UPDATE_SOCIAL_LINK";
export const UPDATE_SOCIAL_LINK_FAIL = "UPDATE_SOCIAL_LINK_FAIL";
export const UPDATE_SOCIAL_LINK_IN_STORE = "UPDATE_SOCIAL_LINK_IN_STORE";
export const REMOVE_SOCIAL_MEDIA_LINK_IN_STORE =
  "REMOVE_SOCIAL_MEDIA_LINK_IN_STORE";

export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";

export const UPGRADE_PREMIUM = "UPGRADE_PREMIUM";
export const PREMIUM_REDIRECT = "PREMIUM_REDIRECT";
export const UPGRADE_PREMIUM_FAIL = "UPGRADE_PREMIUM_FAIL";

export const CONFIRM_PREMIUM_PAYMENT = "CONFIRM_PREMIUM_PAYMENT";
export const CONFIRM_PREMIUM_PAYMENT_SUCCESS =
  "CONFIRM_PREMIUM_PAYMENT_SUCCESS";
export const CONFIRM_PREMIUM_PAYMENT_FAIL = "CONFIRM_PREMIUM_PAYMENT_FAIL";

export const SETUP_STORE = "SETUP_STORE";
export const SETUP_STORE_SUCCESS = "SETUP_STORE_SUCCESS";
export const SETUP_STORE_FAIL = "SETUP_STORE_FAIL";
export const SETUP_STORE_COMPLETE = "SETUP_STORE_COMPLETE";

export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAIL = "GET_PRODUCT_CATEGORIES_FAIL";

export const GET_PRODUCT_WEIGHT_UNIT = "GET_PRODUCT_WEIGHT_UNIT";
export const GET_PRODUCT_WEIGHT_UNIT_SUCCESS =
  "GET_PRODUCT_WEIGHT_UNIT_SUCCESS";
export const GET_PRODUCT_WEIGHT_UNIT_FAIL = "GET_PRODUCT_WEIGHT_UNIT_FAIL";

export const GET_ACCOUNT_NAME = "GET_ACCOUNT_NAME";
export const GET_ACCOUNT_NAME_SUCCESS = "GET_ACCOUNT_NAME_SUCCESS";
export const GET_ACCOUNT_NAME_FAIL = "GET_ACCOUNT_NAME_FAIL";
export const GET_ACCOUNT_NAME_COMPLETE = "GET_ACCOUNT_NAME_COMPLETE";

export const LOAD_MARKETPLACE_STORES = "LOAD_MARKETPLACE_STORES";
export const LOAD_MARKETPLACE_STORES_SUCCESS =
  "LOAD_MARKETPLACE_STORES_SUCCESS";
export const LOAD_MARKETPLACE_STORES_FAIL = "LOAD_MARKETPLACE_STORES_FAIL";
export const GET_TOPSELLING_CATEGORY = "GET_TOPSELLING_CATEGORY";
export const GET_TOPSELLING_CATEGORY_SUCCESS =
  "GET_TOPSELLING_CATEGORY_SUCCESS";
export const GET_TOPSELLING_CATEGORY_FAIL = "GET_TOPSELLING_CATEGORY_FAIL";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const GET_STORE_BY_CATEGORY = "GET_STORE_BY_CATEGORY";
export const GET_STORE_BY_CATEGORY_SUCCESS = "GET_STORE_BY_CATEGORY_SUCCESS";
export const GET_STORE_BY_CATEGORY_FAIL = "GET_STORE_BY_CATEGORY_FAIL";

export const GET_TOP_DEAL = "GET_TOP_DEAL";
export const GET_TOP_DEAL_SUCCESS = "GET_TOP_DEAL_SUCCESS";
export const GET_TOP_DEAL_FAIL = "GET_TOP_DEAL_FAIL";

export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAIL = "GET_STORES_FAIL";

export const GET_FEATURED_STORE = "GET_FEATURED_STORE";
export const GET_FEATURED_STORE_SUCCESS = "GET_FEATURED_STORE_SUCCESS";
export const GET_FEATURED_STORE_FAIL = "GET_FEATURED_STORE_FAIL";

export const SEARCH_IN_STORE = "SEARCH_IN_STORE";

export const GET_CATEGORIZED_STORE_SUCCESS = "GET_CATEGORIZED_STORE_SUCCESS";

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL";

export const PRECHECHOUT_START = "PRECHECHOUT_START";
export const PRECHECKOUT_SUCCESS = "PRECHECKOUT_SUCCESS";
export const PRECHECKOUT_FAIL = "PRECHECKOUT_FAIL";

export const GET_TODAY_DEAL = "GET_TODAY_DEAL";
export const GET_TODAY_DEAL_SUCCESS = "GET_TODAY_DEAL_SUCCESS";
export const GET_TODAY_DEAL_FAIL = "GET_TODAY_DEAL_FAIL";

export const SEARCH_MARKETPLACE = "SEARCH_MARKETPLACE";
export const SEARCH_MARKETPLACE_SUCCESS = "SEARCH_MARKETPLACE_SUCCESS";
export const SEARCH_MARKETPLACE_FAIL = "SEARCH_MARKETPLACE_FAIL";

export const GET_INCOMING_PAYMENT = "GET_INCOMING_PAYMENT";
export const GET_INCOMING_PAYMENT_SUCCESS = "GET_INCOMING_PAYMENT_SUCCESS";
export const GET_INCOMING_PAYMENT_FAIL = "GET_INCOMING_PAYMENT_FAIL";

export const CONFIRM_INCOMING_PAYMENT = "ACCEPT_INCOMING_PAYMENT";
export const CONFIRM_INCOMING_PAYMENT_SUCCESS =
  "CONFIRM_INCOMING_PAYMENT_SUCCESS";
export const CONFIRM_INCOMING_PAYMENT_FAIL = "CONFIRM_INCOMING_PAYMENT_FAIL";

export const TRACK_INCOMING_PAYMENT = "TRACK_INCOMING_PAYMENT";
export const TRACK_INCOMING_PAYMENT_SUCCESS = "TRACK_INCOMING_PAYMENT_SUCCESS";
export const TRACK_INCOMING_PAYMENT_FAIL = "TRACK_INCOMING_PAYMENT_FAIL";

export const UNSUBSCRIBE_PREMIUM = "UNSUBSCRIBE_PREMIUM";
export const UNSUBSCRIBE_PREMIUM_SUCCESS = "UNSUBSCRIBE_PREMIUM_SUCCESS";
export const UNSUBSCRIBE_PREMIUM_FAIL = "UNSUBSCRIBE_PREMIUM_FAIL";

export const GET_BILLING_HISTORY = "GET_BILLING_HISTORY";
export const GET_BILLING_HISTORY_SUCCESS = "GET_BILLING_HISTORY_SUCCESS";
export const GET_BILLING_HISTORY_FAIL = "GET_BILLING_HISTORY_FAIL";

export const PUBLISH_SOCIAL_PRODUCT = "PUBLISH_SOCIAL_PRODUCT";
export const PUBLISH_SOCIAL_PRODUCT_FAIL = "PUBLISH_SOCIAL_PRODUCT_FAIL";
export const PUBLISH_SOCIAL_PRODUCT_SUCCESS = "PUBLISH_SOCIAL_PRODUCT_SUCCESS";
export const PUBLISH_SOCIAL_PRODUCT_COMPLETE =
  "PUBLISH_SOCIAL_PRODUCT_COMPLETE";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";

export const INITIATE_VENDOR_AUTH_START = "INITIATE_VENDOR_AUTH_START";
export const INITIATE_VENDOR_AUTH_SUCCESS = "INITIATE_VENDOR_AUTH_SUCCESS";
export const INITIATE_VENDOR_AUTH_FAIL = "INITIATE_VENDOR_AUTH_FAIL";
export const INITIATE_VENDOR_AUTH_COMPLETE = "INITIATE_VENDOR_AUTH_COMPLETE";

export const ADD_PRODUCT_DISCOUNT = "ADD_PRODUCT_DISCOUNT";
export const ADD_PRODUCT_DISCOUNT_SUCCESS = "ADD_PRODUCT_DISCOUNT_SUCCESS";
export const ADD_PRODUCT_DISCOUNT_FAIL = "ADD_PRODUCT_DISCOUNT_FAIL";
export const ADD_PRODUCT_DISCOUNT_COMPLETE = "ADD_PRODUCT_DISCOUNT_COMPLETE";

export const GET_PRODUCT_DISCOUNTS = "GET_PRODUCT_DISCOUNT";
export const GET_PRODUCT_DISCOUNTS_SUCCESS = "GET_PRODUCT_DISCOUNTS_SUCCESS";
export const GET_PRODUCT_DISCOUNTS_FAIL = "GET_PRODUCT_DISCOUNTS_FAIL";

export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_FAIL = "DELETE_DISCOUNT_FAIL";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAIL = "CREATE_INVOICE_FAIL";
export const CREATE_INVOICE_COMPLETE = "CREATE_INVOICE_COMPLETE";

export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";

export const CHANGE_INVOICE_STATUS_START = "CHANGE_INVOICE_STATUS_START";
export const CHANGE_INVOICE_STATUS_SUCCESS = "CHANGE_INVOICE_STATUS_SUCCESS";
export const CHANGE_INVOICE_STATUS_FAIL = "CHANGE_INVOICE_STATUS_FAIL";

export const UPDATING_INVOICE = "UPDATING_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAIL = "UPDATE_INVOICE_FAIL";
export const UPDATE_INVOICE_COMPLETE = "UPDATE_INVOICE_COMPLETE";

export const VERIFY_INVOICE_PAYMENT = "VERIFY_INVOICE_PAYMENT";
export const VERIFY_INVOICE_PAYMENT_SUCCESS = "VERIFY_INVOICE_PAYMENT_SUCCESS";
export const VERIFY_INVOICE_PAYMENT_FAIL = "VERIFY_INVOICE_PAYMENT_FAIL";

export const DECLINE_INCOMING_PAYMENT = "DECLINE_INCOMING_PAYMENT";
export const DECLINE_INCOMING_PAYMENT_SUCCESS =
  "DECLINE_INCOMING_PAYMENT_SUCCESS";
export const DECLINE_INCOMING_PAYMENT_FAIL = "DECLINE_INCOMING_PAYMENT_FAIL";
export const CUSTOMIZING_STORE = "CUSTOMIZING_STORE";
export const CUSTOMIZE_STORE_SUCCESS = "CUSTOMIZE_STORE_SUCCESS";
export const CUSTOMIZE_STORE_FAIL = "CUSTOMIZE_STORE_FAIL";
export const CUSTOMIZE_STORE_COMPLETE = "CUSTOMIZE_STORE_COMPLETE";

export const DOWNLOAD_SALES_REPORT = "DOWNLOAD_SALES_REPORT";
export const DOWNLOAD_SALES_REPORT_SUCCESS = "DOWNLOAD_SALES_REPORT_SUCCESS";
export const DOWNLOAD_SALES_REPORT_FAIL = "DOWNLOAD_SALES_REPORT_FAIL";
export const DOWNLOAD_SALES_REPORT_COMPLETE = "DOWNLOAD_SALES_REPORT_COMPLETE";

export const UPLOAD_INVOICE_PROOF = "UPLOAD_INVOICE_PROOF";
export const UPLOAD_INVOICE_PROOF_SUCCESS = "UPLOAD_INVOICE_PROOF_SUCCESS";
export const UPLOAD_INVOICE_PROOF_FAIL = "UPLOAD_INVOICE_PROOF_FAIL";
export const UPLOAD_INVOICE_PROOF_COMPLETE = "UPLOAD_INVOICE_PROOF_COMPLETE";

export const REQUEST_INVOICE_PAYMENT = "REQUEST_INVOICE_PAYMENT";
export const REQUEST_INVOICE_PAYMENT_SUCCESS =
  "REQUEST_INVOICE_PAYMENT_SUCCESS";
export const REQUEST_INVOICE_PAYMENT_FAIL = "REQUEST_INVOICE_PAYMENT_FAIL";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAIL = "DELETE_INVOICE_FAIL";

export const GET_PRICING = "GET_PRICING";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export const GET_PRICING_FAIL = "GET_PRICING_FAIL";

export const GET_MY_CHATS = "GET_MY_CHATS";
export const GET_MY_CHATS_SUCCESS = "GET_MY_CHATS_SUCCESS";
export const GET_MY_CHATS_FAIL = "GET_MY_CHATS_FAIL";
export const LOADING_MY_CHATS = "LOADING_MY_CHATS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";
export const SEND_MESSAGE_COMPLETE = "SEND_MESSAGE_COMPLETE";

export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_FAIL = "GET_CHAT_MESSAGES_FAIL";
export const LOADING_CHAT_MESSAGES = "LOADING_CHAT_MESSAGES";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";
export const ADD_COUPON_COMPLETE = "ADD_COUPON_COMPLETE";

export const WITHDRAW_FUND = "WITHDRAW_FUND";
export const WITHDRAW_FUND_SUCCESS = "WITHDRAW_FUND_SUCCESS";
export const WITHDRAW_FUND_FAIL = "WITHDRAW_FUND_FAIL";
export const WITHDRAW_FUND_COMPLETE = "WITHDRAW_FUND_COMPLETE";

export const GET_WALLET_HISTORY = "GET_WALLET_HISTORY";
export const GET_WALLET_HISTORY_SUCCESS = "GET_WALLET_HISTORY_SUCCESS";
export const GET_WALLET_HISTORY_FAIL = "GET_WALLET_HISTORY_FAIL";
export const LOAD_WALLET_HISTORY = "LOAD_WALLET_HISTORY";

export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";
export const LOAD_WALLET = "LOAD_WALLET";

export const ADD_TRANSACTION_PIN = "ADD_TRANSACTION_PIN";
export const ADD_TRANSACTION_PIN_SUCCESS = "ADD_TRANSACTION_PIN_SUCCESS";
export const ADD_TRANSACTION_PIN_FAIL = "ADD_TRANSACTION_PIN_FAIL";
export const ADD_TRANSACTION_PIN_COMPLETE = "ADD_TRANSACTION_PIN_COMPLETE";

export const VALIDATE_TRANSACTION_PIN = "VALIDATE_TRANSACTION_PIN";
export const VALIDATE_TRANSACTION_PIN_SUCCESS =
  "VALIDATE_TRANSACTION_PIN_SUCCESS";
export const VALIDATE_TRANSACTION_PIN_FAIL = "VALIDATE_TRANSACTION_PIN_FAIL";
export const VALIDATE_TRANSACTION_PIN_COMPLETE = "VALIDATE_TRANSACTION_PIN_COMPLETE";

export const TOGGLE_PRODUCT_STOCK = "TOGGLE_PRODUCT_STOCK";
export const TOGGLE_PRODUCT_STOCK_SUCCESS = "TOGGLE_PRODUCT_STOCK_SUCCESS";
export const TOGGLE_PRODUCT_STOCK_FAIL = "TOGGLE_PRODUCT_STOCK_FAIL";

export const RAISE_DISPUTE = "RAISE_DISPUTE";
export const RAISE_DISPUTE_SUCCESS = "RAISE_DISPUTE_SUCCESS";
export const RAISE_DISPUTE_FAIL = "RAISE_DISPUTE_FAIL";
export const RAISE_DISPUTE_COMPLETE = "RAISE_DISPUTE_COMPLETE";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";
export const CANCEL_ORDER_COMPLETE = "CANCEL_ORDER_COMPLETE";

export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";



export const RAISE_WALLET_ISSUE = "RAISE_WALLET_ISSUE";
export const RAISE_WALLET_ISSUE_SUCCESS = "RAISE_WALLET_ISSUE_SUCCESS";
export const RAISE_WALLET_ISSUE_FAIL = "RAISE_WALLET_ISSUE_FAIL";
export const RAISE_WALLET_ISSUE_COMPLETE = "RAISE_WALLET_ISSUE_COMPLETE";
