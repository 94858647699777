import InputField from "components/blocks/InputField";
import UploadIcon from "components/vectors/UploadIcon";
import { FileUploader } from "react-drag-drop-files";
import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputGroup from "components/blocks/InputGroup";
import { MdFileUpload } from "react-icons/md";
import {
  createInvoice,
  createInvoiceComplete,
  getSingleInvoice,
  updateInvoice,
  updateInvoiceComplete,
} from "store/actions/invoices";
import { Loader } from "components/blocks";
import BreadCrumb from "components/shared/BreadCrumb";
import { LoaderProcessing } from "components/shared";
import { formatInvoiceDate } from "libs/utils";

const EditInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { invoice, success, loading, updating } = useSelector(
    (state) => state.invoices
  );
  const [files, setFiles] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [minDate, setMinDate] = useState("");
  const fileTypes = ["png", "jpg", "jpeg", "pdf", "doc", "docx"];
  const params = useParams();
  const { orderID } = params;
  const crumbs = [
    {
      name: "Back to Invoices",
      path: "/dashboard/invoices",
    },
    {
      name: "Edit Invoice",
    },
  ];

  const storeSchema = yup.object().shape({
    buyer_name: yup.string().required("Buyer name is required"),
    description: yup.string().required("Description is required"),
    cost: yup
      .number()
      .typeError("Provide a valid cost")
      .min(1)
      .required("Cost is required"),
    caution_fee: yup
      .number()
      .typeError("Must be numeric.")
      .integer("Error message")
      .min(1, "Minimum 1")
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .optional(),
    buyer_email: yup
      .string()
      .email("Invalid email")
      .required("Buyer email is required"),
    buyer_phone: yup.string().required("Buyer Phone is required"),
    start_date: yup.string(),
    end_date: yup.string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(storeSchema),
    mode: "all",
  });

  const handleChange = useCallback(
    (files) => {
      if (files) {
        setFiles(files);
        console.log({ files });
      }
    },
    [files]
  );

  const submit = (data) => {
    data.orderID = orderID;
    let formData = new FormData();
    if (files) {
      const docs = Array.from(files);
      docs.map((item, index) => {
        formData.append(`order_docs[${index}]`, item);
      });
      console.log({ files, docs });
    }

    for (const key in data) {
      formData.append(key, data[key]);
    }
    dispatch(updateInvoice(formData));
  };
  useEffect(() => {
    const getInvoice = (id) => {
      dispatch(getSingleInvoice(id));
    };
    getInvoice(orderID);
  }, [orderID]);

  useEffect(() => {
    // Get the current date in the format "YYYY-MM-DD"
    const currentDate = new Date().toISOString().slice(0, 10);
    setMinDate(currentDate);
    if (invoice) {
      reset({
        buyer_name: invoice?.buyerName,
        description: invoice?.description,
        buyer_email: invoice.buyerEmail,
        cost: invoice?.cost,
        start_date: formatInvoiceDate(invoice?.startDate),
        end_date: formatInvoiceDate(invoice?.endDate),
        status: invoice?.status,
        buyer_phone: invoice?.buyerPhone,
        caution_fee: invoice?.caution_fee,
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (success) {
      dispatch(updateInvoiceComplete());
      history.push("/dashboard/invoices");
    }
  }, [success]);

  return (
    <div className="invoices">
      {loading ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : (
        <>
          <BreadCrumb crumbs={crumbs} />
          <p className="page__title">Update Invoice</p>
          <form onSubmit={handleSubmit(submit)}>
            <div className="row row__wrapper">
              <div className="col">
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="buyername"
                    label="Buyer Name"
                    name="buyer_name"
                    placeHolder="Buyer Name"
                    register={register}
                    error={errors.buyer_name?.message}
                    type="text"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="buyeremail"
                    label="Buyer Email"
                    name="buyer_email"
                    placeHolder="Buyer Email"
                    register={register}
                    error={errors.buyer_email?.message}
                    type="email"
                  />
                </div>
              </div>
            </div>

            <div className="row row__wrapper">
              <div className="col">
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="buyer_phone"
                    label="Buyer Phone"
                    name="buyer_phone"
                    placeHolder="Buyer Phone"
                    register={register}
                    error={errors.buyer_phone?.message}
                    type="tel"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form__wrapper">
                  <InputGroup
                    require={true}
                    id="cost"
                    label="Cost"
                    name="cost"
                    placeholder="1000"
                    register={register}
                    error={errors.cost?.message}
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="row row__wrapper">
              <div className="col">
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="description"
                    label="Description"
                    name="description"
                    placeHolder="Invoice Description"
                    register={register}
                    error={errors.description?.message}
                    type="textarea"
                  />
                </div>
              </div>
              <div className="col">
                <div>
                  <label className="form__label">Invoice Documents</label>
                </div>
                <section className="file__upload__wrapper">
                  <div
                    className={`form__wrapper border__wrapper ${
                      imageError ? "border-danger" : ""
                    }`}
                  >
                    <FileUploader
                      handleChange={handleChange}
                      multiple={true}
                      types={fileTypes}
                    >
                      <div className="file__uploader__wrapper ">
                        <MdFileUpload size={30} />
                        <div>
                          <p className="title">
                            Drag and drop files here or{" "}
                            <span className="upload__button">
                              browse on your computer
                            </span>
                          </p>
                        </div>
                      </div>
                    </FileUploader>
                  </div>
                </section>
                <p className="text__primary">
                  {files && <>You have selected {files.length} file(s)</>}
                </p>
              </div>
            </div>

            <section className="row row__wrapper">
              <div className="col">
                <div className="form__wrapper ">
                  <InputField
                    require={true}
                    id="startdate"
                    label="Start Date"
                    name="start_date"
                    register={register}
                    error={errors.start_date?.message}
                    type="date"
                    min={minDate}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form__wrapper">
                  <InputField
                    require={true}
                    id="enddate"
                    label="End Date"
                    name="end_date"
                    register={register}
                    error={errors.end_date?.message}
                    type="date"
                    min={minDate}
                  />
                </div>
              </div>
            </section>
            <div className="row row__wrapper">
              <div className="col-md-6 col-12">
                <div className="form__wrapper">
                  <InputGroup
                    id="caution_fee"
                    label="Caution Fee"
                    name="caution_fee"
                    placeholder="1000"
                    register={register}
                    error={errors.caution_fee?.message}
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-md-6">
                <button
                  className="button button--purple mt-4"
                  disabled={updating}
                  type="submit"
                >
                  {updating ? <Loader /> : <>Update Invoice</>}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditInvoice;
