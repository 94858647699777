import React, { lazy } from "react";
import "../buyer-assets/css/index.css";

const Home = lazy(() => import("./home"));
const Product = lazy(() => import("./product"));
const Confirmation = lazy(() => import("./confirmation"));
const Account = lazy(() => import("./account"));
const Cart = lazy(() => import("./cart"));
const Payment = lazy(() => import("./payment"));
const Tracker = lazy(() => import("./tracker"));
const Checkout = lazy(() => import("./checkout"));
const BankTransfer = lazy(() => import("./bank-transfer"));
const ConfirmTransfer = lazy(() => import("./confirm-transfer"));
const Category = lazy(() => import("./category"));
const ProductsCanonical = lazy(() => import("./products-canonical"));
const ConfirmInvoicePayment = lazy(() => import("./confirm-invoice-pyament"));
const WishList = lazy(() => import("./wish-list"));
const WishListCart = lazy(() => import("./wishlist-cart"));
const WishListCheckout = lazy(() => import("./wishlist-checkout"));
const WishListPayment = lazy(() => import("./wishlist-payment"));
const SantaSales = lazy(() => import("./santa-sales"));

const RateStore = lazy(() => import("./rate-store"));
const RateProducts = lazy(() => import("./rate-products"));
const ProductsReviewSumamary = lazy(() => import("./product-review-summary"));
const StoreReviewSummary = lazy(() => import("./store-review-summary"));


// const OrderDetails = lazy(() => import("./orderDetails"));

export {
  Home,
  Product,
  Confirmation,
  Account,
  Payment,
  Tracker,
  Cart,
  Checkout,
  BankTransfer,
  ConfirmInvoicePayment,
  ConfirmTransfer,
  Category,
  ProductsCanonical,
  WishList,
  WishListCart,
  WishListCheckout,
  WishListPayment,
  SantaSales,
  RateStore,
  RateProducts,
  ProductsReviewSumamary,
  StoreReviewSummary,
};
