import { generateKey } from 'libs/utils';
import React  from 'react'
import { useEffect } from 'react';
const TagsInput = props => {
	const [tags, setTags] = React.useState(props.tags);

	const removeTags = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};
	
	
   
	const addTags = event => {
        const {value} = event.target;
		
		if (value !== "" ) {
			setTags([...tags, value]);
            props.setIndex();
			props.selectedTags([...tags,value]);
			event.target.value = "";
		}
	};

	useEffect(()=>{
		setTags(tags)
	},[tags])

	return (
		<div className="tags-input">
			<ul id="tags">
				{tags.map((tag, index) => (
					<li key={generateKey()} className="tag">
						<span className='tag-title'>{tag}</span>
						<span className='tag-close-icon'
							onClick={() => removeTags(index)}
						>
							x
						</span>
					</li>
				))}
			</ul>
			<input
            
				type="text"
				onKeyUp={event => event.key === "Enter" ? addTags(event) : null
				}
				placeholder="e.g 44"
			/>
		</div>
	);
};
export default TagsInput