import WelcomeHeader from 'components/dashboardComponent/WelcomeHeader'
import DashboardLayout from 'components/layouts/dashboardLayout'
import React from 'react'

const Customers = () => {
  return (
    <main className='customers'>
        <WelcomeHeader/>
        <div className='coming__soon'>coming soon</div>
    </main>
  )
}

export default Customers