import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
/**
 * @description generates hash value for a provided value
 * @link https://stackoverflow.com/a/8831937/4398075
 * @param stringValue
 * @returns {number}
 */
// export const getStringHash = (stringValue) => {
//   let hash = 0;
//   if (stringValue.length === 0) {
//     return hash;
//   }
//   for (let i = 0; i < stringValue.length; i++) {
//     const char = stringValue.charCodeAt(i);
//     hash = (hash << 5) - hash + char;
//     hash &= hash; // Convert to 32bit integer
//   }
//   return hash;
// };

export const getStringHash = (stringValue = "") =>
  uuidv4()
    .match(/(\w?\d)/g)
    .join("")
    .substring(0, 16);

export const getAvatarSize = (size) => {
  switch (size) {
    case "xs":
      return "avatar-xs";
    case "md":
      return "avatar-md";
    default:
      return " ";
  }
};

export const getStatusTextClass = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "text--pending";
    case "closed":
      return "text--closed";
    case "open":
      return "text--open";
    case "awaiting":
      return "text--awaiting";
    case "flagged":
    case "canceled":
      return "text--flagged";
    case "refund":
      return "text--refund";
    case "initial":
      return "text--initial";
    case "release":
    case "shipped":
      return "text--release";
    case "confirmed":
      return "text--release";
    case "paid":
      return "text--release";
    case "stop":
      return "text--stop";
    case "fulfilled":
    case "delivered":
      return "text--fulfilled";
    case "active":
      return "text--active";
    case "blocked":
      return "text--blocked";
    case "processing":
      return "text--initial";
    case "in transit":
      return "text--open";
    default:
      return "text--pending";
  }
};

export const getIndicatorClass = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "list-item__indicator-pending";
    case "closed":
      return "list-item__indicator-closed";
    case "open":
      return "list-item__indicator-open";
    case "awaiting":
      return "list-item__indicator-awaiting";
    case "flagged":
    case "canceled":
      return "list-item__indicator-flagged";
    case "refund":
      return "list-item__indicator-refund";
    case "initial":
      return "list-item__indicator-initial";
    case "release":
    case "shipped":
      return "list-item__indicator-release";
    case "confirmed":
      return "list-item__indicator-release";
    case "paid":
      return "list-item__indicator-release";
    case "stop":
      return "list-item__indicator-stop";
    case "fulfilled":
    case "delivered":
      return "list-item__indicator-fulfilled";
    case "active":
      return "list-item__indicator-active";
    case "blocked":
      return "list-item__indicator-blocked";
    case "processing":
      return "list-item__indicator-initial";
    case "in transit":
      return "list-item__indicator-open";
    default:
      return "list-item__indicator-pending";
  }
};

export const getStatusTagClass = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "list-item__status-tag--pending";
    case "closed":
      return "list-item__status-tag--closed";
    case "open":
      return "list-item__status-tag--open";
    case "awaiting":
      return "list-item__status-tag--awaiting";
    case "flagged":
    case "canceled":
      return "list-item__status-tag--flagged";
    case "refund":
      return "list-item__status-tag--refund";
    case "initial":
      return "list-item__status-tag--initial";
    case "release":
    case "shipped":
      return "list-item__status-tag--release";
    case "confirmed":
      return "list-item__status-tag--release";
    case "paid":
      return "list-item__status-tag--release";
    case "stop":
      return "list-item__status-tag--stop";
    case "fulfilled":
    case "delivered":
      return "list-item__status-tag--fulfilled";
    case "active":
      return "list-item__status-tag--active";
    case "blocked":
      return "list-item__status-tag--blocked";
    case "processing":
      return "list-item__status-tag--initial";
    case "in transit":
      return "list-item__status-tag--open";
    default:
      return "list-item__status-tag--pending";
  }
};

export const getStatusText = (status) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "Pending";
    case "closed":
      return "Closed";
    case "open":
      return "Open";
    case "awaiting":
      return "Awaiting Payment";
    case "flagged":
      return "Flagged";
    case "refund":
      return "Refund";
    case "payment initiated":
      return "Payment Initiated";
    case "initial":
      return "Initial Payment";
    case "release":
      return "Release Payment";
    case "stop":
      return "Stop Payment";
    case "fulfilled":
      return "Fulfilled";
    case "active":
      return "Active";
    case "blocked":
      return "Blocked";
    case "processing":
      return "Processing";
    case "in transit":
      return "In Transit";
    case "delivered":
      return "Delivered";
    case "canceled":
      return "Canceled";
    case "shipped":
      return "Shipped";
    case "confirmed":
      return "Confirmed";
    case "paid":
      return "Paid";
    default:
      return "";
  }
};

export const getCamelCase = (value) => {
  const char = value.charAt(0).toUpperCase();
  const restOfString = value.substring(1, value.length);
  return `${char}${restOfString}`;
};
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "NGN",
  style: "currency",
  minimumFractionDigits: 0,
  signDisplay: "auto",
  currencyDisplay: "symbol",
});
export const notify = (message, type) => {
  const customId = "custom-id-yes";
  toast(message, {
    toastId: customId,
    type,
  });
};
export const handleCopy = (text, message = "Copy successful") => {
  navigator.clipboard.writeText(text);
  notify(message, "success");
};

export const formatExpiryDate = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(/\//g, "").substring(0, 2) +
    (expdate.length > 2 ? "/" : "") +
    expdate.replace(/\//g, "").substring(2, 4);

  return expDateFormatter;
};

export const formatCardNumber = (value) => {
  const v = value.replace(/[^0-9]/gi, "").substr(0, 16);

  const parts = [];
  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }
  return parts.length > 1 ? parts.join(" ") : value;
};

export const setCookie = (name, value, option) => {
  Cookies.set(name, value, option);
};

export const getCookie = (name) => {
  const value = Cookies.get(name);
  return value;
};

export const formatDate = (date) => {
  return date?.toISOString().slice(0, 10);
};

export const shareOnFacebook = (url) => {
  window.FB.ui(
    {
      method: "share",
      href: url, // Replace with the URL you want to share
    },
    function (response) {}
  );
};

export const shareOnInstagram = () => {
  const url = "https://www.instagram.com/";
  const caption = encodeURIComponent("Check out this amazing photo!"); // Replace with your desired caption
  const imageUrl = encodeURIComponent("https://example.com/image.jpg"); // Replace with the URL of the image you want to share
  const redirectUrl = `${url}create/story/?caption=${caption}&url=${imageUrl}`;

  window.location.href = redirectUrl;
};

export const shareOnTwitter = (message, url) => {
  const location = encodeURIComponent(url); // Replace with the URL you want to share
  const shareUrl = `https://twitter.com/intent/tweet?text=${message}&url=${location}`;

  window.open(shareUrl, "_blank");
};

export const shareOnWhatsApp = (message) => {
  // Replace with the message and URL you want to share
  const shareUrl = `whatsapp://send?text=${encodeURIComponent(message)}`;

  window.open(shareUrl, "_blank");
};

export const formatVariant = (data) => {
  const dataArray = Object.entries(JSON.parse(data)).map(
    ([key, value]) => value
  );
  return dataArray;
};
export const generateKey = () => {
  const min = 1;
  const max = 9999;
  let key = Math.floor(Math.random() * (max - min + 1)) + min;
  return key;
};

export const getFreeTrialDate = (days) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  let currentTime = new Date();
  currentTime.setDate(currentTime.getDate() + days);
  return currentTime.toLocaleDateString(undefined, options);
};

export const getFreeTrialExpireDate = (date, days) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const parts = date?.split("-");
  if (parts) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1] - 1, 10); // JavaScript months are 0-indexed
    const year = parseInt(parts[2], 10);
    let currentTime = new Date(year, month, day);
    currentTime.setDate(currentTime.getDate() + days);
    return currentTime.toLocaleDateString();
  } else {
    return null;
  }
};

export function hasEmptyProperties(array) {
  const hasEmptyProperty = array.some((item) => {
    const propertyValues = Object.values(item);
    console.log({ propertyValues });
    return propertyValues.some((value) => !value || value.length == 0);
  });

  return hasEmptyProperty;
}

export function formatDateString(inputDate) {
  // Parse the input date string into a Date object
  const dateObject = new Date(inputDate);

  // Extract year, month, and day from the Date object
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const day = String(dateObject.getDate()).padStart(2, "0");

  // Format the date as "yyyy-MM-dd"
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const formatInvoiceDate = (dateString) => {
  console.log({ dateString });
  const [month, day, year] = dateString.split("-");
  const convertedDateStr = `${year}-${day.padStart(2, 0)}-${month.padStart(
    2,
    0
  )}`;

  return convertedDateStr;
};

export const handleDownload = (filename, url) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = url;
  link.click();
};

export function generateCouponCode() {
  const letters = Array.from({ length: 3 }, () =>
    String.fromCharCode(Math.floor(Math.random() * 26) + 65)
  ).join("");

  const numbers = Array.from({ length: 3 }, () =>
    Math.floor(Math.random() * 10)
  ).join("");

  const result = `${letters}-${numbers}`;

  return result;
}
export const checkOrderStatus = (statuses, orderStatus) => {
  return [
    "Completed",
    "In Progress",
    "Confirmed",
    "Canceled",
    "Shipped",
  ].includes(orderStatus)
    ? statuses.filter((item) => item.status !== "Cancel")
    : statuses;
};

export { default as PepperestAxios } from "./PepperestAxios";
