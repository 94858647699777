/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { NavLink } from "components/blocks";
import { Avatar } from "components/shared";
import { DownChevron, HamburgerIcon } from "components/vectors";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mixPanelUserProperty } from "libs/mixPanel";
import { PepperestWebServices } from "libs/constants";

const navLinks = [
  {
    title: "My Orders",
    url: "/orders",
    exact: false,
  },
  
  {
    title: "My Customers",
    url: "/customers",
    exact: false,
  },
  {
    title: "My Catalog",
    url: "/products",
    exact: false,
  },
  {
    title: "Payment",
    url: "/payments",
    exact: false,
  },
  {
    title: "Ambassador",
    url: "/ambassador",
    exact: false,
  },
  // {
  //   title: "Invoices",
  //   url: "/invoices",
  //   exact: false,
  // },
];

const Header = (props) => {
  const [state, setState] = useState({ isMobileMenuOpen: false });
  const history = useHistory();
  let currentUrl = history.location.pathname;

  const isBuyer = props.user.usertype === "Buyer";
  const isAmbassador = props.user?.ambassador;
  const isMerchant = props.user.usertype === "Merchant" || props.user.usertype === "Both";
  const isUser = props.token;
  let isLoggedIn = isUser ? "/onboarding" : "/";
  isLoggedIn = props.hasOrders ? "/orders/dashboard" : "/onboarding";
  let businessname = props?.user?.businessname;
  let logoSource = "/assets/images/logo/pepperest-logo-white.png";
  let logo = businessname?.length > 12 ? businessname : `${businessname}'s Store`;

  const handleCopy = async (text) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(`Copied: ${textField.value} `);
    mixPanelUserProperty("storelink_copied", {});
  };

  const showNav = (nav) => {
    const listOfNav = ["My Customers", "My Catalog", "Invoices"];
    const ambassadorLink = ['Ambassador'];
    
    if (!isBuyer) return true; //if the user is not a buyer it shows all the link

    return listOfNav.includes(nav) ? false: ambassadorLink.includes(nav) && !isAmbassador?false : true;
  };

  return (
    <div className="nsHeader">
      <div className="nsHeader-main">
        <a className="nsHeader-logo" style={{ textDecoration: "none" }} href={isLoggedIn}>
          {!isUser && (
            <img
              src={logoSource}
              srcSet="/assets/images/logo/pepperest-logo-white@2x.png 2x,
                            /assets/images/logo/pepperest-logo-white@3x.png 3x"
              alt="logo"
            />
          )}
          {isUser && isMerchant ? (
            <h3 style={{ color: "white" }}>{logo}</h3>
          ) : (
            <img
              src={logoSource}
              srcSet="/assets/images/logo/pepperest-logo-white@2x.png 2x,
                            /assets/images/logo/pepperest-logo-white@3x.png 3x"
              alt="logo"
            />
          )}
        </a>
        <nav className="nsHeader-nav">
          {navLinks.map(({ title, url, exact }, index) => {
            let isActive = currentUrl.includes(url) && "active";
            return (
              <div key={index}>
                {showNav(title) && (
                  <NavLink
                    title={title}
                    url={url}
                    classNames={`${isActive} nsHeader-nav__item`}
                    exact={exact}
                  />
                )}
              </div>
            );
          })}
        </nav>
        {isMerchant && (
          <div className="nsHeader-nav__item-profile__wrapper">
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                setState({ ...state, isMobileMenuOpen: true });
              }}
            >
              <HamburgerIcon />
            </div>
            <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="xs" />
            <div className="nsHeader-nav__item-profile">
              <li className="nsHeader-nav__item nsHeader-nav__item-alternate">My Account</li>
              <DownChevron />
            </div>
            
            <div
              className={`nsHeader-profile__dropdown-overlay ${
                state.isMobileMenuOpen ? "d-block" : ""
              }`}
              onClick={() => {
                setState({ ...state, isMobileMenuOpen: false });
              }}
            />
            <div
              className={`nsHeader-profile__dropdown ${state.isMobileMenuOpen ? "d-block" : ""}`}
            >
              <div className="dropdown__user-details">
                <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="md" />
                <div>
                  <p className="dropdown__user-details__email">{props.user?.email}</p>
                  <p className="dropdown__user-details__role">{props.user?.usertype}</p>
                </div>
              </div>
              <div className="dropdown-quick__links">
                <ul className="dropdown__list">
                  <a href="/payments" className="dropdown__list-item">
                    Payment
                  </a>
                  <a href="/products" className="dropdown__list-item">
                    Products /Services
                  </a>
                  <a href="/customers" className="dropdown__list-item">
                    Customers
                  </a>
                  <a href="/orders" className="dropdown__list-item">
                    My Orders
                  </a>
                  <a href="/invoices" className="dropdown__list-item">
                    Invoice
                  </a>
                  {
                    isAmbassador? (
                      <a href="/ambassador" className="dropdown__list-item">
                    Ambassador
                  </a>
                    ):(null)
                  }
                </ul>
              </div>
              <div className="dropdown__base">
                <div className="dropdown__list-header">MY ACCOUNT</div>
                <ul className="dropdown__list">
                  {/* <a href="/user-account/notifications" className="dropdown__list-item">
                    Notification
                    <span className="mBadge mBadge--secondary">4</span>
                  </a> */}
                  <a href="/user-account/profile" className="dropdown__list-item">
                    Settings
                  </a>
                  {/* <a
                    href='/user-account/loans'
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                    }}
                    className='dropdown__list-item'
                  > */}
                  {/* <a
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                    }}
                    className='dropdown__list-item'
                  >
                    Request Loan
                    <button
                      style={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "#ff721b",
                      }}
                      aria-label='disabled'
                    >
                      Coming Soon
                    </button>
                  </a> */}
                  <p
                    
                    id="storelink"
                    className="dropdown__list-item"
                  >
                    <Link to={`/merchant/${props.user?.merchantCode}`}
                  
                    className='text-decoration-none'
                    >My Store</Link>
                    <button
                      onClick={() =>
                        handleCopy(
                          `${window.location.origin}/merchant/${props.user?.merchantCode}`
                        )
                      }
                      style={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "#ff721b",
                      }}
                      aria-label="Copy link"
                    >
                      Click To Copy
                    </button>
                  </p>
                  {
                    isAmbassador? (
                      <p
                    
                    id="storelink"
                    className="dropdown__list-item"
                  >
                    <Link to={`/merchant/peppe-977?referer=${props.user?.referral_code}`}
                  
                    className='text-decoration-none'
                    >Ambassador Link</Link>
                    <button
                      onClick={() =>
                        handleCopy(
                          `${window.location.origin}/merchant/peppe-977?referer=${props.user?.referral_code}`
                        )
                      }
                      style={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "#ff721b",
                      }}
                      aria-label="Copy link"
                    >
                      Click To Copy
                    </button>
                  </p>
                    ):(null)
                  }
                  <Link
                    to={"/logout"}
                    onClick={() => {
                      mixPanelUserProperty("logged_out", {});
                    }}
                    className="dropdown__list-item"
                  >
                    Logout
                  </Link>
                </ul>
                <div className="dropdown__list-header">QUICK LINK</div>
                <ul className="dropdown__list">
                  <a href="/user-account/profile" className="dropdown__list-item">
                    Change Password
                  </a>
                  <a href="/user-account/bank-account" className="dropdown__list-item">
                    Add a Bank Account
                  </a>
                </ul>
              </div>
            </div>
          </div>
        )}

        {isBuyer && (
          <div className="nsHeader-nav__item-profile__wrapper">
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                setState({ ...state, isMobileMenuOpen: true });
              }}
            >
              <HamburgerIcon />
            </div>
            <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="xs" />
            <div className="nsHeader-nav__item-profile">
              <li className="nsHeader-nav__item nsHeader-nav__item-alternate">My Account</li>
              <DownChevron />
            </div>
            <div
              className={`nsHeader-profile__dropdown-overlay ${
                state.isMobileMenuOpen ? "d-block" : ""
              }`}
              onClick={() => {
                setState({ ...state, isMobileMenuOpen: false });
              }}
            />
            <div
              className={`nsHeader-profile__dropdown ${state.isMobileMenuOpen ? "d-block" : ""}`}
            >
              <div className="dropdown__user-details">
                <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="md" />
                <div>
                  <p className="dropdown__user-details__email">{props.user?.email}</p>
                  <p className="dropdown__user-details__role">{props.user?.usertype}</p>
                </div>
              </div>
              <div className="dropdown-quick__links">
                <ul className="dropdown__list">
                  <a href="/payments" className="dropdown__list-item">
                    Payment
                  </a>

                  {/* <a href='/products' className='dropdown__list-item'>
                    Products /Services
                  </a>
                  <a href='/customers' className='dropdown__list-item'>
                    Customers
                  </a> */}
                  <a href="/orders" className="dropdown__list-item">
                    My Orders
                  </a>
                  <a href="/invoices" className="dropdown__list-item">
                    Invoice
                  </a>
                  {
                    isAmbassador? (
                      <a href="/ambassador" className="dropdown__list-item">
                    Ambassador
                  </a>
                    ):(null)
                  }
                </ul>
              </div>
              <div className="dropdown__base">
                <div className="dropdown__list-header">MY ACCOUNT</div>
                <ul className="dropdown__list">
                  {/* {isMerchant && (
                    <a
                      href='/user-account/notifications'
                      className='dropdown__list-item'
                    >
                      Notification
                      <span className='mBadge mBadge--secondary'>4</span>
                    </a>
                  )} */}
                  <a href="/user-account/profile" className="dropdown__list-item">
                    Settings
                  </a>
                  {/* <a href="/user-account/profile" className="dropdown__list-item">
                  Payout
                </a> */}

                  {/* {isMerchant && (
                    <a
                      href='/user-account/loans'
                      className='dropdown__list-item'
                    >
                      Request Loan
                    </a>
                  )}
                  {isMerchant && (
                    <a
                      href={`/merchant/${props.user?.merchantCode}`}
                      id='storelink'
                      className='dropdown__list-item'
                    >
                      My Store
                      <button
                        onClick={() =>
                          handleCopy(
                            `https://pepperest.com/merchant/${props.user?.merchantCode}`
                          )
                        }
                        style={{
                          float: "right",
                          color: "#fff",
                          backgroundColor: "#ff721b",
                        }}
                        aria-label='Copy link'
                      >
                        {" "}
                        Click To Copy
                      </button>
                    </a>
                  )} */}

                  {
                    isAmbassador? (
                      <p
                    
                    id="storelink"
                    className="dropdown__list-item"
                  >
                    <Link to={`/merchant/peppe-977?referer=${props.user?.referral_code}`}
                  
                    className='text-decoration-none'
                    >Ambassador Link</Link>
                    <button
                      onClick={() =>
                        handleCopy(
                          `${window.location.origin}/merchant/peppe-977?referer=${props.user?.referral_code}`
                        )
                      }
                      style={{
                        float: "right",
                        color: "#fff",
                        backgroundColor: "#ff721b",
                      }}
                      aria-label="Copy link"
                    >
                      Click To Copy
                    </button>
                  </p>
                    ):(null)
                  }
                  <Link
                    to={"/logout"}
                    onClick={() => {
                      mixPanelUserProperty("logged_out", {});
                    }}
                    className="dropdown__list-item"
                  >
                    Logout
                  </Link>
                </ul>
                <div className="dropdown__list-header">QUICK LINK</div>
                <ul className="dropdown__list">
                  <a href="/user-account/profile" className="dropdown__list-item">
                    Change Password
                  </a>
                  <a href="/user-account/bank-account" className="dropdown__list-item">
                    Add a Bank Account
                  </a>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
    hasOrders: state.orders.Dashboard.total,
    isLoading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchant: (merchantCode, page) =>
      dispatch(actions.getMerchantStoreProducts(merchantCode, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
