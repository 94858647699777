/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {} from "react-router-dom";
import { BackArrow } from "components/vectors";
import { NavLink } from "components/blocks";
import { Avatar } from "components/shared";
import { DownChevron, HamburgerIcon } from "components/vectors";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

const SettingsNavigationBar = ({ navBarTitle, user, token }) => {
  const [state, setState] = useState({ isMobileMenuOpen: false });
  const history = useHistory();
  let currentUrl = history.location.pathname;

  const isMerchant = user?.usertype === "Merchant" || "Both";
  const isUser = token;
  const isBuyer = user?.usertype === "Buyer";
  const isAmbassador = user?.ambassador;
  const isLoggedIn = isUser ? "/onboarding" : "/";

  return (
    <div
      className="nsHeader d-lg-none"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="d-flex align-items-center">
        {currentUrl !== "/onboarding" && (
          <div
            onClick={() => {
              history.go(-1);
            }}
          >
            <BackArrow />
          </div>
        )}
        <h6 className="text--md text-font--medium text--white ml-3">{navBarTitle}</h6>
      </div>

      {isUser && isMerchant && (
        <div className="nsHeader-nav__item-profile__wrapper">
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              setState({ ...state, isMobileMenuOpen: true });
            }}
          >
            <HamburgerIcon />
          </div>

          <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="xs" />
          <div className="nsHeader-nav__item-profile">
            <li className="nsHeader-nav__item nsHeader-nav__item-alternate">My Account</li>
            <DownChevron />
          </div>
          <div
            className={`nsHeader-profile__dropdown-overlay ${
              state.isMobileMenuOpen ? "d-block" : ""
            }`}
            onClick={() => {
              setState({ ...state, isMobileMenuOpen: false });
            }}
          />
          <div className={`nsHeader-profile__dropdown ${state.isMobileMenuOpen ? "d-block" : ""}`}>
            <div className="dropdown__user-details">
              <Avatar imageUrl="/assets/images/avatar.jpg" avatarSize="md" />
              <div>
                <p className="dropdown__user-details__email">{user?.email}</p>
                <p className="dropdown__user-details__role">{user?.usertype}</p>
              </div>
            </div>
            <div className="dropdown-quick__links">
              <ul className="dropdown__list">
                <a href="/payments" className="dropdown__list-item">
                  Payment
                </a>
                {!isBuyer && (
                  <a href="/products" className="dropdown__list-item">
                    Catalogue
                  </a>
                )}
                {!isBuyer && (
                  <a href="/customers" className="dropdown__list-item">
                    Customers
                  </a>
                )}
                <a href="/orders" className="dropdown__list-item">
                  My Orders
                </a>
                {
                    isAmbassador? (
                      <a href="/ambassador" className="dropdown__list-item">
                    Ambassador
                  </a>
                    ):(null)
                  }
                {/* <a href='/invoices' className='dropdown__list-item'>
                    Invoices
                  </a> */}
              </ul>
            </div>
            <div className="dropdown__base">
              <div className="dropdown__list-header">MY ACCOUNT</div>
              <ul className="dropdown__list">
                {/* <a href="/user-account/notifications" className="dropdown__list-item">
                  Notification
                  <span className="mBadge mBadge--secondary">4</span>
                </a> */}
                <a href="/user-account/profile" className="dropdown__list-item">
                  Settings
                </a>
                <Link to={"/logout"} className="dropdown__list-item">
                  Logout
                </Link>
              </ul>
              <div className="dropdown__list-header">QUICK LINK</div>
              <ul className="dropdown__list">
                <a href="/user-account/profile" className="dropdown__list-item">
                  Change Password
                </a>
                <a href="/user-account/bank-account" className="dropdown__list-item">
                  Add a Bank Account
                </a>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(withRouter(SettingsNavigationBar));
