import React from "react";

const AttachmentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
  >
    <path
      d="M10.625 25.2943C8.45276 25.2943 6.61109 24.5387 5.09998 23.0276C3.58887 21.5165 2.83331 19.6748 2.83331 17.5026C2.83331 15.3304 3.58887 13.4887 5.09998 11.9776C6.61109 10.4665 8.45276 9.71094 10.625 9.71094H25.5C27.0583 9.71094 28.3923 10.2658 29.5021 11.3755C30.6118 12.4852 31.1666 13.8193 31.1666 15.3776C31.1666 16.9359 30.6118 18.27 29.5021 19.3797C28.3923 20.4894 27.0583 21.0443 25.5 21.0443H12.0416C11.05 21.0443 10.2118 20.7019 9.52706 20.0172C8.84234 19.3325 8.49998 18.4943 8.49998 17.5026C8.49998 16.5109 8.84234 15.6727 9.52706 14.988C10.2118 14.3033 11.05 13.9609 12.0416 13.9609H25.5V16.0859H12.0416C11.6403 16.0859 11.3036 16.2219 11.0316 16.4939C10.7596 16.7659 10.624 17.1022 10.625 17.5026C10.625 17.904 10.761 18.2407 11.033 18.5127C11.305 18.7847 11.6412 18.9202 12.0416 18.9193H25.5C26.4916 18.9193 27.3298 18.5769 28.0146 17.8922C28.6993 17.2075 29.0416 16.3693 29.0416 15.3776C29.0416 14.3859 28.6993 13.5477 28.0146 12.863C27.3298 12.1783 26.4916 11.8359 25.5 11.8359H10.625C9.06665 11.8359 7.73262 12.3908 6.6229 13.5005C5.51317 14.6102 4.95831 15.9443 4.95831 17.5026C4.95831 19.0609 5.51317 20.395 6.6229 21.5047C7.73262 22.6144 9.06665 23.1693 10.625 23.1693H25.5V25.2943H10.625Z"
      fill="black"
    />
  </svg>
);

export default AttachmentIcon;
