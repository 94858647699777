import React, { useState } from "react";
import ChatContactItem from "../ChatContactItem";

const ChatContact = ({messages,setActiveIndex,activeIndex}) => {
  

  return (
    <div className="chat__contacts">
      {messages?.map((message, index) => (
        <ChatContactItem
         key = {index}
          message={message}
          active={activeIndex == index}
          handleClick={() => setActiveIndex(index)}
        />
      ))}
    </div>
  );
};

export default ChatContact;


