import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "store/utility";

const ProductInfo = ({
  productID,
  productName,
  dateCreated,
  transactions,
  deliveryDate,
  amount,
  productDescription,
}) => (
  <>
    <div className="mx-0 list-item-detail__main-item row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Product ID</p>
      </div>
      <div className="px-0 col-md-9">
        <p className="list-item-detail__main-item__details">{productID}</p>
      </div>
    </div>
    <div className="mx-0 list-item-detail__main-item row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Product Name</p>
      </div>
      <div className="px-0 col-md-9">
        <p className="list-item-detail__main-item__details">{productName}</p>
      </div>
    </div>
    <div className="mx-0 list-item-detail__main-item row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Date Created</p>
      </div>
      <div className="px-0 col-md-9">
        <p className="list-item-detail__main-item__details">{dateCreated}</p>
      </div>
    </div>
    <div className="mx-0 list-item-detail__main-item row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Transactions</p>
      </div>
      <div className="px-0 col-md-9">
        <p className="list-item-detail__main-item__details">{transactions}</p>
      </div>
    </div>
    {/* <div className='mx-0 list-item-detail__main-item row'>
      <div className='px-0 col-md-3'>
        <p className='list-item-detail__main-item__title'>Delivery Date</p>
      </div>
      <div className='px-0 col-md-9'>
        <p className='list-item-detail__main-item__details'>{deliveryDate}</p>
      </div>
    </div> */}
    <div className="mx-0 list-item-detail__main-item row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Amount</p>
      </div>
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__details">{currencyFormatter.format(amount)}</p>
      </div>
    </div>
    <div className="mx-0 list-item-detail__main-item list-item-detail__main-item-alternate row">
      <div className="px-0 col-md-3">
        <p className="list-item-detail__main-item__title">Product Description</p>
      </div>
      <div className="px-0 col-md-9">
        <p className="list-item-detail__main-item__details">{productDescription}</p>
      </div>
    </div>
  </>
);

ProductInfo.propTypes = {
  productID: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  transactions: PropTypes.number.isRequired,
  deliveryDate: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
};

export default ProductInfo;
