import InputField from "components/blocks/InputField";
import InputGroup from "components/blocks/InputGroup";
import { CloseIcon } from "components/vectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { createCoupon, updateCoupon } from "store/actions/products";
import { formatInvoiceDate, generateCouponCode } from "libs/utils";

const CreateCoupon = ({ close, selectedCoupon }) => {
  const [minDate, setMinDate] = useState("");
  const [icon, setIcon] = useState("N");
  const { allProducts, couponError, addingCoupon } = useSelector(
    (state) => state.products
  );
  const dispatch = useDispatch();

  const discountSchema = yup.object().shape({
    code: yup.string().required("Coupon code is required"),
    discount_type: yup.string().required("Discount Type is required"),
    discount: yup
      .number()
      .typeError("Provide a valid Discount")
      .required("Discount is required")
      .when("discount_type", {
        is: (val) => val === "P", // Conditionally apply validation
        then: yup
          .number()
          .typeError("Provide a valid Discount")
          .max(100, "Discount must not exceed 100"),
        otherwise: yup
          .number()
          .typeError("Provide a valid Discount")
          .min(1, "Discount must not be less than 1"),
      }),

    start_date: yup.string().required("Start date is required"),
    end_date: yup.string().required("End date is required"),
    // product_ids: yup.array().min(1, "Select product to apply discount"),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(discountSchema),
    mode: "all",
  });
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      padding: 4,
      color: "#18191F",
      backgroundColor: "#F5F5F5",
      overflow: "auto",
      fontSize: 14,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: 50,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),

    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#5E60CE",
      color: "white",
      padding: "4px",
      borderRadius: "5px",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "#5E60CE",
      color: "white",
      borderRadius: "5px",
      ":hover": {
        backgroundColor: "white",
        color: "red",
      },
    }),
  };

  const handleDiscountType = () => {
    const discountType = getValues("discount_type");
    if (discountType === "P") {
      setIcon("%");
    } else {
      setIcon("N");
    }
  };

  const handleAutoGenerateCouponcode = () => {
    const code = generateCouponCode();
    reset({
      code,
    });
  };

  const onSubmit = (data) => {
    // const productIds = data.product_ids?.map((ids) => ids?.value);
    // data.product_ids = productIds;
    if (selectedCoupon) {
      data.couponID = selectedCoupon?.couponID;
      dispatch(updateCoupon(data));
    } else {
      dispatch(createCoupon(data));
    }
  };

  useEffect(() => {
    // Get the current date in the format "YYYY-MM-DD"
    const currentDate = new Date().toISOString().slice(0, 10);
    setMinDate(currentDate);
  }, []);

  useEffect(() => {
    if (selectedCoupon) {
      reset({
        code: selectedCoupon?.code,
        discount_type: selectedCoupon?.discount_type,
        discount: selectedCoupon?.discount,
        start_date: formatInvoiceDate(selectedCoupon?.startDate),
        end_date: formatInvoiceDate(selectedCoupon?.endDate),
      });
      const selectedIds = selectedCoupon?.discount_products?.map(
        (item) => item.id
      );
      setValue("product_ids", selectedIds);
    }
  }, [selectedCoupon]);

  return (
    <div className="modal__component">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
              {selectedCoupon ? "Update" : "Create New"} Coupon
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
          <p className="error__message">{couponError ? couponError : null}</p>
          <div className="form__wrapper position-relative">
            <InputField
              require={true}
              id="code"
              label="Coupon Code"
              name="code"
              placeHolder="Coupon name e.g Black Friday Sales"
              register={register}
              error={errors.code?.message}
              type="text"
            />
          </div>
          <p className="auto__generate" onClick={handleAutoGenerateCouponcode}>
            Auto-generate
          </p>

          <div className="row row__wrapper">
            <div className="form__wrapper col">
              <InputField
                require={true}
                onChange={handleDiscountType}
                id="discount_type"
                label="Discount Type"
                name="discount_type"
                options={options}
                register={register}
                error={errors.discount_type?.message}
                type="select"
              />
            </div>

            <div className="form__wrapper col">
              <InputGroup
                require={true}
                id="discount"
                label="Amount"
                name="discount"
                placeholder="1000"
                register={register}
                icon={icon}
                error={errors.discount?.message}
                type="number"
              />
            </div>
          </div>
          <div className="row row__wrapper">
            <div className="form__wrapper col-6">
              <InputField
                require={true}
                id="start_date"
                label="Valid From"
                name="start_date"
                register={register}
                error={errors.start_date?.message}
                type="date"
                min={minDate}
              />
            </div>
            <div className="form__wrapper col-6">
              <InputField
                require={true}
                id="end_date"
                label="Valid To"
                name="end_date"
                register={register}
                error={errors.end_date?.message}
                type="date"
                min={minDate}
              />
            </div>
          </div>

          {/* <div className="form__wrapper">
            <label className="form__label">Apply to Products</label>
            <Controller
              name="product_ids"
              control={control}
              defaultValue={
                selectedCoupon !== null
                  ? selectedCoupon?.discount_products?.map((product) => ({
                      label: product?.productname,
                      value: product?.id,
                    }))
                  : []
              }
              render={({ onChange, field }) => (
                <Select
                  options={allProducts?.map((product) => ({
                    label: product?.productName,
                    value: product?.id,
                  }))}
                  isMulti
                  defaultValue={
                    selectedCoupon !== null
                      ? selectedCoupon?.discount_products?.map((product) => ({
                          label: product?.productname,
                          value: product?.id,
                        }))
                      : []
                  }
                  styles={customStyles}
                  onChange={onChange}
                  {...field}
                />
              )}
            />
            <p className="error__message">{errors.product_ids?.message}</p>
          </div> */}
        </div>

        <div className="modal__control control__center">
          <button
            className="button button--purple-outline px-4"
            onClick={close}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button--purple px-md-4 px-2"
            disabled={addingCoupon}
          >
            {selectedCoupon ? "Update" : "Add"} Coupon
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCoupon;

const options = [
  {
    label: "Fixed Amount",
    value: "F",
  },
  {
    label: "Percentage",
    value: "P",
  },
];
