import React from 'react'

const DollarCircle = () => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.05859 8.85912C5.05859 9.61162 5.63609 10.2183 6.35359 10.2183H7.81776C8.44193 10.2183 8.94943 9.68745 8.94943 9.03412C8.94943 8.32245 8.64026 8.07162 8.17943 7.90828L5.82859 7.09162C5.36776 6.92828 5.05859 6.67745 5.05859 5.96578C5.05859 5.31245 5.56609 4.78162 6.19026 4.78162H7.65443C8.37193 4.78162 8.94943 5.38828 8.94943 6.14078" stroke="#18191F" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 4V11" stroke="#18191F" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.00033 13.3333C10.222 13.3333 12.8337 10.7216 12.8337 7.49996C12.8337 4.2783 10.222 1.66663 7.00033 1.66663C3.77866 1.66663 1.16699 4.2783 1.16699 7.49996C1.16699 10.7216 3.77866 13.3333 7.00033 13.3333Z" stroke="#18191F" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)

export default DollarCircle