import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  userProfile: null,
  updatingProfile: false,
  updateProfileError: null,
  updatingBankAccount: false,
  updatingBankAccountSuccess: false,
  updateBankAccountError: null,
  updatingUserType: false,
  updateUserTypeError: null,
  updatingApiSetting: false,
  updateApiSettingError: null,
  updatingNotificationSetting: false,
  updateNotificationSettingError: null,
  updatingPassword: false,
  updatePasswordError: null,
  socialMediaLink: null,
  banks: null,
  gettingBanks: false,
  redirectUrl: null,
  upgradingPremium: false,
  loading: false,
  settingStore: false,
  error: null,
  paymentDetail: null,
  resendingEmail: false,
  setupStoreSuccess: false,
  unsubscribeSuccess: false,
  unsubscribing: false,
  nameEnquiry: {
    loading: false,
    details: null,
    error: null,
  },
  subscription: null,
  subscriptionError: null,
  loadingSubscriptions: true,
  verifyLoading:true,
  paymentError:null
};

const getUserProfile = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    userProfile: action.userProfile,
    loading: false,
  });
};
const getUserProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getBanksSuccess = (state, action) => {
  return updateObject(state, {
    banks: action.banks,
  });
};
const gettingBanks = (state, action) => {
  return updateObject(state, {
    gettingBanks: true,
  });
};

const updatingProfile = (state, action) => {
  return updateObject(state, {
    updatingProfile: true,
  });
};

const resetError = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const updateSocialMediaLink = (state, action) => {
  return updateObject(state, {
    //socialMediaLink: action.payload,
    updatingsocialMediaLink: false,
  });
};

const updateSocialMediaLinkStart = (state, action) => {
  return updateObject(state, {
    updatingsocialMediaLink: true,
  });
};

const updateSocialMediaLinkFail = (state, action) => {
  return updateObject(state, {
    updatingsocialMediaLink: false,
  });
};
const removeSocialMediaLink = (state, action) => {
  return updateObject(state, {
    socialMediaLink: null,
  });
};

const updatedProfile = (state, action) => {
  return updateObject(state, {
    updatingProfile: false,
    updateProfileError: null,
  });
};

const failedToUpdateProfile = (state, action) => {
  return updateObject(state, {
    updatingProfile: false,
    updateProfileError: action.error,
  });
};

const updatingBankAccount = (state, action) => {
  return updateObject(state, {
    updatingBankAccount: true,
    updatingBankAccountSuccess: false,
  });
};

const updatedBankAccount = (state, action) => {
  return updateObject(state, {
    updatingBankAccount: false,
    updatingBankAccountSuccess: true,
    updateBankAccountError: null,
    nameEnquiry: {
      details: null,
      error: null,
      loading: false,
    },
  });
};

const failedToUpdateBankAccount = (state, action) => {
  return updateObject(state, {
    updateBankAccountError: action.error,
    updatingBankAccountSuccess: false,
    updatingBankAccount: false,
  });
};

const updatingUserType = (state, action) => {
  return updateObject(state, {
    updatingUserType: true,
  });
};

const updatedUserType = (state, action) => {
  return updateObject(state, {
    updatingUserType: false,
    updateUserTypeError: null,
  });
};

const failedToUpdateUserType = (state, action) => {
  return updateObject(state, {
    updatingUserType: false,
    updateUserTypeError: action.error,
  });
};

const updatingApiSetting = (state, action) => {
  return updateObject(state, {
    updatingApiSetting: true,
  });
};

const updatedApiSetting = (state, action) => {
  return updateObject(state, {
    updatingApiSetting: false,
    updateApiSettingError: null,
  });
};

const failedToUpdateApiSetting = (state, action) => {
  return updateObject(state, {
    updatingApiSetting: false,
    updateApiSettingError: action.error,
  });
};

const updatingNotificationSetting = (state, action) => {
  return updateObject(state, {
    updatingNotificationSetting: true,
  });
};

const updatedNotificationSetting = (state, action) => {
  return updateObject(state, {
    updatingNotificationSetting: false,
    updateNotificationSettingError: null,
  });
};

const failedToUpdateNotificationSetting = (state, action) => {
  return updateObject(state, {
    updatingNotificationSetting: false,
    updateNotificationSettingError: action.error,
  });
};

const updatingPassword = (state, action) => {
  return updateObject(state, {
    updatingPassword: true,
  });
};

const updatedPassword = (state, action) => {
  return updateObject(state, {
    updatingPassword: false,
    updatePasswordError: null,
  });
};

const failedToUpdatePassword = (state, action) => {
  return updateObject(state, {
    updatingPassword: false,
    updatePasswordError: action.error,
  });
};

const upgradingPremium = (state, action) => {
  return updateObject(state, {
    upgradingPremium: true,
  });
};
const upgradingPremiumFail = (state, action) => {
  return updateObject(state, {
    upgradingPremium: false,
    upgradingPremiumError: action.error,
  });
};

const redirect = (state, action) => {
  const update = {
    tokenization: action.tokenization,
    upgradingPremium: false,
  };
  return updateObject(state, update);
};

const confirmPremiumPayment = (state, action) => {
  return updateObject(state, {
    verifyLoading: true,
  });
};

const confirmPremiumPaymentSuccess = (state, action) => {
  return updateObject(state, {
    paymentDetail: action.paymentDetail,
    paymentError: null,
    verifyLoading: false,
  });
};

const confirmPremiumPaymentFail = (state, action) => {
  return updateObject(state, {
    paymentError: action.error,
    verifyLoading: false,
  });
};

const setupStoreFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    settingStore: false,
  });
};
const setupStoreSuccess = (state, action) => {
  return updateObject(state, {
    setupStoreSuccess: true,
    loading: false,
  });
};
const setupStore = (state, action) => {
  return updateObject(state, {
    settingStore: true,
  });
};

const setupStoreComplete = (state, action) => {
  return updateObject(state, {
    setupStoreSuccess: false,
  });
};

const resendingVerificationEmail = (state, action) => {
  return updateObject(state, {
    resendingEmail: true,
  });
};

const resendingVerificationEmailSuccess = (state, action) => {
  return updateObject(state, {
    resendingEmail: false,
  });
};
const resendingVerificationEmailFail = (state, action) => {
  return updateObject(state, {
    resendingEmail: false,
  });
};

const getAccountNameSuccess = (state, action) => {
  const update = {
    details: action.details,
    error: null,
    loading: false,
  };
  return updateObject(state, {
    nameEnquiry: update,
  });
};

const getAccountNameFail = (state, action) => {
  const update = {
    error: action.error,
    loading: false,
  };
  return updateObject(state, {
    nameEnquiry: update,
  });
};

const getAccountName = (state, action) => {
  const update = {
    error: null,
    loading: true,
  };
  return updateObject(state, {
    nameEnquiry: update,
  });
};

const getAccountNameComplete = (state, action) => {
  const update = {
    details: null,
    error: null,
    loading: false,
  };
  return updateObject(state, {
    nameEnquiry: update,
  });
};
const unsubscribePremiumSuccess = (state, action) => {
  return updateObject(state, {
    unsubscribeSuccess: true,
    unsubscribing: false,
  });
};
const unsubscribePremiumFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    unsubscribeSuccess: false,
    unsubscribing: false,
  });
};

const unsubscribePremium = (state, action) => {
  return updateObject(state, {
    unsubscribing: true,
    unsubscribeSuccess: false,
    error: "",
  });
};

const getBillingHistorySuccess = (state, action) => {
  return updateObject(state, {
    billingHistory: action.billingHistory,
    error: "",
  });
};
const getBillingHistoryFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const customizeStore = (state, action) => {
  return updateObject(state, {
    customizingStore: true,
    error: "",
    success: false,
  });
};
const customizeStoreSuccess = (state, action) => {
  return updateObject(state, {
    error: "",
    success: true,
    customizingStore: false,
  });
};
const customizeStoreFail = (state, action) => {
  return updateObject(state, {
    customizingStore: false,
    success: false,
  });
};

const customizeStoreComplete = (state, action) => {
  return updateObject(state, {
    error: "",
    customizingStore: false,
    success: false,
  });
};

const getSubcriptions = (state, action) => {
  return updateObject(state, {
    loadingSubscriptions: true,
    subscriptionError: null,
  });
};

const getSubcriptionsSuccess = (state, action) => {
  return updateObject(state, {
    subscription: action.subscription,
    loadingSubscriptions: false,
  });
};
const getSubcriptionsFail = (state, action) => {
  return updateObject(state, {
    subscriptionError: action.error,
    loadingSubscriptions: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE:
      return getUserProfile(state, action);
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return getUserProfileSuccess(state, action);
    case actionTypes.GET_USER_PROFILE_FAIL:
      return getUserProfileFail(state, action);
    case actionTypes.UPDATING_PROFILE:
      return updatingProfile(state, action);
    case actionTypes.GET_BANKS:
      return gettingBanks(state, action);
    case actionTypes.GET_BANKS_SUCCESS:
      return getBanksSuccess(state, action);
    case actionTypes.UPDATE_SOCIAL_LINK:
      return updateSocialMediaLinkStart(state, action);
    case actionTypes.UPDATE_SOCIAL_LINK_FAIL:
      return updateSocialMediaLinkFail(state, action);
    case actionTypes.UPDATE_SOCIAL_LINK_IN_STORE:
      return updateSocialMediaLink(state, action);
    case actionTypes.REMOVE_SOCIAL_MEDIA_LINK_IN_STORE:
      return removeSocialMediaLink(state, action);
    case actionTypes.UPDATED_PROFILE:
      return updatedProfile(state, action);
    case actionTypes.FAILED_TO_UPDATE_PROFILE:
      return failedToUpdateProfile(state, action);
    case actionTypes.UPDATING_BANK_ACCOUNT:
      return updatingBankAccount(state, action);
    case actionTypes.UPDATED_BANK_ACCOUNT:
      return updatedBankAccount(state, action);
    case actionTypes.FAILED_TO_UPDATE_BANK_ACCOUNT:
      return failedToUpdateBankAccount(state, action);
    case actionTypes.UPDATING_USER_TYPE:
      return updatingUserType(state, action);
    case actionTypes.UPDATED_USER_TYPE:
      return updatedUserType(state, action);
    case actionTypes.FAILED_TO_UPDATE_USER_TYPE:
      return failedToUpdateUserType(state, action);
    case actionTypes.UPDATING_API_SETTING:
      return updatingApiSetting(state, action);
    case actionTypes.UPDATED_API_SETTING:
      return updatedApiSetting(state, action);
    case actionTypes.FAILED_TO_UPDATE_API_SETTING:
      return failedToUpdateApiSetting(state, action);
    case actionTypes.UPDATING_NOTIFICATION_SETTING:
      return updatingNotificationSetting(state, action);
    case actionTypes.UPDATED_NOTIFICATION_SETTING:
      return updatedNotificationSetting(state, action);
    case actionTypes.FAILED_TO_UPDATE_NOTIFICATION_SETTING:
      return failedToUpdateNotificationSetting(state, action);
    case actionTypes.UPDATING_PASSWORD:
      return updatingPassword(state, action);
    case actionTypes.UPDATED_PASSWORD:
      return updatedPassword(state, action);
    case actionTypes.FAILED_TO_UPDATE_PASSWORD:
      return failedToUpdatePassword(state, action);
    case actionTypes.UPGRADE_PREMIUM:
      return upgradingPremium(state, action);
    case actionTypes.PREMIUM_REDIRECT:
      return redirect(state, action);
    case actionTypes.UPGRADE_PREMIUM_FAIL:
      return upgradingPremiumFail(state, action);
    case actionTypes.CONFIRM_PREMIUM_PAYMENT:
      return confirmPremiumPayment(state, action);
    case actionTypes.CONFIRM_PREMIUM_PAYMENT_SUCCESS:
      return confirmPremiumPaymentSuccess(state, action);
    case actionTypes.CONFIRM_PREMIUM_PAYMENT_FAIL:
      return confirmPremiumPaymentFail(state, action);
    case actionTypes.SETUP_STORE:
      return setupStore(state, action);
    case actionTypes.SETUP_STORE_SUCCESS:
      return setupStoreSuccess(state, action);
    case actionTypes.SETUP_STORE_FAIL:
      return setupStoreFail(state, action);
    case actionTypes.SETUP_STORE_COMPLETE:
      return setupStoreComplete(state, action);
    case actionTypes.GET_ACCOUNT_NAME:
      return getAccountName(state, action);
    case actionTypes.GET_ACCOUNT_NAME_SUCCESS:
      return getAccountNameSuccess(state, action);
    case actionTypes.GET_ACCOUNT_NAME_FAIL:
      return getAccountNameFail(state, action);
    case actionTypes.GET_ACCOUNT_NAME_COMPLETE:
      return getAccountNameComplete(state, action);
    case actionTypes.RESEND_VERIFICATION_EMAIL:
      return resendingVerificationEmail(state, action);
    case actionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return resendingVerificationEmailSuccess(state, action);
    case actionTypes.RESEND_VERIFICATION_EMAIL_FAIL:
      return resendingVerificationEmailFail(state, action);
    case actionTypes.UNSUBSCRIBE_PREMIUM:
      return unsubscribePremium(state, action);
    case actionTypes.UNSUBSCRIBE_PREMIUM_SUCCESS:
      return unsubscribePremiumSuccess(state, action);
    case actionTypes.UNSUBSCRIBE_PREMIUM_FAIL:
      return unsubscribePremiumFail(state, action);
    case actionTypes.GET_BILLING_HISTORY_SUCCESS:
      return getBillingHistorySuccess(state, action);
    case actionTypes.GET_BILLING_HISTORY_FAIL:
      return getBillingHistoryFail(state, action);
    case actionTypes.RESET_ERROR:
      return resetError(state, action);
    case actionTypes.CUSTOMIZING_STORE:
      return customizeStore(state, action);
    case actionTypes.CUSTOMIZE_STORE_SUCCESS:
      return customizeStoreSuccess(state, action);
    case actionTypes.CUSTOMIZE_STORE_FAIL:
      return customizeStoreFail(state, action);
    case actionTypes.CUSTOMIZE_STORE_COMPLETE:
      return customizeStoreComplete(state, action);
    case actionTypes.GET_PRICING:
      return getSubcriptions(state, action);
    case actionTypes.GET_PRICING_SUCCESS:
      return getSubcriptionsSuccess(state, action);
    case actionTypes.GET_PRICING_FAIL:
      return getSubcriptionsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
