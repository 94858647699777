import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  stores: [],
  meta: null,
  links: null,
  loading: false,
  success: false,
  error: null,
  top_deal: [],
  top_selling: [],
  featured_store: [],
  categories: [],
  store_by_category: [],
  categorized_stores: [],
  checkout_data: null,
};

const loading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const loadMarketplaceStoreSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const loadProductCategoriesSuccess = (state, action) => {
  return updateObject(state, action.update);
};
const loadProductCategoriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};
const loadTopSellingSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const loadFeaturedStoreSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const loadMarketplaceStoreFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const loadCategorizedStoreSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const loadTodalDealSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const preCheckoutSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const preChechoutFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const searchMarketplaceSuccess = (state, action) => {
  return updateObject(state, action.update);
};

const searchMarketplaceFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_MARKETPLACE_STORES:
      return loading(state, action);
    case actionTypes.LOAD_MARKETPLACE_STORES_SUCCESS:
      return loadMarketplaceStoreSuccess(state, action);
    case actionTypes.LOAD_MARKETPLACE_STORES_FAIL:
      return loadMarketplaceStoreFail(state, action);
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return loadProductCategoriesSuccess(state, action);
    case actionTypes.GET_CATEGORIES_FAIL:
      return loadProductCategoriesFail(state, action);
    case actionTypes.GET_TOPSELLING_CATEGORY_SUCCESS:
      return loadTopSellingSuccess(state, action);
    case actionTypes.GET_FEATURED_STORE_SUCCESS:
      return loadFeaturedStoreSuccess(state, action);
    case actionTypes.GET_CATEGORIZED_STORE_SUCCESS:
      return loadCategorizedStoreSuccess(state, action);
    case actionTypes.PRECHECHOUT_START:
      return loading(state, action);
    case actionTypes.PRECHECKOUT_SUCCESS:
      return preCheckoutSuccess(state, action);
    case actionTypes.PRECHECKOUT_FAIL:
      return preChechoutFail(state, action);
    case actionTypes.GET_TODAY_DEAL_SUCCESS:
      return loadTodalDealSuccess(state, action);
    case actionTypes.SEARCH_MARKETPLACE:
      return loading(state, action);
    case actionTypes.SEARCH_MARKETPLACE_SUCCESS:
      return searchMarketplaceSuccess(state, action);
    case actionTypes.SEARCH_MARKETPLACE_FAIL:
      return searchMarketplaceFail(state, action);
    default:
      return state;
  }
};

export default reducer;
