import React from "react";
// import { FooterList } from "components/blocks";
// import { withWelcomeLayout } from "components/layouts";
// import { getStringHash } from "libs/utils";
// import { footerLinks } from "config/inner-routes";
import { useHistory } from "react-router-dom";
import "../../index.css";

import Footer from "components/newDashboardComponents/Footer/Footer";
import NavBar from "components/landingPageComponent/NavBar";
import CallToAction from "components/landingPageComponent/CallToAction";

const config = {
  hasAlternateHeader: false,
  hasCommonHeader: true,
  showCart: false,
  commonHeaderTitle: "peppa.io Privacy Policy",
  links: [],
  page: "welcome",
  isSettings: true,
  navBarTitle: "Welcome",
};
const ProductPolicy = () => {
  const history = useHistory();

  return (
    <div className="bg-white landing__page">
      <div style={{ position: "absolute", width: "100%", zIndex: 1, top: "0", left: "0" }}>
        <NavBar />
      </div>
      <div className="onboarding_page container px-3 ">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="onboarding-card">
              {/* <div className="onboarding-header" style={{ marginTop: "15px" }}>
                <h3>Privacy Policy</h3>
              </div> */}

              <div
                className="onboarding-header pageTitle text-center"
                style={{ margin: "4rem 0 2rem 0" }}
              >
                <h3 className="font-weight-bold">Products Policy</h3>
                <p className="text-center">Effective 1st January, 2024</p>
              </div>
              <div className="main-content">
                <div className="paragraph-item">
                  {/* <p>
                    www.peppa.io.com is provided by peppa.io Ltd. (peppa.io)
                    <br />
                    <br />
                  </p> */}
                  <p className="paragraph">
                  Peppa is an international digital platform owned and operated by Pepper Rest Ltd, 
                  a private company limited by shares registered under the laws of England and Wales 
                  (the “Company”). It serves as a storefront for merchants and vendors. Considering the
                   international nature of the operations of Peppa’s users, the Company prioritizes
                    trans-jurisdictional compliance with applicable legislation for the sales of goods
                     and the provision of services by users in the countries in which its services and 
                     those of its users including customers are available. 

                  </p>
                  <p className="paragraph">
                  Each user shall ensure that the listing, sale, and purchase of 
                  goods on Peppa is in compliance with the applicable sale of goods laws
                   and related legislation in their countries of residence and operation
                    as well as the Peppa policies including but not limited to the <a href="/terms-condition" style={{color:"blue"}} >Terms of Service</a> ,
                    <a href="/privacy" style={{color:"blue"}} > Privacy Policy</a>, and <a href="/aml-cft-policy" style={{color:"blue"}} >Anti-Money Laundering and Countering the Financing of
                      Terrorism Policies</a>. Each transaction shall fulfil relevant legal obligations
                       as well as recognise and respect the rights of third parties.
                     </p>

                     <p className="paragraph">In the event that the activity of a user falls short of
                      the requirements, the Company reserves the right to take action as listed in 
                      its policies and/or other law. The Company’s actions may include the deletion 
                      of the product listings, removal of search results, cancellation of transactions, 
                      reduction of seller rating, restriction of vendor or merchant profiles, restriction
                       of user activity, merchant forfeiture of fees including cost and transaction fee,
                        restriction of access to the Peppa application, and account suspension or deletion. </p>
                      
                  
                  
                  

                  <ul>
                    <h2 className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      1. Adult items
                    </h2>
                    <p className="paragraph">
                    Certain goods and services regarding sexual or adult
                     activity including physical and digital content, art, wellness 
                     items, and other related material are not permitted on Peppa. 
                    </p>
                    <br />

                    <p className="sub-text paragraph">
                    Sexual art, sex toys and accessories may be listed for sale
                     in the Adult category provided that each seller of such products shall
                      be pre-approved by Peppa. Items to be listed shall be new and sold in
                       original packaging. The listings of these items shall not include nudity 
                       or sexually explicit images. 

                    </p>
                    <br />
                    

                    <li className="heading font-weight-bold" style={{ marginTop: "8px", fontWeight: "bold" }}>
                      2. Alcohol
                    </li>
                    <p className="d-flex paragraph">
                    All sellers of alcoholic beverages must be compliant with Peppa’s 
                    Terms of Service as well as compliant with the legislation which regulate 
                    the sale of alcohol in the countries of their operation as well as the country
                     of the customer (s) making the purchase.
                    </p>
                    
                    <br />
                  </ul>
                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">
                    3. Arms and Ammunition
                    </li>
                  <p className="paragraph">
                  For compliance and safety concerns, the sale of most weapons on Peppa is
                   prohibited. All offensive weapons defined by the provisions of the Criminal 
                   Justice Act 1988 shall not be listed for sale on Peppa.
                  </p>
                  <br />
                  <p className="paragraph">
                  The following items and their accessories are also not permitted for sale: 
                  rifles, gins or pistols, including airsoft rifles, air guns, BB guns, Pellet guns, 
                  blow or dart guns, flare guns, potato guns, tear gas, pepper spray, mace, stun guns 
                  and all other firearms including replica weapons, hand weapons, throwing weapons, 
                  tasers, swords, crossbows, and long bows.
                  </p>
                  <br />
                  <p className="paragraph">
                  This prohibition on the sale of firearms is inclusive of barrels,
                   bullets, casings, cartridges, firing pins, hulls, shells, receivers, trigger 
                   assemblies, and magazines, bullets, and other parts and accessories including blueprints and accessories.
                  </p>
                  <br />

                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">
                    4. Animals
                  </li>
                  <p className="paragraph">
                  Users may list certain animal and wildlife products on Peppa: 
                  </p>
                  
                   <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                 a. <span style={{ fontWeight:"bold" }}> Live Bait</span>: Minnows, shiners, earthworms, and mealworms are permissible for sale. 
                  </p>
                  <p className="paragraph">
                 b.  <span style={{ fontWeight:"bold" }}> Composting Worms</span>: Sellers are allowed to offer composting worms for those interested in 
                  </p>

                  <p className="paragraph">
                 c.  <span style={{ fontWeight:"bold" }}> Live Insects</span>: Crickets can be sold as bait or as feeder food for pets. 
                  </p>
                  <p className="paragraph">
                 d.  <span style={{ fontWeight:"bold" }}> Shellfish for Human Consumption</span>: Shellfish, such as crabs and lobsters, can be sold live, specifically for human consumption. 
                  </p>
                  <p className="paragraph">
                 e.  <span style={{ fontWeight:"bold" }}> Aquarium or Pond Creatures</span>: Small aquarium or pond creatures, including fish up to 5cm long, snails, tadpoles, or similar species, can be listed. Aquarium or pond fish longer than 5cm may be offered with the "Collection in person" option.
                  </p>
                  <p className="paragraph">
                 f.  <span style={{ fontWeight:"bold" }}> Hatching Eggs</span>Sellers are permitted to offer hatching eggs for certain animals, with the condition that domestic-only next-day delivery within the UK is provided. </p>

                </div>
                <br/>
                <p className="paragraph">
                Endangered and protected species and their products cannot be listed
                 for sale on Peppa. The sellers of permitted animals and animal products have
                  the obligation to comply with applicable laws and policies including international
                   regulation and conventions for the sale and shipping of animal products and 
                   procure the required licenses and permits.
                </p>



                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">5. Animal traps</li>
                  <p className="paragraph">
                  Generally, the sale of traps is not permitted on Peppa. The only
                   traps permitted for sale are fish traps, trap cages,
                   mouse traps, glue traps not intended for rats or mice, and other 
                   traps designed for the humane capture of animals. Traps for the capture of 
                   bears are not permitted for sale.
                  </p>
                  <li style={{ marginTop: "8px", fontWeight: "bold" }} className="heading">6. Charity</li>
                  <p className="paragraph">
                  Peppa may be used for charitable causes, provided that real
                   estate cannot be listed for sale and the proceeds of the sale shall
                    be used for the purpose for which the account, which must be maintained
                     post-sale, was created.
                  </p>

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>7. Content</li>
                  <p className="paragraph">
                  The following content, physical, digital, or otherwise, are banned from Peppa:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                 a.  Items which express insensitivity or benefit from tragedy or human suffering. 
                  </p>
                  <p className="paragraph">
                  b.	Listings or content which promote or glorify hatred, violence, or discrimination.  
                  </p>
                  <p className="paragraph">
                  c.  Obscene content or material describing or featuring child pornography, torture, rape, necrophilia, incest, bestiality or related content. 
                  </p>
                 </div>


                  <br />
                
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    8. Cosmetics
                  </li>
                  <p className="paragraph">
                  The sale and listing of cosmetic products must be in compliance with applicable regulations including the Cosmetics Regulation (for sales in England and Wales). 
                    </p>
                  <p className="paragraph">
                  Used cosmetics and cosmetic products are not permitted for
                   sale. All cosmetic products must be sold and packaged in the original
                    packaging of the manufacturer. 
                    Sellers must include the following information in the listing of their products:

                  </p>

                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                 a. the name of the cosmetic product,  
                  </p>
                  <p className="paragraph">
                  b.  the list of ingredients,  
                  </p>
                  <p className="paragraph">
                  c. the products registered number,
                  </p>
                  <p className="paragraph">
                  d. where applicable, the name and address of the person/entity responsible for the manufacture of the product.
                  </p>
                 </div>
                 <br/>
                 <p className="paragraph">
                 All sellers of cosmetics products which contain Cannabidiol (CBD)
                  shall disclose and seek the approval of Peppa before listing and selling CBD 
                  cosmetics. The sale of CBD cosmetics shall subject to the following:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                 a. The approval of the regulatory authorities.  
                  </p>
                  <p className="paragraph">
                  b. The listing’s disclosure of the required information including the details
                   of manufacturer(s) and the product ingredients and relevant dates.  
                  </p>
                  <p className="paragraph">
                  c. The extent of the products compliance with safety and applicable legal requirements.
                  </p>
                  <p className="paragraph">
                  d.  The compliance of the product with all applicable legal requirements.
                  </p>
                  <p className="paragraph">
                  e.	The product and listing shall provide warranties and shall be exclusive of claims which are either illegal or violate applicable regulation.
                  </p>
                 </div>

                 <p className="paragraph">
                 Each CBD product shall be listed in the dedicated Cosmetics category.
                  </p>
                  
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    9. Counterfeits
                  </li>
                  <p className="paragraph">
                  Counterfeit items are illegal and infringe on the moral and
                   economic rights of manufacturers and creators. 
                   All counterfeit items are prohibited from being sold on Peppa. 
                 Peppa may be informed of the listing of a counterfeit product via email at <u>info@peppa.io</u>

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>10. Currency</li>
                  <p className="paragraph">
                  Peppa permits financial transactions including transactions in physical and virtual currency, subject to the following per category;
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                   a. <span style={{fontWeight:"bold"}}>Bullion:</span> only original bullion are permitted for listing and sale on Peppa. 
                   All replica, counterfeit, or plated bars cannot be sold or listed for sale.
                    The sale of bullion shall comply with applicable government regulation and the 
                    listing include an extensive description of the bar which shall include details 
                    which reflect the originality of the bullion item being sold.  
                  </p>
                  <p className="paragraph">
                  b. <span style={{fontWeight:"bold"}}>Collectible currency:</span> The sale of currency, including notes and coins, from any country. for collection purposes is permitted, provided that the sale of such currency, in a single listing or multiple listings per transaction shall not exceed from any country shall not exceed £2000.    
                  </p>
                  </div>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    11. Credit and Debit Cards
                  </li>
                  <p className="paragraph">
                  The sale of credit cards, debit cards, and money orders is prohibited
                   on Peppa. However, inactive credit and debit cards which expired at least ten 
                   years before the proposed sale and which cannot be reactivated and reused in 
                   financial systems may be listed and sold. Peppa also permits the sale 
                   of blank plastic bank cards without magnetic strips or active chips. 

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    12.	Replica coins
                  </li>
                  <p className="paragraph">
                  Replica coins are not permitted for sale. Only one-sided replica paper currency
                   which is less than 75% or greater than 150% of the genuine currency may be listed
                    for sale. The listing of permissible currency shall include a photograph
                     of the replica paper currency and the inclusion of the word “replica” in
                      the title and description.
                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                    13. Stamps
                  </li>
                  <p className="paragraph">
                  The Company prohibits the listing and sale of counterfeit and replica stamps
                   on Peppa. The sale of counterfeit and replica stamp making equipment is also
                    prohibited. The sale of collectible and replica stamps is 
                    permitted on Peppa, subject to the following: 

                  </p>
                  <br />
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                   a. <span style={{fontWeight:"bold"}}> Collectible Stamps: </span> 
                   The listing must include a picture of the actual item and where applicable, 
                   disclose as alterations or flaws not seen in the photograph.
                  </p>
                  <p className="paragraph">
                  b. <span style={{fontWeight:"bold"}}>	Replica stamps:</span> The title, description, and photographs must show and state that the stamp is a replica.    
                  </p>
                  </div>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>
                  14.	Virtual Currency
                  </li>
                  <p className="paragraph">
                  The listing of and sale of virtual currency via Peppa is prohibited. 
                  Users are prohibited from selling or listing virtual currencies, virtual currency 
                  mining contracts, virtual currency paper wallets.Users may list and sell mining
                   hardware and wallets as well as collectible physical coins, subject to the 
                   collectible currency policy above.

                  </p>
                  <br />

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>15. Vouchers</li>
                  <p className="paragraph">
                  Peppa permits the sale of vouchers provided that a seller may list
                   up to £2000 not exceeding 20 vouchers per 30-day period, provided that 
                   vouchers to be listed shall be physical vouchers not copied, printed, 
                   or scanned vouchers.
                  </p>
                  <p className="paragraph">
                  Sellers shall not list:
                  </p>
                  <br/>
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                   a. Expired vouchers or vouchers which will expire before reaching the buyer.
                  </p>
                  <p className="paragraph">
                  b. Vouchers for recalled items.   
                  </p>
                  <p className="paragraph">
                  c. Vouchers which include other vouchers.   
                  </p>
                  </div>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>16.	Drugs and Drug Paraphernalia</li>
                  <p className="paragraph">
                  Peppa generally prohibits the sale of items which breach the 
                  provisions of the Misuse of Drugs Act 1971 and the Misuse of Drugs
                   Regulations 2001 and other applicable regulations including drugs, drug 
                   paraphernalia, and other items intended for drug use. Permitted sellers are 
                   allowed to sell CBD products which comply with applicable legal requirements.
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>17.	Electronic equipment</li>
                  <p className="paragraph">
                  Peppa implements restrictions on the sale of a wide range of electronic equipment, especially equipment used to transmit signals or form surveillance purposes. </p>
                  <p className="paragraph">
                 The following cannot be listed for sale on Peppa
                  </p>
                  <br/>
                  <div style={{ marginLeft: "20px", }}>
                   <p className="paragraph">
                   a.	Handheld telephone below 853MHz
                  </p>
                  <p className="paragraph">
                  b. Electricity or energy-saving devices.   
                  </p>
                  <p className="paragraph">
                  c.	Devices which interfere with traffic light controls.   
                  </p>
                  <p className="paragraph">
                  d. Electronic signal jamming devices.   
                  </p>
                  <p className="paragraph">
                  e. Digital decoders.   
                  </p>
                  <p className="paragraph">
                  f. Radar detectors.   
                  </p>
                  </div>
                  <p className="paragraph">
                  Cameras, recorders, and other similar devices may be listed as long as the listing expressly discourages their use for illicit purposes.    
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>18. Emission Control Devices</li>
                  <p className="paragraph">
                  Peppa prohibits the sale of goods or services which bypass interfere with or
                   disable emission control systems. This prohibition extends to items which may in 
                   combination with other products be used to cause such disruptions.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>19. Food</li>
                  <p className="paragraph">
                  Certain food products are prohibited on Peppa to safeguard
                   the health and safety of our users. When listing permitted food items,
                    sellers must adhere to the following policy:
                   
                  </p>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>a.	Prohibited Food Items</span>
                  </p>
                  <p style={{ marginLeft: "10px", }} className="paragraph">
                   The following food items and similar products are not allowed on Peppa:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  i.	Unpasteurized dairy products (except for cheese complying with government guidelines).   
                  </p>
                  <p className="paragraph">
                  ii.	Unpasteurized fruit or vegetable juice   
                  </p>
                  <p className="paragraph">
                  iii.	Wild mushrooms (except for morel and shiitake mushrooms).   
                  </p>
                  <p className="paragraph">
                  iv.	Ackee fruit or seeds.   
                  </p>
                  <p className="paragraph">
                  v.	Expired food   
                  </p>
                  <p className="paragraph">
                  vi.	Government assistance benefits that can be used for food.   
                  </p>
                  </div>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>b. CBD-Containing Food Products</span>
                  </p>
                  <p style={{ marginLeft: "10px", }} className="paragraph">
                  Permitted business sellers can sell products containing CBD,
                   but these items must adhere to specific legal requirements, including:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  i. Compliance with all applicable legal standards.   
                  </p>
                  <p className="paragraph">
                  ii.	Absence of health or other claims that may be illegal or violate regulations.  
                  </p>
                  <p className="paragraph">
                  iii.	Compliance with the Drugs and Drug Paraphernalia provisions of this Policy.   
                  </p>
                  <p className="paragraph">
                  iv.	Containing pure CBD only.   
                  </p>
                  </div>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>c. Authorization Requirements for CBD-Containing Food Products</span>
                  </p>
                  <p style={{ marginLeft: "10px", }} className="paragraph">
                  The CBD-containing food product must meet one of the following authorization criteria:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  i.	Authorized as a novel food by the Food Standards Agency (FSA).   
                  </p>
                  <p className="paragraph">
                  ii.	On the market by 13 February 2021 and under consideration for novel food
                   authorization by the FSA, evidenced by inclusion in the FSA's current list of CBD 
                   products linked to novel food applications or written confirmation of a timely application 
                   pending validation. 
                  </p>
                  </div>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>d.	Additional Requirements for CBD Food Products</span>
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  i.	Listed in the dedicated Food category..   
                  </p>
                  <p className="paragraph">
                  ii.	Shipped only to England and Wales.
                  </p>
                  </div>

                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>e.	Listing Requirements for Allowed Food Items</span>
                  </p>
                  <p style={{ marginLeft: "10px", }} className="paragraph">
                  When listing permitted food items, sellers must:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  i.	Ensure proper packaging and safe storage of food items  
                  </p>
                  <p className="paragraph">
                  ii.	Clearly state the expiration date in the item description.
                  </p>
                  <p className="paragraph">
                  iii.	Specify how perishable items will be delivered, ensuring delivery before the expiration date.  
                  </p>
                  </div>

                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>f.	Business Registration</span>
                  </p>
                  <p style={{ marginLeft: "10px", }} className="paragraph">
                  Before selling food items, sellers must register their business
                  with the UK local authority. By following these guidelines, sellers contribute
                  to a secure and transparent marketplace for food items on Peppa.
                  </p>

                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>20.	Funeral items</li>
                  <p className="paragraph">
                  Users are permitted to sell new funeral items such as caskets, gravestones headstones, urns, tombstones, which have never been used.
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>21.	Government Items</li>
                  <p className="paragraph">
                  Subject to applicable regulation, the Company prohibits the listing of
                   all government issued documents and items including identity cards, uniforms, 
                   numberplates, licenses and permits. 
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>22.	Hazardous Materials</li>
                  <p className="paragraph">
                  The Company prohibits the sale of hazardous material 
                  including but not limited to poisons and radioactive materials on Peppa.
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>23.	Human parts</li>
                  <p className="paragraph">
                  Save for items which contain human scalp hair such as wigs, 
                  the sale of human body parts and items which wholly or partly consist 
                  of human body parts are not permitted for sale on Peppa.
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>24.	Intangibles</li>
                  <p className="paragraph">
                  Peppa prohibits the sale of items or products which a buyer cannot
                   confirm receipt of. Prohibited items under this category include ghosts, 
                   souls, destinies, spells, haunted items, and all related items.
                   
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>25.	Jewelry</li>
                  <p className="paragraph">
                  Seller of jewelry shall have the obligation to disclose specific
                   details of their items including but not limited to sourcing 
                   (naturally occurring or lab-created), quality, categories, and other
                    information which may inform the purchasing decision of buyers.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>26.	Knives</li>
                  <p className="paragraph">
                  Only kitchen knives, cutlery, general multi-purpose
                   camping knives and silverware may be sold on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>27.	Lockpicks</li>
                  <p className="paragraph">
                  The sale of lockpicking and locksmithing devices is prohibited on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>28.	Medical Devices</li>
                  <p className="paragraph">
                  All medical items to be listed on Peppa must be registered with the
                   relevant medical authorities and must prior to their listing, fulfil relevant
                    provisions of the applicable regulation.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>29.	Military and Police Items</li>
                  <p className="paragraph">
                  The sale of military and police items such as emergency response uniforms, 
                  equipment and accessories cannot be listed on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>30.	Perfumes</li>
                  <p className="paragraph">
                  Peppa prohibits the sale of testers, samples, counterfeits,
                   imposter perfumes and decanters. All other perfumes, including 
                   home-made perfumes, and accessories may be sold provided that new perfumes 
                   shall be sold in original packaging which shall disclose the manufacturing 
                   information, the list of ingredients, and all other details relevant to 
                   the use and preservation of the product.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>31.	Personal Information</li>
                  <p className="paragraph">
                  At Peppa, we prioritize the safety and privacy of our users. Therefore,
                   the sale of certain items is strictly prohibited. The following items are not
                    permitted for sale:
                  </p>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>a.	Personal Contact Information</span>
                  </p>
                  <p style={{ marginLeft: "20px", }} className="paragraph">
                  Items containing personal contact information, 
                  including phone numbers, email lists, and addresses, are strictly prohibited.
                  </p>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>b.	Sensitive Information</span>
                  </p>
                  <p style={{ marginLeft: "20px", }} className="paragraph">
                  Sale of sensitive information such as social security numbers
                   or similar confidential details is not permitted.
                  </p>
                  <p className="paragraph">
                  <span style={{fontWeight:"bold"}}>c.	Accounts Requiring Passwords</span>
                  </p>
                  <p style={{ marginLeft: "20px", }} className="paragraph">
                  Selling accounts that require users to provide passwords,
                   such as access to the next level of social media games, is prohibited.
                  </p>

                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>32.	Social Media Items</li>
                  <p className="paragraph">
                  Items related to social media, including but not 
                  limited to "accounts," "likes," "followers," or "subscribers," 
                  cannot be sold on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>33.	Unsolicited Contact Software</li>
                  <p className="paragraph">
                  The sale of software or tools designed to send unsolicited contacts,
                   such as spam via text messages or email, or to harvest contact details, 
                   is not allowed.
                  </p>
                  <p className="paragraph">
                  By implementing these policies, Peppa aims to create a secure
                   and private marketplace, ensuring a positive and trustworthy 
                   experience for all users. If you have any questions or concerns
                    about these policies, kindly contact Peppa customer support for assistance.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>34.	Pesticides and Fertilizers</li>
                  <p className="paragraph">
                  The sale of pesticides on Peppa is regulated by the relevant regulation in 
                  the United Kingdom.The following pesticides are not permitted for sale on Peppa:
                  </p>
                  <div style={{ marginLeft: "20px", }}>
                  <p className="paragraph">
                  a. Unregistered, unbranded, misbranded or pesticides restricted by regulation from general use.   
                  </p>
                  <p className="paragraph">
                  b.	Pesticides outlawed by federal or state regulation.
                  </p>
                  <p className="paragraph">
                  c.	Repackaged pesticides.   
                  </p>
                  <p className="paragraph">
                d.	Pesticides ineligible for shipment through standard shipping providers.
                  </p>
                  </div>
                  <p className="paragraph">
                  Sellers of pesticides are required to procure relevant
                   permits and licenses and comply with regulatory provisions for
                    the production, sale, storage and shipping ahead of the listing
                     of their products on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>35.	Plants</li>
                  <p className="paragraph">
                  The sale of seeds and other plant products shall be subject to applicable government regulation.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>36.	Price gouging</li>
                  <p className="paragraph">
                  The listing of items for sale at a price higher than is fair or
                   reasonable is not permitted on Peppa. Sellers shall not inflate the price
                    of their goods in response to an emergency. Peppa reserves the right to grant 
                    the right to sell certain items to authorised dealers listed on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>37.	Property</li>
                  <p className="paragraph">
                  Property may be listed for sale on Peppa.
                   However, intenders purchasers should note that the listing of 
                   a property is not a binding offer to sell and each transaction is
                    subject to the applicable real estate laws.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>38.	Prescription Drugs</li>
                  <p className="paragraph">
                  The Company prohibits the listing of prescription drugs 
                  and other over the counter-medical products which may contain prescription 
                  strength medication.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>39.	Single-Use Plastics</li>
                  <p className="paragraph">
                  The Company has a commitment to environmental sustainability
                   and the reduction of adverse climate change. The listing of single
                    use plastics is prohibited on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>40.	Tickets and Lotteries</li>
                  <p className="paragraph">
                  Peppa prohibits the listing of all contests and transactions,
                   such as raffles draws, sweepstakes and drawings, which create the 
                   opportunity to win items by chance or contest.
                  </p>
                  <p className="paragraph">
                  Generally, event tickets cannot be listed for sale on Peppa. 
                  However, users may list tickets to cinema, amusement or theme parks
                   for sale, provided that listing shall disclose the face value of new and 
                   resold tickets.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>41.	Titles</li>
                  <p className="paragraph">
                  Subject to the provisions of applicable regulation, the sale of national honours and titles is prohibited on Peppa.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>42.	Tobacco items</li>
                  <p className="paragraph">
                  The sale of items containing tobacco and nicotine, 
                  including electronic cigarettes is prohibited on Peppa.
                  </p>
                  <br/>

                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>43.	Travel</li>
                  <p className="paragraph">
                  Travel items such as tickets and other related items and services, subject
                   to the other terms of this policy, may be listed for sale, provided that
                    the good and services to be provided comply with relevant provisions of
                     applicable regulation.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>44.	Used Clothing</li>
                  <p className="paragraph">
                  The sale of used clothing is permitted provided
                   that the following items regardless of their sanitary condition
                    shall not be sold on Peppa-boxers and boxer shorts, brassiere, 
                    briefs, lingerie, nappies, panties, singlets, and other related items.
                  </p>
                  <p className="paragraph">
                  The sellers of used clothing items permitted
                   for sale shall include a statement to the effect that the 
                   listed item has been cleaned prior to sale, is stain-free, 
                   and in good condition.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>45.	Software</li>
                  <p className="paragraph">
                  Peppa prohibits the sale of beta software, 
                  including test keys, cards, or access codes. All original equipment
                   manufacturer (OEM) must be sold with the original hardware and 
                   not separately. All OEM software listed for sale on Peppa must include
                    the original hardware of the manufacture or the utility software of such OEM. 
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>46.	Stocks and Securities</li>
                  <p className="paragraph">
                  The Company generally prohibits the sale of stocks,
                   investments, and other security interests on Peppa. 
                   Non-transferable single-share stock and collectible stock
                   certificates may be listed for sale on Peppa, provided that
                   the sale price of such items shall be at least twice the trading 
                    price of the security.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>47.	Stolen Property</li>
                  <p className="paragraph">
                  The listing and sale of stolen property is not permitted on Peppa. 
                  Users are encouraged to report listing or sale of stolen property 
                  transactions to Peppa via email to <u>[notifications@peppa.io]</u> 
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>48.	Vehicles</li>
                  <p className="paragraph">
                  Peppa prohibits the sale of vehicles and vehicles
                   accessories which breach applicable government regulation.
                  </p>
                  <br/>
                  <li className="heading" style={{ marginTop: "8px", fontWeight: "bold" }}>49.	Violence and Crimes</li>
                  <p className="paragraph">
                  Products which are associated with crimes, individuals notable for committing violent acts, and items which promote violence or acts of violence shall not be listed on Peppa.
                  </p>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction/>
      <Footer />
    </div>
  );
};

export default ProductPolicy;
// export default withWelcomeLayout(PrivacyPage, config);
