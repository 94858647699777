import React from "react";
import styles from "./similarstore.module.css";
import storelogo from "../../buyer-assets/img/storelogo.png";
import { Link } from "react-router-dom";

const SimilarStores = ({ stores }) => {
  console.log({ stores });
  return (
    <section className="container mt-auto">
      <h2 className={styles.headerText}>Related Stores</h2>
      <main className={styles.storeContainer}>
        {stores.length==0 ? (
          <p>No similar store</p>
        ) : (
          <>
            {stores.map((store) => (
              <Link
                to={`/store/${store?.merchant_code}`}
                key={store?.merchant_id}
              >
                <div className={styles.store}>
                  <img
                    src={store.store_logo ? store.store_logo : storelogo}
                    alt="store Logo"
                    className={styles.storeLogo}
                  />
                  <h4 className={styles.storeName}>{store?.store_name}</h4>
                </div>
              </Link>
            ))}
          </>
        )}
      </main>
    </section>
  );
};

export default SimilarStores;
