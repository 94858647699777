import React from 'react'

const EyeCloseIcon = ({classNames, hasError, onClick}) => (
    <svg 
    className={`${classNames} ${hasError ? 'error__message' : ''}`}
    onClick={() => { onClick(); }}
    width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1_2372)">
<path d="M18.1925 14.7334C18.3999 14.1792 18.5051 13.5919 18.503 13.0001C18.503 11.6861 17.981 10.4259 17.0519 9.49678C16.1228 8.56764 14.8626 8.04565 13.5486 8.04565C12.9641 8.04634 12.3844 8.15151 11.8369 8.35621L12.9997 9.5551C13.1764 9.52682 13.3551 9.51233 13.5341 9.51177C14.4632 9.50983 15.3553 9.87558 16.0157 10.5292C16.676 11.1827 17.051 12.071 17.0586 13.0001C17.058 13.1791 17.0435 13.3578 17.0152 13.5345L18.1925 14.7334Z" 
fill={hasError?'#b00020':"#18191F"}/>
<path d="M24.7647 12.6605C22.3308 8.16101 18.0625 5.43823 13.3391 5.43823C12.0531 5.44125 10.7757 5.64846 9.55469 6.05212L10.7175 7.22212C11.5739 7.00078 12.4546 6.88676 13.3391 6.88268C17.4052 6.88268 21.103 9.15045 23.3058 12.971C22.4977 14.3884 21.4265 15.6386 20.1497 16.6543L21.1752 17.6799C22.653 16.4878 23.8802 15.015 24.7864 13.3466L24.9741 12.9999L24.7647 12.6605Z" 
fill={hasError?'#b00020':"#18191F"}/>
<path d="M3.51708 4.1745L6.73819 7.39561C4.70219 8.70652 3.03818 10.5198 1.90653 12.6606L1.71875 13.0001L1.90653 13.3467C4.34042 17.8462 8.60875 20.5689 13.3321 20.5689C15.1757 20.5686 16.9953 20.1513 18.6549 19.3484L22.266 22.9595L23.5299 21.8762L4.75208 3.09839L3.51708 4.1745ZM10.5587 11.2162L15.3615 16.0189C14.8189 16.3548 14.1942 16.5348 13.556 16.5389C13.0923 16.539 12.6331 16.4473 12.205 16.2692C11.7768 16.091 11.3881 15.83 11.0612 15.5011C10.7343 15.1722 10.4757 14.7819 10.3002 14.3527C10.1248 13.9235 10.0359 13.4638 10.0387 13.0001C10.0469 12.3692 10.2267 11.7525 10.5587 11.2162ZM9.51153 10.1689C8.83584 11.1218 8.51914 12.2831 8.6175 13.4471C8.71586 14.6111 9.22293 15.7028 10.0489 16.5288C10.8749 17.3548 11.9666 17.8618 13.1306 17.9602C14.2946 18.0586 15.4558 17.7419 16.4087 17.0662L17.5643 18.2217C16.2266 18.7935 14.7869 19.0884 13.3321 19.0884C9.26597 19.0884 5.56819 16.8206 3.36542 13.0001C4.42254 11.1282 5.94499 9.56098 7.78542 8.45006L9.51153 10.1689Z" 
fill={hasError?'#b00020':"#18191F"}/>
</g>
<defs>
<clipPath id="clip0_1_2372">
<rect width="26" height="26" fill="white"/>
</clipPath>
</defs>
</svg>

)

export default EyeCloseIcon