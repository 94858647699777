import React from "react";
import ModalComponent from "../ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCouponCode,
  deleteProductDiscount,
} from "store/actions/products";
import { Loader } from "components/blocks";

const DeleteCoupon = ({ coupon, setDeleteModal, deleteModal, isMultiple }) => {
  const { deletingCoupon } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const deleteCoupon = () => {
    dispatch(
      deleteCouponCode({
        couponID: coupon?.couponID,
      })
    );
  };
  return (
    <ModalComponent show={deleteModal} centered size="md">
      <div className="modal__component">
        <div className="modal__title">Delete Coupon?</div>
        <div className="modal__content">
          <p>
            Are you sure you want to delete these coupon:{" "}
            <span className="fw-bold">{coupon?.code}</span>?{" "}
          </p>
          <div className="modal__control">
            <button
              className="button button--purple-outline px-4"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="button button--purple px-4"
              onClick={deleteCoupon}
              disabled={deletingCoupon}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteCoupon;
