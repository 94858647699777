import React, { useState, useEffect } from "react";
import ChatContact from "components/dashboardComponent/ChatContact";
import ChatMessageBox from "components/dashboardComponent/ChatMessageBox";
import { SearchBar, SearchBox } from "components/dashboardComponent/SearchBar";
import { LoaderProcessing } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyChats,
  getChatMessages
} from "store/actions/messaging";

const Messaging = () => {

  const { messageSent, sendMessageError, sendingMessage } = useSelector(
    (state) => state.messaging
  );
  
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getMyChats());
  },[messageSent])
 
  const {
    chats,
    loadingMyChats
  } = useSelector((state) => state.messaging);


 
  const messages = chats?.data
  const userChatData = chats?.data[activeIndex]
  
    
  return (
    
      <main className="messaging">
      {loadingMyChats ? 
     <div className="loader__container">
     <LoaderProcessing />
   </div>
    :
    <>
      <div className="title__wrapper">
        <h2 className="page__title">Messaging</h2>
      </div>

      <section className="chat__wrapper">
        <div className="contact__container">
          <div className="header">
            <h3 className="title">Contacts</h3>
            <p className="chat__number">{messages?.length}</p>
          </div>
          <div className="search__wrapper">
            <SearchBox placeholder="Search" boxBorder={true} />
          </div>
          <ChatContact messages={messages} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
        </div>
        <div className="chat__container">
          <ChatMessageBox userdata={userChatData} />
        </div>
      </section>
      </>
}
      </main>
    
  
  
   
    
  );
};

export default Messaging;


