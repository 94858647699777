import React from "react";

const EmailInput = ({
  type,
  register,
  error,
  label,
  id,
  options,
  placeHolder,
  require,
  ...inputProps
}) => {
  return (
    <>
      {type == "textarea" ? (
        <div className="message__box">
          <textarea rows={10} placeholder={placeHolder}></textarea>
        </div>
      ) : (
        <div className="email__input">
          <label>{label}</label>
          <input type={type} />
        </div>
      )}
    </>
  );
};

export default EmailInput;
