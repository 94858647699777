import React from 'react';

const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
  >
    <path
      fill="#C4C7CC"
      d="M11.667 6h-1V4.667C10.667 2.093 8.573 0 6 0S1.333 2.093 1.333 4.667V6h-1C.15 6 0 6.15 0 6.333v8.334C0 15.402.598 16 1.333 16h9.334c.735 0 1.333-.598 1.333-1.333V6.333C12 6.15 11.85 6 11.667 6zm-4.669 6.963c.01.094-.02.189-.083.26-.063.07-.154.11-.248.11H5.333c-.094 0-.185-.04-.248-.11-.063-.071-.093-.166-.083-.26l.21-1.89c-.341-.25-.545-.642-.545-1.073 0-.735.598-1.333 1.333-1.333S7.333 9.265 7.333 10c0 .431-.204.824-.545 1.072l.21 1.891zM8.667 6H3.333V4.667C3.333 3.197 4.53 2 6 2c1.47 0 2.667 1.196 2.667 2.667V6z"
    />
  </svg>
);

export default LockIcon;
